import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import download from '@/assets/download.svg?url';
import downloadError from '@/assets/downloaderror.svg?url';
import getToken from '@/utils/token';
import Modal from './Modal';

const prefixUrl = import.meta.env.VITE_GATEWAY_URL;

const FileDownloadModal = () => {
  const token = getToken();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showModal, setShowModal] = useState<boolean>(false);

  // consolidated invoice download URL: usercast + '/api/c2fo/taker/invoices/eligible/csv?jobUuid'
  // consolidated invoice download params
  const downloadConsolidatedInvoice = searchParams.get('downloadConsolidatedInvoice');

  // consolidated history downloadURL: usercast + '/api/c2fo/taker/consolidated-history/award/archive?startDate&endDate&makerIds'
  // consolidated history download params
  const downloadConsolidatedTakerAward = searchParams.get('downloadConsolidatedTakerAward');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const makerIds = searchParams.getAll('makerIds');

  const currentDate = new Date();
  const expiration = new Date(searchParams.get('urlExpirationDate') ?? currentDate);
  const isUrlExpired = expiration < currentDate;

  useEffect(() => {
    setShowModal(!!downloadConsolidatedInvoice || !!downloadConsolidatedTakerAward);
  }, [downloadConsolidatedInvoice, downloadConsolidatedTakerAward]);

  let downloadUrl;
  let onFinish;

  if (downloadConsolidatedInvoice) {
    const params = qs.stringify({
      jobUuid: downloadConsolidatedInvoice,
      token,
    });

    downloadUrl = `${prefixUrl}/usercast/api/c2fo/taker/invoices/eligible/csv?${params}`;
    onFinish = () => {
      setShowModal(false);
      navigate('/supplier/markets');
    };
  } else if (downloadConsolidatedTakerAward) {
    const params = qs.stringify(
      {
        startDate,
        endDate,
        makerIds,
        token,
      },
      { indices: false }
    );

    downloadUrl = `${prefixUrl}/usercast/api/c2fo/taker/consolidated-history/award/archive?${params}`;
    onFinish = () => {
      // clean up the params to remove token and non-URL pretty characters
      const cancelParams = qs.stringify(
        {
          startDate,
          endDate,
          makerIds,
        },
        { indices: false }
      );

      setShowModal(false);
      navigate(`/supplier/history?${cancelParams}`);
    };
  }

  return downloadUrl && !isUrlExpired ? (
    <Modal
      okLink={downloadUrl}
      okText={t('core.download')}
      onCancel={onFinish}
      onClose={onFinish}
      onOk={onFinish}
      open={showModal}
      title={t('core.authenticatedDownload.title')}
    >
      <div className="flex w-full justify-center">
        <img src={download} alt="download" className="h-16 w-16" />
      </div>
      <div>{t('core.authenticatedDownload.description')}</div>
    </Modal>
  ) : isUrlExpired ? (
    <Modal
      onOk={onFinish}
      onClose={onFinish}
      open={showModal}
      okButtonVariant="primary"
      title={t('core.authenticatedDownload.expiredTitle')}
    >
      <div className="flex w-full justify-center">
        <img src={downloadError} alt="download error" className="h-16 w-16" />
      </div>
      <div>{t('core.authenticatedDownload.expired')}</div>
    </Modal>
  ) : null;
};

export default FileDownloadModal;

import { Suspense, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Button, Modal, Select } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { TimesIcon } from '@c2fo/liquidity/icons';
import { Agreement } from '@/generated/gql/graphql';
import { AgreementWithContent, agreementsByCmsId, useAgreementContent } from '@/data/useAgreements';
import Spinner from './Spinner';

interface AdditionalAgreementsModalProps {
  agreements: Agreement[];
  open: boolean;
  onClose: () => void;
}

const AdditionalAgreementsModalContent = ({ agreements, open }: AdditionalAgreementsModalProps) => {
  // Organizes agreements by CMS ID since multiple markets can share the same agreement
  const filteredAgreements = agreementsByCmsId(agreements);

  // Fetches the agreement content for each agreement's CMS ID
  const agreementContents = useAgreementContent(
    filteredAgreements.map((agreement) => agreement.cmsId),
    open
  );

  // Combine the agreement metadata with the agreement content
  const agreementsWithContent: AgreementWithContent[] = agreementContents.map(({ data: agreementContent }) => {
    const agreement = filteredAgreements.find((agreement) => agreement.cmsId === agreementContent?.cmsId);

    return {
      cmsId: agreement?.cmsId ?? '',
      marketId: agreement?.marketIds[0] ?? '',
      id: agreement?.agreementIds[0] ?? '',
      title: agreementContent?.title ?? '',
      copy: agreementContent?.copy ?? '',
      updatedDate: agreementContent?.updatedDate ?? '',
      updatedLabel: agreementContent?.updatedLabel ?? '',
    };
  });
  const [displayedAgreementIndex, setDisplayedAgreementIndex] = useState<number>(0);
  const displayedAgreement: AgreementWithContent | undefined = agreementsWithContent[displayedAgreementIndex];

  const showMultipleAgreements = agreementsWithContent.length > 1;

  return (
    <>
      {showMultipleAgreements && displayedAgreement && (
        <div className="m-auto pb-4 sm:w-3/4">
          <div className="w-full sm:w-3/4 md:w-3/5">
            <Select
              items={agreementsWithContent.map((agreement, index) => {
                return {
                  key: agreement.id[0],
                  label: `${agreement.title} (${t('core.countOfTotal', {
                    count: index + 1,
                    total: agreementsWithContent.length,
                  })})`,
                  value: agreement.cmsId,
                };
              })}
              value={displayedAgreement.cmsId}
              onChange={(value) =>
                setDisplayedAgreementIndex(
                  agreementsWithContent.findIndex((agreement) => agreement.cmsId === value) ?? 0
                )
              }
            />
          </div>
        </div>
      )}
      <div className="max-h-[100%] overflow-auto">
        <div className="m-auto  sm:w-3/4" dangerouslySetInnerHTML={{ __html: displayedAgreement?.copy ?? '' }} />
      </div>
    </>
  );
};

const AdditionalAgreementsModal = (props: AdditionalAgreementsModalProps) => {
  useEffect(() => {
    const qualtricsButton = document.getElementById('QSIFeedbackButton-btn');

    if (qualtricsButton) {
      qualtricsButton.style.visibility = props.open ? 'hidden' : 'visible';
    }
  }, [props.open]);

  return (
    <>
      <Modal fullScreen open={props.open} onClose={props.onClose}>
        <div className="relative flex h-[100%] flex-col px-4 sm:px-0">
          <div className="py-6 sm:pl-24">
            <h3 className="m-0 text-start text-2xl font-medium">{t('agreements.title')}</h3>
          </div>

          <div className="flex-1 overflow-hidden">
            <Suspense
              fallback={
                <div className="flex h-full w-full items-center justify-center">
                  <Spinner className="h-12 w-12 fill-primary-500" />
                </div>
              }
            >
              <AdditionalAgreementsModalContent {...props} />
            </Suspense>
          </div>

          <div className="flex justify-center border-t">
            <div className="w-full p-6 md:w-1/3 md:px-0">
              <Button onClick={props.onClose} variant="primary" full>
                {t('agreements.close')}
              </Button>
            </div>
          </div>

          <div className="absolute right-1 top-1">
            <button
              className="m-1 rounded-full p-1 text-xl transition-all duration-200 hover:bg-gray-100"
              onClick={props.onClose}
              type="button"
            >
              <TimesIcon className="h-6 w-6" fill={colors.gray[800]} />
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdditionalAgreementsModal;

import { useTranslation } from 'react-i18next';
import download from '@/assets/download.svg?url';
import Modal from '@/components/Modal';
import { useReporting } from '@/reporting';
import { useToken } from '@/utils/token';

export interface CreditNoteModalProps {
  open: boolean;
  onClose: () => void;
  takerId: number;
  marketId: string;
  creditNoteId?: string | null;
}

const prefixUrl = import.meta.env.VITE_GATEWAY_URL;

export default function CreditNoteModal({ open, onClose, takerId, marketId, creditNoteId }: CreditNoteModalProps) {
  const { t } = useTranslation();
  const { token } = useToken();
  const { track } = useReporting();

  const downloadUrl = `${prefixUrl}/api/c2fo/taker/${takerId}/market/${marketId}/retrieve-award-file/${creditNoteId}?token=${token}`;

  return (
    <Modal
      okLink={downloadUrl}
      okText={t('core.download')}
      onOk={() => track('history-credit-note-request::submitted')}
      onCancel={onClose}
      onClose={onClose}
      open={open}
      title={t('core.authenticatedDownload.title')}
    >
      <div className="flex w-full justify-center">
        <img src={download} alt="download" className="h-16 w-16" />
      </div>
      <div>{t('core.authenticatedDownload.description')}</div>
    </Modal>
  );
}

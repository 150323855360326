import { useCallback } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Popover } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CalendarIcon, ClockIcon, QuestionCircleIcon } from '@c2fo/liquidity/icons';
import LinkTabs from '@/components/LinkTabs';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';
import NextMarketClearTimeCountdown from '@/features/takerMarketTable/components/NextMarketClearTimeCountdown';
import getTakerMarketName from '@/utils/getTakerMarketName';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import { MarketLayoutError } from './MarketLayoutError';

export interface MarketLayoutOutletContext {
  readonly takerMarket: TakerMarket;
  readonly urlParams: {
    marketId: string;
    takerId: number;
  };
}

export function useMarketLayoutOutletContext() {
  return useOutletContext<MarketLayoutOutletContext>();
}

export default function MarketLayout() {
  const { t } = useTranslation();
  const params = useParams<{ marketId: string; takerId: string }>();
  const { marketId = '', takerId: takerIdParam = '' } = params;
  const takerId = parseInt(takerIdParam);
  const { data: takerMarket } = useTakerMarkets(
    useCallback(
      (takerMarkets: TakerMarket[]) => {
        if (isNaN(takerId)) {
          return;
        }

        return takerMarkets.find((tm) => tm.marketId === marketId && tm.takerDivisionId === takerId);
      },
      [marketId, takerId]
    )
  );

  if (!marketId || !takerId || !takerMarket) {
    return <MarketLayoutError marketId={marketId} takerId={takerId} />;
  }

  return (
    <div className="-mt-10">
      <div className="fixed left-0 z-[9] w-full border-b bg-white">
        <div className="mx-auto max-w-screen-2xl px-4 lg:px-6">
          <LinkTabs
            items={[
              {
                key: 'invoices',
                label: t('maker.invoices'),
                link: 'invoices/eligible',
                matcher: 'invoices',
              },
              {
                key: 'history',
                label: t('core.history'),
                link: 'history',
                matcher: 'history',
              },
            ]}
            variant="secondary"
          />
        </div>
      </div>
      <div className="pt-24">
        {/* heading */}
        <div className="flex flex-col justify-between gap-2 pb-8 lg:flex-row lg:items-center">
          <div>
            <h1 className="m-0 pb-2 text-4xl">{getTakerMarketName(takerMarket)}</h1>
            <div className="font-medium">{`${takerMarket.takerDivisionName} (${takerMarket.currency})`}</div>
          </div>
          <div className="flex flex-col lg:flex-row lg:gap-0 lg:divide-x lg:divide-gray-300">
            <div className="flex items-center lg:pr-2">
              <ClockIcon className="mr-2" />
              <NextMarketClearTimeCountdown nextClearTime={takerMarket.marketNextClearTime} />
              <span className="ml-1">{t('core.toMarketClear')}</span>
            </div>
            <div className="lg:pl-2">
              <Popover>
                <Popover.Trigger className="flex items-center gap-1">
                  <span className="mr-1">
                    <CalendarIcon className="h-6 w-6" fill={colors.primary[500]} />
                  </span>
                  <span>{`${t('taker.newPayDate')}:`}</span>
                  <span>{trimDateTimestamp(takerMarket.marketPayDate)}</span>
                  <QuestionCircleIcon className="ml-1" fill={colors.gray[700]} />
                </Popover.Trigger>
                <Popover.Content arrow side="left">
                  <div className="w-72 space-y-2 text-sm">
                    <div className="font-medium">{t('taker.nextPayDatePopover.header')}</div>
                    <div>{t('taker.nextPayDatePopover.content')}</div>
                  </div>
                </Popover.Content>
              </Popover>
            </div>
          </div>
        </div>
        <Outlet
          context={
            {
              takerMarket,
              urlParams: { marketId, takerId },
            } satisfies MarketLayoutOutletContext
          }
        />
      </div>
    </div>
  );
}

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { PreferredOfferInput } from '@/generated/gql/graphql';
import useTakerMarkets, { OfferConfig, TakerMarket } from '@/data/useTakerMarkets';
import { gqlClient } from '@/lib/gqlClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';

type ExtendedPreferredOfferInput = PreferredOfferInput & {
  setOfferConfigQueryData?: Partial<OfferConfig> & { disableAfterMarketClearsDate: string };
};

export const SUBMIT_PREFERRED_OFFER = graphql(`
  mutation SubmitPreferredOffer($offer: PreferredOfferInput!) {
    submitPreferredOffer(offer: $offer)
  }
`);

const useSubmitPreferredOffer = () => {
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();
  const { subscribeToMarketStats } = useServerSideEventListeners();

  return useMutation({
    mutationKey: ['submitPreferredOffer'],
    mutationFn: ({ draftId, marketUuid, supplierDivisionUuids }: ExtendedPreferredOfferInput) => {
      const offer: PreferredOfferInput = {
        draftId,
        marketUuid,
        supplierDivisionUuids,
      };

      return gqlClient.request(SUBMIT_PREFERRED_OFFER, { offer });
    },
    onSuccess: (_data, variables) => {
      const effectedTakersMarkets = takerMarkets?.filter(
        (meta) =>
          meta.marketUuid === variables.marketUuid && variables.supplierDivisionUuids.includes(meta.takerDivisionUuid)
      );

      if (effectedTakersMarkets && effectedTakersMarkets.length) {
        effectedTakersMarkets.forEach((effectedTm) => {
          // update the cache with the returned data
          queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) => {
            return (prevTakerMarkets ?? []).map((prevTm) => {
              const isMatch =
                prevTm.marketUuid === effectedTm.marketUuid &&
                prevTm.takerDivisionUuid === effectedTm.takerDivisionUuid;

              if (!isMatch) {
                return prevTm;
              }

              let offerConfig: OfferConfig = {
                ...prevTm.offerConfig,
                maxApr: null,
                maxDiscount: null,
                isEnabled: true,
                offerType: 'PREFERRED_TERM',
              };

              if (variables.setOfferConfigQueryData) {
                offerConfig = {
                  ...offerConfig,
                  ...variables.setOfferConfigQueryData,
                };
              }

              return {
                ...prevTm,
                offerConfig,
                // Set the "Term End Date" before stats are updated
                ...(variables.setOfferConfigQueryData?.disableAfterMarketClearsDate && {
                  disableAfterMarketClearsDate: variables.setOfferConfigQueryData?.disableAfterMarketClearsDate,
                }),
              };
            });
          });

          // subscribe to market stats
          // this will refetch stats queries after we know stats have updated
          subscribeToMarketStats({
            marketUuid: effectedTm.marketUuid,
            takerId: effectedTm.takerDivisionId,
          });
        });
      }
    },
  });
};

export default useSubmitPreferredOffer;

import { useMutation } from '@tanstack/react-query';
import qs from 'qs';
import apiClient from '@/lib/apiClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { EligibleInvoiceFetchFilters, buildEligibleInvoiceFetchFilter } from './useEligibleInvoices';

// PUT exclude = https://fss-gateway.c2fo.com/api/c2fo/taker/3563872/market/b578662a-6125-4bb7-88cd-87835199ca57/invoices/update
// payload: {exclude: true}

// PUT include - https://fss-gateway.c2fo.com/api/c2fo/taker/3563872/market/b578662a-6125-4bb7-88cd-87835199ca57/invoices/update?ignoreFullyAdjustedInvoices=true
// payload: {exclude: false}

export interface BulkInvoiceParams {
  exclude: boolean;
  takerId: number;
  marketUuid: string;
  filters?: EligibleInvoiceFetchFilters;
}

const useBulkInvoices = () => {
  const { subscribeToMarketStats } = useServerSideEventListeners();

  return useMutation({
    mutationKey: ['bulkInvoices'],
    mutationFn: ({ takerId, marketUuid, exclude, filters }: BulkInvoiceParams) => {
      const params = qs.stringify({
        ...(exclude && { ignoreInvoicesCoveringAdjustments: true }),
        ...(!exclude && { ignoreFullyAdjustedInvoices: true }),
        ...(filters && buildEligibleInvoiceFetchFilter(filters)),
      });
      const url = `api/c2fo/taker/${takerId}/market/${marketUuid}/invoices/update?${params}`;
      return apiClient.put(url, { json: { exclude } }).json();
    },
    onSuccess: (_data, variables) => {
      subscribeToMarketStats({
        marketUuid: variables.marketUuid,
        takerId: variables.takerId,
      });
    },
  });
};

export default useBulkInvoices;

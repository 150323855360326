import { useTranslation } from 'react-i18next';
import { cn } from '@c2fo/liquidity';
import { getEnvName, isProd } from '@/utils/env';
import { getTokenContent } from '@/utils/token';

export const ImpersonationBanner: React.FC = () => {
  const { t } = useTranslation();
  const content = getTokenContent();
  const isProdEnv = isProd();
  const envName = getEnvName().toUpperCase();
  const impersonator = content?.payload.user.emailAddress || '';
  const subject = content?.payload.impersonationSubject?.emailAddress || '';
  const impersonationMessage = t('impersonation.bannerMessage', { impersonator, subject });

  return (
    <div
      className={cn('fixed top-0 z-[10] w-full bg-warning-400 font-medium text-text-primary', {
        'bg-red-800 text-white': isProdEnv,
      })}
    >
      <div className="flex h-10 items-center justify-between gap-4 px-4">
        <div>{envName}</div>
        <div aria-description={impersonationMessage} className="min-w-0 truncate" title={impersonationMessage}>
          {impersonationMessage}
        </div>
        <div>{envName}</div>
      </div>
    </div>
  );
};

import { useMutation } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

// creditNoteByClear: '/api/c2fo/taker/:takerId/market/:marketId/clear/:payDate/credit-note',

export interface CreditNoteByClearParams {
  takerId: number;
  marketId: string;
  payDate: string;
}

const useCreditNoteByClear = () => {
  return useMutation({
    mutationKey: ['creditNoteByClear'],
    mutationFn: ({ takerId, marketId, payDate }: CreditNoteByClearParams) => {
      return apiClient.post(`api/c2fo/taker/${takerId}/market/${marketId}/clear/${payDate}/credit-note`);
    },
  });
};

export default useCreditNoteByClear;

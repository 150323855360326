import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Chip, Popover } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { PlusIcon } from '@c2fo/liquidity/icons';

interface SetRuleCriteriaProps {
  title?: string;
  description?: string;
}

// I anticipate passing in a way to identify the division and rule type
// So I can open the Rule Criteria popup with the correct information
const SetRuleCriteria = ({ title, description }: SetRuleCriteriaProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return title && description ? (
    <Popover open={open}>
      <Popover.Trigger
        name="edit-rule"
        className="w-full px-4"
        onClick={(e) => {
          e.preventDefault();
          // Open Rule Criteria popup
        }}
      >
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Chip variant="outlined" label={title} className="m-auto" />
        </div>
      </Popover.Trigger>
      <Popover.Content arrow>
        <div>{description}</div>
      </Popover.Content>
    </Popover>
  ) : (
    <div className="flex items-center justify-center">
      {/* Open Rule Criteria popup on click */}
      <Button variant="ancillary" size="sm" icon={<PlusIcon fill={colors.secondary[500]} />}>
        <div className="text-deepBlue-500">{t('core.create')}</div>
      </Button>
    </div>
  );
};

export default SetRuleCriteria;

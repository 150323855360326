import { useTranslation } from 'react-i18next';
import invoices from '@/assets/invoicesgrey.svg?url';
import { useInvoiceLayoutOutletContext } from '@/components/InvoiceLayout';
import NoDataState from '@/components/NoDataState';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  TableSortArrow,
  TableDisplay,
} from '@/components/Table';
import useAwardedInvoices, { AwardedInvoiceFetchOptions } from '@/data/useAwardedInvoices';
import useAwardedInvoiceStats from '@/data/useAwardedInvoiceStats';
import { TakerMarket } from '@/data/useTakerMarkets';
import { AwardedInvoiceSortKey, AwardedInvoiceTableSort } from '@/pages/AwardedInvoices';
import getInvoiceDisplay from '@/utils/getInvoiceDisplay';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useUserHasIndiaDivisions from '@/utils/useUserHasIndiaDivisions';

const AwardedInvoiceTable = ({
  handleSort,
  options,
  pagination,
  takerMarket,
  sort,
}: {
  handleSort: (key: AwardedInvoiceSortKey) => void;
  options: Partial<AwardedInvoiceFetchOptions>;
  pagination: {
    currentPage: number;
    onNextChange: () => void;
    onPageSizeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onPrevChange: () => void;
    pageSize: number;
  };
  takerMarket: TakerMarket;
  sort: AwardedInvoiceTableSort;
}) => {
  const { t } = useTranslation();
  const hasIndiaDivisions = useUserHasIndiaDivisions();
  const { asCurrency, asPercent } = useLocaleFormat();
  const {
    urlParams: { marketId, takerId },
  } = useInvoiceLayoutOutletContext();
  const { data: awardedInvoices, isFetching } = useAwardedInvoices({
    marketId,
    takerId,
    options,
  });
  const { data: awardedInvoiceStats } = useAwardedInvoiceStats({
    marketId,
    takerId,
  });

  return (
    <>
      <div className="flex border-b px-8 py-6">
        <div className="flex items-center gap-2 text-center">
          <div className="font-medium">{t('core.awarded')}:</div>
          <div>{asCurrency(awardedInvoiceStats?.subTotal ?? 0, takerMarket.currency)}</div>
        </div>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead onClick={() => handleSort('isCleared')}>
              <TableSortArrow accessorKey="isCleared" sort={sort}>
                {t('core.status')}
              </TableSortArrow>
            </TableHead>
            <TableHead className="max-w-[224px]" onClick={() => handleSort('voucherId')}>
              <TableSortArrow accessorKey="voucherId" sort={sort}>
                {t('core.invoiceId')}
              </TableSortArrow>
            </TableHead>
            <TableHead onClick={() => handleSort('dueDate')}>
              <TableSortArrow accessorKey="dueDate" sort={sort} textRight>
                {hasIndiaDivisions ? t('core.tentativePaymentDate') : t('core.originalDueDate')}
              </TableSortArrow>
            </TableHead>
            <TableHead onClick={() => handleSort('normalizedClearDate')}>
              <TableSortArrow accessorKey="normalizedClearDate" sort={sort} textRight>
                {t('core.marketClearDate')}
              </TableSortArrow>
            </TableHead>
            <TableHead onClick={() => handleSort('newPayDate')}>
              <TableSortArrow accessorKey="newPayDate" sort={sort} textRight>
                {t('core.newPayDate')}
              </TableSortArrow>
            </TableHead>
            <TableHead onClick={() => handleSort('amount')}>
              <TableSortArrow accessorKey="amount" sort={sort} textRight>
                {t('core.amount')}
              </TableSortArrow>
            </TableHead>
            <TableHead onClick={() => handleSort('minDiscount')}>
              <TableSortArrow accessorKey="minDiscount" sort={sort} textRight>
                {t('core.discountWithPercent')}
              </TableSortArrow>
            </TableHead>
            <TableHead onClick={() => handleSort('earn')}>
              <TableSortArrow accessorKey="earn" sort={sort} textRight>
                {t('core.discount')}
              </TableSortArrow>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody loading={isFetching}>
          {!awardedInvoices || !awardedInvoices.length ? (
            <TableDisplay colSpan={8}>
              <NoDataState
                className="py-8"
                icon={<img src={invoices} alt="invoices" className="h-28 w-28" />}
                title={t('invoices.noAvailableInvoices')}
                message={t('invoices.noAvailableAwardedInvoicesDescription')}
              />
            </TableDisplay>
          ) : (
            awardedInvoices.map((awardedInvoice) => (
              <tr key={awardedInvoice.id} className="border-b">
                <TableCell>
                  <span className={getInvoiceDisplay(awardedInvoice).color}>
                    {t(getInvoiceDisplay(awardedInvoice).translation)}
                  </span>
                </TableCell>
                <TableCell className="max-w-[224px] whitespace-pre-wrap break-words">
                  {awardedInvoice.voucherId}
                </TableCell>
                <TableCell className="text-right">
                  {awardedInvoice.dueDate && trimDateTimestamp(awardedInvoice.dueDate)}
                </TableCell>
                <TableCell className="text-right">
                  {awardedInvoice.clearDate && trimDateTimestamp(awardedInvoice.clearDate)}
                </TableCell>
                <TableCell className="text-right">
                  {awardedInvoice.newPayDate && trimDateTimestamp(awardedInvoice.newPayDate)}
                </TableCell>
                <TableCell className="text-right">
                  {asCurrency(awardedInvoice.amount, awardedInvoice.currency)}
                </TableCell>
                <TableCell className="text-right">
                  {asPercent({
                    value: awardedInvoice.minDiscount / 100,
                    decimals: 2,
                  })}
                </TableCell>
                <TableCell className="text-right">{asCurrency(awardedInvoice.earn, awardedInvoice.currency)}</TableCell>
              </tr>
            ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        className="mt-auto"
        disableNext={
          pagination.currentPage ===
          Math.ceil((awardedInvoiceStats?.count ?? pagination.pageSize) / pagination.pageSize)
        }
        disablePrev={pagination.currentPage === 1}
        onNextChange={() => pagination.onNextChange()}
        onPageSizeChange={(e) => pagination.onPageSizeChange(e)}
        onPrevChange={() => pagination.onPrevChange()}
        pageSize={pagination.pageSize}
      />
    </>
  );
};

export default AwardedInvoiceTable;

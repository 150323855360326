import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { useInterval } from 'react-use';
import { Button, Modal } from '@c2fo/liquidity';
import personclock from '@/assets/personclock.svg?url';
import { useReporting } from '@/reporting';
import reauthenticate from '@/utils/reauthenticate';
import { getTokenContent, refreshToken } from '@/utils/token';

export default function InactivityMonitor() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { track } = useReporting();
  const { getLastActiveTime } = useIdleTimer();

  const extendSession = async () => {
    try {
      await refreshToken();
    } catch (error) {
      track('refresh-token::error', {
        type: 'session-extend',
      });
      reauthenticate();
    }

    setShowModal(false);
  };

  useInterval(async () => {
    const tokenContent = getTokenContent();
    const lastActiveTime = getLastActiveTime();
    const nowSeconds = Math.round(Date.now() / 1000);

    if (!tokenContent || !lastActiveTime) {
      return;
    }

    if (tokenContent.exp <= nowSeconds) {
      setShowModal(false);
      track('inactivity-monitor-logout');
      reauthenticate();
      return;
    }

    if (tokenContent.exp - nowSeconds <= 30) {
      const lastActiveSeconds = Math.round(lastActiveTime.getTime() / 1000);

      if (lastActiveSeconds > tokenContent.iat && showModal === false) {
        await extendSession();
      } else {
        if (!showModal) {
          track('inactivity-monitor-logout::shown');
          setShowModal(true);
        }
      }
    }
  }, 1000);

  return (
    <Modal variant="info" open={showModal} onClose={extendSession} className="max-w-4xl">
      <Modal.Title className="justify-end" data-testid="inactivity.modal.header" />
      <div className="mb-4 text-center text-xl">
        <h3 className="my-1">{t('inactivity.stillThere')}</h3>
        <img src={personclock} alt="Person Clock" className="mx-auto" />
        <p>{t('inactivity.logoutWarning')}</p>
      </div>
      <Modal.Actions className="flex justify-end">
        <Button variant="cta" onClick={extendSession}>
          {t('inactivity.extendSession')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

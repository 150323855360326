import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import QueryBoundaries from '@/components/QueryBoundaries';
import { useReporting } from '@/reporting';

const PageTrackerComponent = () => {
  const location = useLocation();
  const { track } = useReporting();

  // sent when the app loads
  useEffectOnce(() => {
    track('app::initialized');
  });

  // send when the page changes
  useEffect(() => {
    track('app::page-change');
  }, [location, track]);

  return null;
};

const PageTracker = () => (
  <QueryBoundaries>
    <PageTrackerComponent />
  </QueryBoundaries>
);

export default PageTracker;

import { useTranslation } from 'react-i18next';
import { Button, Paper } from '@c2fo/liquidity';
import brokenlink from '@/assets/broken-link.svg';
import calendartime from '@/assets/calendartime.svg?url';
import { useReporting } from '@/reporting';

const SuggestedRules = ({ showRecurringRulesModal }: { showRecurringRulesModal: () => void }) => {
  const { t } = useTranslation();
  const { track } = useReporting();

  const onClick = (type: string) => {
    showRecurringRulesModal();
    track('recurring-rules::suggested-rules::create', { type });
  };

  /**
    Reduce your terms:
      All divisions selected
      Params:
      DPE - exclude greater than 90 days


    Quarter end prep:
      All divisions selected
      Params:
      Date - exclude greater than date
      [empty date box - let them pick]
  */

  return (
    <div>
      <h2 className="m-0 pb-8 text-2xl">{t('recurringRules.suggestedRules')}</h2>
      <div className="grid gap-10 md:grid-cols-2 lg:gap-16">
        <Paper elevation={2}>
          <div className="flex h-full flex-col justify-between gap-4 p-10">
            <div className="flex items-start gap-2">
              <img src={brokenlink} alt="broken link" className="w-16 sm:w-20" />
              <div>
                <h4 className="m-0 pb-4 text-xl font-normal sm:text-2xl">{t('recurringRules.reduceYourTerms')}</h4>
                <p>{t('recurringRules.reduceYourTermsDescription')}</p>
              </div>
            </div>
            <div className="flex justify-end">
              <Button className="w-full sm:w-fit" onClick={() => onClick('reduceTerms')} variant="secondary">
                {t('recurringRules.createRule')}
              </Button>
            </div>
          </div>
        </Paper>
        <Paper elevation={2}>
          <div className="flex h-full flex-col justify-between gap-4 p-10">
            <div className="flex items-start gap-2">
              <img src={calendartime} alt="calendar" className="w-16 sm:w-20" />
              <div>
                <h4 className="m-0 pb-4 text-xl font-normal sm:text-2xl">{t('recurringRules.quarterEndPrep')}</h4>
                <p>{t('recurringRules.quarterEndPrepDescription')}</p>
              </div>
            </div>
            <div className="flex justify-end">
              <Button className="w-full sm:w-fit" onClick={() => onClick('quarterEnd')} variant="secondary">
                {t('recurringRules.createRule')}
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default SuggestedRules;

import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from '@c2fo/liquidity';
import apiClient from '@/lib/apiClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { useRefetchStatsQueries } from '@/lib/serverSentEvents/useServerSideEventListeners';

// https://fss-gateway.c2fo.com/usercast/api/c2fo/invoice/include

interface ReincludeInvoiceResponse {
  data: [[string, number[]]]; // [[legacyMarketId, [takerIds]]]
}

const useReincludeInvoices = () => {
  const showSnackbar = useSnackbar();
  const { refetchAllStats } = useRefetchStatsQueries();
  const { subscribeToMarketStats } = useServerSideEventListeners();

  return useMutation({
    mutationKey: ['reincludeInvoices'],
    mutationFn: () => apiClient.put('usercast/api/c2fo/invoice/include').json<ReincludeInvoiceResponse>(),
    onSuccess: () => {
      showSnackbar({ message: 'Invoices updated' });
      refetchAllStats();
      // NOTE: the following is not a real marketUuid/takerId, this is a specific hard-coded string
      // used only within the frontend to tap into server sent event listeners for global reinclude invoice
      subscribeToMarketStats({
        marketUuid: 'global-reinclude-invoice-mutation',
        takerId: 0,
      });
    },
    onError: () => showSnackbar({ message: 'Error updating invoices' }),
  });
};

export default useReincludeInvoices;

import { Dispatch, SetStateAction, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PreferredAlertBanner from '@/features/preferred/PreferredRenewalBanner';
import TakerMarketStatsGrid from '@/features/takerMarketStatsGrid/TakerMarketStatsGrid';
import { isPreferredOfferNearTermEnd } from '@/utils/preferredOfferRenewal';
import LinkTabs from './LinkTabs';
import { MarketDisabledAlert } from './MarketDisabledAlert';
import { MarketLayoutOutletContext } from './MarketLayout';

export interface InvoiceLayoutOutletContext extends MarketLayoutOutletContext {
  readonly loader: {
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
  };
}

export function useInvoiceLayoutOutletContext() {
  return useOutletContext<InvoiceLayoutOutletContext>();
}

const InvoiceLayout = () => {
  const { t } = useTranslation();
  const {
    takerMarket,
    urlParams: { marketId, takerId },
  } = useOutletContext<MarketLayoutOutletContext>();
  const [loading, setLoading] = useState<boolean>(false);
  const preferredOfferNearTermEnd = isPreferredOfferNearTermEnd(takerMarket);

  return (
    <>
      <div>
        {preferredOfferNearTermEnd && <PreferredAlertBanner takerMarket={takerMarket} />}
        {!takerMarket.marketIsEnabled && <MarketDisabledAlert takerMarket={takerMarket} />}
        <TakerMarketStatsGrid marketId={marketId} takerId={takerId} takerMarket={takerMarket} />
        <div className="mt-10 rounded-md bg-white shadow-md">
          <div className="px-8 pt-4">
            <div className="text-2xl font-medium">{t('maker.invoices')}</div>
            <LinkTabs
              items={[
                {
                  key: 'eligible',
                  label: t('core.eligible'),
                  link: 'eligible',
                  matcher: 'eligible',
                },
                {
                  key: 'ineligible',
                  label: t('core.ineligible'),
                  link: 'ineligible',
                  matcher: 'ineligible',
                },
                {
                  key: 'adjustments',
                  label: t('maker.adjustments'),
                  link: 'adjustments',
                  matcher: 'adjustments',
                },
                {
                  key: 'awarded',
                  label: t('taker.awarded'),
                  link: 'awarded',
                  matcher: 'awarded',
                },
              ]}
              variant="secondary"
            />
          </div>
          <Outlet
            context={
              {
                loader: { loading, setLoading },
                takerMarket,
                urlParams: { marketId, takerId },
              } satisfies InvoiceLayoutOutletContext
            }
          />
        </div>
      </div>
    </>
  );
};

export default InvoiceLayout;

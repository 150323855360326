import { useEffect, useState } from 'react';
import qs from 'qs';
import { EligibleInvoiceFetchFilters, buildEligibleInvoiceFetchFilter } from '@/data/useEligibleInvoices';
import { IneligibleInvoiceFetchFilters, buildIneligibleInvoiceFetchFilter } from '@/data/useIneligibleInvoices';
import { InvoiceAdjustmentFetchFilters, buildInvoiceAdjustmentFetchFilter } from '@/data/useInvoiceAdjustments';
import getToken from './token';

const prefixUrl = import.meta.env.VITE_GATEWAY_URL;

interface CommonArgs {
  marketId: string;
  takerId: number;
}

type FilterTypeArgs =
  | {
      type: 'adjustments';
      filters?: InvoiceAdjustmentFetchFilters;
    }
  | {
      type: 'eligible';
      filters?: EligibleInvoiceFetchFilters;
    }
  | {
      type: 'ineligible';
      filters?: IneligibleInvoiceFetchFilters;
    };

type InvoiceDownloadUrlArgs = CommonArgs & FilterTypeArgs;

const useInvoiceDownloadUrl = ({ filters, marketId, takerId, type }: InvoiceDownloadUrlArgs) => {
  const token = getToken();
  const [invoiceDownloadUrl, setInvoiceDownloadUrl] = useState<string>('');

  const baseInvoiceDownloadUrl = `${prefixUrl}/api/c2fo/taker/${takerId}/market/${marketId}/invoices/${type}/csv`;

  useEffect(() => {
    if (filters) {
      const builtFilters =
        type === 'ineligible'
          ? buildIneligibleInvoiceFetchFilter(filters)
          : type === 'adjustments'
          ? buildInvoiceAdjustmentFetchFilter(filters)
          : buildEligibleInvoiceFetchFilter(filters);
      const params = qs.stringify(builtFilters);

      return setInvoiceDownloadUrl(`${baseInvoiceDownloadUrl}?token=${token}&${params}`);
    }

    return setInvoiceDownloadUrl(`${baseInvoiceDownloadUrl}?token=${token}`);
  }, [baseInvoiceDownloadUrl, filters, token, type]);

  return invoiceDownloadUrl;
};

export default useInvoiceDownloadUrl;

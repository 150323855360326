import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '@c2fo/liquidity';
import RangePicker from '@/components/RangePicker';
import { dateRanges, getRangeLabel, type DateRange, type RangeKey } from './utils';

export interface HistoryFilterProps {
  /**
   * The currently selected date range.
   */
  value: DateRange;
  /**
   * A callback that is called when the date range is changed.
   */
  onChange: (bag: { range: DateRange; rangeKey: RangeKey }) => void;
}

/**
 * A component that allows the user to select a date range for use on the consolidated history page.
 */
export default function HistoryFilter({ value, onChange }: HistoryFilterProps) {
  const { t } = useTranslation();
  const [customPickerOpen, setCustomPickerOpen] = useState(false);
  const { startDate, endDate } = value;
  const initialSelectedKey =
    Object.entries(dateRanges).find(([, { startDate: rangeStart, endDate: rangeEnd }]) => {
      return startDate === rangeStart && endDate === rangeEnd;
    })?.[0] ?? 'custom';
  const [selectedKey, setSelectedKey] = useState<string>(initialSelectedKey);

  return (
    <div>
      <ul className="space-y-2">
        {Object.entries(dateRanges).map(([key, range]) => (
          <li key={key}>
            <button
              type="button"
              className={cn({ 'font-bold': selectedKey === key })}
              onClick={() => {
                onChange({
                  range,
                  rangeKey: key as RangeKey,
                });

                setSelectedKey(key as RangeKey);
              }}
            >
              {t(...getRangeLabel(key))}
            </button>
          </li>
        ))}
        <li>
          <RangePicker
            open={customPickerOpen}
            onOpenChange={setCustomPickerOpen}
            className={cn({ 'font-bold': selectedKey === 'custom' })}
            value={{ startDate, endDate }}
            onChange={(range) => {
              onChange({
                range: range ?? dateRanges.last7Days,
                rangeKey: 'custom',
              });

              setSelectedKey('custom');
            }}
          />
        </li>
      </ul>
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@c2fo/liquidity';
import { TimesIcon } from '@c2fo/liquidity/icons';
import { useToken } from '@/utils/token';

const DownloadInvoicesModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation();
  const { tokenContent } = useToken();
  const email = tokenContent?.payload.user.emailAddress;

  return (
    <Modal className="w-fit" open={open} variant="custom">
      <div className="flex justify-center p-6">
        <div className="w-96">
          <div className="relative bg-white text-text-primary">
            <div>
              <div className="text-xl font-medium">{t('taker.downloadInvoicesTitle')}</div>
              <div className="absolute -right-3 -top-3">
                <button className="rounded-md p-2 text-xl hover:bg-gray-100" type="button" onClick={onClose}>
                  <TimesIcon />
                </button>
              </div>
            </div>
          </div>
          <div className="space-y-3 pt-6">{t('taker.downloadInvoicesSubtitle', { email })}</div>
          <div className="flex justify-end pt-6">
            <Button variant="primary" onClick={onClose}>
              {t('core.ok')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadInvoicesModal;

import { TableSortDirection } from '@/components/Table';
import { TakerMarket } from '@/data/useTakerMarkets';
import { DotNestedKeys } from '@/utils/common';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';

export type SortKey = DotNestedKeys<TakerMarket> | 'offerConfig' | 'participation' | 'disableAfterMarketClearsDate';
export type Sort = { key: SortKey; direction: TableSortDirection } | null;

const sortTakerMarketTable = (takerMarkets: TakerMarket[] = [], sort: Sort) => {
  // defaults to eligibleInvoiceAmountUsd/desc
  if (!sort?.key || !sort?.direction) {
    return takerMarkets;
  }

  return takerMarkets.sort((a, b) => {
    const divisonNameA = a.marketType ? `${a.makerDivisionName}-${a.legacyMarketId}` : a.makerDivisionName;
    const divisonNameB = b.marketType ? `${b.makerDivisionName}-${b.legacyMarketId}` : b.makerDivisionName;

    if (sort.key === 'participation') {
      const sortKeyA = a.offerConfig?.isEnabled ? 1 : -1;
      const sortKeyB = b.offerConfig?.isEnabled ? 1 : -1;

      return sort.direction === 'desc'
        ? sortKeyB - sortKeyA || divisonNameA.localeCompare(divisonNameB)
        : sortKeyA - sortKeyB || divisonNameA.localeCompare(divisonNameB);
    }

    if (sort.key === 'makerOrganizationName') {
      return sort.direction === 'desc'
        ? divisonNameA.localeCompare(divisonNameB) || b.eligibleInvoiceAmount - a.eligibleInvoiceAmount
        : divisonNameB.localeCompare(divisonNameA) || a.eligibleInvoiceAmount - b.eligibleInvoiceAmount;
    }

    if (sort.key === 'marketPayDate') {
      // defaulting to a date in the past ensure those with no marketPayDate get pushed to the end
      const sortKeyA = a.marketPayDate ? trimDateTimestamp(a.marketPayDate) : '1999-01-01';
      const sortKeyB = b.marketPayDate ? trimDateTimestamp(b.marketPayDate) : '1999-01-01';

      return sort.direction === 'desc'
        ? sortKeyB.localeCompare(sortKeyA) || divisonNameA.localeCompare(divisonNameB)
        : sortKeyA.localeCompare(sortKeyB) || divisonNameA.localeCompare(divisonNameB);
    }

    if (sort.key === 'offerConfig') {
      // sort by offer discount or APR depending on if/how the offer is configured
      const sortKeyA =
        a.offerConfig.isDiscountBidding && a.offerConfig.maxDiscount
          ? a.offerConfig.maxDiscount
          : a.offerConfig.maxApr || -1;
      const sortKeyB =
        b.offerConfig.isDiscountBidding && b.offerConfig.maxDiscount
          ? b.offerConfig.maxDiscount
          : b.offerConfig.maxApr || -1;

      return sort.direction === 'desc'
        ? sortKeyB - sortKeyA || divisonNameA.localeCompare(divisonNameB)
        : sortKeyA - sortKeyB || divisonNameA.localeCompare(divisonNameB);
    }

    if (sort.key === 'acceptedInvoiceAmount') {
      return sort.direction === 'desc'
        ? b.acceptedInvoiceAmount - a.acceptedInvoiceAmount || divisonNameA.localeCompare(divisonNameB)
        : a.acceptedInvoiceAmount - b.acceptedInvoiceAmount || divisonNameA.localeCompare(divisonNameB);
    }

    if (sort.key === 'notAcceptedInvoiceAmount') {
      return sort.direction === 'desc'
        ? b.notAcceptedInvoiceAmount - a.notAcceptedInvoiceAmount || divisonNameA.localeCompare(divisonNameB)
        : a.notAcceptedInvoiceAmount - b.notAcceptedInvoiceAmount || divisonNameA.localeCompare(divisonNameB);
    }

    if (sort.key === 'acceptedEarn') {
      return sort.direction === 'desc'
        ? b.acceptedEarn - a.acceptedEarn || divisonNameA.localeCompare(divisonNameB)
        : a.acceptedEarn - b.acceptedEarn || divisonNameA.localeCompare(divisonNameB);
    }

    if (sort.key === 'eligibleDpeWeightedAvg') {
      return sort.direction === 'desc'
        ? b.eligibleDpeWeightedAvg - a.eligibleDpeWeightedAvg || divisonNameA.localeCompare(divisonNameB)
        : a.eligibleDpeWeightedAvg - b.eligibleDpeWeightedAvg || divisonNameA.localeCompare(divisonNameB);
    }

    return sort.direction === 'desc'
      ? b.eligibleInvoiceAmountUsd - a.eligibleInvoiceAmountUsd || divisonNameA.localeCompare(divisonNameB)
      : a.eligibleInvoiceAmountUsd - b.eligibleInvoiceAmountUsd || divisonNameA.localeCompare(divisonNameB);
  });
};

export default sortTakerMarketTable;

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

// TODO: check which fields are actually nullable/optional
interface TakerHistoryResponseItem {
  acceptedAprWeightedAvg: number;
  acceptedDiscountWeightedAvg: number;
  acceptedDiscountedAmount: number;
  acceptedDpeWeightedAvg: number;
  acceptedEarn: number;
  acceptedInvoiceAmount: number;
  acceptedInvoiceCount: number;
  acceptedYield: number;
  awardedAprWeightedAvg: number | null;
  awardedDiscountWeightedAvg: number | null;
  awardedDiscountedAmount: number;
  awardedDpeWeightedAvg: number | null;
  awardedEarn: number;
  awardedInvoiceAmount: number;
  awardedInvoiceCount: number;
  awardedYield: number;
  clearedAprWeightedAvg: number;
  clearedDiscountWeightedAvg: number;
  clearedDiscountWeightedSum: number;
  clearedDiscountedAmount: number;
  clearedDpeWeightedAvg: number;
  clearedEarn: number;
  clearedInvoiceAmount: number;
  clearedInvoiceCount: number;
  clearedYield: number;
  coveringInvoiceAmount: number;
  coveringInvoiceCount: number;
  currency: string;
  hasCreditNote: boolean;
  isPendingClear: boolean;
  makerOrganizationName: string;
  makerOrganizationUuid: string;
  matchedAdjustmentAmount: number;
  matchedAdjustmentCount: number;
  maxAcceptedApr: number;
  maxAcceptedDiscount: number;
  maxAcceptedDpe: number;
  maxAwardedApr: number | null;
  maxAwardedDiscount: number | null;
  maxAwardedDpe: number | null;
  maxClearedApr: number;
  maxClearedDiscount: number;
  maxClearedDpe: number;
  minAcceptedApr: number;
  minAcceptedDiscount: number;
  minAcceptedDpe: number;
  minAwardedApr: number | null;
  minAwardedDiscount: number | null;
  minAwardedDpe: number | null;
  minClearedApr: number;
  minClearedDiscount: number;
  minClearedDpe: number;
  notAcceptedAprWeightedAvg: number | null;
  notAcceptedDiscountWeightedAvg: number | null;
  notAcceptedDiscountedAmount: number;
  notAcceptedDpeWeightedAvg: number | null;
  notAcceptedEarn: number;
  notAcceptedInvoiceAmount: number;
  notAcceptedInvoiceCount: number;
  notAcceptedYield: number;
  payDate: string;
  snapshotComplete: boolean;
  takerDivisionName: string;
  takerId: number;
  totalAdjustmentAmount: number;
  totalAdjustmentCount: number;
  totalInvoiceCount: number;
  unmatchedAdjustmentAmount: number;
  unmatchedAdjustmentCount: number;
  uuid: string;
  id: number | null;
}

export interface TakerHistory extends TakerHistoryResponseItem {
  /**
   * Alias for `uuid`
   */
  marketId: string;
}

export interface TakerHistoryArgs {
  // chartData?: boolean;
  startDate: string;
  endDate: string;
  currency: string;
  page: number;
  limit: number;
  sort: string;
  pendingClear?: boolean;
  makerIds?: string[] | undefined;
}

// https://fss-gateway.c2fo.com/usercast/api/c2fo/user/taker/history?currency=USD&filter=%7B%22isPendingClear__eq%22:false,%22payDate__between%22:%5B%222023-04-01,2023-06-30%22%5D%7D&page=%7B%22page%22:1,%22limit%22:100%7D&sort=payDate

const fetchTakerHistory = async ({
  startDate,
  endDate,
  page,
  limit,
  makerIds,
  pendingClear,
  ...rest
}: TakerHistoryArgs) => {
  const searchParams: Record<string, string> = {
    ...rest,
    filter: JSON.stringify({
      isPendingClear__eq: pendingClear,
      payDate__between: [`${startDate},${endDate}`],
      ...(makerIds?.length ? { makerId__eq: makerIds } : undefined),
    }),
    page: JSON.stringify({ page, limit }),
  };
  const data = await apiClient
    .get(`usercast/api/c2fo/user/taker/history`, {
      searchParams,
    })
    .json<{ data: TakerHistory[] }>();
  return (data?.data || []).map((item) => ({
    ...item,
    marketId: item.uuid,
  }));
};

export default function useTakerHistory(params: TakerHistoryArgs, options?: UseQueryOptions<TakerHistory[]>) {
  return useQuery({
    queryKey: ['taker-history', params],
    queryFn: () => fetchTakerHistory(params),
    keepPreviousData: true,
    ...options,
  });
}

import { useRef, useEffect } from 'react';
import { cn } from '@c2fo/liquidity';

declare global {
  export interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Trustpilot: any;
  }
}

/**
 * Trustpilot widget component
 * This component is used to render a Trustpilot widget.
 */
const TrustpilotWidget = () => {
  const ref = useRef(null);
  const href = 'https://www.trustpilot.com/review/www.c2fo.com';
  const variantId = '5419b6ffb0d04a076446a9af'; // "micro-combo"
  const className = 'trustpilot-widget';

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }

    // add trustpilot script only when needed
    const script = document.createElement('script');
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div
      ref={ref}
      className={cn(className)}
      data-locale="en-US"
      data-template-id={variantId}
      data-width="100%"
      data-businessunit-id="5862d8760000ff000599c95f"
    >
      <a href={href} target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  );
};

export default TrustpilotWidget;

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, useSnackbar } from '@c2fo/liquidity';
import Modal, { ModalProps } from '@/components/Modal';
import { OfferConfigDetails, OfferConfigDetailsItem } from '@/components/OfferConfigDetails';
import { OfferReviewDetails } from '@/components/OfferReviewDetails';
import { useConfirmAgreementsWhenRequired } from '@/data/useAgreements';
import useSubmitBenchmarkOffer from '@/data/useSubmitBenchmarkOffer';
import { TakerMarket } from '@/data/useTakerMarkets';
import { useReporting } from '@/reporting';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';
import { Agreement } from '../agreements/Agreement';
import { VariableRateDetails } from './DetailModal';
import useVariableRateDetails from './useVariableRateDetails';

const VariableRateSetOfferComponent = ({
  onClose,
  open,
  takerMarket,
}: {
  onClose: () => void;
  open: boolean;
  takerMarket: TakerMarket;
}) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const { asCurrency } = useLocaleFormat();
  const [step, setStep] = useState<1 | 2>(1);
  const { getVariableRateDetails } = useVariableRateDetails();
  const { formattedDiscountAmount, formattedEstimatedRate, formattedReferenceRate, formattedSpread, rateName } =
    getVariableRateDetails(takerMarket);
  const { mutateAsync: submitBenchmarkOffer, isLoading: submitBenchmarkOfferIsLoading } = useSubmitBenchmarkOffer();
  const { validateAndConfirmAgreementsConditionally } = useConfirmAgreementsWhenRequired([takerMarket]);
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  const [isAgreementsErrorNotChecked, setIsAgreementsErrorNotChecked] = useState(false);

  const submitValues = {
    marketUuid: takerMarket.offerConfig.marketUuid,
    supplierDivisionUuid: takerMarket.takerDivisionUuid,
  };

  const offerConfigItems = useMemo(() => {
    const items: OfferConfigDetailsItem[] = [
      {
        label: t('taker.dictionary.availableAR.label'),
        value: asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency),
      },
    ];

    if (step === 2) {
      items.push({
        label: t('core.variableRate'),
        value: (
          <>
            {formattedEstimatedRate} ({formattedDiscountAmount})
          </>
        ),
      });
    }

    return items;
  }, [
    asCurrency,
    formattedDiscountAmount,
    formattedEstimatedRate,
    step,
    t,
    takerMarket.currency,
    takerMarket.eligibleInvoiceAmount,
  ]);

  const modalConfig: Partial<ModalProps> =
    step === 1
      ? {
          cancelText: t('core.cancel'),
          okButtonVariant: 'primary',
          okText: t('core.next'),
          onCancel: onClose,
          onOk: () => {
            setStep(2);
            track('offer-next::variable-rate::clicked');
          },
        }
      : {
          cancelText: t('core.back'),
          okButtonVariant: 'cta',
          okText: t('core.submit'),
          onCancel: () => setStep(1),
          onOk: async () => {
            try {
              await validateAndConfirmAgreementsConditionally({ isAgreementsChecked });
            } catch {
              setIsAgreementsErrorNotChecked(true);
              return;
            }

            await submitBenchmarkOffer(submitValues, {
              onSuccess: () => showSnackbar({ message: t('offerSubmitDialog.offerSet') }),
              onError: () => showSnackbar({ message: t('offerSubmitDialog.offerSetError') }),
            });

            onClose();

            track('offer::variable-rate::submitted', {
              marketUuid: takerMarket.offerConfig.marketUuid,
              takerId: takerMarket.offerConfig.divisionId,
              supplierDivisionUuid: takerMarket.takerDivisionUuid,
            });
          },
        };

  return (
    <Modal
      className="w-fit"
      fullWidthButtons
      loading={submitBenchmarkOfferIsLoading}
      onClose={onClose}
      open={open}
      subTitle={getTakerMarketName(takerMarket)}
      title={t('core.setVariableRateOfferLabel')}
      variant="info"
      {...modalConfig}
    >
      {takerMarket.eligibleInvoiceAmount <= 0 && (
        <Alert
          variant="outlined"
          type="warning"
          full
          title={t('offerSubmitDialog.zeroApWarningTitle')}
          description={t('variableRate.details.zeroApDescription')}
        />
      )}
      <OfferConfigDetails items={offerConfigItems} />
      <div className="space-y-6">
        {step === 1 ? (
          <VariableRateDetails
            discountAmount={formattedDiscountAmount}
            estimatedRate={formattedEstimatedRate}
            referenceRate={formattedReferenceRate}
            spread={formattedSpread}
            rateName={rateName}
          />
        ) : (
          <div className="space-y-4">
            <OfferReviewDetails takerMarket={takerMarket} />

            <Agreement
              takersMarkets={[takerMarket]}
              onChange={(v) => {
                setIsAgreementsChecked(v);
                setIsAgreementsErrorNotChecked(false);
              }}
            />
            {isAgreementsErrorNotChecked && (
              <Alert type="error" variant="filled" description={t('agreements.error.checkbox')} full />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

const VariableRateSetOffer = ({
  onClose,
  open,
  takerMarket,
}: {
  onClose: () => void;
  open: boolean;
  takerMarket?: TakerMarket | null;
}) => {
  return takerMarket ? <VariableRateSetOfferComponent onClose={onClose} open={open} takerMarket={takerMarket} /> : null;
};

export default VariableRateSetOffer;

import { useTranslation } from 'react-i18next';
import { Alert } from '@c2fo/liquidity';
import ChecklistFilter from '@/components/ChecklistFilter';
import QueryBoundaries from '@/components/QueryBoundaries';
import Skeleton from '@/components/Skeleton';
import TabSection from '@/components/TabSection';
import { useReporting } from '@/reporting';
import HistoryChart, { HistoryChartDatum } from './HistoryChart';
import HistoryFilter from './HistoryFilter';
import HistoryStatsBar, { HistoryStatsBarProps } from './HistoryStatsBar';
import HistoryTable, { HistoryTableDatum } from './HistoryTable';

export interface HistoryProps<TAwarded extends HistoryTableDatum, TPending extends HistoryTableDatum> {
  /**
   * The currency to display the data in.
   */
  currency: string;
  /**
   * The start date of the date range.
   */
  startDate: string;
  /**
   * The end date of the date range.
   */
  endDate: string;
  /**
   * A callback that is called when the date range changes.
   */
  onDateRangeChange: (startDate: string, endDate: string) => void;
  /**
   * The sort to use for the table.
   */
  sort: string;
  /**
   * A callback that is called when the sort changes.
   */
  onSortChange: (sort: string) => void;
  /**
   * The page to display.
   */
  page: number;
  /**
   * A callback that is called when the page changes.
   */
  onPageChange: (page: number) => void;
  /**
   * The limit to use for the table.
   */
  limit: number;
  /**
   * A callback that is called when the limit changes.
   */
  onLimitChange: (limit: number) => void;
  /**
   * The customers to display. If provided, the customer filter will be displayed.
   * If not provided, the customer filter will not be displayed.
   */
  customers?: Array<{ value: string; label: string }>;
  /**
   * The selected customers.
   */
  selectedCustomers?: string[];
  /**
   * The callback that is called when the customer filter is changed.
   * It is called with the selected customers.
   */
  onCustomersChange?: (selected: string[]) => void;
  /**
   * The data to display in the chart.
   */
  chartData: HistoryChartDatum[];
  /**
   * The data to display in the awarded table.
   */
  awardedData?: TAwarded[];
  /**
   * The data to display in the pending table.
   */
  pendingData?: TPending[];
  /**
   * The stats to display in the stats bar.
   */
  stats?: HistoryStatsBarProps | undefined;
  /**
   * The type of history the table should display.
   */
  historyType: 'consolidated' | 'market';
  /**
   * The loading state of pending data set.
   */
  pendingDataFetching: boolean;
  /**
   * The loading state of awarded data set.
   */
  awardedDataFetching: boolean;
}

/**
 * This component displays the history view for both the consolidated and the market history pages.
 * Each page provides their own data and callbacks.
 */
export default function History<TAwarded extends HistoryTableDatum, TPending extends HistoryTableDatum>({
  currency,
  startDate,
  endDate,
  onDateRangeChange,
  sort,
  onSortChange,
  page,
  onPageChange,
  limit,
  onLimitChange,
  chartData,
  awardedData = [],
  pendingData,
  stats,
  customers = [],
  selectedCustomers,
  onCustomersChange,
  historyType,
  pendingDataFetching = false,
  awardedDataFetching = false,
}: HistoryProps<TAwarded, TPending>) {
  const { t } = useTranslation();
  const { track } = useReporting();

  return (
    <>
      <div className="flex">
        {/* FILTERS */}
        <div className="w-64 shrink-0 border-r">
          <div className="px-8 py-6">
            <div className="pb-6 font-medium">{t('core.filters')}</div>
            <div className="space-y-6">
              {/* history filter */}
              <div className="text-sm">
                <div className="pb-3 font-medium">{t('core.history')}</div>
                <div className="space-y-3">
                  <HistoryFilter
                    value={{ startDate, endDate }}
                    onChange={({ range: { startDate, endDate } }) => {
                      onDateRangeChange(startDate, endDate);
                      track('history-filter::clicked', { startDate, endDate });
                    }}
                  />
                </div>
              </div>
              <Alert
                variant="outlined"
                type="info"
                title={t('core.didyouknow')}
                description={t('core.futureAwardFilesDescription')}
                icon={false}
              />
              {/* customer filter */}
              {!!customers?.length && (
                <div className="text-sm">
                  <div className="pb-3 font-medium">{t('core.customers')}</div>
                  <div className="space-y-3">
                    <ChecklistFilter
                      selected={selectedCustomers}
                      options={customers}
                      onChange={(selected) => {
                        onCustomersChange?.(selected);
                        track('history-customer::clicked');
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* TABLE */}
        <div className="flex w-full flex-col overflow-hidden">
          {stats && (
            <div>
              <HistoryStatsBar
                clearedDiscountWeightedAvg={stats.clearedDiscountWeightedAvg}
                clearedEarn={stats.clearedEarn}
                clearedInvoiceAmount={stats.clearedInvoiceAmount}
                currency={currency}
              />
              <div className="border-y p-6">
                <HistoryChart data={chartData} currency={currency} />
              </div>
            </div>
          )}
          <TabSection>
            {!!pendingData?.length && (
              <TabSection.Tab name="pending" title={t('core.pending')}>
                <QueryBoundaries LoadingComponent={() => <Skeleton />}>
                  <HistoryTable
                    data={pendingData}
                    currency={currency}
                    sort={sort}
                    setSort={onSortChange}
                    pageIndex={page}
                    pageSize={limit}
                    onPageChange={onPageChange}
                    onPageSizeChange={onLimitChange}
                    displayType="pending"
                    historyType={historyType}
                    loading={pendingDataFetching}
                  />
                </QueryBoundaries>
              </TabSection.Tab>
            )}
            <TabSection.Tab name="awarded" title={t('core.awarded')}>
              <QueryBoundaries LoadingComponent={() => <Skeleton />}>
                <HistoryTable
                  data={awardedData}
                  currency={currency}
                  sort={sort}
                  setSort={onSortChange}
                  pageIndex={page}
                  pageSize={limit}
                  onPageChange={onPageChange}
                  onPageSizeChange={onLimitChange}
                  displayType="awarded"
                  historyType={historyType}
                  loading={awardedDataFetching}
                />
              </QueryBoundaries>
            </TabSection.Tab>
          </TabSection>
        </div>
      </div>
    </>
  );
}

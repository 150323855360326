import { ReactNode, useEffect, useRef, useState } from 'react';
import { cn, Popover } from '@c2fo/liquidity';

const EllipseTooltip = ({ className, children }: { className?: string; children: ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);

  const compareSize = () => {
    const compare = ref.current && ref.current.offsetWidth < ref.current.scrollWidth;
    setIsOverflow(!!compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  });

  const handleMouseEnter = () => {
    if (isOverflow) {
      setOpen(true);
    }
  };

  return (
    <Popover open={open}>
      <Popover.Trigger
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <div
          ref={ref}
          className={cn('max-w-fit truncate', className, { 'cursor-text': !isOverflow })}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => setOpen(false)}
        >
          {children}
        </div>
      </Popover.Trigger>
      <Popover.Content arrow className="z-20">
        {children}
      </Popover.Content>
    </Popover>
  );
};

export default EllipseTooltip;

import { useState } from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { FullPageLoader } from '@/components/FullPageLoader';
import useMagicLogin from '@/data/useMagicLogin';
import { useReporting } from '@/reporting';
import getToken, { setToken } from '@/utils/token';

export const Auth = () => <Outlet />;

/**
 * Magic Login URL
 * https://app.c2fo.com/enterprise/auth/magic-login?magicLoginToken=${MagicLoginToken}&redirect=draftOffer&id=${DraftRequestId}&referrer=${PutAnyWordHere}
 */
export const AuthMagicLogin = () => {
  const [searchParams] = useSearchParams();
  const [readyToNavigate, setReadyToNavigate] = useState(false);
  const { track } = useReporting();
  const { mutateAsync: fetchAuthToken } = useMagicLogin();

  const magicLoginToken = searchParams.get('magicLoginToken');
  const redirect = searchParams.get('redirect');
  const draftOfferId = searchParams.get('id');

  useEffectOnce(() => {
    const exchangeMagicLoginTokenForAuthToken = async () => {
      try {
        if (!magicLoginToken) {
          throw new Error('No valid magic login token to exchange');
        }

        const { jwt } = await fetchAuthToken({ magicLoginToken });

        setToken(jwt);

        setReadyToNavigate(true);
      } catch (error) {
        console.error(error);
        track('magic-login::error', {
          errorMessage:
            error instanceof Error ? error.message : 'Unknown error occurred while exchanging magic login token',
        });
        setReadyToNavigate(true);
      }
    };

    exchangeMagicLoginTokenForAuthToken();
  });

  if (readyToNavigate && redirect === 'draftOffer') {
    return <Navigate to={`/supplier/offer/${draftOfferId}`} />;
  }

  if (readyToNavigate && !redirect) {
    return <Navigate to={`/supplier`} />;
  }

  return <FullPageLoader />;
};

/**
 * Impersonation URL
 * https://app.c2fo.com/enterprise/auth/impersonate?authorization=${impersonateToken}&draftId=${draftId}&referrer=${referrer}
 */
export const AuthImpersonation = () => {
  const [searchParams] = useSearchParams();
  const [readyToNavigate, setReadyToNavigate] = useState(false);
  const { track } = useReporting();

  const draftOfferId = searchParams.get('draftId');

  useEffectOnce(() => {
    try {
      // Our token handling logic already looks for 'authorization' in query params and handles it
      const token = getToken();

      if (!token) {
        throw new Error('No impersonation token provided via query param');
      }

      setReadyToNavigate(true);
    } catch (error) {
      console.error(error);
      track('impersonation::error', {
        errorMessage:
          error instanceof Error
            ? error.message
            : 'Unknown error occurred while attempting impersonation with draft offer id',
      });
      setReadyToNavigate(true);
    }
  });

  if (readyToNavigate && draftOfferId != null) {
    return <Navigate to={`/supplier/offer/${draftOfferId}`} />;
  }

  if (readyToNavigate && draftOfferId == null) {
    return <Navigate to={`/supplier`} />;
  }

  return <FullPageLoader />;
};

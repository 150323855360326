import { useCallback, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import useUserDetails from '@/data/useUserDetails';
import { useToken } from '@/utils/token';

const appId = import.meta.env.VITE_ENTERPRISE_UI_RUM_APP_ID;

if (appId) {
  datadogRum.init({
    applicationId: appId,
    clientToken: `${import.meta.env.VITE_ENTERPRISE_UI_RUM_CLIENT_TOKEN}`,
    site: 'datadoghq.com',
    service: `${import.meta.env.VITE_APP_NAME}`,
    env: `${import.meta.env.VITE_ENV}`,
    version: `${import.meta.env.VITE_COMMIT_REF}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackSessionAcrossSubdomains: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [
      /https:\/\/.*\.c2fo\.com/,
      /https:\/\/.*\.c2fo\.dev/,
      /https:\/\/.*\.c2fo\.io/,
      /http:\/\/.*\.localhost/,
    ],
    enableExperimentalFeatures: ['feature_flags'],
    /**
     * V5 introduces new configurations and privacy defaults for Session Replay
     * https://docs.datadoghq.com/real_user_monitoring/guide/browser-sdk-upgrade/#session-replay-masking
     */
    defaultPrivacyLevel: 'mask-user-input', // https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/
  });

  if (import.meta.env.VITE_ENTERPRISE_UI_RUM_ENABLE_SESSION_REPLAY === 'true') {
    datadogRum.startSessionReplayRecording();
  }
}

/**
 * Initializes the DataDog RUM user properties.
 */
export function DataDogProvider({ children }: { children: React.ReactNode }) {
  useDataDogRumUserPropertiesInitialize();
  return <>{children}</>;
}

function useDataDogRumUserPropertiesInitialize() {
  const { tokenContent } = useToken();
  const payload = tokenContent?.payload;

  useEffect(() => {
    if (payload) {
      let user;

      const tokenType = payload.type;
      const isImpersonation = tokenType === 'impersonation';

      if (isImpersonation) {
        user = payload.impersonationSubject ?? payload.user;
      } else {
        user = payload.user;
      }

      datadogRum.setGlobalContextProperty('isImpersonation', isImpersonation);
      datadogRum.setGlobalContextProperty('isC2FO', user.emailAddress.includes('@c2fo.com'));
      datadogRum.setUser({
        id: user.uuid,
        email: user.emailAddress,
      });
    }
  }, [payload]);
}

export function useDataDogRumUserAccountIdInitialize() {
  const { data: accountId } = useUserDetails((userDetails) => userDetails?.me?.account?.id);
  useEffect(() => {
    if (accountId) {
      datadogRum.setGlobalContextProperty('accountId', accountId);
    }
  }, [accountId]);
}

export function useDataDog() {
  const addAction = useCallback(<T extends Record<string, unknown>>(event: string, context: T) => {
    try {
      datadogRum.addAction(event, context);
    } catch {
      // Do nothing
    }
  }, []);
  return { addAction };
}

export default useDataDog;

import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@c2fo/liquidity';
import { TakerMarket } from '@/data/useTakerMarkets';
import useUpdateCalculatedInvoices, {
  CalculatedStatsFilter,
  InvoiceEditOperation,
} from '@/data/useUpdateCalculatedInvoices';
import { useRefetchStatsQueries } from '@/lib/serverSentEvents/useServerSideEventListeners';
import groupTakerMarketsByMakerOrg from '@/utils/groupTakerMarketsByMakerOrg';

export type EditInvoiceParamsRuleType =
  | 'amount-greater'
  | 'amount-less'
  | 'dpe-greater'
  | 'dpe-less'
  | 'due-before'
  | 'due-after'
  | 'invoice-id-contains';

export interface EditInvoiceParamsRule {
  type: EditInvoiceParamsRuleType;
  value: string | number | null;
}

export type InvoiceFilters = Omit<CalculatedStatsFilter, 'operation'>;

const useEditInvoices = () => {
  const { t } = useTranslation();
  const showSnackbar = useSnackbar();
  const { refetchOneTakerMarket } = useRefetchStatsQueries();
  const { mutateAsync } = useUpdateCalculatedInvoices();

  const applyInvoiceOperation = async ({
    filters,
    operation,
    takerMarkets,
    toCurrency,
  }: {
    filters: InvoiceFilters;
    operation: InvoiceEditOperation;
    takerMarkets: TakerMarket[];
    toCurrency?: string;
  }) => {
    const takerMarketsByMakerOrg = groupTakerMarketsByMakerOrg(takerMarkets);
    const messages =
      operation === InvoiceEditOperation.EXCLUDE
        ? {
            onError: t('core.invoicesExcludedError'),
            onSuccess: t('core.invoicesExcluded'),
          }
        : {
            onError: t('core.invoicesIncludedError'),
            onSuccess: t('core.invoicesIncluded'),
          };

    /**
     * when an originalDueDate filter is included, we need to use a stepped operation to exclude everything TO a value
     * and a separate operation to exclude everything FROM a value for excluding invoices NOT BETWEEN
     * example:
     * [
     *   {
     *     "originalDueDate": {
     *       "to": "2025-08-03",
     *     },
     *     "amount": {
     *       "from": 1,
     *       "to": 2
     *     },
     *     "daysPaidEarly": {
     *       "from": 1,
     *       "to": 10
     *     },
     *     "operation": "EXCLUDE"
     *   },
     *   {
     *     "originalDueDate": {
     *       "from": "2025-08-28",
     *     },
     *    "amount": {
     *       "from": 1,
     *       "to": 2
     *     },
     *     "daysPaidEarly": {
     *       "from": 1,
     *       "to": 10
     *     },
     *     "operation": "EXCLUDE"
     *   }
     * ]
     */
    const mappedFilters: CalculatedStatsFilter[] = [];

    if (filters.originalDueDate) {
      const { originalDueDate, ...restFilters } = filters;

      if (originalDueDate.from) {
        mappedFilters.push({
          ...restFilters,
          originalDueDate: { from: originalDueDate.from },
          ...(restFilters.voucherSearchText && { all: true }), // must include all true for voucherId search
          operation,
        });
      }

      if (originalDueDate.to) {
        mappedFilters.push({
          ...restFilters,
          originalDueDate: { to: originalDueDate.to },
          ...(restFilters.voucherSearchText && { all: true }), // must include all true for voucherId search
          operation,
        });
      }
    } else {
      mappedFilters.push({
        ...filters,
        ...(filters.voucherSearchText && { all: true }), // must include all true for voucherId search
        operation,
      });
    }

    // flatten into an array of arguments for useUpdateCalculatedInvoices
    const editInvoiceArguments = Object.entries(takerMarketsByMakerOrg).flatMap(
      ([makerOrganizationUuid, currencies]) => {
        return Object.entries(currencies).flatMap(([currency, takersMarkets]) => {
          return {
            currency,
            filters: mappedFilters,
            makerOrganizationUuid,
            takersMarkets,
            toCurrency,
          };
        });
      }
    );

    const successes: typeof editInvoiceArguments = [];
    const errors: typeof editInvoiceArguments = [];

    // loop through each argument
    // Promise.allSettled() is used to keep track of which promises resolve/reject
    await Promise.allSettled(
      editInvoiceArguments.map(async (argument) => {
        try {
          await mutateAsync(argument);
          successes.push(argument);
        } catch {
          errors.push(argument);
        }
      })
    );

    // Refetch taker market data, to get new stats, for each impacted taker market
    takerMarkets.forEach((takerMarket) => {
      refetchOneTakerMarket({ marketUuid: takerMarket.marketUuid, takerId: takerMarket.takerDivisionId });
    });

    /**
     * TODO: confirm how we want to handle notifying the user of an
     * error that may only impact some of the requested include/exclude
     */
    // some promises rejected
    if (errors.length > 0) {
      showSnackbar({ message: messages.onError });
    } else {
      showSnackbar({ message: messages.onSuccess });
    }
  };

  const excludeInvoices = async ({
    filters,
    takerMarkets,
    toCurrency,
  }: {
    filters: InvoiceFilters;
    takerMarkets: TakerMarket[];
    toCurrency?: string;
  }) => await applyInvoiceOperation({ filters, operation: InvoiceEditOperation.EXCLUDE, takerMarkets, toCurrency });

  const includeInvoices = async ({
    filters,
    takerMarkets,
    toCurrency,
  }: {
    filters: InvoiceFilters;
    takerMarkets: TakerMarket[];
    toCurrency?: string;
  }) => await applyInvoiceOperation({ filters, operation: InvoiceEditOperation.INCLUDE, takerMarkets, toCurrency });

  return { excludeInvoices, includeInvoices };
};

export default useEditInvoices;

import { useMemo } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import colors from '@c2fo/liquidity/colors';
import useLocaleFormat from '@/utils/useLocaleFormat';
import { parseDate } from './utils';

/**
 * The data required for each chart.
 */
export interface HistoryChartDatum {
  /**
   * The date of the pay period.
   */
  payDate: string;
  /**
   * The weighted average APR for the pay period.
   */
  clearedAprWeightedAvg: number;
  /**
   * The total amount of cleared invoices for the pay period.
   */
  clearedInvoiceAmount: number;
}

export interface HistoryChartProps<T extends HistoryChartDatum> {
  data?: Array<T>;
  currency?: string;
}

const ONE_DAY = 24 * 60 * 60 * 1000;

export default function HistoryChart<T extends HistoryChartDatum>({
  data = [],
  currency = 'USD',
}: HistoryChartProps<T>) {
  const { asCurrency, asPercent, asDateString, asAbbreviatedNumber } = useLocaleFormat();

  const combinedData = useMemo(() => {
    const clearedIncomeData = data.map((item) => ({
      x: parseDate(item.payDate).valueOf(),
      y1: item.clearedInvoiceAmount,
      formattedY1: asCurrency(item.clearedInvoiceAmount, currency),
    }));

    return clearedIncomeData;
  }, [data, currency, asCurrency]);

  const barSize = useMemo(() => Math.max(600 / combinedData.length, 40), [combinedData]);

  return (
    <ResponsiveContainer height={300}>
      <ComposedChart data={combinedData}>
        <XAxis
          dataKey="x"
          type="number"
          domain={([dataMin, dataMax]) => {
            return [dataMin - ONE_DAY, dataMax + ONE_DAY];
          }}
          tickFormatter={(value) =>
            asDateString(value, {
              day: 'numeric',
              month: 'short',
              year: undefined,
            })
          }
        />
        <YAxis dataKey="y1" yAxisId="left" tickFormatter={(value) => asAbbreviatedNumber(value as number)} />
        <YAxis
          dataKey="y2"
          yAxisId="right"
          orientation="right"
          tickFormatter={(value) => {
            return asPercent({ value: value / 100, decimals: 2 });
          }}
        />
        <Tooltip
          formatter={(value, name) => {
            if (name === 'APR') {
              return [asPercent({ value: +value / 100, decimals: 2 }), name];
            }

            return [asCurrency(+value, currency), name];
          }}
          labelFormatter={(value) => asDateString(value)}
        />
        <Legend
          align="left"
          height={56}
          verticalAlign="top"
          wrapperStyle={{
            top: 0,
            left: 32,
          }}
        />
        <CartesianGrid stroke={colors.gray[100]} />
        <Bar
          name="Awarded A/R"
          dataKey="y1"
          yAxisId="left"
          barSize={barSize}
          maxBarSize={400}
          fill={colors.lightBlue[600]}
        ></Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
}

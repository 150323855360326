import ky from 'ky-universal';
import getToken from '@/utils/token';

const prefixUrl = import.meta.env.VITE_GATEWAY_URL;

/**
 * Wrapper for making API calls.
 * This autoamtically sets the baseurl and auth token.
 */
const apiClient = ky.create({
  prefixUrl,
  timeout: false,
  headers: {
    'Content-Type': 'application/json',
  },
  hooks: {
    beforeRequest: [
      (request) => {
        const token = getToken();
        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`);
        }
      },
    ],
  },
});

export default apiClient;

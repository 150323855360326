import { useQuery } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { EarlyPayDraftOfferByUuidQuery, QueryEarlyPayDraftOfferByUuidArgs } from '@/generated/gql/graphql';
import { gqlClient } from '@/lib/gqlClient';

const GET_DRAFT_OFFER = graphql(`
  query EarlyPayDraftOfferByUuid($uuid: String!) {
    earlyPayDraftOfferByUuid(uuid: $uuid) {
      created
      draftType
      expirationDate
      rate
      rateType
      spread
      marketUuid
      status
      submitterAuthServiceUuid
      submitterSystemName
      supplierDivisionUuids
      termExpirationDate
      type
      uuid
      authServiceUuid
    }
  }
`);

function fetchDraftOffer(variables: QueryEarlyPayDraftOfferByUuidArgs): Promise<EarlyPayDraftOfferByUuidQuery> {
  return gqlClient.request(GET_DRAFT_OFFER, variables);
}

export default function useDraftOffer(uuid: string | undefined) {
  return useQuery({
    enabled: !!uuid,
    queryKey: ['draft-offer', uuid],
    // Asserting with ! as we won't even enable the query without a uuid
    queryFn: () => fetchDraftOffer({ uuid: uuid! }),
    select: (data) => data.earlyPayDraftOfferByUuid,
  });
}

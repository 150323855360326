import { add } from 'date-fns';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';
import { parseIsoDateStringToDateCorrectTz } from './parseIsoDateStringToDate';

export const usePreferredOffersNearTermEnd = () => {
  const { data: takerMarkets = [] } = useTakerMarkets((takerMarkets: TakerMarket[]) => {
    return takerMarkets.filter(
      (tm) =>
        tm.offerConfig.offerType === 'PREFERRED_TERM' &&
        tm.disableAfterMarketClearsDate &&
        parseIsoDateStringToDateCorrectTz(tm.disableAfterMarketClearsDate) < add(new Date(), { days: 14 })
    );
  });

  return {
    hasPreferredOffersNearTermEnd: takerMarkets?.length > 0,
    takerMarketsNearTermEnd: takerMarkets,
  };
};

export const isPreferredOfferNearTermEnd = (takerMarket?: TakerMarket) => {
  return takerMarket
    ? takerMarket.offerConfig.offerType === 'PREFERRED_TERM' &&
        takerMarket.disableAfterMarketClearsDate &&
        parseIsoDateStringToDateCorrectTz(takerMarket.disableAfterMarketClearsDate) < add(new Date(), { days: 14 })
    : false;
};

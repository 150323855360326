// Returns true if currently running in Prod, false otherwise
export const isProd = () => {
  const env = import.meta.env.VITE_ENV;
  return env.startsWith('prod');
};

// Returns string of the current environment
export const getEnvName = (): string => {
  return import.meta.env.VITE_ENV;
};

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAgreementsUrl } from '@/features/agreements/utils/useAgreementsUrl';
import SrmCard from '@/features/srmCard/SrmCard';

const useShowFooter = () => {
  const { pathname } = useLocation();
  const pathsToHideFooter = ['recurring-rules/create'];

  return !pathsToHideFooter.some((path) => pathname.includes(path));
};

export default function Footer() {
  const { t } = useTranslation();
  const showFooter = useShowFooter();
  const year = new Date().getFullYear();

  const { privacyLink, termsOfUseLink } = useAgreementsUrl();

  return showFooter ? (
    <>
      <SrmCard />
      <div className="container mx-auto mt-5 flex h-16 items-center">
        <div className="w-full space-x-2 text-center">
          <span className="inline">{t('core.legalCopyright', { year })}</span>
          <a className="inline-block" href={privacyLink}>
            {t('footer.privacyNoticeLinkText')}
          </a>
          <a className="inline-block" href={termsOfUseLink}>
            {t('footer.termsOfUseLinkText')}
          </a>
        </div>
      </div>
    </>
  ) : null;
}

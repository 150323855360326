import { cn } from '@c2fo/liquidity';

interface SkeletonProps {
  /**
   * Custom className overrides.
   */
  className?: string;
  /**
   * The height of the skeleton loader as a tailwind h- class - defaults to h-80 (20rem/320px).
   */
  height?: string;
  /**
   * Whether to display a shadow - defaults to true.
   */
  shadow?: boolean;
}

const Skeleton = ({ className, height = 'h-80', shadow = true }: SkeletonProps) => {
  return <div className={cn('animate-pulse rounded-md bg-gray-300', height, { 'shadow-md': shadow }, className)} />;
};

export default Skeleton;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { MutationToggleOfferArgs } from '@/generated/gql/graphql';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';
import { gqlClient } from '@/lib/gqlClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { useQualtrics } from '@/reporting/Qualtrics';

export const TOGGLE_OFFER = graphql(`
  mutation ToggleOffer($marketUuid: String!, $supplierDivisionUuid: String!) {
    toggleOffer(marketUuid: $marketUuid, supplierDivisionUuid: $supplierDivisionUuid) {
      enabled
    }
  }
`);

const useToggleOffer = () => {
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();
  const { subscribeToMarketStats } = useServerSideEventListeners();
  const { triggerNPSSurvey } = useQualtrics();

  return useMutation({
    mutationKey: ['toggleOffer'],
    mutationFn: ({ marketUuid, supplierDivisionUuid }: MutationToggleOfferArgs) => {
      const input = { marketUuid, supplierDivisionUuid };
      return gqlClient.request(TOGGLE_OFFER, input);
    },
    onSuccess: (_data, variables) => {
      const tmMeta = takerMarkets?.find(
        (meta) => meta.marketUuid === variables.marketUuid && meta.takerDivisionUuid === variables.supplierDivisionUuid
      );

      if (tmMeta?.marketUuid && tmMeta?.takerDivisionId) {
        // update the cache with the returned data
        queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) =>
          (prevTakerMarkets ?? []).map((tm) => {
            const isTogglingOff = tmMeta.offerConfig.isEnabled === true; // config was true on existing tm data (before toggle called)
            if (tm.marketUuid === variables.marketUuid && tm.takerDivisionUuid === variables.supplierDivisionUuid) {
              // At this time, only price discovery offers will reset an expiration date when toggled off
              const preemptivelyResetExpirationDate = isTogglingOff && tmMeta.offerConfig.type === 'PRICE_DISCOVERY';
              return {
                ...tm,
                offerConfig: {
                  ...tm.offerConfig,
                  isEnabled: !tmMeta.offerConfig.isEnabled,
                },
                disableAfterMarketClearsDate: preemptivelyResetExpirationDate ? null : tm.disableAfterMarketClearsDate,
              };
            }

            return tm;
          })
        );

        // subscribe to market stats
        // this will refetch stats queries after we know stats have updated
        subscribeToMarketStats({
          marketUuid: tmMeta.marketUuid,
          takerId: tmMeta.takerDivisionId,
        });
      }

      setTimeout(() => {
        const isEnabled = !tmMeta?.offerConfig.isEnabled;
        if (isEnabled) {
          triggerNPSSurvey();
        }
      }, 3000);
    },
  });
};

export default useToggleOffer;

import { useMutation } from '@tanstack/react-query';

export interface RecurringRuleArgument {
  makerOrganizationUuid: string;
  takerMarkets: {
    marketId: number;
    takerId: number;
  }[];
  minDpe?: string;
  maxDpe?: string;
  minDueDate?: string;
  maxDueDate?: string;
  minInvoiceAmount?: string;
  maxInvoiceAmount?: string;
  excludedVoucherIds?: string;
}

const useCreateRecurringRule = () => {
  return useMutation({
    mutationKey: ['createRecurringRule'],
    mutationFn: (rule: RecurringRuleArgument) => {
      return new Promise((resolve) => {
        resolve(console.log(rule));
      });
    },
  });
};

export default useCreateRecurringRule;

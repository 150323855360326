import { Outlet } from 'react-router-dom';
import HomepageHeader from '@/components/HomepageHeader';
import AggregateStatsBanner from '@/features/aggregateStatsBanner/AggregateStatsBanner';
import CapFinTable from '@/features/capFinTable/CapFinTable';
import PayDateAnnouncements from '@/features/payDateAnnouncements/PayDateAnnouncements';
import { BannerAnnouncements } from './BannerAnnouncements';

const HomepageLayout = () => {
  return (
    <>
      <HomepageHeader />
      <div className="flex flex-col gap-6">
        <AggregateStatsBanner />
        <BannerAnnouncements />
      </div>
      <Outlet />
      <PayDateAnnouncements />
      <CapFinTable />
    </>
  );
};

export default HomepageLayout;

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn, IconButton, Paper } from '@c2fo/liquidity';
import { TrashCanIcon } from '@c2fo/liquidity/icons';
import EllipseTooltip from '@/components/EllipseTooltip';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSortArrow,
  TableSortDirection,
} from '@/components/Table';
import { useActiveRecurringRules } from '@/data/useActiveRecurringRules';
import useLocaleFormat from '@/utils/useLocaleFormat';
import Pagination, { getStartEnd } from './components/Pagination';
import SetRuleCriteria from './SetRuleCriteria';
import { getDpeRule, getDueDateRule, getInvoiceAmountRule, getInvoiceIdsRule } from './utils/getRulesTableTitles';
import sortRulesTable, { Sort, SortKey } from './utils/sortRulesTable';

const DivisionTitle = ({
  takerMarketName,
  currency,
  buyerDivisionName,
}: {
  takerMarketName: string;
  currency: string;
  buyerDivisionName?: JSX.Element;
}) => {
  return (
    <div className="flex flex-col">
      <EllipseTooltip className="font-medium text-text-primary">{takerMarketName}</EllipseTooltip>
      <div className="text-sm text-text-primary">{currency}</div>
      <EllipseTooltip className="text-sm text-text-primary">{buyerDivisionName}</EllipseTooltip>
    </div>
  );
};

const RulesTable = () => {
  const { t } = useTranslation();
  const recurringRules = useActiveRecurringRules();
  const { asCurrency } = useLocaleFormat();
  const ref = useRef<HTMLDivElement>(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [sort, setSort] = useState<Sort>({ key: 'eligibleInvoiceAmount', direction: 'desc' });
  const sortedRules = sortRulesTable(recurringRules, sort);
  const totalCount = recurringRules.length;
  const { start, end } = getStartEnd({ limit, page, totalCount });

  const [isScrollable, setIsScrollable] = useState(false);

  const onPageSizeChange = (limit: number) => {
    setLimit(limit);
    setPage(1);
  };

  const onSort = (accessorKey: SortKey) => {
    let direction: TableSortDirection = accessorKey === 'takerMarketName' ? 'asc' : 'desc';

    if (sort?.key === accessorKey) {
      direction = sort.direction === 'desc' ? 'asc' : 'desc';
    }

    setSort({ key: accessorKey, direction });
  };

  const findScrollable = () => {
    const scrollable = ref.current && ref.current.offsetWidth < ref.current.scrollWidth;
    setIsScrollable(!!scrollable);
  };

  useEffect(() => {
    findScrollable();
    window.addEventListener('resize', findScrollable);
  });

  const tableHeadColumns: { accessorKey?: SortKey; translation: string; className: string }[] = [
    {
      accessorKey: 'takerMarketName',
      translation: t('core.division'),
      className: cn('sticky left-0 top-0 z-10 w-60 bg-white', { 'drop-shadow-lg': isScrollable }),
    },
    {
      accessorKey: 'eligibleInvoiceAmount',
      translation: t('core.eligibleAr'),
      className: 'w-36 pr-4',
    },
    {
      translation: t('core.daysPaidEarly'),
      className: 'w-48 text-center',
    },
    {
      translation: t('core.dueDate'),
      className: 'w-48 text-center',
    },
    {
      translation: t('core.amount'),
      className: 'w-48 text-center',
    },
    {
      translation: t('core.invoiceId'),
      className: 'w-48 text-center',
    },
    {
      translation: '',
      className: 'w-20 text-center',
    },
  ];

  return (
    <div>
      <h2 className="m-0 pb-8 text-2xl">{t('recurringRules.activeRules')}</h2>
      <Paper elevation={2}>
        <div className="w-full overflow-auto p-4">
          <div ref={ref} className="overflow-auto rounded-md border-2 border-gray-200">
            <Table className="table-fixed">
              <TableHeader>
                <TableRow>
                  {tableHeadColumns.map(({ accessorKey, translation, className }) => (
                    <TableHead
                      key={translation}
                      className={cn('px-2 py-4', className)}
                      {...(!!accessorKey && { onClick: () => onSort(accessorKey) })}
                    >
                      {!!accessorKey ? (
                        <TableSortArrow
                          accessorKey={accessorKey}
                          sort={sort}
                          {...(['eligibleInvoiceAmount'].includes(accessorKey) && { textRight: true })}
                        >
                          {translation}
                        </TableSortArrow>
                      ) : (
                        translation
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedRules.slice(start - 1, end).map((rule) => {
                  const {
                    takerMarketName,
                    currency,
                    buyerDivisionName,
                    minDpe,
                    maxDpe,
                    minDueDate,
                    maxDueDate,
                    minInvoiceAmount,
                    maxInvoiceAmount,
                    excludedVoucherIds,
                  } = rule;
                  return (
                    <TableRow key={rule.id}>
                      <TableCell className={cn('sticky left-0 z-10 bg-white', { 'drop-shadow-lg': isScrollable })}>
                        <DivisionTitle
                          takerMarketName={takerMarketName}
                          currency={currency}
                          buyerDivisionName={buyerDivisionName}
                        />
                      </TableCell>
                      <TableCell className="truncate border-r pr-4 text-end">
                        {asCurrency(rule.eligibleInvoiceAmount, rule.currency)}
                      </TableCell>
                      <TableCell className="border-r">
                        <SetRuleCriteria {...getDpeRule({ minDpe, maxDpe })} />
                      </TableCell>
                      <TableCell className="border-r">
                        <SetRuleCriteria {...getDueDateRule({ minDueDate, maxDueDate })} />
                      </TableCell>
                      <TableCell className="border-r">
                        <SetRuleCriteria {...getInvoiceAmountRule({ minInvoiceAmount, maxInvoiceAmount })} />
                      </TableCell>
                      <TableCell className="border-r">
                        <SetRuleCriteria {...getInvoiceIdsRule(excludedVoucherIds)} />
                      </TableCell>
                      <TableCell className="px-0 last-of-type:pr-0">
                        <div className="flex items-center justify-center">
                          <IconButton name="delete-rule" variant="destructive" size="md" inverse icon={TrashCanIcon} />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <div className="flex justify-center border-t p-4">
              <Pagination
                limit={limit}
                onPageChange={setPage}
                onPageSizeChange={onPageSizeChange}
                page={page}
                totalCount={totalCount}
              />
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default RulesTable;

import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 3,
      retryDelay: 3000,
      suspense: true,
      useErrorBoundary: true,
    },
    mutations: {
      networkMode: 'always',
    },
  },
});

// setup the sever sent events listener store
queryClient.setQueryData(['server-sent-event-listeners'], {
  marketStats: [],
});

// setup the user selected currency store, defaults to USD
queryClient.setQueryData(['selected-currency'], 'USD');

export default queryClient;

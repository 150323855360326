import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Regular expression to find ISO date strings (YYYY-MM-DD)
 */
const isoDateStringRegex = /\b\d{4}-\d{2}-\d{2}\b/;

export function useLocaleFormat(locale?: string, options?: Intl.RelativeTimeFormatOptions) {
  const { i18n } = useTranslation();
  const language = locale ?? i18n.language;
  const rtf = useMemo(
    () =>
      new Intl.RelativeTimeFormat(language, {
        // localeMatcher: 'best fit',
        numeric: 'auto',
        style: 'long',
        ...options,
      }),
    [language, options]
  );

  const asRelativeDate = (date: string | Date, start: string | Date = new Date()) => {
    date = new Date(date);
    start = new Date(start);
    // get the number of days between them
    const diff = Math.floor((start.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
    if (diff < 32) {
      return rtf.format(-diff, 'day');
    }

    if (diff < 365) {
      return rtf.format(-Math.floor(diff / 30), 'month');
    }

    return rtf.format(-Math.floor(diff / 365), 'year');
  };

  const asDateString = (value: string | Date, formatOptions?: Intl.DateTimeFormatOptions) => {
    let date: Date;

    if (typeof value === 'string' && isoDateStringRegex.test(value)) {
      date = new Date(`${value}T00:00:00`);
    } else {
      date = new Date(value);
    }

    const formatter = new Intl.DateTimeFormat(language, {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      ...formatOptions,
    });
    const formattedDate = formatter.format(date);
    return formattedDate;
  };

  const asISODateString = (value: string | Date, formatOptions?: Intl.DateTimeFormatOptions) => {
    let date: Date;

    if (typeof value === 'string' && isoDateStringRegex.test(value)) {
      date = new Date(`${value}T00:00:00`);
    } else {
      date = new Date(value);
    }

    const formatter = new Intl.DateTimeFormat(language, {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      ...formatOptions,
    });
    const { year, month, day } = Object.fromEntries(
      formatter
        .formatToParts(date)
        .filter(({ type }) => type !== 'literal')
        .map(({ type, value }) => [type, value])
    );
    return `${year}-${month}-${day}`;
  };

  const formatter = useMemo(
    () => (options?: Intl.NumberFormatOptions) => new Intl.NumberFormat(language, options),
    [language]
  );

  const asCurrency = (value: number, currency: string) =>
    formatter({
      style: 'currency',
      currency: currency,
    }).format(value);

  // asNumber defaults to no decimal places
  const asNumber = ({ decimals, value }: { decimals?: number; value: number }) => {
    const options = decimals
      ? { minimumFractionDigits: decimals, maximumFractionDigits: decimals }
      : { minimumFractionDigits: 0, maximumFractionDigits: 0 };

    return formatter({ ...options }).format(value);
  };

  const asAbbreviatedNumber = (num: number, decimalPlaces = 0) => {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(decimalPlaces) + 'B';
    }

    if (num >= 1e6) {
      return (num / 1e6).toFixed(decimalPlaces) + 'M';
    }

    if (num >= 1e3) {
      return (num / 1e3).toFixed(decimalPlaces) + 'K';
    }

    return `${num}`;
  };

  // asPercent defaults to no decimal places
  const asPercent = ({ decimals, value }: { decimals?: number; value: number }) => {
    const options = decimals
      ? { minimumFractionDigits: decimals, maximumFractionDigits: decimals }
      : { minimumFractionDigits: 0, maximumFractionDigits: 0 };

    return formatter({ style: 'percent', ...options }).format(value);
  };

  return {
    asRelativeDate,
    asDateString,
    asISODateString,
    asCurrency,
    asNumber,
    asPercent,
    asAbbreviatedNumber,
  };
}

export default useLocaleFormat;

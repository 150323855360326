import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@c2fo/liquidity';
import { TimesIcon } from '@c2fo/liquidity/icons';
import { useReporting } from '@/reporting';

interface BulkInvoiceActionModalProps {
  open: boolean;
  onClose: () => void;
  bulkActionType: 'exclude' | 'include' | null;
  takerMarketName: string;
  includedInvoiceCount: number;
  excludedInvoiceCount: number;
  includedInvoiceAmount: string;
  excludedInvoiceAmount: string;
  toggleBulkInvoiceInclusionExclusion: (action: 'include' | 'exclude') => void;
}

const BulkInvoiceActionButton = (
  props: Omit<
    BulkInvoiceActionModalProps,
    'takerMarketName' | 'includedInvoiceAmount' | 'excludedInvoiceAmount' | 'open'
  >
) => {
  const { bulkActionType, toggleBulkInvoiceInclusionExclusion, onClose, excludedInvoiceCount, includedInvoiceCount } =
    props;
  const { t } = useTranslation();
  const { track } = useReporting();

  // type guard if bulkActionType is sent as null for some reason
  if (!bulkActionType) {
    return null;
  }

  const buttonProps = {
    className: 'w-full text-sm sm:w-auto sm:text-lg',
    onClick: () => {
      toggleBulkInvoiceInclusionExclusion(bulkActionType);
      track(`invoice-${bulkActionType}-all::clicked`);
      onClose();
    },
  };

  return bulkActionType === 'include' ? (
    <Button variant="cta" {...buttonProps}>
      {t('core.includeInvoices') + ` (${excludedInvoiceCount})`}
    </Button>
  ) : (
    <Button variant="destructive" {...buttonProps}>
      {t('core.excludeInvoices') + ` (${includedInvoiceCount})`}
    </Button>
  );
};

const BulkInvoiceActionModal = ({
  takerMarketName,
  includedInvoiceAmount,
  excludedInvoiceAmount,
  open,
  ...props
}: BulkInvoiceActionModalProps) => {
  const { t } = useTranslation();

  const config = {
    exclude: {
      amount: includedInvoiceAmount,
      count: props.includedInvoiceCount,
      info: t('core.bulkExcludeInvoicesInfo'),
      title: t('core.excludeInvoices'),
      warning: t('core.bulkExcludeInvoicesWarning'),
    },
    include: {
      amount: excludedInvoiceAmount,
      count: props.excludedInvoiceCount,
      info: t('core.bulkIncludeInvoicesInfo'),
      title: t('core.includeInvoices'),
      warning: t('core.bulkIncludeInvoicesWarning'),
    },
  };

  // type guard if bulkActionType is sent as null for some reason
  if (!props.bulkActionType) {
    return null;
  }

  return (
    <Modal className="z-10 flex flex-col" open={open} variant="custom">
      <div className="flex h-full flex-col p-4 sm:h-fit sm:w-[28rem] sm:p-6">
        <div className="flex flex-row">
          <div className="text-xl font-medium">{config[props.bulkActionType].title}</div>
          <button className="ml-auto rounded-md text-xl hover:bg-gray-100" type="button" onClick={props.onClose}>
            <TimesIcon className="h-6 w-6 fill-gray-900" />
          </button>
        </div>
        <div className="space-y-3 pt-6">{config[props.bulkActionType].info}</div>
        <div className="space-y-3 pt-6 text-gray-700">{takerMarketName}</div>
        <div className="flex flex-col space-y-3 pt-1 text-xl font-normal">
          {t('core.bulkInvoicesCount', {
            count: config[props.bulkActionType].count,
          })}
          <span>{config[props.bulkActionType].amount}</span>
        </div>
        <div className="space-y-3 pt-6">{config[props.bulkActionType].warning}</div>
      </div>
      <div className="border-t shadow-[0_-3px_3px_0px_rgb(0,0,0,0.035)]">
        <div className="flex flex-col justify-end space-y-3 px-5 py-6 sm:flex-row sm:space-x-3 sm:space-y-0">
          <Button variant="secondary" onClick={props.onClose} className="w-full text-sm sm:w-auto sm:text-lg">
            {t('core.cancel')}
          </Button>
          <BulkInvoiceActionButton {...props} />
        </div>
      </div>
    </Modal>
  );
};

export default BulkInvoiceActionModal;

import { useQuery } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { GetPreferredOfferConfigQuery, QueryGetPreferredOfferConfigArgs } from '@/generated/gql/graphql';
import useFeature, { Features } from '@/lib/features';
import { gqlClient } from '@/lib/gqlClient';
import { usePreferredOfferIsActive } from '@/utils/usePreferredOfferIsActive';
import useUserDetails from './useUserDetails';

const GET_PREFERRED_OFFER_CONFIG = graphql(`
  query GetPreferredOfferConfig($accountId: String!) {
    getPreferredOfferConfig(accountId: $accountId) {
      accountId
      canEditInvoices
    }
  }
`);

function fetchPreferredOfferConfig(variables: QueryGetPreferredOfferConfigArgs): Promise<GetPreferredOfferConfigQuery> {
  return gqlClient.request(GET_PREFERRED_OFFER_CONFIG, variables);
}

export default function useAccountPreferredConfig() {
  const [preferredOffersEnabled] = useFeature(Features['enterprise-ui_enablePreferredOffers']);
  const { data: userData } = useUserDetails();
  const hasActivePreferredOffer = usePreferredOfferIsActive();

  const accountId = userData?.me?.account?.id;

  return useQuery({
    // Only run this query if we actually need the data
    enabled: !!accountId && hasActivePreferredOffer && preferredOffersEnabled,
    queryKey: ['preferred-offer-config', accountId],
    queryFn: () => fetchPreferredOfferConfig({ accountId: accountId! }),
    select: (data) => data.getPreferredOfferConfig,
    // No need for this query to crash the app, everything should fallback to a cannot edit state
    useErrorBoundary: false,
  });
}

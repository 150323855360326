import { useQueryClient } from '@tanstack/react-query';
import QueryBoundaries from '@/components/QueryBoundaries';
import { useReporting } from '@/reporting';

/**
 * fires a track any time a useMutation error occurs
 */
const MutationErrorTrackerComponent = () => {
  const { track } = useReporting();
  const queryClient = useQueryClient();

  queryClient.setMutationDefaults([], {
    onError(error) {
      track('mutation::error', {
        mutationKey: this?.mutationKey ?? 'undefinedMutationKey',
        errorMessage: error instanceof Error ? error.message : 'Unknown mutation error',
      });
    },
  });

  return null;
};

const MutationErrorTracker = () => (
  <QueryBoundaries>
    <MutationErrorTrackerComponent />
  </QueryBoundaries>
);

export default MutationErrorTracker;

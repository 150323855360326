import { useMutation } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

const useMagicLogin = () => {
  return useMutation({
    mutationKey: ['magicLogin'],
    mutationFn: ({ magicLoginToken }: { magicLoginToken: string }) =>
      apiClient
        .post(`api/c2fo/user-source/magic-login`, {
          json: { token: magicLoginToken },
          headers: {
            Authorization: undefined,
          },
        })
        .json<{ jwt: string }>(),
  });
};

export default useMagicLogin;

import { removeSessionTrackingData } from '@/reporting/sessionTrackingData';

const redirectUrl = window.location.origin;
const loginUrl = import.meta.env.VITE_LOGIN_URL;

/**
 * Remove session tracking data, clear the token from local storage and redirect to the login page.
 * @param redirectPath The path to redirect to after login. Defaults to the current path.
 */
export default function reauthenticate(redirectPath?: string) {
  const { pathname, search } = window.location;
  const newSearch = new URLSearchParams(search);

  // remove session tracking data when a user is directed to reauthenticate to prevent inconsistency in reporting
  removeSessionTrackingData();

  // remove token and authorization from search params
  newSearch.delete('token');
  newSearch.delete('authorization');
  // remove redirect-to from search params
  newSearch.delete('redirect-to');

  const url = new URL(redirectPath ?? pathname, redirectUrl);
  url.search = newSearch.toString();

  const redirectAfterLogin = encodeURIComponent(url.toString());

  // since we're logging out, directly remove the token from
  // localStorage but don't worry about invalidating the query cache
  // since we're redirecting to the login page anyway
  const tokenName = import.meta.env.VITE_TOKEN_NAME;
  localStorage.removeItem(tokenName);

  const redirect = `${loginUrl}?redirect-to=${redirectAfterLogin}`;
  window.location.replace(redirect);
}

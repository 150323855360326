import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import QueryBoundaries from '@/components/QueryBoundaries';
import useUserDetails from '@/data/useUserDetails';

const validImageUrl = (url: string) => {
  const img = new Image();
  img.src = url;

  return new Promise<boolean>((resolve) => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};

const useSrmImage = (url: string) =>
  useQuery({
    enabled: !!url,
    queryKey: ['srm-image', url],
    queryFn: () => validImageUrl(url),
  });

const fallbackImage =
  'https://c2fo-static.s3-us-west-2.amazonaws.com/app/srm-images/srm-transparent/customerservice@c2fo.com.png';

export const useSrm = () => {
  const { t } = useTranslation();
  const { data: srm } = useUserDetails((userDetails) => {
    const srm = userDetails.me?.account?.srm;
    return srm && srm.emailAddress
      ? {
          calendarLink: srm?.calendarLink ?? null,
          emailAddress: srm.emailAddress,
          image: `https://c2fo-static.s3-us-west-2.amazonaws.com/app/srm-images/srm-transparent/${srm.emailAddress}.png`,
          name: srm?.firstName && srm?.lastName ? `${srm.firstName} ${srm.lastName}` : null,
          phoneNumber: srm?.phoneNumber ? srm.phoneNumber : null,
        }
      : {
          calendarLink: 'https://outlook.office365.com/owa/calendar/C2FOSupplierSupportTeam@c2fo.com/bookings/',
          emailAddress: 'support-amer@c2fo.com',
          image: fallbackImage,
          name: t('srm.accountSupport'),
          phoneNumber: '(866) 463-6565',
        };
  });

  const { data: validSrmImageUrl = false } = useSrmImage(srm?.image ?? fallbackImage);

  const image = validSrmImageUrl && srm?.image ? srm.image : fallbackImage;

  return { ...srm, image };
};

const SrmCardComponent = () => {
  const srm = useSrm();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-2">
      <div>{t('srm.contactMessage')}</div>
      <div className="flex w-full max-w-[420px] items-center justify-start gap-5 rounded-full bg-white px-5 py-4 shadow-md">
        {srm?.image ? (
          <div className="flex h-20 w-20 items-center justify-center overflow-hidden rounded-full bg-blue-500 sm:h-24 sm:w-24">
            <img alt={srm?.emailAddress ?? srm.image} src={srm.image} className="ml-2.5" />
          </div>
        ) : null}
        <div className="font-medium">
          {srm?.name ? <div>{srm.name}</div> : null}
          {srm?.emailAddress ? (
            <div>
              <a href={`mailto:${srm.emailAddress}`} className="text-blue-500">
                {srm.emailAddress}
              </a>
            </div>
          ) : null}
          {/* Some srm have two phone numbers split with a / */}
          {srm?.phoneNumber
            ? srm.phoneNumber.split('/').map((phoneNumber) => (
                <div key={phoneNumber}>
                  <a href={`tel:${phoneNumber}`} className="text-blue-500">
                    {phoneNumber}
                  </a>
                </div>
              ))
            : null}
          {srm?.calendarLink ? (
            <div>
              <a href={srm.calendarLink} rel="noreferrer" target="_blank" className="text-blue-500">
                {t('srm.scheduleACall')}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const SrmCard = () => (
  <QueryBoundaries>
    <SrmCardComponent />
  </QueryBoundaries>
);

export default SrmCard;

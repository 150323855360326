import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import getTakerMarketName from '@/utils/getTakerMarketName';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useTakerMarkets from './useTakerMarkets';

/*
  Contract for storing rule
  Rule
  =============
  id: UUID,
  takerId: Long, 
  marketId: Long, 
  minDpe: Int, 
  maxDpe: Int, 
  minDueDate: LocalDate, 
  maxDueDate: LocalDate, 
  minInvoiceAmount: BigDecimal, 
  maxInvoiceAmount: BigDecimal, 
  // I don't think we're doing any inclusion type rules?
  includedVoucherIds: String[], 
  excludedVoucherIds: String[]
*/

interface Rule {
  id: string;
  takerId: number;
  marketId: number;
  minDpe?: number;
  maxDpe?: number;
  // Number? String?
  minDueDate?: Date;
  maxDueDate?: Date;
  minInvoiceAmount?: number;
  maxInvoiceAmount?: number;
  excludedVoucherIds?: string[];
}

// Temporary mock data that map to rules for einar.weissnat@bergnaum.com
const mockRules: Rule[] = [
  {
    id: '1',
    takerId: 3563896,
    marketId: 3422,
    minDpe: 10,
  },
  {
    id: '2',
    takerId: 3563178,
    marketId: 3281,
    minDueDate: new Date('2024-09-10'),
    maxDueDate: new Date('2024-09-15'),
  },
  {
    id: '3',
    takerId: 337053,
    marketId: 921,
    maxInvoiceAmount: 1000,
  },
  {
    id: '4',
    takerId: 3563871,
    marketId: 3383,
    excludedVoucherIds: ['mock-voucher-id-1', 'mock-voucher-id-2'],
  },
  {
    id: '5',
    takerId: 3608169,
    marketId: 3606,
    maxDpe: 5,
  },
  {
    id: '6',
    takerId: 3563410,
    marketId: 3468,
    minInvoiceAmount: 1000,
    maxInvoiceAmount: 5000,
  },
];

// Temporary mock function for fetching recurring rules
const fetchRecurringRules = () => {
  return mockRules;
};

export interface RecurringRule {
  id: string;
  takerMarketName: string;
  currency: string;
  buyerDivisionName?: JSX.Element;
  eligibleInvoiceAmount: number;
  minDpe?: number;
  maxDpe?: number;
  minDueDate?: string;
  maxDueDate?: string;
  minInvoiceAmount?: string;
  maxInvoiceAmount?: string;
  excludedVoucherIds?: string[];
}

export const useActiveRecurringRules = () => {
  const { asCurrency } = useLocaleFormat();

  const rules = fetchRecurringRules();

  // How to make matching rule to takerMarket more efficient?
  // const takerMarketsWithRules = rules.map((rule) => { return { takerId: rule.takerId, marketId: rule.marketId} });

  // const { data: takerMarkets = [] } = useTakerMarkets((takerMarkets: TakerMarket[]) => {
  //   return takerMarkets.filter(
  //     (tm) => takerMarketsWithRules.some((tmwr) => tmwr.takerId === tm.takerDivisionId && tmwr.marketId.toString() === tm.marketId)
  //   );
  // });

  const { data: takerMarkets = [] } = useTakerMarkets();

  const res = rules.reduce<RecurringRule[]>((recurringRules, rule) => {
    const takerMarket = takerMarkets.find(
      (tm) => tm.takerDivisionId === rule.takerId && tm.legacyMarketId === rule.marketId
    );

    if (takerMarket) {
      recurringRules.push({
        id: rule.id,
        takerMarketName: getTakerMarketName(takerMarket),
        currency: takerMarket.currency,
        buyerDivisionName: getTakerMarketDivisionTitle(takerMarket)?.content,
        eligibleInvoiceAmount: takerMarket.eligibleInvoiceAmount,
        minDpe: rule.minDpe,
        maxDpe: rule.maxDpe,
        minDueDate: rule.minDueDate ? trimDateTimestamp(rule.minDueDate.toISOString()) : undefined,
        maxDueDate: rule.maxDueDate ? trimDateTimestamp(rule.maxDueDate.toISOString()) : undefined,
        minInvoiceAmount: rule.minInvoiceAmount ? asCurrency(rule.minInvoiceAmount, takerMarket.currency) : undefined,
        maxInvoiceAmount: rule.maxInvoiceAmount ? asCurrency(rule.maxInvoiceAmount, takerMarket.currency) : undefined,
        excludedVoucherIds: rule.excludedVoucherIds,
      });
    }

    return recurringRules;
  }, []);

  return res;
};

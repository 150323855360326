import { Dispatch, Reducer, useReducer } from 'react';

interface StateWithHistory<T> {
  prev: T | null;
  curr: T;
}

type ReducerWithHistory<S, A> = (prevState: S, action: A) => S;

/**
 * useReducer with history. A wrapper around the native useReducer hook that keeps track of the previous state.
 * @param reducer The reducer function
 * @param initialState The initial state
 */
export default function useReducerWithHistory<S, A>(
  reducer: ReducerWithHistory<S, A>,
  initialState: S
): [StateWithHistory<S>, Dispatch<A>] {
  const reducerWithHistory: Reducer<StateWithHistory<S>, A> = (state, action) => {
    return {
      prev: state.curr,
      curr: reducer(state.curr, action),
    };
  };

  return useReducer(reducerWithHistory, {
    prev: null,
    curr: initialState,
  });
}

const F = {
  gray: {
    50: "#F7F7F7",
    100: "#EFEFEF",
    200: "#DDDDDD",
    300: "#CACACA",
    400: "#B6B6B6",
    500: "#9E9E9E",
    600: "#8D8D8D",
    700: "#7A7A7A",
    800: "#646464",
    900: "#474747"
  },
  deepGray: {
    50: "#F3F3F3",
    100: "#E5E5E5",
    200: "#C9C9C9",
    300: "#A7A7A7",
    400: "#7D7D7D",
    500: "#393939",
    600: "#333333",
    700: "#2C2C2C",
    800: "#242424",
    900: "#191919"
  },
  green: {
    50: "#F2F8F4",
    100: "#E4F0E9",
    200: "#C6E0D1",
    300: "#A1CFB6",
    400: "#72BC95",
    500: "#06A76C",
    600: "#059561",
    700: "#058154",
    800: "#046A44",
    900: "#034B30"
  },
  blue: {
    50: "#F2F4F7",
    100: "#E4E9EE",
    200: "#C6D0DB",
    300: "#A2B3C7",
    400: "#7391B0",
    500: "#136596",
    600: "#115A86",
    700: "#0F4E74",
    800: "#0C405F",
    900: "#082D43"
  },
  red: {
    50: "#FCF3F3",
    100: "#F9E7E6",
    200: "#F3CCCA",
    300: "#EDACA9",
    400: "#E78680",
    500: "#E14F42",
    600: "#C9473B",
    700: "#AE3D33",
    800: "#8E322A",
    900: "#65231E"
  },
  pink: {
    50: "#FCF4F8",
    100: "#F8E8F1",
    200: "#F1CEE3",
    300: "#EAB1D4",
    400: "#E38DC3",
    500: "#DB5DB1",
    600: "#C4539E",
    700: "#AA4889",
    800: "#8B3B70",
    900: "#622A4F"
  },
  purple: {
    50: "#F9F5FC",
    100: "#F2EAF9",
    200: "#E5D4F2",
    300: "#D7BAEB",
    400: "#C79DE4",
    500: "#B778DD",
    600: "#A46BC6",
    700: "#8E5DAB",
    800: "#744C8C",
    900: "#523663"
  },
  deepPurple: {
    50: "#F4F3F8",
    100: "#E9E6F0",
    200: "#D1CAE1",
    300: "#B5AAD0",
    400: "#9482BE",
    500: "#6A46AA",
    600: "#5F3F98",
    700: "#523684",
    800: "#432C6C",
    900: "#2F1F4C"
  },
  lightBlue: {
    50: "#F3F7FB",
    100: "#E5EFF8",
    200: "#C8DDF1",
    300: "#A7CAE9",
    400: "#7CB5E1",
    500: "#369DD9",
    600: "#308CC2",
    700: "#2A7AA8",
    800: "#226389",
    900: "#184661"
  },
  indigo: {
    50: "#F3F3FC",
    100: "#E7E6F8",
    200: "#CDCAF1",
    300: "#AEA9EA",
    400: "#8980E3",
    500: "#5541DB",
    600: "#4C3AC4",
    700: "#4232AA",
    800: "#36298B",
    900: "#261D62"
  },
  teal: {
    50: "#F2F9FB",
    100: "#E4F3F7",
    200: "#C6E7EF",
    300: "#A1D9E6",
    400: "#72CBDD",
    500: "#00BCD4",
    600: "#00A8BE",
    700: "#0092A4",
    800: "#007786",
    900: "#00545F"
  },
  lightGreen: {
    50: "#F5FAF8",
    100: "#EAF5F0",
    200: "#D3EBE1",
    300: "#B9E0D0",
    400: "#9AD4BD",
    500: "#74C8A9",
    600: "#68B397",
    700: "#5A9B83",
    800: "#497E6B",
    900: "#34594C"
  },
  lime: {
    50: "#F7FBF3",
    100: "#EEF6E6",
    200: "#DDEDCA",
    300: "#C9E4AA",
    400: "#B4DA82",
    500: "#9BD045",
    600: "#8BBA3E",
    700: "#78A135",
    800: "#62842C",
    900: "#455D1F"
  },
  yellow: {
    50: "#FEF9F2",
    100: "#FCF4E4",
    200: "#FAE8C6",
    300: "#F7DCA2",
    400: "#F5CF73",
    500: "#F2C113",
    600: "#D8AD11",
    700: "#BB950F",
    800: "#997A0C",
    900: "#6C5608"
  },
  amber: {
    50: "#FDF7F2",
    100: "#FCEEE4",
    200: "#F8DBC6",
    300: "#F5C7A1",
    400: "#F1B172",
    500: "#EE9709",
    600: "#D58708",
    700: "#B87507",
    800: "#976006",
    900: "#6A4404"
  },
  orange: {
    50: "#FDF4F2",
    100: "#FBE9E4",
    200: "#F7D1C6",
    300: "#F3B6A1",
    400: "#EF9672",
    500: "#EB6D00",
    600: "#D26100",
    700: "#B65400",
    800: "#954500",
    900: "#693100"
  },
  deepOrange: {
    50: "#F9F3F2",
    100: "#F2E6E4",
    200: "#E5CBC6",
    300: "#D7ABA1",
    400: "#C88472",
    500: "#B84A00",
    600: "#A54200",
    700: "#8F3900",
    800: "#742F00",
    900: "#522100"
  },
  blueGray: {
    50: "#F4F5F6",
    100: "#E8EBEC",
    200: "#CFD5D8",
    300: "#B2BCC2",
    400: "#8FA0A9",
    500: "#607D8B",
    600: "#56707C",
    700: "#4A616C",
    800: "#3D4F58",
    900: "#2B383E"
  },
  deepBlue: {
    50: "#F2F2F3",
    100: "#E5E5E7",
    200: "#C7C8CC",
    300: "#A5A6AC",
    400: "#797A86",
    500: "#2C324F",
    600: "#272D47",
    700: "#22273D",
    800: "#1C2032",
    900: "#141623"
  },
  white: "#FFFFFF",
  black: "#211F1F",
  transparent: "transparent",
  current: "currentColor",
  inherit: "inherit",
  text: {
    primary: "rgba(33, 31, 31, 0.87)",
    secondary: "rgba(33, 31, 31, 0.64)",
    disabled: "rgba(33, 31, 31, 0.38)",
    inverse: "#FFFFFF",
    hint: "rgba(33,31,31, 0.54)"
  },
  canvas: "#F9F9F9",
  "canvas-green": "#E3FAF1",
  stroke: "rgba(33, 31, 31, 0.3)",
  overlay: "rgba(57, 57, 57, 0.25)"
}, E = {
  ...F,
  primary: F.green,
  secondary: F.deepBlue,
  error: F.red,
  info: F.lightBlue,
  warning: F.yellow,
  success: F.green
};
export {
  E as default
};

import { useCallback } from 'react';
import { GetMeQuery } from '@/generated/gql/graphql';
import useUserDetails from '@/data/useUserDetails';

const useReadOnlyUser = () => {
  const { data: readOnlyUser } = useUserDetails(useCallback((user: GetMeQuery) => user.me?.supplierReadOnly, []));
  return readOnlyUser ?? false;
};

export default useReadOnlyUser;

import { useState } from 'react';
import { useEffectOnce } from 'react-use';

const pad2Digits = (number: number) => `${number}`.padStart(2, '0');

const NextMarketClearTimeCountdown = ({ nextClearTime }: { nextClearTime: string }) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [total, setTotal] = useState(Date.parse(nextClearTime) - Date.now());

  const getCountdownTime = () => {
    const totalFromNow = Date.parse(nextClearTime) - Date.now();
    setHours(Math.floor((totalFromNow / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((totalFromNow / 1000 / 60) % 60));
    setTotal(totalFromNow);
  };

  useEffectOnce(() => {
    const interval = setInterval(() => getCountdownTime(), 1000);
    return () => clearInterval(interval);
  });

  return <>{total > 0 ? `${pad2Digits(hours)}h ${pad2Digits(minutes)}m` : '00h 00m'}</>;
};

export default NextMarketClearTimeCountdown;

import { useTranslation } from 'react-i18next';
import { Pricing } from '@/generated/gql/graphql';

/**
 * maps a market pricing type to the desired display text
 * default to returning Name Your Rate
 */
const useMarketPricingTypeDisplay = () => {
  const { t } = useTranslation();

  const getMarketPricingTypeDisplay = (marketPricingType?: Pricing | null) => {
    // benchmark -> variable
    if (marketPricingType === 'BENCHMARK') {
      return t('core.variableRate');
    }

    // static -> fixed
    if (marketPricingType === 'STATIC') {
      return t('core.fixedRate');
    }

    // price discovery -> name your rate
    return t('core.nameYourRate');
  };

  return { getMarketPricingTypeDisplay };
};

export default useMarketPricingTypeDisplay;

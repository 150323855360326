import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { Button, Modal, TextField, useSnackbar } from '@c2fo/liquidity';
import useUserDetails from '@/data/useUserDetails';
import { useSrm } from '@/features/srmCard/SrmCard';
import apiClient from '@/lib/apiClient';
import { useReporting } from '@/reporting';

export interface RequestModalProps {
  open?: boolean;
  onClose?: () => void;
}

export default function RequestDemoModal({ open, onClose }: RequestModalProps) {
  const srm = useSrm();
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const { data: userDetails } = useUserDetails((data) => {
    const { primaryPhoneNumber } = data.me?.personalInfo ?? {};
    return { primaryPhoneNumber };
  });
  const [phone, setPhone] = useState(userDetails?.primaryPhoneNumber ?? '');
  const srmEmailAddress = srm?.emailAddress ?? 'customerservice@c2fo.com';

  const { mutate: submitRequest } = useMutation({
    mutationKey: ['requestDemo'],
    mutationFn: (srmEmailAddress: string) =>
      apiClient.post(`api/c2fo/user-source/request-demo`, {
        json: { srmEmailAddress },
      }),
    onSuccess: () => {
      onClose?.();
      track('srm::emailed', { email: srmEmailAddress });
      showSnackbar({ message: t('demoRequest.contactedSoon') });
    },
    onError: () => {
      showSnackbar({ message: t('demoRequest.error') });
    },
  });

  return (
    <Modal className="w-fit" open={open} onClose={onClose}>
      <Modal.Title className="gap-3">{t('demoRequest.verifyPhone')}</Modal.Title>
      <Modal.Description>{t('demoRequest.contactedSoon')}</Modal.Description>
      <div className="flex justify-center px-4 py-2">
        <TextField
          name="phone"
          size="large"
          full
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          label={t('profile.primaryPhone')}
        />
      </div>
      <Modal.Actions>
        <Button variant="primary" onClick={() => submitRequest(srmEmailAddress)}>
          {t('core.submit')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

import { useParams } from 'react-router-dom';
import QueryBoundaries from '@/components/QueryBoundaries';
import useDraftOffer from '@/data/useDraftOffer';
import { DraftOfferProps } from './common/DraftOffer.types';
import { DraftOfferError } from './common/DraftOfferError';
import { DraftOfferLoading } from './common/DraftOfferLoading';
import { DraftOfferPreferred } from './offerTypes/DraftOfferPreferred';
import { DraftOfferPriceDiscovery } from './offerTypes/DraftOfferPriceDiscovery';

const DraftOfferErrorUnknown = () => {
  return (
    <DraftOfferError
      errorType="INVALID"
      trackEventMessage="An unknown error occurred while rendering draft offer, hitting the error boundary."
    />
  );
};

const DraftOffer = () => {
  return (
    <QueryBoundaries LoadingComponent={DraftOfferLoading} ErrorComponent={DraftOfferErrorUnknown}>
      <Composer />
    </QueryBoundaries>
  );
};

const Composer = () => {
  const params = useParams<{ draftOfferUuid: string }>();
  const { draftOfferUuid } = params;
  const { data: draftOfferData } = useDraftOffer(draftOfferUuid);

  if (!draftOfferUuid) {
    return <DraftOfferError errorType="INVALID" trackEventMessage="No draft offer uuid available as a param." />;
  }

  if (!draftOfferData) {
    return (
      <DraftOfferError
        errorType="INVALID"
        trackEventMessage={`No draft offer data available from uuid: ${draftOfferUuid}`}
      />
    );
  }

  // Expired messaging should work for just about any status here that is not ready for submission
  if (draftOfferData.status !== 'READY_FOR_SUBMISSION') {
    return (
      <DraftOfferError
        errorType="EXPIRED"
        trackEventMessage={`Draft offer status (${draftOfferData.status}) is not valid for draft submission.`}
      />
    );
  }

  const draftOfferProps: DraftOfferProps = {
    draftOfferUuid,
  };

  switch (draftOfferData.type) {
    case 'PREFERRED': {
      return <DraftOfferPreferred {...draftOfferProps} />;
    }

    case 'PRICE_DISCOVERY': {
      return <DraftOfferPriceDiscovery {...draftOfferProps} />;
    }

    default: {
      return (
        <DraftOfferError
          errorType="INVALID"
          trackEventMessage={`No defined case for rendering draft offer type of: ${draftOfferData.type}`}
        />
      );
    }
  }
};

export default DraftOffer;

import { useEffect } from 'react';
import { useToken } from '@/utils/token';
import { EventStreamContentType, fetchEventSource } from './fetch';
import { useServerSideEventListeners } from './useServerSideEventListeners';

interface ServerSentEvent {
  done: boolean;
  event: string;
  marketUuid: string;
  takerIds: number[];
}

const prefixUrl = import.meta.env.VITE_GATEWAY_URL;
const url = `${prefixUrl}/api/c2fo/push-events`;

export const useServerSideEvents = () => {
  const { token } = useToken();
  const { notifyMarketStatsSubscribers } = useServerSideEventListeners();

  const controller = new AbortController();

  useEffect(() => {
    const connectToServerSideEvents = async () => {
      try {
        await fetchEventSource(url, {
          method: 'GET',
          headers: {
            Accept: EventStreamContentType,
            Authorization: `Bearer ${token}`,
          },
          signal: controller.signal,
          // async onopen() { },
          onmessage(event) {
            try {
              const parsedData: ServerSentEvent = JSON.parse(event.data);

              if (parsedData.done && parsedData.event === 'market.stats') {
                notifyMarketStatsSubscribers({
                  marketUuid: parsedData.marketUuid,
                  takerIds: parsedData.takerIds,
                });
              }
            } catch {
              // catch for some error logging
            }
          },
          onclose() {
            // connection closed by the server
            // console.log('Connection closed by the server');
            // catch for some error logging
          },
          onerror() {
            // error from server
            // console.log('There was an error from server');
            // catch for some error logging
          },
          openWhenHidden: true,
        });
      } catch {
        // error opening connection
      }
    };

    connectToServerSideEvents();

    return function cancel() {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
};

import ky from 'ky-universal';
import getToken from '@/utils/token';

const prefixUrl = import.meta.env.VITE_SUPPLIER_AGREEMENTS_CONTENT_URL;

const agreementApiClient = ky.create({
  prefixUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  hooks: {
    beforeRequest: [
      (request) => {
        const token = getToken();
        request.headers.set('Authorization', `Bearer ${token}`);
      },
    ],
  },
});

export default agreementApiClient;

import { useTranslation } from 'react-i18next';
import { cn } from '@c2fo/liquidity';
import { ExclamationTriangleIcon } from '@c2fo/liquidity/icons';

const ReadOnlyBanner = ({ isImpersonation }: { isImpersonation: boolean }) => {
  const { t } = useTranslation();
  const message = isImpersonation ? t('core.readOnlyBannerImpersonation') : t('core.readOnlyBanner');

  return (
    <div
      className={cn('fixed top-0 z-[10] w-full bg-warning-400 text-text-primary', {
        'top-10': isImpersonation,
      })}
    >
      <div className="flex h-10 items-center gap-2 px-4">
        <div>
          <ExclamationTriangleIcon />
        </div>
        <div aria-description={message} className="min-w-0 truncate text-sm" title={message}>
          {message}
        </div>
      </div>
    </div>
  );
};

export default ReadOnlyBanner;

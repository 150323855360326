import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { cn, useSnackbar } from '@c2fo/liquidity';
import Modal, { ModalProps } from '@/components/Modal';
import { OfferConfigDetails, OfferConfigDetailsItem } from '@/components/OfferConfigDetails';
import useGlobalPriceDiscoveryOffer from '@/data/useGlobalPriceDiscoveryOffer';
import { useReporting } from '@/reporting';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useReadOnlyUser from '@/utils/useReadOnlyUser';
import { OfferLimits, SetOfferFormInputs, validateRate } from './utils';

const NameYourRateSetGlobalOffer = ({
  disableApr = false,
  numMarkets,
  open,
  onClose,
}: {
  disableApr: boolean;
  numMarkets?: number;
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const readOnlyUser = useReadOnlyUser();
  const { asPercent } = useLocaleFormat();
  const [step, setStep] = useState<number>(1);
  const { mutateAsync: submitGlobalPriceDiscoveryOffer, isLoading: submitGlobalPriceDiscoveryOfferLoading } =
    useGlobalPriceDiscoveryOffer();

  const offerLimits: OfferLimits = {
    disc: {
      low: 0,
      high: 10,
    },
    apr: {
      low: 0,
      high: 99,
    },
  };

  const defaultValues: SetOfferFormInputs = {
    rate: null,
    rateType: 'disc',
    expireOn: null,
  };

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm<SetOfferFormInputs>({ defaultValues });

  useEffect(() => {
    if (open) {
      reset(defaultValues);
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const offerConfigItems = useMemo(() => {
    const items: OfferConfigDetailsItem[] = [];

    if (step === 2) {
      items.push({
        label: t('taker.dictionary.offer.label'),
        value: `${asPercent({
          value: parseFloat(getValues('rate')!) / 100,
          decimals: 2,
        })} ${getValues('rateType').toUpperCase()}`,
      });
    }

    return items;
  }, [asPercent, getValues, step, t]);

  const onSubmit: SubmitHandler<SetOfferFormInputs> = async (data) => {
    if (readOnlyUser) {
      return;
    }

    const { rate, rateType } = data;

    if (step === 1) {
      // step 1 - validate rate/rate type are set & send to step 2
      // validate the rate based on rate type and offer limits and send to step 2
      const validRate = validateRate({ rate, rateType, offerLimits });

      const advanceStep = () => {
        setStep(2);
        track('global-offer-next::clicked');
      };

      return !validRate
        ? setError('rate', {
            message:
              rateType === 'disc'
                ? t('offerSubmitDialog.errors.discRateBetween', {
                    low: asPercent({
                      value: offerLimits.disc.low / 100,
                      decimals: 2,
                    }),
                    high: asPercent({ value: offerLimits.disc.high / 100, decimals: 2 }),
                  })
                : t('offerSubmitDialog.errors.aprRateBetween', {
                    low: asPercent({
                      value: offerLimits.apr.low / 100,
                      decimals: 2,
                    }),
                    high: asPercent({ value: offerLimits.apr.high / 100, decimals: 2 }),
                  }),
          })
        : advanceStep();
    } else {
      // step 2 - submit the form
      // make sure rate is positive and rounded to 2 decimal places
      const normalizedRate = Math.abs(Math.round(parseFloat(rate!) * 100) / 100);
      const isDiscountBidding = rateType === 'disc';

      const submitValues = {
        isDiscountBidding,
        ...(!isDiscountBidding && { maxApr: normalizedRate }),
        ...(isDiscountBidding && { maxDiscount: normalizedRate }),
      };

      await submitGlobalPriceDiscoveryOffer(submitValues, {
        onSuccess: () => {
          showSnackbar({ message: t('globalOfferSubmitDialog.offerUpdate') });
          track('global-offer::submitted', submitValues);
          onClose();
        },
        onError: () => showSnackbar({ message: t('globalOfferSubmitDialog.offerUpdateError') }),
      });
    }
  };

  const trackRateChange = (rateType: SetOfferFormInputs['rateType']) =>
    track('global-offer-rate-type::changed', { rateType });

  const handleRateTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as SetOfferFormInputs['rateType'];
    trackRateChange(value);
    // We need to do an explicit setValue here (or make a custom register function for radio inputs)
    // due to a rare issue with how react-hook-form and React attach radio inputs to the DOM
    // more info here https://github.com/react-hook-form/react-hook-form/issues/4604
    setValue('rateType', value);
  };

  const modalConfig: Partial<ModalProps> =
    step === 1
      ? {
          cancelText: t('core.cancel'),
          okButtonVariant: 'primary',
          okText: t('core.next'),
          onCancel: onClose,
          onOk: handleSubmit(onSubmit),
        }
      : {
          cancelText: t('core.back'),
          okButtonVariant: 'cta',
          okText: t('core.submit'),
          onCancel: () => setStep(1),
          onOk: handleSubmit(onSubmit),
        };

  return (
    <Modal
      className="w-fit"
      fullWidthButtons
      loading={submitGlobalPriceDiscoveryOfferLoading}
      onClose={onClose}
      open={open}
      subTitle={t('taker.globalOfferMsg', {
        markets: numMarkets ?? '',
      })}
      title={t('core.setOfferLabel')}
      variant="info"
      {...modalConfig}
    >
      <div className="flex justify-center">
        <div className="relative space-y-6 bg-white text-text-primary">
          <OfferConfigDetails items={offerConfigItems} />

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* offer form */}
            <div
              className={cn('space-y-6', {
                hidden: step === 2,
                'mt-4': step === 1,
              })}
            >
              <div>
                <label
                  htmlFor="rate"
                  className={cn('text-sm', {
                    'text-red-500': errors.rate,
                  })}
                >
                  {t('core.offerRate')}
                </label>
                <div className="relative mt-2 w-fit">
                  <input
                    id="rate"
                    className={cn(
                      'block w-full rounded-md border-0 py-2 pl-3 pr-10 outline-0 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600',
                      { 'ring-red-500': errors.rate }
                    )}
                    placeholder={t('offer.enterRate')}
                    type="text"
                    {...register('rate')}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <div aria-hidden="true">%</div>
                  </div>
                </div>
                {errors.rate && <div className="pt-2 text-sm text-red-500">{errors.rate.message}</div>}
              </div>
              <div className="pb-2">
                <div className="text-sm">{t('core.rateType')}</div>
                <div className="mt-2 grid grid-cols-1">
                  <fieldset>
                    <legend className="sr-only">{t('core.rateType')}</legend>
                    <div className="space-y-3">
                      <div className="relative flex items-start">
                        <div className="flex h-6 w-4 shrink-0 items-center">
                          <input
                            id={t('core.discount')}
                            aria-describedby={`${t('core.discount')}-description`}
                            className="h-4 w-4 cursor-pointer text-secondary-600 accent-secondary-500 focus:ring-secondary-600"
                            type="radio"
                            value="disc"
                            {...register('rateType', { onChange: handleRateTypeChange })}
                          />
                        </div>
                        <div className="ml-3">
                          <label htmlFor={t('core.discount')} className="cursor-pointer font-medium">
                            {t('core.discount')}
                          </label>
                          <p id={`${t('core.discount')}-description`} className="text-sm text-gray-800">
                            {t('offer.flatRateMessage')}
                          </p>
                        </div>
                      </div>
                      {!disableApr && (
                        <div className="relative flex items-start">
                          <div className="flex h-6 w-4 shrink-0 items-center">
                            <input
                              id={t('core.apr')}
                              aria-describedby={`${t('core.apr')}-description`}
                              className="h-4 w-4 cursor-pointer text-secondary-600 accent-secondary-500 focus:ring-secondary-600"
                              type="radio"
                              value="apr"
                              {...register('rateType', { onChange: handleRateTypeChange })}
                            />
                          </div>
                          <div className="ml-3">
                            <label htmlFor={t('core.apr')} className="cursor-pointer font-medium">
                              {t('core.apr')}
                            </label>
                            <p id={`${t('core.apr')}-description`} className="text-sm text-gray-800">
                              {t('offer.recurringMessage')}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            {/* understand info */}
            <div className={cn({ hidden: step === 1 })}>
              <ul className="ml-4 list-disc space-y-2">
                <li>{t('offer.applyToAllMarketsMessage')}</li>
                <li>{t('allOfferDialogs.description2')}</li>
                <li>{t('allOfferDialogs.description3')}</li>
                <li>{t('offerSubmitDialog.disclaimer')}</li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default NameYourRateSetGlobalOffer;

import { ReactNode } from 'react';
import { Paper } from '@c2fo/liquidity';

export const ErrorPageTemplate = ({
  title,
  subtitle,
  cardSlot,
}: {
  title?: string;
  subtitle?: string;
  cardSlot: ReactNode;
}) => {
  return (
    <div className="mx-auto flex max-w-screen-sm flex-col gap-4 pb-32">
      <div className="flex flex-col gap-4">
        <h1 className="m-0 text-4xl">{title}</h1>
        <h2 className="m-0 font-sans text-lg font-medium">{subtitle}</h2>
      </div>
      <Paper elevation={2} className="p-8">
        {cardSlot}
      </Paper>
    </div>
  );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import { useReporting } from '@/reporting';
import HowItWorksModal from './HowItWorksModal';
import RequestDemoModal from './RequestDemoModal';

const LearnMore = ({ asButtons = false }: { asButtons?: boolean }) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const [hiwModalOpen, setHiwModalOpen] = useState(false);
  const [demoModalOpen, setDemoModalOpen] = useState(false);

  const openHiwModal = () => {
    setHiwModalOpen(true);
    track('how-it-works-modal::clicked');
  };

  const openDemoModal = () => {
    setDemoModalOpen(true);
    track('request-a-demo-modal::clicked');
  };

  return (
    <>
      {asButtons ? (
        <div className="flex gap-3">
          <Button size="sm" variant="tertiary" onClick={openDemoModal}>
            {t('demoRequest.requestDemo')}
          </Button>
          <Button size="sm" variant="tertiary" onClick={openHiwModal}>
            {t('core.learnHowItWorks')}
          </Button>
        </div>
      ) : (
        <div className="text-center text-sm">
          {t('aggregateStats.subtext')}
          <button className="mx-1 font-bold underline" type="button" onClick={openHiwModal}>
            {t('core.learnHowItWorks')}
          </button>
          {t('core.or')}
          <button className="ml-1 font-bold lowercase underline" type="button" onClick={openDemoModal}>
            {t('demoRequest.requestDemo')}
          </button>
          .
        </div>
      )}
      <HowItWorksModal open={hiwModalOpen} onClose={() => setHiwModalOpen(false)} />
      <RequestDemoModal open={demoModalOpen} onClose={() => setDemoModalOpen(false)} />
    </>
  );
};

export default LearnMore;

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  useSnackbar,
} from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CaretDownFilledIcon } from '@c2fo/liquidity/icons';
import useGlobalPriceDiscoveryOffer from '@/data/useGlobalPriceDiscoveryOffer';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';
import NameYourRateSetGlobalOffer from '@/features/nameYourRate/SetGlobalOffer';
import NameYourRateSetGlobalParticipation from '@/features/nameYourRate/SetGlobalParticipation';
import { useReporting } from '@/reporting';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import useReadOnlyUser from '@/utils/useReadOnlyUser';

const GlobalActionsDropdown = () => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const readOnlyUser = useReadOnlyUser();
  const [showSetGlobalOfferModal, setShowSetGlobalOfferModal] = useState<boolean>(false);
  const [showSetGlobalParticipationModal, setShowSetGlobalParticipationModal] = useState<boolean>(false);
  const { data } = useTakerMarkets(
    useCallback((takerMarkets: TakerMarket[]) => {
      const disableApr = takerMarkets.some((takerMarket) => takerMarket.isDiscountMarket);
      const numberOfActiveTakerMarkets =
        takerMarkets.filter(
          (takerMarket) => takerMarket.marketIsEnabled && takerMarket.marketPricingType === 'PRICE_DISCOVERY'
        ).length ?? 0;
      return { disableApr, numberOfActiveTakerMarkets };
    }, [])
  );
  const { mutateAsync: toggleGlobalPriceDiscoveryParticipationOff } = useGlobalPriceDiscoveryOffer();

  const turnGlobalParticipationOff = async () => {
    if (readOnlyUser) {
      return;
    }

    const submitValues = { isEnabled: false };

    await toggleGlobalPriceDiscoveryParticipationOff(submitValues, {
      onSuccess: () => {
        showSnackbar({ message: t('globalOfferSubmitDialog.offerUpdate') });
        track('global-participation::submitted', submitValues);
      },
      onError: () => showSnackbar({ message: t('globalOfferSubmitDialog.offerUpdateError') }),
    });
  };

  const openSetGlobalOfferModal = () => {
    setShowSetGlobalOfferModal(true);
    track('global-offer::clicked');
  };

  const openSetGlobalParticipationModal = () => {
    setShowSetGlobalParticipationModal(true);
    track('global-participation::clicked');
  };

  const items = [
    {
      label: t('takerMarketTabs.allParticipationOn'),
      name: t('takerMarketTabs.allParticipationOn'),
      onClick: openSetGlobalParticipationModal,
    },
    {
      label: t('takerMarketTabs.allParticipationOff'),
      name: t('takerMarketTabs.allParticipationOff'),
      onClick: turnGlobalParticipationOff,
    },
    {
      label: t('takerMarketTabs.applySingleOfferToAll'),
      name: t('takerMarketTabs.applySingleOfferToAll'),
      onClick: openSetGlobalOfferModal,
    },
  ];

  const numberOfActiveTakerMarkets = data?.numberOfActiveTakerMarkets ?? 0;
  const disableApr = data?.disableApr ?? false;

  return (
    <IntercomDataTarget target="globalActionButton">
      <NameYourRateSetGlobalOffer
        disableApr={disableApr}
        numMarkets={numberOfActiveTakerMarkets}
        onClose={() => setShowSetGlobalOfferModal(false)}
        open={showSetGlobalOfferModal}
      />
      <NameYourRateSetGlobalParticipation
        onClose={() => setShowSetGlobalParticipationModal(false)}
        open={showSetGlobalParticipationModal}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="primary" size="sm">
            <span className="flex items-center gap-2">
              {t('core.globalActions')}
              <CaretDownFilledIcon className="h-3 w-3" aria-hidden="true" fill={colors.white} />
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" collisionPadding={24}>
          {items.map((item) => (
            <DropdownMenuItem key={item.name} onClick={item.onClick}>
              {item.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </IntercomDataTarget>
  );
};

export default GlobalActionsDropdown;

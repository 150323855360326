import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

function convertURLSearchParamsToObject(params: URLSearchParams): Record<string, string | string[]> {
  const result: Record<string, string | string[]> = {};

  for (const [key, value] of params.entries()) {
    if (Object.prototype.hasOwnProperty.call(result, key)) {
      if (Array.isArray(result[key])) {
        (result[key] as string[]).push(value);
      } else {
        result[key] = [result[key] as string, value];
      }
    } else {
      result[key] = value;
    }
  }

  return result;
}

/**
 * A hook for getting and setting URL search params.
 * @returns A tuple containing the URL search params and a function to set them.
 * This hook is different from `useUrlParams` in that it uses the `URLSearchParams`
 * API instead of `qs` to parse the URL search params. Also, the setter function
 * merges the new params with the existing params by default.
 */
export default function useParams() {
  const [params, _setParams] = useSearchParams();

  /**
   * Sets the URL search params.
   * @param newParams - The new params to set.
   * @param merge - Whether to merge the new params with the existing params.
   * Defaults to `true`.
   */
  const setParams = useCallback(
    (newParams: Record<string, string | string[]>, merge = true) => {
      _setParams((oldParams) => {
        if (merge) {
          const old = convertURLSearchParamsToObject(oldParams);
          return {
            ...old,
            ...newParams,
          };
        }

        return newParams;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.toString()]
  );

  return [params, setParams] as const;
}

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pricing } from '@/generated/gql/graphql';
import { useSelectedCurrency } from '@/components/CurrencySelect';
import useDraftOffer from '@/data/useDraftOffer';
import useExchangeRates from '@/data/useExchangeRates';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';
import { useOfferDiscountAPRDisplay } from '@/features/takerMarketTable/utils/useOfferDiscountAPRDisplay';
import convertCurrency from '@/utils/convertCurrency';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';

interface DraftOfferStats {
  marketPricingType: Pricing;
  eligibleInvoiceCount: number;
  eligibleInvoiceAmount: number;
  formattedEligibleInvoiceAmount: string;
  eligibleDpeWeightedSum: number;
  currency: string;
  formattedRate: string;
  discountAmount: number;
  formattedDiscountAmount: string;
  estimatedDepositAmount: number;
  formattedEstimatedDepositAmount: string;
  estimatedDepositDate: string;
}
export interface UseTakerMarketsInDraftOffer {
  takerMarkets: TakerMarket[];
  stats: DraftOfferStats;
}

export const useTakerMarketsInDraftOffer = (draftOfferUuid: string): UseTakerMarketsInDraftOffer => {
  const { data: draftOfferData } = useDraftOffer(draftOfferUuid);
  const { data: takerMarkets } = useTakerMarkets();
  const userSelectedCurrency = useSelectedCurrency();
  const { data: exchangeRates = {} } = useExchangeRates();
  const { offerDiscountAPRDisplay } = useOfferDiscountAPRDisplay();
  const { t } = useTranslation();
  const { asDateString, asCurrency } = useLocaleFormat();

  const takerMarketsInDraftOffer = useMemo(() => {
    if (!draftOfferData || !takerMarkets) {
      return [];
    }

    const { marketUuid, supplierDivisionUuids } = draftOfferData;

    return takerMarkets.filter((tm) => {
      return tm.marketUuid === marketUuid && supplierDivisionUuids.includes(tm.takerDivisionUuid);
    });
  }, [draftOfferData, takerMarkets]);

  const selectedCurrency = useMemo(() => {
    if (!takerMarketsInDraftOffer.length || !userSelectedCurrency) {
      return 'USD';
    }

    const takerMarketCurrency = takerMarketsInDraftOffer[0].currency;

    // When all currencies in this offer are the same, use that currency.
    // At time of comment, this will always be true, but we're prepping for the future.
    if (takerMarketsInDraftOffer.every((tm) => tm.currency === takerMarketCurrency)) {
      return takerMarketCurrency;
    }

    // Otherwise, honor the user selected aggregate currency
    return userSelectedCurrency;
  }, [takerMarketsInDraftOffer, userSelectedCurrency]);

  const formattedOfferRate = useMemo(() => {
    if (!draftOfferData) {
      return t('core.na');
    }

    const { rate, rateType, spread } = draftOfferData;

    if (rateType === 'REF') {
      const takerMarket = takerMarketsInDraftOffer[0];

      if (!takerMarket) {
        return t('core.na');
      }

      const rateInfo = takerMarket.rateInfo;

      if (rateInfo && 'rateSourceName' in rateInfo) {
        return `${rateInfo.rateSourceName} + ${spread ?? 0}%`;
      }

      console.error('No rate source name found for REF rate type.', takerMarket, rateInfo);

      return `REF + ${spread ?? 0}%`;
    }

    if (rateType === 'APR' && rate) {
      return offerDiscountAPRDisplay(rate, t('core.aprAbbreviation'));
    }

    if (rateType === 'DISC' && rate) {
      return offerDiscountAPRDisplay(rate, t('core.discountAbbreviation'));
    }

    return t('core.na');
  }, [draftOfferData, offerDiscountAPRDisplay, t, takerMarketsInDraftOffer]);

  const formattedMaxMarketPayDate = useMemo(() => {
    let maxMarketPayDate: string | null = null;

    takerMarketsInDraftOffer.forEach((takerMarket) => {
      const marketPayDate = takerMarket.marketPayDate;
      const date = new Date(marketPayDate);
      if (!maxMarketPayDate || date > new Date(maxMarketPayDate)) {
        maxMarketPayDate = takerMarket.marketPayDate;
      }
    });

    if (!maxMarketPayDate) {
      return t('core.na');
    }

    return asDateString(trimDateTimestamp(maxMarketPayDate), {
      month: 'long',
      year: 'numeric',
    });
  }, [asDateString, t, takerMarketsInDraftOffer]);

  const stats = useMemo(() => {
    const data: DraftOfferStats = {
      marketPricingType: 'PRICE_DISCOVERY',
      eligibleInvoiceCount: 0,
      eligibleInvoiceAmount: 0,
      formattedEligibleInvoiceAmount: '',
      eligibleDpeWeightedSum: 0,
      currency: selectedCurrency,
      formattedRate: formattedOfferRate,
      discountAmount: 0,
      formattedDiscountAmount: '',
      estimatedDepositDate: formattedMaxMarketPayDate,
      estimatedDepositAmount: 0,
      formattedEstimatedDepositAmount: '',
    };

    takerMarketsInDraftOffer.forEach((takerMarket) => {
      const convertedEligibleInvoiceAmount = convertCurrency({
        amount: takerMarket.eligibleInvoiceAmount,
        from: takerMarket.currency,
        to: selectedCurrency,
        exchangeRates,
      });

      const convertedEligibleDpeWeightedSum = convertCurrency({
        amount: takerMarket.eligibleDpeWeightedSum,
        from: takerMarket.currency,
        to: selectedCurrency,
        exchangeRates,
      });

      data.eligibleInvoiceAmount += convertedEligibleInvoiceAmount;
      data.eligibleInvoiceCount += takerMarket.eligibleInvoiceCount;
      data.eligibleDpeWeightedSum += convertedEligibleDpeWeightedSum;
    });

    if (draftOfferData?.rateType === 'DISC' && draftOfferData.rate) {
      data.discountAmount = data.eligibleInvoiceAmount * (draftOfferData.rate / 100);
    } else if (draftOfferData?.rateType === 'APR' && draftOfferData.rate) {
      const weightedAverageDpe =
        data.eligibleInvoiceAmount > 0 ? data.eligibleDpeWeightedSum / data.eligibleInvoiceAmount : 0;
      const equivalentDisc = (draftOfferData.rate / 100) * (weightedAverageDpe / 360);
      data.discountAmount = data.eligibleInvoiceAmount * equivalentDisc;
    } else {
      // In the future, this is where we would calculate the discount amount for benchmark/static rate types
    }

    data.formattedEligibleInvoiceAmount = asCurrency(data.eligibleInvoiceAmount, selectedCurrency);
    data.formattedDiscountAmount = asCurrency(data.discountAmount, selectedCurrency);
    data.estimatedDepositAmount = data.eligibleInvoiceAmount - data.discountAmount;
    data.formattedEstimatedDepositAmount = asCurrency(data.estimatedDepositAmount, selectedCurrency);

    return data;
  }, [
    asCurrency,
    draftOfferData?.rate,
    draftOfferData?.rateType,
    exchangeRates,
    formattedMaxMarketPayDate,
    formattedOfferRate,
    selectedCurrency,
    takerMarketsInDraftOffer,
  ]);

  return {
    takerMarkets: takerMarketsInDraftOffer,
    stats,
  };
};

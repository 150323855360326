import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@c2fo/liquidity';

export const DraftOfferTemplate = ({
  title,
  subtitle,
  cardSlot,
  actionSlot,
}: {
  title?: string;
  subtitle?: string;
  cardSlot: ReactNode;
  actionSlot?: ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <div className="mx-auto flex max-w-screen-sm flex-col gap-8 pb-32">
      <div className="flex flex-col gap-4">
        <h1 className="m-0 text-4xl">{title ?? t('draftOffer.title')}</h1>
        <h2 className="m-0 font-sans text-lg font-medium">{subtitle ?? t('draftOffer.subTitle')}</h2>
      </div>
      <Paper elevation={2} className="p-8">
        {cardSlot}
      </Paper>
      {!!actionSlot && <div className="flex items-center justify-end gap-4">{actionSlot}</div>}
    </div>
  );
};

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import useDownloadInvoices, { TakerMarketIds } from '@/data/useDownloadInvoices';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';
import DownloadInvoicesModal from '@/features/aggregateStatsBanner/components/DownloadInvoicesModal';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import QueryBoundaries from './QueryBoundaries';

const DownloadInvoices = () => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const { mutate: downloadInvoices } = useDownloadInvoices();
  const [showDownloadInvoicesModal, setShowDownloadInvoicesModal] = useState(false);
  const { data: takerMarketsWithEligibleInvoices = [] } = useTakerMarkets(
    useCallback((takerMarkets: TakerMarket[]) => {
      const withEligibleInvoices: TakerMarketIds = [];

      takerMarkets.forEach((tm) => {
        if (tm.eligibleInvoiceCount > 0) {
          withEligibleInvoices.push({
            takerId: tm.takerDivisionId,
            marketUuid: tm.marketUuid,
          });
        }
      });

      return withEligibleInvoices;
    }, [])
  );

  const handleDowndloadInvoices = () => {
    downloadInvoices({ takerMarketIds: takerMarketsWithEligibleInvoices });
    setShowDownloadInvoicesModal(true);
    track('download-invoices::submitted', { takerMarketIds: takerMarketsWithEligibleInvoices });
  };

  return takerMarketsWithEligibleInvoices.length > 0 ? (
    <>
      <DownloadInvoicesModal open={showDownloadInvoicesModal} onClose={() => setShowDownloadInvoicesModal(false)} />
      <IntercomDataTarget target="downloadAllInvoicesButton">
        <Button className="mt-6 w-full sm:mt-0 sm:w-auto" onClick={handleDowndloadInvoices} variant="secondary">
          {t('core.downloadInvoices')}
        </Button>
      </IntercomDataTarget>
    </>
  ) : null;
};

const HomepageHeader = () => {
  const { t } = useTranslation();
  const [enableUpdatedGlobalActions] = useFeature('enterprise-ui_enableUpdatedGlobalActions');

  return (
    <div className="mb-8 min-h-12 items-center justify-between space-y-4 sm:flex sm:space-y-0">
      <div>
        <h1 className="m-0 text-4xl font-medium">{t('core.markets')}</h1>
      </div>
      {enableUpdatedGlobalActions && (
        <QueryBoundaries>
          <DownloadInvoices />
        </QueryBoundaries>
      )}
    </div>
  );
};

export default HomepageHeader;

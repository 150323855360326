import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { cn } from '@c2fo/liquidity';

const TabSectionContext = createContext<{
  registerTab: (values: { name: string; title: string; selected?: boolean; children: ReactNode }) => void;
  unregisterTab: (name: string) => void;
} | null>(null);

export interface TabProps {
  name: string;
  title: string;
  children: ReactNode;
}

function Tab({ children, name, title }: TabProps) {
  const context = useContext(TabSectionContext);
  if (!context) {
    throw new Error('Tab must be used within a TabSection');
  }

  useEffect(() => {
    const { registerTab, unregisterTab } = context;
    registerTab({ name, title, children });
    return () => unregisterTab(name);
    // adding context to this causes it to rerender infinitely.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, title, children]);

  return null;
}

function TabSection({ children }: { children: ReactNode }) {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [tabs, setTabs] = useState<TabProps[]>([]);
  const registerTab = useCallback(
    ({ name, title, children }: TabProps) => {
      setTabs((prevTabs) => {
        const existingTabIndex = prevTabs.findIndex((tab) => tab.name === name);
        if (existingTabIndex !== -1) {
          const updatedTabs = [...prevTabs];
          updatedTabs[existingTabIndex] = { name, title, children };
          return updatedTabs;
        }

        return [...prevTabs, { name, title, children }];
      });
    },
    [setTabs]
  );

  const unregisterTab = useCallback(
    (name: string) => {
      setTabs((prevTabs) => prevTabs.filter((tab) => tab.name !== name));
    },
    [setTabs]
  );

  const content = useMemo(() => tabs[selectedTab]?.children, [tabs, selectedTab]);

  return (
    <TabSectionContext.Provider value={{ registerTab, unregisterTab }}>
      <div className="flex items-center gap-1 border-b px-8 font-medium capitalize">
        {tabs.map((tab, index) => (
          <button
            type="button"
            key={tab.name}
            name={tab.name}
            className={cn(
              'border-b-2 border-white bg-white p-4 text-text-secondary transition-all duration-200 hover:border-b-gray-50 hover:bg-gray-50 hover:text-text-primary',
              {
                'border-secondary-500 text-text-primary hover:border-secondary-500 hover:bg-white':
                  index === selectedTab,
              }
            )}
            onClick={() => setSelectedTab(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="flex flex-1 flex-col">{content}</div>
      {children}
    </TabSectionContext.Provider>
  );
}

TabSection.Tab = Tab;

export default TabSection;

import { TakerMarket } from '@/data/useTakerMarkets';

const getTakerMarketDivisionTitle = (takerMarket: TakerMarket) => {
  const takerDivisionName = takerMarket.takerDivisionName ? takerMarket.takerDivisionName.toUpperCase() : null;
  const takerMakerId = takerMarket.takerMakerId ? takerMarket.takerMakerId.toUpperCase() : null;

  if (takerDivisionName && takerMakerId) {
    return {
      title: `${takerDivisionName} ⋅ ${takerMakerId}`,
      content: (
        <>
          <span>{takerDivisionName}</span>
          <span className="mx-0.5 text-lg leading-4">&sdot;</span>
          <span>{takerMakerId}</span>
        </>
      ),
    };
  } else if (takerDivisionName) {
    return {
      title: takerDivisionName,
      content: <span>{takerDivisionName}</span>,
    };
  } else if (takerMakerId) {
    return {
      title: takerMakerId,
      content: <span>{takerMakerId}</span>,
    };
  }
};

export default getTakerMarketDivisionTitle;

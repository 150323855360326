import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@c2fo/liquidity';
import apiClient from '@/lib/apiClient';
import { useRefetchStatsQueries } from '@/lib/serverSentEvents/useServerSideEventListeners';
import { fetchPlatformOffers } from './usePlatformOffers';
import { TakerMarket } from './useTakerMarkets';
import { TakerMarketGroupType } from './useTakerMarketsGroups';

const patchOfferExpirationDate = async ({
  makerOrganizationUuid,
  identifier,
  offerExpiration,
  offerType = 'NAME_YOUR_RATE',
}: {
  makerOrganizationUuid: string;
  identifier: string;
  offerExpiration: string | null;
  // Add support for Static when available
  offerType?: TakerMarketGroupType;
}) => {
  // Add support for Static when available
  const offerTypeSegment = offerType === 'BENCHMARK' ? 'benchmark-offers' : 'price-discovery-offers';

  return await apiClient.patch(
    `api/platform/maker-organizations/${makerOrganizationUuid}/${offerTypeSegment}/${identifier}`,
    {
      headers: {
        'c2fo-application-name': 'enterprise-ui',
      },
      json: {
        frequency: 'recurring',
        offerExpiration,
      },
    }
  );
};

export const useBulkExpirationDateUpdate = () => {
  const { t } = useTranslation();
  const showSnackbar = useSnackbar();
  const { refetchOneTakerMarket } = useRefetchStatsQueries();

  const applyBulkExpirationDateOperation = async ({
    takerMarkets,
    expirationDate,
  }: {
    takerMarkets: TakerMarket[];
    expirationDate: string | null;
  }) => {
    const successes: { marketUuid: string; divisionUuid: string }[] = [];

    showSnackbar({ message: t('expirationDate.bulkUpdate.snackbar.updating') });

    const platformOffers = await fetchPlatformOffers({ takerMarkets });

    // Promise.allSettled() is used to keep track of which promises resolve/reject
    await Promise.allSettled(
      platformOffers.map(async (platformOffer) => {
        try {
          // Offer must have an identifier to be updated
          if (platformOffer.identifier && platformOffer.status === 'active') {
            await patchOfferExpirationDate({
              makerOrganizationUuid: platformOffer.makerOrganizationUuid,
              identifier: platformOffer.identifier,
              offerExpiration: expirationDate,
            });
            successes.push({ marketUuid: platformOffer.marketUuid, divisionUuid: platformOffer.divisionUuid });
          }
        } catch {
          // We're not explicitly catching errors here because we will ultimately compare all takerMarkets with successes.
          // This will not only account for api errors, but also for takerMarkets that never matched with platformOffers to begin with.
        }
      })
    );

    takerMarkets.forEach((takerMarket) => {
      refetchOneTakerMarket({ marketUuid: takerMarket.marketUuid, takerId: takerMarket.takerDivisionId });
    });

    // Find any taker markets that were not specifically updated
    // (This could mean the taker market never matched a platform offer, or the platform api update failed)
    const takerMarketsNotUpdated = takerMarkets.filter(
      (takerMarket) =>
        !successes.some(
          (success) =>
            success.marketUuid === takerMarket.marketUuid && success.divisionUuid === takerMarket.takerDivisionUuid
        )
    );

    if (takerMarketsNotUpdated.length > 0) {
      showSnackbar({ message: t('expirationDate.bulkUpdate.snackbar.failure') });
    } else {
      showSnackbar({ message: t('expirationDate.bulkUpdate.snackbar.success') });
    }

    return {
      takerMarketsNotUpdated,
    };
  };

  return {
    applyBulkExpirationDateOperation,
  };
};

import {
  startOfQuarter,
  subQuarters,
  lastDayOfQuarter,
  startOfMonth,
  subMonths,
  lastDayOfMonth,
  subDays,
} from 'date-fns';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import type { TParams } from '@/types';

/**
 * An object representing a date range with a start and
 * end date represented as a string in the format YYYY-MM-DD.
 */
export interface DateRange {
  /**
   * Start date as YYYY-MM-DD
   */
  startDate: string;
  /**
   * End date as YYYY-MM-DD
   */
  endDate: string;
}

const formatAsDateRangeIso = (date: Date) => trimDateTimestamp(date.toISOString());
const today = formatAsDateRangeIso(new Date());

/**
 * An object representing a date range with a start and
 * end date represented as a string in the format YYYY-MM-DD.
 * These are the possible date ranges that can be selected.
 */
export const dateRanges = {
  monthToDate: {
    startDate: formatAsDateRangeIso(startOfMonth(new Date())),
    endDate: today,
  },
  quarterToDate: {
    startDate: formatAsDateRangeIso(startOfQuarter(new Date())),
    endDate: today,
  },
  last7Days: {
    startDate: formatAsDateRangeIso(subDays(new Date(), 7)),
    endDate: today,
  },
  last30Days: {
    startDate: formatAsDateRangeIso(subDays(new Date(), 30)),
    endDate: today,
  },
  last60Days: {
    startDate: formatAsDateRangeIso(subDays(new Date(), 60)),
    endDate: today,
  },
  last90Days: {
    startDate: formatAsDateRangeIso(subDays(new Date(), 90)),
    endDate: today,
  },
  lastMonth: {
    startDate: formatAsDateRangeIso(startOfMonth(subMonths(new Date(), 1))),
    endDate: formatAsDateRangeIso(lastDayOfMonth(subMonths(new Date(), 1))),
  },
  lastQuarter: {
    startDate: formatAsDateRangeIso(startOfQuarter(subQuarters(new Date(), 1))),
    endDate: formatAsDateRangeIso(lastDayOfQuarter(subQuarters(new Date(), 1))),
  },
} as const;

/**
 * The possible keys of the date ranges.
 */
export type RangeKey = keyof typeof dateRanges | 'custom';

/**
 * Map date range keys to i18n translation arguments.
 */
const rangeI18nMap = {
  monthToDate: ['core.monthToDate'],
  quarterToDate: ['core.quarterToDate'],
  last7Days: ['core.lastNumDays', { num: 7 }],
  last30Days: ['core.lastNumDays', { num: 30 }],
  last60Days: ['core.lastNumDays', { num: 60 }],
  last90Days: ['core.lastNumDays', { num: 90 }],
  lastMonth: ['core.lastMonth'],
  lastQuarter: ['core.lastQuarter'],
  custom: ['core.customRange'],
} satisfies Record<keyof typeof dateRanges | 'custom', TParams>;

/**
 * Gets the i18n translation arguments for a given date range.
 * @param key - The key of the date range.
 */
export function getRangeLabel(key: string) {
  return rangeI18nMap[key as keyof typeof rangeI18nMap];
}

/**
 * Gets the key of a date range given the start and end dates.
 * @param startDate - The start date of the range.
 * @param endDate - The end date of the range.
 * @returns The key of the date range.
 */
export function getRangeKey(startDate: string, endDate: string) {
  return (
    (Object.entries(dateRanges).find(([, { startDate: rangeStart, endDate: rangeEnd }]) => {
      return startDate === rangeStart && endDate === rangeEnd;
    })?.[0] as keyof typeof rangeI18nMap) ?? 'custom'
  );
}

/**
 * Checks if the given date range is a rolling date range.
 * A rolling date range is a date range that is relative to the current date.
 * @param startDate - The start date of the range.
 * @param endDate - The end date of the range.
 * @returns True if the date range is a rolling date range.
 */
export function isRollingDateRange(startDate: string, endDate: string) {
  const rangeKey = getRangeKey(startDate, endDate);
  return ['monthToDate', 'quarterToDate', 'last7Days', 'last30Days', 'last60Days', 'last90Days'].includes(rangeKey);
}

/**
 * Sleeps an async function for a given number of milliseconds.
 * @param ms - The number of milliseconds to sleep.
 */
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Parses a date string in the format YYYY-MM-DD to a Date object and ensures that the time is set to 00:00:00.
 * @param dateString - The date string to parse.
 * @returns A Date object.
 */
export const parseDate = (dateString: string): Date => new Date(`${dateString}T00:00:00`);

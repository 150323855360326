import { useQueryClient } from '@tanstack/react-query';
import { useInterval } from 'react-use';
import { ServerSentEventListeners, useMarketStatsStore } from './useServerSideEventListeners';

// checks for server sent event listeners every 1 second
// if a listener is found > 15 seconds old, refetches stats
const useServerSideEventsInterval = () => {
  const queryClient = useQueryClient();
  const updateMarketStatsStore = useMarketStatsStore();

  useInterval(() => {
    const sseListeners = queryClient.getQueryData<ServerSentEventListeners>(['server-sent-event-listeners']);

    sseListeners?.marketStats.forEach((marketStat) => {
      if (marketStat.time + 15000 < Date.now()) {
        updateMarketStatsStore({ marketUuid: marketStat.marketUuid, takerId: marketStat.takerId });
      }
    });
  }, 1000);
};

export default useServerSideEventsInterval;

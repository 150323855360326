import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from '@c2fo/liquidity';
import apiClient from '@/lib/apiClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { useRefetchStatsQueries } from '@/lib/serverSentEvents/useServerSideEventListeners';
import { usePreferredOfferIsActive } from '@/utils/usePreferredOfferIsActive';
import useAccountPreferredConfig from './useAccountPreferredConfig';
import useTakerMarkets from './useTakerMarkets';

// https://fss-gateway.c2fo.com/usercast/api/c2fo/invoice/exclude

interface GlobalExcludeInvoiceResponse {
  data: [[string, number[]]]; // [[legacyMarketId, [takerIds]]]
}

export type GlobalExcludeInvoiceParamsRuleType =
  | 'amount-greater'
  | 'amount-less'
  | 'dpe-greater'
  | 'dpe-less'
  | 'due-before'
  | 'due-after';

export interface GlobalExcludeInvoiceParamsRule {
  type: GlobalExcludeInvoiceParamsRuleType;
  value: string | number | null;
}

interface takerMarketFilter {
  takerDivisionUuid: string;
  marketUuid: string;
}

export interface GlobalExcludeInvoiceParams {
  currency: string;
  rules: GlobalExcludeInvoiceParamsRule[];
  takerMarketFilter?: takerMarketFilter[];
}

const useGlobalExcludeInvoices = () => {
  const showSnackbar = useSnackbar();
  const { refetchAllStats } = useRefetchStatsQueries();
  const { subscribeToMarketStats } = useServerSideEventListeners();
  const { data: takerMarkets } = useTakerMarkets();
  const { data: accountPreferredConfigData } = useAccountPreferredConfig();
  const hasActivePreferredOffer = usePreferredOfferIsActive();

  let takerMarketFilter: takerMarketFilter[] | undefined;

  if (hasActivePreferredOffer && accountPreferredConfigData?.canEditInvoices !== true) {
    takerMarketFilter = takerMarkets
      // Filter out taker markets that are not permitted to exclude invoices
      ?.filter((takerMarket) => {
        const { offerType, isEnabled } = takerMarket.offerConfig;
        if (offerType === 'PREFERRED_TERM' && isEnabled) {
          return false;
        }

        return true;
      })
      .map((takerMarket) => {
        return {
          takerDivisionUuid: takerMarket.takerDivisionUuid,
          marketUuid: takerMarket.marketUuid,
        };
      });
  }

  return useMutation({
    mutationKey: ['globalExcludeInvoices'],
    mutationFn: ({ currency, rules }: GlobalExcludeInvoiceParams) =>
      apiClient
        .put('usercast/api/c2fo/invoice/exclude', {
          json: { currency, rules, takerMarketFilter },
        })
        .json<GlobalExcludeInvoiceResponse>(),
    onSuccess: () => {
      showSnackbar({ message: 'Invoices excluded' });
      refetchAllStats();
      // NOTE: the following is not a real marketUuid/takerId, this is a specific hard-coded string
      // used only within the frontend to tap into server sent event listeners for global exclude invoice
      subscribeToMarketStats({
        marketUuid: 'global-exclude-invoice-mutation',
        takerId: 0,
      });
    },
    onError: () => showSnackbar({ message: 'Error excluding invoices' }),
  });
};

export default useGlobalExcludeInvoices;

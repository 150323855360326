import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import { useActiveRecurringRules } from '@/data/useActiveRecurringRules';
import useTakerMarkets from '@/data/useTakerMarkets';
import NoActiveRules from '@/features/recurringRules/NoActiveRules';
import RecurringRulesFormModal from '@/features/recurringRules/RecurringRulesFormModal';
import RulesTable from '@/features/recurringRules/RulesTable';
import SuggestedRules from '@/features/recurringRules/SuggestedRules';
import { useReporting } from '@/reporting';

const RecurringRulesManager = () => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const { data: takerMarkets = [] } = useTakerMarkets();
  const [recurringRulesFormOpen, setRecurringRulesFormOpen] = useState<boolean>(false);

  const showRecurringRulesModal = () => setRecurringRulesFormOpen(true);

  const onNewRuleClick = () => {
    showRecurringRulesModal();
    track('recurring-rules::create');
  };

  const hasActiveRules = useActiveRecurringRules().length > 0;

  return (
    <>
      <RecurringRulesFormModal
        onClose={() => setRecurringRulesFormOpen(false)}
        open={recurringRulesFormOpen}
        takerMarkets={takerMarkets}
      />
      <div className="flex flex-col gap-10">
        <div className="flex flex-col items-center justify-between sm:flex-row">
          <h1 className="m-0 pb-4 text-4xl font-medium sm:pb-0">{t('recurringRules.recurringRuleSettings')}</h1>
          <Button className="w-full sm:w-fit" onClick={onNewRuleClick} variant="cta">
            {t('recurringRules.newRule')}
          </Button>
        </div>
        {!hasActiveRules && <NoActiveRules showRecurringRulesModal={showRecurringRulesModal} />}
        {hasActiveRules && <RulesTable />}
        <SuggestedRules showRecurringRulesModal={showRecurringRulesModal} />
      </div>
    </>
  );
};

export default RecurringRulesManager;

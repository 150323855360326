import { RecurringRuleFormValues } from '../RecurringRulesForm';

type Steps = 'category' | 'division' | 'review';

export interface RecurringRulesFormState {
  availableSteps: Steps[];
  currentStep: Steps;
  display: 'createAnother' | 'form';
}

export type RecurringRulesFormAction =
  | {
      type: 'setDisplay';
      payload: { display: RecurringRulesFormState['display'] };
    }
  | {
      type: 'stepBack';
    }
  | {
      type: 'stepForward';
    };

export const getInitialState = (category?: RecurringRuleFormValues['category']): RecurringRulesFormState => {
  // if category is provided, we skip the category step and go straight to division
  if (category) {
    return {
      availableSteps: ['division', 'review'],
      currentStep: 'division',
      display: 'form',
    };
  }

  return {
    availableSteps: ['category', 'division', 'review'],
    currentStep: 'category',
    display: 'form',
  };
};

export const recurringRulesFormReducer = (state: RecurringRulesFormState, action: RecurringRulesFormAction) => {
  const { type } = action;

  switch (type) {
    case 'setDisplay': {
      return {
        ...state,
        display: action.payload.display,
      };
    }

    case 'stepBack': {
      const currentStep = state.currentStep;
      const currentStepIndex = state.availableSteps.indexOf(currentStep);
      const prevStep: RecurringRulesFormState['currentStep'] =
        currentStepIndex === 0 ? currentStep : state.availableSteps[currentStepIndex - 1];

      return {
        ...state,
        currentStep: prevStep,
      };
    }

    case 'stepForward': {
      const currentStep = state.currentStep;
      const currentStepIndex = state.availableSteps.indexOf(currentStep);
      const nextStep: RecurringRulesFormState['currentStep'] =
        currentStepIndex === state.availableSteps.length - 1 ? currentStep : state.availableSteps[currentStepIndex + 1];

      return {
        ...state,
        currentStep: nextStep,
      };
    }

    default:
      return state;
  }
};

import useLocaleFormat from '@/utils/useLocaleFormat';

export const useOfferDiscountAPRDisplay = () => {
  const { asPercent } = useLocaleFormat();

  const offerDiscountAPRDisplay = (value: number, translatedLabel: string) =>
    `${asPercent({ value: value / 100, decimals: 2 })} ${translatedLabel}`;

  return { offerDiscountAPRDisplay };
};

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

// TODO: check which fields are actually nullable/optional
export interface TakerHistoryStatsData {
  acceptedAprWeightedAvg: number;
  acceptedDiscountWeightedAvg: number;
  acceptedDiscountedAmount: number;
  acceptedDpeWeightedAvg: number;
  acceptedEarn: number;
  acceptedInvoiceAmount: number;
  acceptedInvoiceCount: number;
  awardedAprWeightedAvg: number | null;
  awardedDiscountWeightedAvg: number | null;
  awardedDiscountedAmount: number;
  awardedDpeWeightedAvg: number | null;
  awardedEarn: number;
  awardedInvoiceAmount: number;
  awardedInvoiceCount: number;
  clearedAprWeightedAvg: number;
  clearedDiscountWeightedAvg: number;
  clearedDiscountWeightedSum: number;
  clearedDiscountedAmount: number;
  clearedDpeWeightedAvg: number;
  clearedEarn: number;
  clearedInvoiceAmount: number;
  clearedInvoiceCount: number;
  coveringInvoiceAmount: number;
  coveringInvoiceCount: number;
  currency: string;
  matchedAdjustmentAmount: number;
  matchedAdjustmentCount: number;
  maxAcceptedApr: number;
  maxAcceptedDiscount: number;
  maxAcceptedDpe: number;
  maxAwardedApr: number | null;
  maxAwardedDiscount: number | null;
  maxAwardedDpe: number | null;
  maxClearedApr: number;
  maxClearedDiscount: number;
  maxClearedDpe: number;
  minAcceptedApr: number;
  minAcceptedDiscount: number;
  minAcceptedDpe: number;
  minAwardedApr: number | null;
  minAwardedDiscount: number | null;
  minAwardedDpe: number | null;
  minClearedApr: number;
  minClearedDiscount: number;
  minClearedDpe: number;
  notAcceptedAprWeightedAvg: number | null;
  notAcceptedDiscountWeightedAvg: number | null;
  notAcceptedDiscountedAmount: number;
  notAcceptedDpeWeightedAvg: number | null;
  notAcceptedEarn: number;
  notAcceptedInvoiceAmount: number;
  notAcceptedInvoiceCount: number;
  totalAdjustmentAmount: number;
  totalAdjustmentCount: number;
  totalInvoiceCount: number;
  unmatchedAdjustmentAmount: number;
  unmatchedAdjustmentCount: number;
}

export interface HistoryStats {
  clearedInvoiceAmount: number;
  clearedEarn: number;
  clearedDiscountWeightedSum: number;
  clearedDiscountWeightedAvg: number | null;
}

// https://fss-gateway.c2fo.com/usercast/api/c2fo/user/taker/history/stats?currency=USD&filter=%7B%22payDate__between%22:%5B%222023-04-01,2023-06-30%22%5D%7D

export interface TakerHistoryStatsArgs {
  startDate: string;
  endDate: string;
  currency: string;
  makerIds?: string[];
}

const fetchTakerHistoryStats = async ({ startDate, endDate, currency, makerIds }: TakerHistoryStatsArgs) => {
  const filter = JSON.stringify({
    payDate__between: [`${startDate},${endDate}`],
    ...(makerIds && makerIds.length > 0 && { makerId__eq: makerIds }),
  });

  const { data } = await apiClient
    .get(`usercast/api/c2fo/user/taker/history/stats`, {
      searchParams: {
        currency,
        filter,
      },
    })
    .json<{ data: TakerHistoryStatsData[] }>();

  const stats = data.reduce<HistoryStats>(
    (acc, stat) => {
      acc.clearedInvoiceAmount += stat.clearedInvoiceAmount;
      acc.clearedEarn += stat.clearedEarn;
      acc.clearedDiscountWeightedSum += stat.clearedDiscountWeightedSum;
      return acc;
    },
    {
      clearedInvoiceAmount: 0,
      clearedEarn: 0,
      clearedDiscountWeightedAvg: 0,
      clearedDiscountWeightedSum: 0,
    }
  );

  if (stats.clearedDiscountWeightedSum && stats.clearedInvoiceAmount) {
    stats.clearedDiscountWeightedAvg = stats.clearedDiscountWeightedSum / stats.clearedInvoiceAmount;
  } else {
    stats.clearedDiscountWeightedAvg = null;
  }

  return stats;
};

export default function useTakerHistoryStats(params: TakerHistoryStatsArgs, options?: UseQueryOptions<HistoryStats>) {
  return useQuery({
    queryKey: ['taker-history-stats', params],
    queryFn: () => fetchTakerHistoryStats(params),
    suspense: false,
    ...options,
  });
}

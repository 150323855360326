import { useTranslation } from 'react-i18next';
import { Alert } from '@c2fo/liquidity';
import Modal from '@/components/Modal';

interface VariableRateDetailModalProps {
  onCancel: () => void;
  open: boolean;
  discountAmount?: string;
  estimatedRate?: string;
  referenceRate?: string;
  spread?: string;
  rateName?: string;
  showZeroApAlert?: boolean;
}

export const VariableRateDetails = ({
  discountAmount,
  estimatedRate,
  referenceRate,
  spread,
  rateName,
  showZeroApAlert,
}: {
  discountAmount?: string;
  estimatedRate?: string;
  referenceRate?: string;
  spread?: string;
  rateName?: string;
  showZeroApAlert?: boolean;
}) => {
  const { t } = useTranslation();

  // N/A fallback shouldn't happen but just in case, better than nothing at all
  const formattedRateName = rateName ? rateName.toUpperCase() : t('core.na');

  return (
    <>
      {showZeroApAlert && (
        <Alert
          variant="outlined"
          type="warning"
          full
          title={t('offerSubmitDialog.zeroApWarningTitle')}
          description={t('variableRate.details.zeroApDescription')}
        />
      )}
      {/* EXPLANATION SECTION */}
      <div>{t('variableRate.details.intro')}</div>
      <div>{t('variableRate.details.description', { rateName: formattedRateName, spread: spread })}</div>
      {/* BREAKDOWN SECTION */}
      <div className="space-y-2">
        <div className="font-medium">{t('variableRate.details.breakdown.title')}</div>
        <div>
          <div className="flex flex-row">
            <div className="w-1/2 text-text-secondary">
              {t('variableRate.details.rateLabel', { rateName: formattedRateName })}:
            </div>
            <div className="w-1/2 pl-6">
              {referenceRate} {t('core.apr')}*
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-1/2 text-text-secondary">{t('variableRate.details.spread')}:</div>
            <div className="w-1/2 pl-6">
              {spread} {t('core.apr')}
            </div>
          </div>
        </div>
        <div className="text-xs italic text-text-secondary">*{t('variableRate.details.caveat')}</div>
      </div>
      {/* ESTIMATION SECTION */}
      <div className="space-y-2">
        <div className="font-medium">{t('variableRate.details.estimation.title')}</div>
        <div className="flex flex-row">
          <div className="w-1/2">{t('core.variableRate')}:</div>
          <div className="w-1/2 pl-6 font-medium">
            {estimatedRate} ({discountAmount})
          </div>
        </div>
      </div>
    </>
  );
};

const VariableRateDetailModalComponent = (props: VariableRateDetailModalProps) => {
  const { t } = useTranslation();
  const { open, onCancel, discountAmount, estimatedRate, referenceRate, spread, rateName, showZeroApAlert } = props;

  return (
    <Modal
      cancelText={t('core.close')}
      onCancel={onCancel}
      onClose={onCancel}
      open={open}
      title={t('core.variableRate')}
      variant="info"
    >
      <VariableRateDetails
        discountAmount={discountAmount}
        estimatedRate={estimatedRate}
        referenceRate={referenceRate}
        spread={spread}
        rateName={rateName}
        showZeroApAlert={showZeroApAlert}
      />
    </Modal>
  );
};

const VariableRateDetailModal = ({
  onCancel,
  open,
  formattedDiscountAmount,
  formattedEstimatedRate,
  formattedReferenceRate,
  formattedSpread,
  rateName,
  showZeroApAlert = false,
}: {
  onCancel: () => void;
  open: boolean;
  formattedDiscountAmount?: string;
  formattedEstimatedRate?: string;
  formattedReferenceRate?: string;
  formattedSpread?: string;
  rateName?: string;
  showZeroApAlert?: boolean;
}) => {
  return (
    <VariableRateDetailModalComponent
      onCancel={onCancel}
      open={open}
      discountAmount={formattedDiscountAmount}
      estimatedRate={formattedEstimatedRate}
      referenceRate={formattedReferenceRate}
      spread={formattedSpread}
      rateName={rateName}
      showZeroApAlert={showZeroApAlert}
    />
  );
};

export default VariableRateDetailModal;

import { useMutation } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

// api implements mdc async job
// https://fss-gateway.c2fo.com/api/c2fo/user-source/taker/invoices/eligible/csv'

export type TakerMarketIds = {
  marketUuid: string;
  takerId: number;
}[];

const useDownloadInvoices = () => {
  return useMutation({
    mutationKey: ['downloadInvoices'],
    mutationFn: ({ takerMarketIds }: { takerMarketIds: TakerMarketIds }) =>
      apiClient.post('api/c2fo/user-source/taker/invoices/eligible/csv', {
        json: {
          takerMarketIds,
        },
      }),
  });
};

export default useDownloadInvoices;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { OfferFromDraftInput } from '@/generated/gql/graphql';
import { OfferConfig, TakerMarket } from '@/data/useTakerMarkets';
import { useTakerMarketsInDraftOffer } from '@/features/draftOffer/common/useTakerMarketsInDraftOffer';
import { gqlClient } from '@/lib/gqlClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import useDraftOffer from './useDraftOffer';

export const SUBMIT_DRAFT_OFFER = graphql(`
  mutation SubmitOfferFromDraft($offer: OfferFromDraftInput!) {
    submitOfferFromDraft(offer: $offer)
  }
`);

const useSubmitOfferFromDraft = (draftOfferUuid: string) => {
  const queryClient = useQueryClient();
  const { data: draftOfferData } = useDraftOffer(draftOfferUuid);
  const { takerMarkets: takerMarketsInOffer } = useTakerMarketsInDraftOffer(draftOfferUuid);
  const { subscribeToMarketStats } = useServerSideEventListeners();

  return useMutation({
    mutationKey: ['submitOfferFromDraft'],
    mutationFn: () => {
      const offer: OfferFromDraftInput = {
        draftId: draftOfferUuid,
      };

      return gqlClient.request(SUBMIT_DRAFT_OFFER, { offer });
    },
    onSuccess: () => {
      if (draftOfferData && takerMarketsInOffer && takerMarketsInOffer.length) {
        takerMarketsInOffer.forEach((effectedTm) => {
          // update the cache with the returned data
          queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) => {
            return (prevTakerMarkets ?? []).map((prevTm) => {
              const isMatch =
                prevTm.marketUuid === effectedTm.marketUuid &&
                prevTm.takerDivisionUuid === effectedTm.takerDivisionUuid;

              if (!isMatch) {
                return prevTm;
              }

              const { rate, rateType } = draftOfferData;

              const offerConfig: OfferConfig = {
                ...prevTm.offerConfig,
                maxApr: rateType === 'APR' ? rate : null,
                maxDiscount: rateType === 'DISC' ? rate : null,
                isDiscountBidding: rateType === 'DISC',
                isEnabled: true,
                // TODO: Once we support other offer types, we need to map this.
                offerType: 'PRICE_DISCOVERY',
              };

              return {
                ...prevTm,
                offerConfig,
              };
            });
          });

          // subscribe to market stats
          // this will refetch stats queries after we know stats have updated
          subscribeToMarketStats({
            marketUuid: effectedTm.marketUuid,
            takerId: effectedTm.takerDivisionId,
          });
        });
      }
    },
  });
};

export default useSubmitOfferFromDraft;

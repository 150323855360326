import { useTranslation } from 'react-i18next';
import ExcludedInvoicesAlert, { ExcludedInvoicesAlertProps } from '@/components/ExcludedInvoicesAlert';
import useTakerMarketsGroups, { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import useFeature from '@/lib/features';
import GlobalActionDropdown from './GlobalActionsDropdown';
import TabActionsDropdown from './TabActionsDropdown';

interface TabContentProps {
  description: string;
  showActions: boolean;
  showDescription: boolean;
  showExcludedAlert: boolean;
  tab: TakerMarketGroupType;
  excludedAlertProps?: ExcludedInvoicesAlertProps;
}

const TabContent = ({
  description,
  excludedAlertProps,
  showActions,
  showDescription,
  showExcludedAlert,
  tab,
}: TabContentProps) => (
  <div className="flex-1 space-y-4">
    <div className="flex min-h-[48px] items-center justify-between gap-6">
      {showDescription && <div>{description}</div>}
      {showActions && <TabActionsDropdown tab={tab} />}
    </div>
    {showExcludedAlert && excludedAlertProps && (
      <div>
        <ExcludedInvoicesAlert tab={tab} {...excludedAlertProps} />
      </div>
    )}
  </div>
);

const TabActions = ({ activeTab }: { activeTab: TakerMarketGroupType }) => {
  const { t } = useTranslation();
  const takerMarketsGroups = useTakerMarketsGroups();
  const [enableUpdatedGlobalActions] = useFeature('enterprise-ui_enableUpdatedGlobalActions');

  const showPreferredDescription = takerMarketsGroups['PREFERRED'].rollup.count > 0;
  const showNameYourRateActions = takerMarketsGroups['NAME_YOUR_RATE'].takerMarkets.length > 0;
  const showBenchmarkActions = takerMarketsGroups['BENCHMARK'].takerMarkets.length > 0;
  const showFixedActions = takerMarketsGroups['FIXED'].takerMarkets.length > 0;

  const tabConfig: Record<TakerMarketGroupType, TabContentProps> = {
    BENCHMARK: {
      description: t('takerMarketTabs.variableRateDescription'),
      excludedAlertProps: {
        excludedAmount: takerMarketsGroups.BENCHMARK.rollup.excludedAmount,
        takerMarkets: takerMarketsGroups.BENCHMARK.takerMarkets,
      },
      showActions: showBenchmarkActions,
      showDescription: true,
      showExcludedAlert: takerMarketsGroups.BENCHMARK.rollup.excludedAmount > 0,
      tab: 'BENCHMARK',
    },
    FIXED: {
      description: t('takerMarketTabs.fixedRateDescription'),
      excludedAlertProps: {
        excludedAmount: takerMarketsGroups.FIXED.rollup.excludedAmount,
        takerMarkets: takerMarketsGroups.FIXED.takerMarkets,
      },
      showActions: showFixedActions,
      showDescription: true,
      showExcludedAlert: takerMarketsGroups.FIXED.rollup.excludedAmount > 0,
      tab: 'FIXED',
    },
    INACTIVE: {
      description: t('takerMarketTabs.inactiveMarketsDescription'),
      excludedAlertProps: {
        excludedAmount: takerMarketsGroups.INACTIVE.rollup.excludedAmount,
        takerMarkets: takerMarketsGroups.INACTIVE.takerMarkets,
      },
      showActions: false,
      showDescription: true,
      showExcludedAlert: takerMarketsGroups.INACTIVE.rollup.excludedAmount > 0,
      tab: 'INACTIVE',
    },
    NAME_YOUR_RATE: {
      description: t('takerMarketTabs.nameYourRateDescription'),
      excludedAlertProps: {
        excludedAmount: takerMarketsGroups.NAME_YOUR_RATE.rollup.excludedAmount,
        takerMarkets: takerMarketsGroups.NAME_YOUR_RATE.takerMarkets,
      },
      showActions: showNameYourRateActions,
      showDescription: takerMarketsGroups.NAME_YOUR_RATE.rollup.count > 0,
      showExcludedAlert: takerMarketsGroups.NAME_YOUR_RATE.rollup.excludedAmount > 0,
      tab: 'NAME_YOUR_RATE',
    },
    PREFERRED: {
      description: t('takerMarketTabs.preferredRateDescription'),
      showActions: false,
      showDescription: showPreferredDescription,
      showExcludedAlert: false,
      tab: 'PREFERRED',
    },
  };

  if (enableUpdatedGlobalActions) {
    return (
      <div className="flex min-h-[80px] justify-between p-4 sm:flex-row sm:items-center sm:space-y-0">
        <TabContent {...tabConfig[activeTab]} />
      </div>
    );
  }

  return (
    <div className="flex min-h-[80px] flex-col items-start justify-between space-y-4 p-4 sm:flex-row sm:items-center sm:space-y-0">
      {activeTab === 'INACTIVE' && <div>{t('takerMarketTabs.inactiveMarketsDescription')}</div>}
      {activeTab === 'FIXED' && <div>{t('takerMarketTabs.fixedRateDescription')}</div>}
      {/* Do not show the description if the marketing info will be shown */}
      {activeTab === 'PREFERRED' && showPreferredDescription && (
        <div>{t('takerMarketTabs.preferredRateDescription')}</div>
      )}
      {activeTab === 'BENCHMARK' && <div>{t('takerMarketTabs.variableRateDescription')}</div>}
      {activeTab === 'NAME_YOUR_RATE' && (
        <>
          <div>{t('takerMarketTabs.nameYourRateDescription')}</div>
          {showNameYourRateActions && <GlobalActionDropdown />}
        </>
      )}
    </div>
  );
};

export default TabActions;

import { ReactNode } from 'react';
import { Calendar, Popover, cn } from '@c2fo/liquidity';
import { parseDate } from '@/features/history/utils';
import { dateAsIsoString } from '@/utils/dateAsIsoString';

interface DatePickerProps {
  /**
   * Optional class name to apply to the root element.
   */
  className?: string;
  /**
   * Event fired when the popover is opened or closed.
   */
  onOpenChange: (open: boolean) => void;
  /**
   * Event fired when the date range changes.
   */
  onSelect: (date: Date | undefined) => void;
  /**
   * Whether the popover is open. This is controlled externall
   * to allow for it to be programmatically closed in certain scenarios.
   */
  open: boolean;
  /**
   * The value of the date range.
   */
  selected: string | null;
  /**
   * Element used for triggering the RangerPicker. Defaults to "Custom Range".
   */
  trigger: ReactNode;

  triggerClassName?: string;

  disabledOptions?: { before: Date };
}

const DatePicker = ({
  className,
  open,
  onOpenChange,
  onSelect,
  selected,
  trigger,
  triggerClassName,
  disabledOptions,
}: DatePickerProps) => {
  const selectedDate = parseDate(selected ?? dateAsIsoString());

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger className={cn(triggerClassName)}>{trigger}</Popover.Trigger>
      <Popover.Content className={cn(className)} sideOffset={5} variant="light">
        <Calendar mode="single" onSelect={(e) => onSelect(e)} selected={selectedDate} disabled={disabledOptions} />
      </Popover.Content>
    </Popover>
  );
};

export default DatePicker;

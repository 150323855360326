import { useTranslation } from 'react-i18next';
import { PreferredRateInfo } from '@/generated/gql/graphql';
import { TakerMarket } from '@/data/useTakerMarkets';
import handleNaN from '@/utils/handleNaN';
import useLocaleFormat from '@/utils/useLocaleFormat';

export interface PreferredRateDetails {
  formattedDiscountAmount?: string;
  formattedEstimatedRate?: string;
  totalRate?: number;
  formattedReferenceRate?: string;
  formattedSpread?: string;
  rateLabel?: string;
  rateName?: string;
}

const defaultPreferredRateDetails: PreferredRateDetails = {
  formattedDiscountAmount: undefined,
  formattedEstimatedRate: undefined,
  totalRate: undefined,
  formattedReferenceRate: undefined,
  formattedSpread: undefined,
  rateLabel: undefined,
  rateName: undefined,
};

const usePreferredRateDetails = () => {
  const { t } = useTranslation();
  const { asCurrency, asPercent } = useLocaleFormat();

  const getPreferredRateDetails = (takerMarket: TakerMarket | null): PreferredRateDetails => {
    if (!takerMarket) {
      return { ...defaultPreferredRateDetails };
    }

    const rateInfo = takerMarket.rateInfo as PreferredRateInfo;
    const isReferenceRateBidding = takerMarket.offerConfig.isReferenceRateBidding ?? false;
    const isDiscountBidding = takerMarket.offerConfig.isDiscountBidding ?? false;
    const discountRate = takerMarket.offerConfig.maxDiscount;
    const aprRate = takerMarket.offerConfig.maxApr;
    const eligibleDpeWeightedSum = takerMarket.eligibleDpeWeightedSum ?? 0;
    const eligibleInvoiceAmount = takerMarket.eligibleInvoiceAmount ?? 0;
    const weightedAverageDpe = eligibleDpeWeightedSum / eligibleInvoiceAmount;

    if (isReferenceRateBidding && rateInfo && rateInfo.rateSourceName) {
      const totalRate = rateInfo?.totalRate ?? 0;
      const rateSourceName = rateInfo?.rateSourceName;
      const referenceRate = rateInfo?.referenceRate ?? 0;
      const spread = rateInfo?.spread ?? 0;
      const equivalentDisc = (totalRate / 100) * (weightedAverageDpe / 360);
      const estimatedDiscountAmount = eligibleInvoiceAmount * equivalentDisc;
      const formattedDiscountAmount = asCurrency(
        /**
         * Note: acceptedEarn is what we show in the discount column on the market table,
         * because preferred offers should clear 100%, this value should generally be safe to use here if we have it.
         * Otherwise, we use the estimated discount amount.
         */
        handleNaN(0 - (takerMarket.acceptedEarn || estimatedDiscountAmount)),
        takerMarket.currency
      );
      const formattedEstimatedRate = `${asPercent({ value: handleNaN(totalRate / 100), decimals: 2 })} ${t(
        'core.aprAbbreviation'
      )}`;
      const formattedReferenceRate = asPercent({ value: handleNaN(referenceRate / 100), decimals: 2 });
      const formattedSpread = asPercent({ value: handleNaN(spread / 100), decimals: 2 });

      const rateLabel = rateSourceName ? `${rateSourceName} + ${formattedSpread}` : `${formattedSpread}`;

      return {
        formattedDiscountAmount,
        formattedEstimatedRate,
        totalRate,
        formattedReferenceRate,
        formattedSpread,
        rateLabel,
        rateName: rateSourceName,
      };
    }

    // DISC rate
    if (isDiscountBidding && typeof discountRate === 'number') {
      const discountAmount = eligibleInvoiceAmount * discountRate;
      const formattedDiscountAmount = asCurrency(discountAmount, takerMarket.currency);
      const formattedEstimatedRate = `${asPercent({ value: handleNaN(discountRate / 100), decimals: 2 })} ${t(
        'core.discountAbbreviation'
      )}`.toUpperCase();
      return {
        formattedDiscountAmount,
        formattedEstimatedRate,
      };
    }

    // APR rate
    // convert ARR to discount to calculate discount amount
    const equivalentDisc = (aprRate ?? 0) * (weightedAverageDpe / 360);
    const discountAmount = eligibleInvoiceAmount * equivalentDisc;
    const formattedDiscountAmount = asCurrency(handleNaN(0 - discountAmount), takerMarket.currency);
    const formattedEstimatedRate = `${asPercent({ value: handleNaN((aprRate ?? 0) / 100), decimals: 2 })} ${t(
      'core.aprAbbreviation'
    )}`.toUpperCase();

    return {
      formattedDiscountAmount,
      formattedEstimatedRate,
    };
  };

  return {
    getPreferredRateDetails,
  };
};

export default usePreferredRateDetails;

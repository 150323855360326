import { TableSortDirection } from '@/components/Table';
import { RecurringRule } from '@/data/useActiveRecurringRules';

export type SortKey = 'takerMarketName' | 'eligibleInvoiceAmount';
export type Sort = { key: SortKey; direction: TableSortDirection } | null;

const sortRulesTable = (rules: RecurringRule[] = [], sort: Sort) => {
  // should default to eligibleInvoiceAmount desc
  if (!sort?.key || !sort?.direction) {
    return rules;
  }

  return rules.sort((a, b) => {
    const divisonNameA = a.takerMarketName;
    const divisonNameB = b.takerMarketName;

    if (sort.key === 'takerMarketName') {
      return sort.direction === 'desc'
        ? divisonNameB.localeCompare(divisonNameA)
        : divisonNameA.localeCompare(divisonNameB);
    }

    if (sort.key === 'eligibleInvoiceAmount') {
      return sort.direction === 'desc'
        ? b.eligibleInvoiceAmount - a.eligibleInvoiceAmount || divisonNameA.localeCompare(divisonNameB)
        : a.eligibleInvoiceAmount - b.eligibleInvoiceAmount || divisonNameA.localeCompare(divisonNameB);
    }

    return sort.direction === 'desc'
      ? b.eligibleInvoiceAmount - a.eligibleInvoiceAmount || divisonNameA.localeCompare(divisonNameB)
      : a.eligibleInvoiceAmount - b.eligibleInvoiceAmount || divisonNameA.localeCompare(divisonNameB);
  });
};

export default sortRulesTable;

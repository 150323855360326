import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '@c2fo/liquidity';
import { TechnicalDifficultyIcon } from '@c2fo/liquidity/icons';
import invoices from '@/assets/invoicesgrey.svg?url';
import { useInvoiceLayoutOutletContext } from '@/components/InvoiceLayout';
import NoDataState from '@/components/NoDataState';
import QueryBoundaries from '@/components/QueryBoundaries';
import { TableSortDirection } from '@/components/Table';
import {
  IneligibleInvoiceFetchFilters,
  IneligibleInvoiceFetchOptions,
  IneligibleInvoiceSort,
} from '@/data/useIneligibleInvoices';
import IneligibleInvoiceTable from '@/features/ineligibleInvoiceTable/IneligibleInvoiceTable';
import { useReporting } from '@/reporting';
import addOrRemoveFromArray from '@/utils/addOrRemoveFromArray';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useUrlState from '@/utils/useUrlState';

export type IneligibleInvoiceSortKey = Exclude<IneligibleInvoiceSort, `-${string}`>;

export interface IneligibleInvoiceTableSort {
  key: IneligibleInvoiceSortKey;
  direction: TableSortDirection;
}

const IneligibleInvoices = () => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const { asCurrency } = useLocaleFormat();
  const [urlState, setUrlState] = useUrlState<IneligibleInvoiceFetchOptions>({
    page: { page: 1, limit: 100 },
    sort: '-originalAmount',
  });
  const { takerMarket } = useInvoiceLayoutOutletContext();

  const sort: IneligibleInvoiceTableSort = {
    key: (urlState.sort?.replace(/^-/, '') as IneligibleInvoiceSortKey) ?? 'originalAmount',
    direction: urlState.sort?.includes('-') ? 'desc' : 'asc',
  };

  const handleSort = (key: IneligibleInvoiceSortKey) => {
    setUrlState((f) => ({
      ...f,
      sort: f.sort === `-${key}` ? key : `-${key}`,
    }));

    track('invoice-sort::clicked', { key });
  };

  const handleFilter = ({ key, value }: { key: keyof IneligibleInvoiceFetchFilters; value: string }) => {
    setUrlState((f) => ({
      ...f,
      filter: {
        ...f.filter,
        [key]: addOrRemoveFromArray(urlState.filter?.[key] ?? [], value),
      },
    }));

    track('invoice-filter::clicked', { key, value });
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const limit = Number(e.target.value);

    setUrlState((f) => ({
      ...f,
      page: { ...f.page!, limit, page: 1 },
    }));

    track('invoice-page-size::clicked', { size: limit });
  };

  const handleNextChange = () => {
    setUrlState((f) => ({
      ...f,
      page: { ...f.page!, page: f.page!.page + 1 },
    }));

    track('invoice-page::clicked');
  };

  const handlePrevChange = () => {
    setUrlState((f) => ({
      ...f,
      page: { ...f.page!, page: f.page!.page - 1 },
    }));

    track('invoice-page::clicked');
  };

  return (
    <div className="flex border-t">
      {/* FILTERS */}
      <div className="w-44 shrink-0 border-r lg:w-64">
        <div className="px-8 py-6">
          <div className="pb-6 font-medium">{t('core.filters')}</div>
          <div className="space-y-6">
            <div className="text-sm">
              <div className="pb-3 font-medium">{t('core.status')}</div>
              <div className="space-y-3 capitalize">
                <Checkbox
                  checked={(urlState?.filter?.status ?? []).includes('isAdjusted')}
                  color="primary"
                  id="isAdjusted"
                  label={t('taker.invoiceStatus.isAdjusted')}
                  onChange={() => handleFilter({ key: 'status', value: 'isAdjusted' })}
                  value="isAdjusted"
                />
                <Checkbox
                  checked={(urlState?.filter?.status ?? []).includes('isPastDue')}
                  color="primary"
                  id="isPastDue"
                  label={t('taker.invoiceStatus.isPastDue')}
                  onChange={() => handleFilter({ key: 'status', value: 'isPastDue' })}
                  value="isPastDue"
                />
                <Checkbox
                  checked={(urlState?.filter?.status ?? []).includes('isMakerExcluded')}
                  color="primary"
                  id="isMakerExcluded"
                  label={t('taker.invoiceStatus.isMakerExcluded')}
                  onChange={() => handleFilter({ key: 'status', value: 'isMakerExcluded' })}
                  value="isMakerExcluded"
                />
              </div>
            </div>
            <div className="text-sm">
              <div className="pb-3 font-medium">{t('taker.amountFilterLabel')}</div>
              <div className="space-y-3">
                <Checkbox
                  checked={(urlState?.filter?.amount ?? []).includes(',25000')}
                  color="primary"
                  id=",25000"
                  label={t('core.rangeFilterLowest', { max: asCurrency(25000, takerMarket.currency) })}
                  onChange={() => handleFilter({ key: 'amount', value: ',25000' })}
                  value=",25000"
                />
                <Checkbox
                  checked={(urlState?.filter?.amount ?? []).includes('25000,50000')}
                  color="primary"
                  id="25000,50000"
                  label={t('core.rangeFilterMiddle', {
                    min: asCurrency(25000, takerMarket.currency),
                    max: asCurrency(50000, takerMarket.currency),
                  })}
                  onChange={() => handleFilter({ key: 'amount', value: '25000,50000' })}
                  value="25000,50000"
                />
                <Checkbox
                  checked={(urlState?.filter?.amount ?? []).includes('50000,75000')}
                  color="primary"
                  id="50000,75000"
                  label={t('core.rangeFilterMiddle', {
                    min: asCurrency(50000, takerMarket.currency),
                    max: asCurrency(75000, takerMarket.currency),
                  })}
                  onChange={() => handleFilter({ key: 'amount', value: '50000,75000' })}
                  value="50000,75000"
                />
                <Checkbox
                  checked={(urlState?.filter?.amount ?? []).includes('75000,')}
                  color="primary"
                  id="75000,"
                  label={t('core.rangeFilterHighest', { min: asCurrency(75000, takerMarket.currency) })}
                  onChange={() => handleFilter({ key: 'amount', value: '75000,' })}
                  value="75000,"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* TABLE */}
      <div className="flex w-full flex-col overflow-auto">
        <QueryBoundaries
          ErrorComponent={() => (
            <NoDataState
              icon={<TechnicalDifficultyIcon className="h-24 w-24" />}
              title={t('invoices.errorLoadingInvoices')}
              message={t('invoices.errorLoadingInvoicesDescription')}
              action={
                <Button size="sm" onClick={() => window.location.reload()}>
                  {t('somethingsWrong.cta1Heading')}
                </Button>
              }
            />
          )}
          LoadingComponent={() => (
            <NoDataState
              icon={<img src={invoices} alt="invoices" className="h-28 w-28" />}
              message={t('invoices.fetchingIneligibleInvoices')}
            />
          )}
        >
          <IneligibleInvoiceTable
            handleSort={handleSort}
            options={urlState}
            pagination={{
              currentPage: urlState.page?.page ?? 1,
              onNextChange: handleNextChange,
              onPageSizeChange: (e) => handlePageSizeChange(e),
              onPrevChange: handlePrevChange,
              pageSize: urlState.page?.limit ?? 100,
            }}
            sort={sort}
            takerMarket={takerMarket}
          />
        </QueryBoundaries>
      </div>
    </div>
  );
};

export default IneligibleInvoices;

import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Calendar, DateRange, Popover } from '@c2fo/liquidity';
import { parseDate } from '@/features/history/utils';
import useLocaleFormat from '@/utils/useLocaleFormat';

/**
 * Checks if a string is a valid date.
 */
const isValidDate = (date: string) => {
  return !isNaN(Date.parse(date));
};

const isValidDateRange = (range: DateRange | undefined) => {
  if (range === undefined) {
    return false;
  }

  if (range.from === undefined && range.to === undefined) {
    return false;
  }

  return true;
};

interface StringDateRange {
  startDate: string;
  endDate: string;
}

export interface RangePickerProps {
  /**
   * The class name to apply to the root element.
   */
  className?: string;
  /**
   * The value of the date range.
   */
  value: StringDateRange;
  /**
   * Event fired when the date range changes.
   */
  onChange: (range: StringDateRange | undefined) => void;
  /**
   * Whether the popover is open. This is controlled externall
   * to allow for it to be programmatically closed in certain scenarios.
   */
  open?: boolean;
  /**
   * Event fired when the popover is opened or closed.
   */
  onOpenChange?: (open: boolean) => void;
  /**
   * Element used for triggering the RangerPicker. Defaults to "Custom Range".
   */
  trigger?: ReactNode;
}

/**
 * A component that allows the user to select a custom date range.
 */
export default function RangePicker({ value, onChange, className, open, onOpenChange, trigger }: RangePickerProps) {
  // NOTE: react-day-picker defines ranges with from/to properties.
  // Our legacy code uses startDate/endDate. This component
  // is a bridge between the two.
  const [inputRange, setInputRange] = useState<{ from?: string; to?: string } | undefined>();
  const [calRange, setCalRange] = useState<DateRange | undefined>();
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // update the values when the outside value changes
    setInputRange({ from: value.startDate, to: value.endDate });
    setCalRange({
      from: parseDate(value?.startDate ?? ''),
      to: parseDate(value?.endDate ?? ''),
    });
    setIsValid(true);
  }, [value]);

  const { asISODateString } = useLocaleFormat();
  const handleInputChange = (type: keyof DateRange) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setInputRange({ ...inputRange, [type]: value });
    if (isValidDate(value)) {
      setCalRange({
        to: undefined,
        from: undefined,
        ...calRange,
        [type]: parseDate(value),
      });
      setIsValid(true);
    } else {
      setCalRange({
        to: undefined,
        from: undefined,
        ...calRange,
        [type]: undefined,
      });
      setIsValid(false);
    }
  };

  const handleRangeSelect = (range: DateRange | undefined) => {
    setCalRange(range);
    setInputRange({
      from: range?.from ? asISODateString(range.from) : '',
      to: range?.to ? asISODateString(range.to) : '',
    });
    setIsValid(isValidDateRange(range));
  };

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger className={className}>
        {trigger ?? <span className="capitalize">{t('core.customRange')}</span>}
      </Popover.Trigger>
      <Popover.Content align="start" sideOffset={5} variant="light">
        <div className="flex-col: flex flex-nowrap gap-2 sm:flex-row">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col">
              <label className="pb-1 text-sm capitalize">{t('core.from')}</label>
              <input
                name="from"
                className="h-10 rounded-md border px-2 focus-visible:outline-primary-500"
                type="text"
                pattern="\d{2}/\d{2}/\d{4}"
                value={inputRange?.from}
                onChange={handleInputChange('from')}
              />
            </div>
            <div className="flex flex-col">
              <label className="pb-1 text-sm capitalize">{t('core.to')}</label>
              <input
                name="to"
                className="h-10 rounded-md border px-2 focus-visible:outline-primary-500"
                type="text"
                pattern="\d{2}/\d{2}/\d{4}"
                value={inputRange?.to}
                onChange={handleInputChange('to')}
              />
            </div>
            <div className="flex gap-2">
              <Button variant="secondary" size="sm" type="button" onClick={() => onOpenChange?.(false)}>
                {t('core.cancel')}
              </Button>
              <Button
                variant="primary"
                size="sm"
                type="button"
                disabled={!isValid}
                onClick={() => {
                  onChange({
                    startDate: inputRange?.from ? asISODateString(inputRange.from) : '',
                    endDate: inputRange?.to ? asISODateString(inputRange.to) : '',
                  });
                  onOpenChange?.(false);
                }}
              >
                {t('core.apply')}
              </Button>
            </div>
          </div>
          <div>
            <Calendar
              mode="range"
              numberOfMonths={2}
              onSelect={handleRangeSelect}
              pagedNavigation
              selected={calRange}
            />
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
}

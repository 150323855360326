import { GraphQLClient, RequestMiddleware, ResponseMiddleware } from 'graphql-request';
import reauthenticate from '@/utils/reauthenticate';
import getToken from '@/utils/token';

const endpoint = import.meta.env.VITE_PUBLIC_GRAPH_URL;

const requestMiddleware: RequestMiddleware = async (request) => {
  const token = getToken();

  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: `Bearer ${token}`,
    },
  };
};

// temp responseMiddleware logic to handle expired tokens while testing
const responseMiddleware: ResponseMiddleware = (response) => {
  if (JSON.stringify(response).includes('UNAUTHENTICATED')) {
    reauthenticate();
    return;
  }

  return response;
};

export const gqlClient = new GraphQLClient(endpoint, {
  headers: { 'Content-Type': 'application/json' },
  requestMiddleware,
  responseMiddleware,
});

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, cn } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { PencilIcon, QuestionCircleIcon } from '@c2fo/liquidity/icons';
import { TakerMarket } from '@/data/useTakerMarkets';
import NameYourRateParticipationToggle from '@/features/nameYourRate/ParticipationToggle';
import NameYourRateSetOffer from '@/features/nameYourRate/SetOffer';
import VariableRateInfoPopover from '@/features/variableRate/InfoPopover';
import VariableRateParticipationToggle from '@/features/variableRate/ParticipationToggle';
import VariableRateSetOffer from '@/features/variableRate/SetOffer';
import useVariableRateDetails from '@/features/variableRate/useVariableRateDetails';
import useFeature, { Features } from '@/lib/features';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import { isPreferredOfferNearTermEnd } from '@/utils/preferredOfferRenewal';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import FixedRateInfoPopover from '../fixedRate/InfoPopover';
import FixedRateParticipationToggle from '../fixedRate/ParticipationToggle';
import FixedRateSetOffer from '../fixedRate/SetOffer';
import useFixedRateDetails from '../fixedRate/useFixedRateDetails';
import PreferredParticipationToggle from '../preferred/ParticipationToggle';
import RenewalRequestIcon from '../preferred/RenewalRequestIcon';
import usePreferredRateDetails from '../preferred/usePreferredRateDetails';
import VariableRateDetailModal from '../variableRate/DetailModal';

interface OfferContentConfig {
  marketRate: React.ReactNode;
  expirationDate: React.ReactNode;
  offer: React.ReactNode;
  participationToggle?: React.ReactNode;
}

const OfferContent = ({
  openInfoModal,
  openOfferModal,
  takerMarket,
}: {
  openInfoModal?: () => void;
  openOfferModal: () => void;
  takerMarket: TakerMarket;
}) => {
  const { t } = useTranslation();
  const { asPercent, asDateString } = useLocaleFormat();
  const [preferredOffersEnabled] = useFeature(Features['enterprise-ui_enablePreferredOffers']);
  const { getVariableRateDetails } = useVariableRateDetails();
  const {
    formattedEstimatedRate: formattedVariableEstimatedRate,
    rateLabel: variableRateLabel,
    rateName: variableRateName,
  } = getVariableRateDetails(takerMarket);
  const { getFixedRateDetails } = useFixedRateDetails();
  const { formattedMarketRate: formattedFixedMarketRate } = getFixedRateDetails(takerMarket);
  const { getPreferredRateDetails } = usePreferredRateDetails();
  const { formattedEstimatedRate: formattedPreferredEstimatedRate, rateLabel: preferredRateLabel } =
    getPreferredRateDetails(takerMarket);
  // For market pricing types, the pricing type reverts to PRICE_DISCOVERY when the feature flag is
  // turned off. For Preferred, the pricing type is always PRICE_DISCOVERY, so include the feature
  // flag here in order to prevent leaking Preferred features
  const offerType =
    preferredOffersEnabled && takerMarket.offerConfig.offerType === 'PREFERRED_TERM'
      ? 'PREFERRED'
      : takerMarket.marketPricingType === 'BENCHMARK'
      ? 'BENCHMARK'
      : takerMarket.marketPricingType === 'STATIC'
      ? 'STATIC'
      : 'NAME_YOUR_RATE';

  let offerContentConfig: OfferContentConfig = {
    marketRate: null,
    expirationDate: takerMarket.disableAfterMarketClearsDate ? (
      <div className="shrink-0">
        <div className="pb-1 text-sm uppercase">{t('taker.dictionary.expirationDate.label')}</div>
        <div className="flex items-center gap-1 text-2xl">
          {asDateString(trimDateTimestamp(takerMarket.disableAfterMarketClearsDate), {
            month: 'short',
            year: 'numeric',
          })}
        </div>
      </div>
    ) : null,
    offer: (
      <button
        className="-ml-1 -mt-1 flex cursor-pointer items-center gap-3 rounded-md p-1 hover:bg-secondary-700 focus:outline-none active:outline-none"
        onClick={openOfferModal}
        type="button"
      >
        <span className="text-2xl uppercase">
          {takerMarket.offerConfig.isDiscountBidding
            ? `${asPercent({
                value: takerMarket.offerConfig.maxDiscount ? takerMarket.offerConfig.maxDiscount / 100 : 0,
                decimals: 2,
              })} ${t('core.discountAbbreviation')}`
            : `${asPercent({
                value: takerMarket.offerConfig.maxApr ? takerMarket.offerConfig.maxApr / 100 : 0,
                decimals: 2,
              })} ${t('core.aprAbbreviation')}`}
        </span>
        <span className="text-xl">
          <PencilIcon fill={colors.white} />
        </span>
      </button>
    ),
    participationToggle: <NameYourRateParticipationToggle takerMarket={takerMarket} />,
  };

  switch (offerType) {
    case 'PREFERRED':
      // NOTE: The PREFERRED flow will only be visible to users when the enterprise-ui_enablePreferredOffers feature flag is on
      const preferredVariableRateContent = (
        <button
          className="-ml-1 -mt-1 flex cursor-pointer items-center gap-3 rounded-md p-1 text-2xl uppercase hover:bg-secondary-700 focus:outline-none active:outline-none"
          onClick={openInfoModal}
          type="button"
        >
          {preferredRateLabel}
          <QuestionCircleIcon fill={colors.white} />
        </button>
      );
      offerContentConfig = {
        marketRate: takerMarket.rateInfo ? (
          <div className="shrink-0">
            <div className="flex items-center pb-1 text-sm uppercase">
              <VariableRateInfoPopover iconColor={colors.white} />
            </div>
            {preferredVariableRateContent}
          </div>
        ) : null,
        expirationDate: takerMarket.disableAfterMarketClearsDate ? (
          <div className="shrink-0">
            <div className="pb-1 text-sm uppercase">{t('taker.termEnd')}</div>
            <div className="flex items-center gap-1 text-2xl">
              <span className={cn({ 'font-bold text-red-500': isPreferredOfferNearTermEnd(takerMarket) })}>
                {asDateString(trimDateTimestamp(takerMarket.disableAfterMarketClearsDate), {
                  month: 'short',
                  year: 'numeric',
                })}
              </span>
              <RenewalRequestIcon takerMarket={takerMarket} fill={colors.red[500]} />
            </div>
          </div>
        ) : null,
        offer: <div className="text-2xl uppercase">{formattedPreferredEstimatedRate}</div>,
        participationToggle: <PreferredParticipationToggle variant="light" />,
      };
      break;
    case 'BENCHMARK':
      const variableRateContent = variableRateName ? (
        <button
          className="-ml-1 -mt-1 flex cursor-pointer items-center gap-3 rounded-md p-1 text-2xl uppercase hover:bg-secondary-700 focus:outline-none active:outline-none"
          onClick={openInfoModal}
          type="button"
        >
          {variableRateLabel}
          <QuestionCircleIcon fill={colors.white} />
        </button>
      ) : (
        <div className="-ml-1 -mt-1 flex items-center gap-3 rounded-md p-1 text-2xl uppercase">{t('core.na')}</div>
      );
      offerContentConfig = {
        marketRate: (
          <div className="shrink-0">
            <div className="flex items-center pb-1 text-sm uppercase">
              <VariableRateInfoPopover iconColor={colors.white} />
            </div>
            {variableRateContent}
          </div>
        ),
        expirationDate: offerContentConfig.expirationDate,
        offer: (
          <div className="text-2xl uppercase">
            {takerMarket.marketIsEnabled ? formattedVariableEstimatedRate : t('core.na')}
          </div>
        ),
        // Participation toggle is only shown for enabled variable rate markets
        participationToggle: takerMarket.marketIsEnabled && (
          <VariableRateParticipationToggle openEditOfferModal={openOfferModal} takerMarket={takerMarket} />
        ),
      };
      break;
    case 'STATIC':
      const staticRateContent = (
        <p className="-ml-1 -mt-1 flex items-center gap-3 rounded-md p-1 text-2xl uppercase">
          {formattedFixedMarketRate}
        </p>
      );
      offerContentConfig = {
        marketRate: (
          <div className="shrink-0">
            <div className="flex items-center pb-1 text-sm uppercase">
              <FixedRateInfoPopover iconColor={colors.white} />
            </div>
            {staticRateContent}
          </div>
        ),
        expirationDate: offerContentConfig.expirationDate,
        offer: offerContentConfig.offer,
        participationToggle: takerMarket.marketIsEnabled && (
          <FixedRateParticipationToggle openEditOfferModal={openOfferModal} takerMarket={takerMarket} />
        ),
      };
      break;
    case 'NAME_YOUR_RATE':
    default:
      offerContentConfig;
  }

  const offerContent = (
    <div className="flex shrink-0 flex-col gap-4 text-white md:flex-row md:gap-8">
      {offerContentConfig.marketRate}
      <div className="shrink-0">
        <div className="pb-1 text-sm uppercase">{t('taker.offer')}</div>
        {offerContentConfig.offer}
      </div>
      {offerContentConfig.expirationDate}
      {offerContentConfig.participationToggle && (
        <div className="flex flex-col items-start">
          <div className="pb-1 text-sm uppercase">{t('core.participation')}</div>
          <div>{offerContentConfig.participationToggle}</div>
        </div>
      )}
    </div>
  );

  return offerType === 'NAME_YOUR_RATE' ? (
    <IntercomDataTarget className="flex shrink-0" target="nameYourRateBannerActions">
      {offerContent}
    </IntercomDataTarget>
  ) : (
    offerContent
  );
};

const MarketTypeHeader = ({ loading, takerMarket }: { loading: boolean; takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { asPercent } = useLocaleFormat();
  const [preferredOffersEnabled] = useFeature(Features['enterprise-ui_enablePreferredOffers']);
  const isPreferredTerm = takerMarket.offerConfig.offerType === 'PREFERRED_TERM';
  const clearingPercent = asPercent({
    value: takerMarket.acceptedInvoiceAmount
      ? takerMarket.acceptedInvoiceAmount / takerMarket.eligibleInvoiceAmount
      : 0,
  });

  let marketTypeHeader = t('core.nameYourRate');

  takerMarket.marketPricingType === 'BENCHMARK'
    ? (marketTypeHeader = t('core.variableRate'))
    : takerMarket.marketPricingType === 'STATIC'
    ? (marketTypeHeader = t('core.fixedRate'))
    : preferredOffersEnabled && isPreferredTerm
    ? (marketTypeHeader = t('core.preferredOffer'))
    : (marketTypeHeader = t('core.nameYourRate'));

  return (
    <div className="w-full border-b border-white pb-4 lg:border-b-0 lg:pb-0">
      <h5 className="m-0 text-xl font-medium text-white">{marketTypeHeader}</h5>
      <div
        className={cn('text-lg text-white', {
          'animate-pulse': loading,
        })}
      >
        {loading
          ? t('taker.marketFeedback.calculating')
          : preferredOffersEnabled && isPreferredTerm
          ? t('taker.offerBarDefaultMsg', { clearingPercent })
          : !takerMarket.offerConfig.id
          ? t('taker.offerBarFirstOfferMsg', { clearingPercent })
          : takerMarket.isTpf
          ? t('taker.offerBarDefaultMsgTpf', { clearingPercent })
          : !takerMarket.offerConfig.isEnabled
          ? t('taker.offerBarMsgOfferOff')
          : !takerMarket.marketIsEnabled
          ? t('closedMarketMessaging.offerWillBeEnabled')
          : t('taker.offerBarDefaultMsg', { clearingPercent })}
      </div>
      {loading && (
        <div className="pt-2 text-sm text-white">
          <div>{t('updating.numbersInaccurate')}</div>
          <div>{t('updating.takingTooLong')}</div>
        </div>
      )}
    </div>
  );
};

const SetOfferButton = ({ onClick, takerMarket }: { onClick: () => void; takerMarket: TakerMarket }) => {
  const { t } = useTranslation();

  const showFutureOfferText = !takerMarket.marketIsEnabled && takerMarket.marketPricingType === 'PRICE_DISCOVERY';

  return (
    <div className="shrink-0 text-center">
      <div className="flex items-center justify-center">
        <Button variant="tertiary" onClick={onClick}>
          {showFutureOfferText ? t('core.setOfferLabelFuture') : t('core.setOfferLabel')}
        </Button>
      </div>
    </div>
  );
};

const TakerMarketBannerWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="flex min-h-[64px] flex-col items-start justify-between gap-4 rounded-t-md bg-secondary-500 p-8 md:items-center lg:flex-row lg:gap-8">
    {children}
  </div>
);

const TakerMarketBanner = ({ loading = false, takerMarket }: { loading?: boolean; takerMarket: TakerMarket }) => {
  const { getPreferredRateDetails } = usePreferredRateDetails();
  const { getVariableRateDetails } = useVariableRateDetails();

  const [showSetOfferModal, setShowSetOfferModal] = useState<boolean>(false);
  const [showVariableRateDetails, setShowVariableRateDetails] = useState<TakerMarket | null>(null);

  const openVariableRateModal = (takerMarket: TakerMarket) => {
    setShowVariableRateDetails(takerMarket);
  };

  const closeVariableRateModal = () => setShowVariableRateDetails(null);

  if (takerMarket.offerConfig.offerType === 'PREFERRED_TERM') {
    // NOTE: The PREFERRED flow will only be visible to users when the enterprise-ui_enablePreferredOffers feature flag is on
    const { formattedDiscountAmount, formattedEstimatedRate, formattedReferenceRate, formattedSpread, rateName } =
      getPreferredRateDetails(takerMarket);
    return (
      <>
        <VariableRateDetailModal
          onCancel={closeVariableRateModal}
          open={!!showVariableRateDetails}
          formattedDiscountAmount={formattedDiscountAmount}
          formattedEstimatedRate={formattedEstimatedRate}
          formattedReferenceRate={formattedReferenceRate}
          formattedSpread={formattedSpread}
          rateName={rateName}
          showZeroApAlert={takerMarket.eligibleInvoiceAmount <= 0}
        />
        <TakerMarketBannerWrapper>
          <MarketTypeHeader loading={loading} takerMarket={takerMarket} />
          <OfferContent
            openInfoModal={() => setShowVariableRateDetails(takerMarket)}
            openOfferModal={() => setShowSetOfferModal(true)}
            takerMarket={takerMarket}
          />
        </TakerMarketBannerWrapper>
      </>
    );
  }

  if (takerMarket.marketPricingType === 'STATIC') {
    return (
      <>
        <FixedRateSetOffer
          open={showSetOfferModal}
          onClose={() => setShowSetOfferModal(false)}
          takerMarket={takerMarket}
        />
        <TakerMarketBannerWrapper>
          <MarketTypeHeader loading={loading} takerMarket={takerMarket} />
          {!takerMarket.offerConfig.id ? (
            <SetOfferButton onClick={() => setShowSetOfferModal(true)} takerMarket={takerMarket} />
          ) : (
            <OfferContent openOfferModal={() => setShowSetOfferModal(true)} takerMarket={takerMarket} />
          )}
        </TakerMarketBannerWrapper>
      </>
    );
  }

  if (takerMarket.marketPricingType === 'BENCHMARK') {
    const { formattedDiscountAmount, formattedEstimatedRate, formattedReferenceRate, formattedSpread, rateName } =
      getVariableRateDetails(takerMarket);
    return (
      <>
        <VariableRateSetOffer
          open={showSetOfferModal}
          onClose={() => setShowSetOfferModal(false)}
          takerMarket={takerMarket}
        />
        <VariableRateDetailModal
          onCancel={closeVariableRateModal}
          open={!!showVariableRateDetails}
          formattedDiscountAmount={formattedDiscountAmount}
          formattedEstimatedRate={formattedEstimatedRate}
          formattedReferenceRate={formattedReferenceRate}
          formattedSpread={formattedSpread}
          rateName={rateName}
          showZeroApAlert={takerMarket.eligibleInvoiceAmount <= 0}
        />
        <TakerMarketBannerWrapper>
          <MarketTypeHeader loading={loading} takerMarket={takerMarket} />
          {!takerMarket.offerConfig.id ? (
            <SetOfferButton onClick={() => setShowSetOfferModal(true)} takerMarket={takerMarket} />
          ) : (
            <OfferContent
              openInfoModal={() => openVariableRateModal(takerMarket)}
              openOfferModal={() => setShowSetOfferModal(true)}
              takerMarket={takerMarket}
            />
          )}
        </TakerMarketBannerWrapper>
      </>
    );
  }

  return (
    <>
      <NameYourRateSetOffer
        open={showSetOfferModal}
        futureOffer={!takerMarket.marketIsEnabled}
        onClose={() => setShowSetOfferModal(false)}
        takerMarket={takerMarket}
      />
      <TakerMarketBannerWrapper>
        <MarketTypeHeader loading={loading} takerMarket={takerMarket} />
        {!takerMarket.offerConfig.id ? (
          <SetOfferButton onClick={() => setShowSetOfferModal(true)} takerMarket={takerMarket} />
        ) : (
          <OfferContent openOfferModal={() => setShowSetOfferModal(true)} takerMarket={takerMarket} />
        )}
      </TakerMarketBannerWrapper>
    </>
  );
};

export default TakerMarketBanner;

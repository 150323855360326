import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@c2fo/liquidity';
import Divider from '@/components/Divider';
import QueryBoundaries from '@/components/QueryBoundaries';
import Skeleton from '@/components/Skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableMessage,
  TablePagination,
  TableRow,
  TableSortArrow,
} from '@/components/Table';
import useCreditNoteByClear, { CreditNoteByClearParams } from '@/data/useCreditNoteByClear';
import { useReporting } from '@/reporting';
import { useToken } from '@/utils/token';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import RequestCreditNoteModal from './RequestCreditNoteModal';

const prefixUrl = import.meta.env.VITE_GATEWAY_URL;

/**
 * The data required for each chart.
 */
export interface HistoryTableDatum {
  /**
   * The date of the pay period.
   */
  payDate: string;
  /**
   * The total amount of cleared invoices for the pay period.
   */
  clearedInvoiceAmount: number;
  /**
   * The id of the taker.
   */
  takerId: number;
  /**
   * The id of the market.
   */
  marketId: string;
  /**
   * The name of the maker organization.
   */
  makerOrganizationName?: string;
  /**
   * The name of the taker division.
   */
  takerDivisionName?: string;
  /**
   * The total amount of cleared invoices for the pay period.
   */
  clearedEarn: number;
  /**
   * The cleared discount weighted avg of cleared invoices for the pay period.
   */
  clearedDiscountWeightedAvg: number;
  /**
   * The cleared dpe weighted avg of cleared invoices for the pay period.
   */
  clearedDpeWeightedAvg: number;
  /**
   * The cleared apr weighted avg of cleared invoices for the pay period.
   */
  clearedAprWeightedAvg: number;
  /**
   * The pending clear id
   */
  id: number | null;
  /**
   * The record includes a credit note
   */
  hasCreditNote?: boolean;
}

export interface HistoryTableProps<T extends HistoryTableDatum> {
  data?: Array<T>;
  sort: string;
  setSort?: (sort: string) => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  pageIndex?: number;
  pageSize: number;
  currency?: string;
  displayType: 'awarded' | 'pending';
  historyType: 'consolidated' | 'market';
  loading: boolean;
}

export default function HistoryTable<T extends HistoryTableDatum>({
  data = [],
  pageSize,
  pageIndex = 0,
  sort: sortKey,
  onPageChange,
  onPageSizeChange,
  setSort,
  currency = 'USD',
  displayType,
  historyType,
  loading = false,
}: HistoryTableProps<T>) {
  const { token } = useToken();
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const { asCurrency, asNumber, asPercent } = useLocaleFormat();
  const { mutateAsync: requestCreditNote } = useCreditNoteByClear();
  const [showRequestCreditNoteModal, setShowRequestCreditNoteModal] = useState(false);

  const sort = useMemo(() => {
    const [, desc, key] = sortKey.match(/^(-)?(.*)$/) ?? [];
    return { key, direction: desc ? 'desc' : 'asc' } as const;
  }, [sortKey]);

  const handleSort = useCallback(
    (columnId: string) => (sortKey === `-${columnId}` ? setSort?.(columnId) : setSort?.(`-${columnId}`)),
    [setSort, sortKey]
  );

  const hasMakerOrganizationName = useMemo(() => data.some((row) => row.makerOrganizationName), [data]);

  const handleRequestCreditNote = async ({ takerId, marketId, payDate }: CreditNoteByClearParams) => {
    track('history-credit-note-request::clicked');

    await requestCreditNote(
      { takerId, marketId, payDate },
      {
        onSuccess: (response) => {
          if (response.status === 202) {
            setShowRequestCreditNoteModal(true);
          } else {
            showSnackbar({
              message: t('core.downloadError'),
            });
          }
        },
        onError: () => {
          showSnackbar({
            message: t('core.downloadError'),
          });
        },
      }
    );
  };

  return (
    <QueryBoundaries LoadingComponent={() => <Skeleton />}>
      <RequestCreditNoteModal open={showRequestCreditNoteModal} onClose={() => setShowRequestCreditNoteModal(false)} />
      <div className="relative w-full overflow-auto">
        <Table>
          <TableHeader>
            <TableRow>
              {hasMakerOrganizationName && (
                <TableHead onClick={() => handleSort('makerOrganizationName')}>
                  <TableSortArrow accessorKey="makerOrganizationName" sort={sort}>
                    {t('tpf.customer')}
                  </TableSortArrow>
                </TableHead>
              )}
              <TableHead onClick={() => handleSort('payDate')}>
                <TableSortArrow accessorKey="payDate" sort={sort} textRight>
                  {t('core.payDate')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('clearedInvoiceAmount')}>
                <TableSortArrow accessorKey="clearedInvoiceAmount" sort={sort} textRight>
                  {t('taker.totalPaid')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('clearedEarn')}>
                <TableSortArrow accessorKey="clearedEarn" sort={sort} textRight>
                  {t('taker.totalDiscount')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('clearedDiscountWeightedAvg')}>
                <TableSortArrow accessorKey="clearedDiscountWeightedAvg" sort={sort} textRight>
                  {t('taker.averageDiscount')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('clearedDpeWeightedAvg')}>
                <TableSortArrow accessorKey="clearedDpeWeightedAvg" sort={sort} textRight>
                  {t('maker.averageDpe')}
                </TableSortArrow>
              </TableHead>
              {displayType === 'awarded' || (displayType === 'pending' && historyType === 'market') ? (
                <TableHead className="text-right">{t('maker.download')}</TableHead>
              ) : null}
            </TableRow>
          </TableHeader>
          <TableBody loading={loading}>
            <>{!data.length && <TableMessage colSpan={7} message="No awarded data found" />}</>
            <>
              {data.map((row, i) => (
                <TableRow key={`${row.marketId}-${i}`}>
                  {hasMakerOrganizationName && (
                    <TableCell>
                      <div className="flex w-full flex-col">
                        <div className="text-sm">{row.makerOrganizationName}</div>
                        <div className="text-xs text-gray-500">{row.takerDivisionName}</div>
                      </div>
                    </TableCell>
                  )}
                  <TableCell className="text-right">{trimDateTimestamp(row.payDate)}</TableCell>
                  <TableCell className="text-right">{asCurrency(row.clearedInvoiceAmount, currency)}</TableCell>
                  <TableCell className="text-right">{asCurrency(row.clearedEarn, currency)}</TableCell>
                  <TableCell className="text-right">
                    {asPercent({
                      value: row.clearedDiscountWeightedAvg / 100,
                      decimals: 2,
                    })}
                  </TableCell>
                  <TableCell className="text-right">
                    {asNumber({
                      value: row.clearedDpeWeightedAvg,
                      decimals: 0,
                    })}
                  </TableCell>
                  {displayType === 'pending' ? (
                    <>
                      {historyType === 'market' && (
                        <TableCell className="text-right">
                          <a
                            href={`${prefixUrl}/api/c2fo/taker/${row.takerId}/market/${row.marketId}/pending-clear/${row.id}/csv?token=${token}`}
                            target="_self"
                            onClick={() => track('history-csv-download::clicked')}
                          >
                            {t('maker.csv')}
                          </a>
                        </TableCell>
                      )}
                    </>
                  ) : (
                    <TableCell className="text-right">
                      <div className="flex items-center justify-end gap-2">
                        <a
                          href={`${prefixUrl}/api/c2fo/taker/${row.takerId}/market/${row.marketId}/archive/${row.payDate}/csv?token=${token}`}
                          target="_self"
                          onClick={() => track('history-csv-download::clicked')}
                        >
                          {t('maker.csv')}
                        </a>
                        <Divider vertical />
                        <a
                          href={`${prefixUrl}/api/c2fo/taker/${row.takerId}/market/${row.marketId}/archive/${row.payDate}/pdf?token=${token}`}
                          target="_self"
                          onClick={() => track('history-pdf-download::clicked')}
                        >
                          {t('core.pdf')}
                        </a>
                        {row.hasCreditNote ? (
                          <>
                            <Divider vertical />
                            <button
                              onClick={() =>
                                handleRequestCreditNote({
                                  takerId: row.takerId,
                                  marketId: row.marketId,
                                  payDate: row.payDate,
                                })
                              }
                              type="button"
                            >
                              Notes
                            </button>
                          </>
                        ) : null}
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
      </div>
      <TablePagination
        className="mt-auto"
        pageSize={pageSize}
        onPageSizeChange={(e) => onPageSizeChange(+e.target.value)}
        onNextChange={() => {
          onPageChange(pageIndex + 1);
        }}
        onPrevChange={() => {
          onPageChange(pageIndex - 1);
        }}
        disablePrev={pageIndex === 1}
        disableNext={data.length < pageSize}
      />
    </QueryBoundaries>
  );
}

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { InfoCircleIcon } from '@c2fo/liquidity/icons';
import useDownloadInvoices, { TakerMarketIds } from '@/data/useDownloadInvoices';
import useReincludeInvoices from '@/data/useReincludeInvoices';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';
import { useReporting } from '@/reporting';
import useLocaleFormat from '@/utils/useLocaleFormat';
import DownloadInvoicesModal from './DownloadInvoicesModal';
import GlobalExcludeInvoicesModal from './GlobalExcludeInvoicesModal';

const InvoiceActions = ({
  currency,
  eligibleInvoiceCount,
  takerExcludedInvoiceAmount,
}: {
  currency: string;
  eligibleInvoiceCount: number;
  takerExcludedInvoiceAmount: number;
}) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const { asCurrency } = useLocaleFormat();
  const [showDownloadInvoicesModal, setShowDownloadInvoicesModal] = useState(false);
  const [showExcludeInvoicesModal, setShowExcludeInvoicesModal] = useState(false);
  const { mutateAsync: reInculdeInvoices, isLoading: reinculdeInvoicesLoading } = useReincludeInvoices();
  const { mutate: downloadInvoices } = useDownloadInvoices();

  const { data: takerMarketsWithEligibleInvoices } = useTakerMarkets(
    useCallback((takerMarkets: TakerMarket[]) => {
      const withEligibleInvoices: TakerMarketIds = [];

      takerMarkets.forEach((tm) => {
        if (tm.eligibleInvoiceCount > 0) {
          withEligibleInvoices.push({
            takerId: tm.takerDivisionId,
            marketUuid: tm.marketUuid,
          });
        }
      });

      return withEligibleInvoices;
    }, [])
  );

  const handleReinculdeInvoices = async () => {
    await reInculdeInvoices();
    track('reinclude-invoices::submitted', {
      amount: takerExcludedInvoiceAmount,
    });
  };

  const handleDowndloadInvoices = () => {
    downloadInvoices({ takerMarketIds: takerMarketsWithEligibleInvoices! });
    setShowDownloadInvoicesModal(true);
    track('download-invoices::submitted', { takerMarketIds: takerMarketsWithEligibleInvoices! });
  };

  const openExcludeInvoiceModal = () => {
    setShowExcludeInvoicesModal(true);
    track('exclude-invoices::clicked');
  };

  return (
    <>
      <DownloadInvoicesModal open={showDownloadInvoicesModal} onClose={() => setShowDownloadInvoicesModal(false)} />
      <GlobalExcludeInvoicesModal open={showExcludeInvoicesModal} onClose={() => setShowExcludeInvoicesModal(false)} />
      <div className="flex flex-col items-center justify-between gap-4 rounded-b-md bg-secondary-700 px-8 py-5 text-white lg:flex-row">
        {takerExcludedInvoiceAmount === 0 && (
          <div className="flex items-center gap-2">
            <InfoCircleIcon className="h-5 w-5" fill={colors.white} />
            <div>{t('invoiceSettings.none')}</div>
          </div>
        )}
        {takerExcludedInvoiceAmount > 0 && (
          <div className="flex items-center gap-2">
            <InfoCircleIcon className="h-5 w-5" fill={colors.white} />
            <div>
              {t('invoiceSettings.excludedAmount', {
                amount: asCurrency(takerExcludedInvoiceAmount, currency),
              })}
            </div>
          </div>
        )}
        <div className="flex w-full shrink-0 flex-col items-center gap-4 md:w-fit md:flex-row">
          <div className="w-full shrink-0 md:w-fit">
            <Button
              size="sm"
              variant="tertiary"
              className="w-full md:w-fit"
              disabled={eligibleInvoiceCount === 0}
              onClick={handleDowndloadInvoices}
            >
              {t('core.downloadInvoices')}
            </Button>
          </div>
          {takerExcludedInvoiceAmount > 0 && (
            <div className="w-full shrink-0 md:w-fit">
              <Button
                size="sm"
                variant="tertiary"
                className="w-full md:w-fit"
                onClick={handleReinculdeInvoices}
                disabled={reinculdeInvoicesLoading}
                loading={reinculdeInvoicesLoading}
              >
                {t('invoiceSettings.undo')}
              </Button>
            </div>
          )}
          <div className="w-full shrink-0 md:w-fit">
            <Button
              size="sm"
              variant="tertiary"
              className="w-full md:w-fit"
              disabled={eligibleInvoiceCount === 0}
              onClick={openExcludeInvoiceModal}
            >
              {t('invoiceSettings.cta')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceActions;

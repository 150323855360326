import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button } from '@c2fo/liquidity';
import { RecurringRuleFormValues } from '../RecurringRulesForm';

const CriteriaSelect = () => {
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'criteria',
  });

  const [category, criteria]: [RecurringRuleFormValues['category'], RecurringRuleFormValues['criteria']] = getValues([
    'category',
    'criteria',
  ]);

  const onAddCriteria = () => {
    if (category === 'invoiceId') {
      return;
    } else if (category === 'amount') {
      if (criteria.map(({ type }) => type).includes('minInvoiceAmount')) {
        append({ type: 'maxInvoiceAmount', value: '' });
      } else {
        append({ type: 'minInvoiceAmount', value: '' });
      }
    } else if (category === 'dpe') {
      if (criteria.map(({ type }) => type).includes('minDpe')) {
        append({ type: 'maxDpe', value: '' });
      } else {
        append({ type: 'minDpe', value: '' });
      }
    } else if (category === 'dueDate') {
      if (criteria.map(({ type }) => type).includes('minDueDate')) {
        append({ type: 'maxDueDate', value: '' });
      } else {
        append({ type: 'minDueDate', value: '' });
      }
    }
  };

  return (
    <div className="space-y-1">
      <div className="font-medium">Rule Criteria</div>
      <div className="space-y-6">
        {fields.map((item, index) => (
          <div key={item.id} className="flex items-center gap-4">
            <div>{criteria[index].type}</div>
            <Controller
              control={control}
              name={`criteria[${index}].value`}
              render={({ field }) => (
                <input
                  {...field}
                  aria-label={criteria[index].type}
                  className="rounded border border-stroke px-4 py-3"
                />
              )}
            />
            {criteria.length > 1 && (
              <button onClick={() => remove(index)} type="button">
                Remove
              </button>
            )}
          </div>
        ))}
        {category !== 'invoiceId' && (
          <Button disabled={criteria.length === 2} onClick={onAddCriteria} size="sm" variant="tertiary">
            Add Criteria
          </Button>
        )}
      </div>
    </div>
  );
};

export default CriteriaSelect;

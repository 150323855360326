import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, cn } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { TimesIcon } from '@c2fo/liquidity/icons';
import DatePicker from '@/components/DatePicker';
import Modal from '@/components/Modal';
import useAccountPreferredConfig from '@/data/useAccountPreferredConfig';
import useEditInvoices, {
  EditInvoiceParamsRule,
  EditInvoiceParamsRuleType,
  InvoiceFilters,
} from '@/data/useEditInvoices';
import { TakerMarket } from '@/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import { useReporting } from '@/reporting';
import { dateAsIsoString } from '@/utils/dateAsIsoString';
import { usePreferredOfferIsActive } from '@/utils/usePreferredOfferIsActive';

type RuleErrorType = 'empty' | 'invalid';

interface RuleErrorList {
  error: RuleErrorType;
  type: EditInvoiceParamsRuleType;
}

const mapRules = (rules: EditInvoiceParamsRule[]): InvoiceFilters => {
  let filter: InvoiceFilters = {};

  rules.forEach((rule) => {
    if (['amount-greater', 'amount-less'].includes(rule.type)) {
      filter = {
        ...filter,
        amount: {
          ...filter.amount,
          ...(rule.type === 'amount-greater' && rule.value && { from: Number(rule.value) }),
          ...(rule.type === 'amount-less' && rule.value && { to: Number(rule.value) }),
        },
      };
    }

    if (['dpe-greater', 'dpe-less'].includes(rule.type)) {
      filter = {
        ...filter,
        daysPaidEarly: {
          ...filter.daysPaidEarly,
          ...(rule.type === 'dpe-greater' && rule.value && { from: Number(rule.value) }),
          ...(rule.type === 'dpe-less' && rule.value && { to: Number(rule.value) }),
        },
      };
    }

    if (['due-before', 'due-after'].includes(rule.type)) {
      filter = {
        ...filter,
        originalDueDate: {
          ...filter.originalDueDate,
          ...(rule.type === 'due-after' && rule.value && { from: rule.value }),
          ...(rule.type === 'due-before' && rule.value && { to: rule.value }),
        },
      };
    }

    if (rule.type === 'invoice-id-contains') {
      filter = {
        ...filter,
        ...(rule.value && { voucherSearchText: rule.value as string }),
      };
    }
  });

  return filter;
};

const ExcludeInvoicesModal = ({
  open,
  onClose,
  takerMarkets,
  toCurrency,
  type,
}: {
  open: boolean;
  onClose: () => void;
  takerMarkets: TakerMarket[];
  type: TakerMarketGroupType | 'division';
  toCurrency?: string;
}) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const { excludeInvoices } = useEditInvoices();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<RuleErrorList[]>([]);
  const hasActivePreferredOffer = usePreferredOfferIsActive();
  const { data: accountPreferredConfig } = useAccountPreferredConfig();
  const [selectedRules, setSelectedRules] = useState<EditInvoiceParamsRule[]>([]);
  const [dueBeforeDatePickerOpen, setDueBeforeDatePickerOpen] = useState<boolean>(false);
  const [dueAfterDatePickerOpen, setDueAfterDatePickerOpen] = useState<boolean>(false);

  const ruleOptions = useMemo<{ name: string; type: EditInvoiceParamsRuleType }[]>(
    () => [
      { name: t('invoiceSettings.rules.amountGreater'), type: 'amount-greater' },
      { name: t('invoiceSettings.rules.amountLess'), type: 'amount-less' },
      { name: t('invoiceSettings.rules.dpeGreater'), type: 'dpe-greater' },
      { name: t('invoiceSettings.rules.dpeLess'), type: 'dpe-less' },
      { name: t('invoiceSettings.rules.dueBefore'), type: 'due-before' },
      { name: t('invoiceSettings.rules.dueAfter'), type: 'due-after' },
      { name: t('invoiceSettings.rules.invoiceIdContains'), type: 'invoice-id-contains' },
    ],
    [t]
  );

  const ruleErrorMap = useMemo(
    () =>
      ({
        'amount-greater': {
          empty: t('invoiceSettings.rules.amountErrorEmpty'),
          invalid: t('invoiceSettings.rules.amountErrorInvalid'),
        },
        'amount-less': {
          empty: t('invoiceSettings.rules.amountErrorEmpty'),
          invalid: t('invoiceSettings.rules.amountErrorInvalid'),
        },
        'dpe-greater': {
          empty: t('invoiceSettings.rules.dpeErrorEmpty'),
          invalid: t('invoiceSettings.rules.dpeErrorInvalid'),
        },
        'dpe-less': {
          empty: t('invoiceSettings.rules.dpeErrorEmpty'),
          invalid: t('invoiceSettings.rules.dpeErrorInvalid'),
        },
        'due-before': {
          empty: t('invoiceSettings.rules.dueDateErrorEmpty'),
          invalid: t('invoiceSettings.rules.dueDateErrorInvalid'),
        },
        'due-after': {
          empty: t('invoiceSettings.rules.dueDateErrorEmpty'),
          invalid: t('invoiceSettings.rules.dueDateErrorInvalid'),
        },
        'invoice-id-contains': {
          empty: t('invoiceSettings.rules.invoiceIdErrorEmpty'),
          invalid: t('invoiceSettings.rules.invoiceIdErrorEmpty'),
        },
      }) satisfies { [ruleType in EditInvoiceParamsRuleType]: { [errorType in RuleErrorType]: string } },
    [t]
  );

  useEffect(() => {
    if (open) {
      setSelectedRules([{ type: ruleOptions[0].type, value: null }]);
      setErrors([]);
    }
  }, [open, ruleOptions]);

  const addSelectedRule = () => {
    const selectedRulesMap = selectedRules.map(({ type }) => type);
    const ruleOptionsMap = ruleOptions.filter(({ type }) => !selectedRulesMap.includes(type));
    setSelectedRules([...selectedRules, { type: ruleOptionsMap[0].type, value: null }]);
  };

  const removeSelectedRule = (type: string) => {
    if (selectedRules.length > 1) {
      setSelectedRules(selectedRules.filter((sr) => sr.type !== type));
      setErrors((errors) => errors.filter((error) => error.type !== type));
    }
  };

  const handleRuleSelect = ({
    existingRule,
    updatedRule,
  }: {
    existingRule: EditInvoiceParamsRuleType;
    updatedRule: EditInvoiceParamsRuleType;
  }) => {
    setSelectedRules((selectedRule) =>
      selectedRule.map((rule) => {
        if (rule.type === existingRule) {
          return {
            ...rule,
            type: updatedRule,
            value: ['due-before', 'due-after'].includes(updatedRule) ? dateAsIsoString() : null,
          };
        }

        return rule;
      })
    );

    setErrors((errors) => errors.filter((error) => error.type !== existingRule));
  };

  const handleRuleChange = ({ ruleType, value }: { ruleType: EditInvoiceParamsRuleType; value: string }) => {
    setSelectedRules((selectedRule) =>
      selectedRule.map((rule) => {
        if (rule.type === ruleType) {
          return {
            ...rule,
            value,
          };
        }

        return rule;
      })
    );

    setErrors((errors) => errors.filter((error) => error.type !== ruleType));
  };

  const handleExcludeInvoices = async () => {
    setLoading(true);

    // any with empty values
    const hasEmptyErrors = selectedRules
      .filter((rule) => !rule.value)
      .map(({ type }) => ({ error: 'empty', type })) satisfies RuleErrorList[];

    // any with invalid values
    const hasInvalidErrors = selectedRules
      .filter(
        (rule) =>
          ['amount-greater', 'amount-less', 'dpe-greater', 'dpe-less'].includes(rule.type) && isNaN(Number(rule.value))
      )
      .map(({ type }) => ({ error: 'invalid', type })) satisfies RuleErrorList[];

    if (hasEmptyErrors.length > 0 || hasInvalidErrors.length > 0) {
      setLoading(false);
      return setErrors([...hasEmptyErrors, ...hasInvalidErrors]);
    }

    const filters = mapRules(selectedRules);
    await excludeInvoices({ filters, takerMarkets, ...(toCurrency && { toCurrency }) });

    onClose();
    track('exclude-invoices::submitted', { rules: selectedRules, type });
    setLoading(false);
  };

  const toggleDatePicker = (ruleType: EditInvoiceParamsRuleType) => {
    if (ruleType === 'due-before') {
      return setDueBeforeDatePickerOpen(!dueBeforeDatePickerOpen);
    }

    return setDueAfterDatePickerOpen(!dueAfterDatePickerOpen);
  };

  const handleDateSelect = (ruleType: EditInvoiceParamsRuleType, date?: Date) => {
    const dateString = dateAsIsoString(date);
    handleRuleChange({ ruleType, value: dateString });
    toggleDatePicker(ruleType);
  };

  return (
    <Modal
      cancelText={t('core.cancel')}
      className="overflow-y-auto overflow-x-hidden"
      customWidth="sm:w-fit max-w-4xl"
      loading={loading}
      okButtonVariant="primary"
      okText={t('invoiceSettings.modal.cta')}
      onCancel={onClose}
      onClose={onClose}
      onOk={handleExcludeInvoices}
      open={open}
      title={<span className="capitalize">{t('invoiceSettings.modal.title')}</span>}
      variant="info"
    >
      <div>
        <div className="mb-6">{t('invoiceSettings.modal.description')}</div>
        <div className="mb-2 font-medium">{t('invoiceSettings.modal.selectCriteria')}</div>
        <div className="space-y-6">
          <div className="space-y-2">
            {selectedRules.map((rule, idx) => {
              // filters any already selected rules from the available select options
              const selectedRulesMap = selectedRules.filter(({ type }) => type !== rule.type).map(({ type }) => type);
              const ruleOptionsMap = ruleOptions.filter(({ type }) => !selectedRulesMap.includes(type));

              return (
                <div
                  key={rule.type}
                  className={cn('rounded-md border border-transparent bg-gray-100 p-4', {
                    'border-red-400': errors.map(({ type }) => type).includes(rule.type),
                  })}
                >
                  <div className="relative flex items-start justify-between lg:items-center">
                    {/* rule */}
                    <div className="flex grow flex-col gap-2 lg:grow-0 lg:flex-row lg:items-center">
                      <div className="shrink-0 font-medium">
                        {idx === 0
                          ? t('invoiceSettings.modal.rulePredicate')
                          : t('invoiceSettings.modal.ruleConjunction')}
                      </div>
                      <div className="flex flex-col gap-2 md:flex-row md:items-center">
                        {/* select */}
                        <div>
                          <select
                            aria-label={t('invoiceSettings.modal.criteriaSelect')}
                            className="h-9 w-full overflow-hidden text-ellipsis rounded-md border px-2 focus-visible:outline-none md:w-96"
                            onChange={(e) =>
                              handleRuleSelect({
                                existingRule: rule.type,
                                updatedRule: e.target.value as EditInvoiceParamsRuleType,
                              })
                            }
                            value={rule.type}
                          >
                            {ruleOptionsMap.map((option) => (
                              <option key={option.type} value={option.type}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* input */}
                        <div className="flex items-center gap-2">
                          {['due-before', 'due-after'].includes(rule.type) ? (
                            <DatePicker
                              className="z-10"
                              open={rule.type === 'due-before' ? dueBeforeDatePickerOpen : dueAfterDatePickerOpen}
                              onOpenChange={() => toggleDatePicker(rule.type)}
                              onSelect={(date) => handleDateSelect(rule.type, date)}
                              selected={rule.value as string | null}
                              trigger={
                                <div
                                  aria-label={rule.type}
                                  className="flex h-9 w-32 items-center rounded-md border bg-white px-2"
                                  role="button"
                                >
                                  {rule.value}
                                </div>
                              }
                            />
                          ) : (
                            <>
                              <div>
                                <input
                                  aria-label={rule.type}
                                  type="text"
                                  className={cn('h-9 w-32 rounded-md border px-2 focus-visible:outline-none', {
                                    'w-56': rule.type === 'invoice-id-contains',
                                  })}
                                  onChange={(e) =>
                                    handleRuleChange({
                                      ruleType: rule.type,
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              {/* amount input addon */}
                              {['amount-greater', 'amount-less'].includes(rule.type) && (
                                <div>{toCurrency ?? takerMarkets[0].currency}</div>
                              )}
                              {/* dpe input addon */}
                              {['dpe-greater', 'dpe-less'].includes(rule.type) && <div>{t('core.days')}</div>}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* remove button */}
                    <div className="absolute -right-2 -top-2 ml-10 text-right lg:static ">
                      {selectedRules.length > 1 && (
                        <button
                          aria-label={t('invoiceSettings.modal.removeCriteria')}
                          className="rounded-full p-1 text-xl transition-all duration-200 hover:bg-black/10"
                          type="button"
                          onClick={() => removeSelectedRule(rule.type)}
                        >
                          <TimesIcon fill={colors.gray[800]} />
                        </button>
                      )}
                    </div>
                  </div>
                  {/* error */}
                  {errors.map(({ type }) => type).includes(rule.type) &&
                    errors
                      .filter(({ type }) => type === rule.type)
                      .map(({ error, type }) => (
                        <div key={`${type}${error}`} className="mt-2 text-sm text-red-600">
                          {ruleErrorMap[type][error]}
                        </div>
                      ))}
                </div>
              );
            })}
            <div className="pt-2">
              <Button
                disabled={selectedRules.length === ruleOptions.length}
                onClick={addSelectedRule}
                variant="tertiary"
              >
                {t('invoiceSettings.modal.add')}
              </Button>
            </div>
          </div>
          <div className="space-y-2">
            <div className="italic">{t('invoiceSettings.modal.disclaimer')}</div>
            {hasActivePreferredOffer && accountPreferredConfig?.canEditInvoices !== true && (
              <div className="italic">{t('invoiceSettings.modal.disclaimerPreferred')}</div>
            )}
            {toCurrency && (
              <div className="italic">
                {t('invoiceSettings.modal.preferredCurrencyMessage', { currency: toCurrency })}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExcludeInvoicesModal;

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, cn, Modal as LiquidityModal } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { TimesIcon } from '@c2fo/liquidity/icons';

// NOTE: need to lift this into the liquidity package

export interface ModalProps {
  children: ReactNode;
  open: boolean;
  title: ReactNode;
  cancelText?: string;
  className?: string;
  fullWidthButtons?: boolean;
  loading?: boolean;
  okButtonVariant?: 'cta' | 'primary';
  okButtonDisabled?: boolean;
  okLink?: string;
  okText?: string;
  onCancel?: () => void;
  onClose?: () => void;
  onOk?: () => void;
  showCloseIcon?: boolean;
  subTitle?: string;
  variant?: 'info' | 'primary';
  customWidth?: string;
}

const Modal = ({
  cancelText,
  children,
  className,
  fullWidthButtons,
  loading,
  okButtonVariant = 'cta',
  okButtonDisabled,
  okLink,
  okText,
  onCancel,
  onClose,
  onOk,
  open,
  showCloseIcon = true,
  subTitle,
  title,
  variant = 'primary',
  customWidth,
}: ModalProps) => {
  const { t } = useTranslation();

  const isInfoVariant = variant === 'info';

  return (
    <LiquidityModal className={cn('relative max-h-full w-fit overflow-auto', className)} onClose={onClose} open={open}>
      <div
        className={cn('box-content flex max-h-full flex-col gap-6 overflow-auto p-6 sm:w-[496px]', customWidth, {
          'pt-12': showCloseIcon && !isInfoVariant,
        })}
      >
        <div>
          <h3 className={cn('m-0 text-center text-2xl font-medium', { 'text-left text-xl': isInfoVariant })}>
            {title}
          </h3>
          {subTitle && <div>{subTitle}</div>}
        </div>

        {children}

        <div className={cn('-mx-6 border-t', { 'shadow-[0_-3px_3px_0px_rgb(0,0,0,0.035)]': !isInfoVariant })}>
          <div className="flex flex-row-reverse justify-start gap-2 px-6 pt-6">
            {okLink ? (
              <a className={cn({ 'w-1/2': fullWidthButtons })} href={okLink} onClick={onOk} target="_self">
                <Button variant="primary">{okText ?? t('core.ok')}</Button>
              </a>
            ) : onOk ? (
              <Button
                className={cn({ 'w-1/2': fullWidthButtons, 'w-full': fullWidthButtons && !onCancel })}
                loading={loading}
                onClick={onOk}
                variant={okButtonVariant}
                disabled={okButtonDisabled}
              >
                {okText ?? t('core.ok')}
              </Button>
            ) : null}
            {onCancel && (
              <Button className={cn({ 'w-1/2': fullWidthButtons })} onClick={onCancel} variant="secondary">
                {cancelText ?? t('core.cancel')}
              </Button>
            )}
          </div>
        </div>
      </div>

      {showCloseIcon && onClose && (
        <div className="absolute right-3 top-3 bg-white text-text-primary">
          <button
            className="m-1 rounded-full p-1 text-xl transition-all duration-200 hover:bg-gray-100"
            onClick={onClose}
            type="button"
          >
            <TimesIcon className="h-6 w-6" fill={colors.gray[800]} />
          </button>
        </div>
      )}
    </LiquidityModal>
  );
};

export default Modal;

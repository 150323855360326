import { Suspense } from 'react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import ErrorBoundary from './ErrorBoundary';

const GenericLoadingComponent = () => <></>;

const GenericErrorComponent = () => <></>;

const QueryBoundaries = ({
  children,
  ErrorComponent,
  LoadingComponent,
}: {
  children: React.ReactNode;
  ErrorComponent?: () => JSX.Element;
  LoadingComponent?: () => JSX.Element;
}) => (
  <QueryErrorResetBoundary>
    {() => (
      <ErrorBoundary fallback={ErrorComponent ? <ErrorComponent /> : <GenericErrorComponent />}>
        <Suspense fallback={LoadingComponent ? <LoadingComponent /> : <GenericLoadingComponent />}>{children}</Suspense>
      </ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
);

export default QueryBoundaries;

import { useTranslation } from 'react-i18next';
import { Button, Popover } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { InfoCircleIcon } from '@c2fo/liquidity/icons';
import emptystatements from '@/assets/emptystatementsgrey.svg?url';
import invoices from '@/assets/invoicesgrey.svg?url';
import { useInvoiceLayoutOutletContext } from '@/components/InvoiceLayout';
import NoDataState from '@/components/NoDataState';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  TableSortArrow,
  TableDisplay,
} from '@/components/Table';
import useInvoiceAdjustments, { InvoiceAdjustmentFetchOptions } from '@/data/useInvoiceAdjustments';
import useInvoiceAdjustmentStats from '@/data/useInvoiceAdjustmentStats';
import { TakerMarket } from '@/data/useTakerMarkets';
import { InvoiceAdjusmentSortKey, InvoiceAdjusmentTableSort } from '@/pages/InvoiceAdjustments';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useInvoiceDownloadUrl from '@/utils/useInvoiceDownloadUrl';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useUserHasIndiaDivisions from '@/utils/useUserHasIndiaDivisions';

const InvoiceAdjustmentTable = ({
  handleSort,
  options,
  pagination,
  takerMarket,
  sort,
}: {
  handleSort: (key: InvoiceAdjusmentSortKey) => void;
  options: Partial<InvoiceAdjustmentFetchOptions>;
  pagination: {
    currentPage: number;
    onNextChange: () => void;
    onPageSizeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onPrevChange: () => void;
    pageSize: number;
  };
  takerMarket: TakerMarket;
  sort: InvoiceAdjusmentTableSort;
}) => {
  const { t } = useTranslation();
  const { asCurrency } = useLocaleFormat();
  const hasIndiaDivisions = useUserHasIndiaDivisions();
  const {
    urlParams: { marketId, takerId },
  } = useInvoiceLayoutOutletContext();
  const { data: invoiceAdjustments, isFetching } = useInvoiceAdjustments({
    marketId,
    takerId,
    options,
  });
  const { data: invoiceAdjustmentStats } = useInvoiceAdjustmentStats({
    marketId,
    takerId,
    options,
  });
  const invoiceDownloadUrl = useInvoiceDownloadUrl({
    filters: options?.filter,
    marketId,
    takerId,
    type: 'adjustments',
  });

  return (
    <>
      <div className="flex border-b px-8 py-6">
        <div className="flex items-center gap-2 text-center">
          <div className="font-medium">{t('core.adjustments')}:</div>
          <div>{asCurrency(invoiceAdjustmentStats?.subTotal ?? 0, takerMarket.currency)}</div>
        </div>
      </div>
      <div className="flex items-center border-b px-8 py-4">
        <div className="mr-6">
          <Popover>
            <Popover.Trigger>
              <span className="flex items-center gap-2">
                <span className="font-medium">{t('core.bulkInvoiceActions')}</span>
                <InfoCircleIcon fill={colors.gray[600]} />
              </span>
            </Popover.Trigger>
            <Popover.Content arrow side="right">
              <div className="w-64 space-y-2 text-sm">
                <div>{t('core.bulkInvoiceActionsDescription')}</div>
                <div>{t('core.bulkInvoiceAppliedFilters')}</div>
              </div>
            </Popover.Content>
          </Popover>
        </div>
        <div className="flex">
          <a href={invoiceDownloadUrl} target="_self">
            <Button
              className="h-10"
              disabled={(invoiceAdjustmentStats?.count ?? 0) === 0 || (invoiceAdjustments?.length ?? 0) === 0}
              size="sm"
              type="button"
              variant="secondary"
            >
              {t('core.download')}
            </Button>
          </a>
        </div>
      </div>
      <div className="w-full overflow-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead onClick={() => handleSort('status')}>
                <TableSortArrow accessorKey="status" sort={sort}>
                  {t('core.status')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('adjInvoiceId')}>
                <TableSortArrow accessorKey="adjInvoiceId" sort={sort}>
                  {t('maker.adjustmentId')}
                </TableSortArrow>
              </TableHead>
              <TableHead className="max-w-[224px]" onClick={() => handleSort('voucherId')}>
                <TableSortArrow accessorKey="voucherId" sort={sort}>
                  {t('core.invoiceId')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('dueDate')}>
                <TableSortArrow accessorKey="dueDate" sort={sort} textRight>
                  {hasIndiaDivisions ? t('core.tentativePaymentDate') : t('core.originalDueDate')}
                </TableSortArrow>
              </TableHead>
              <TableHead onClick={() => handleSort('amount')}>
                <TableSortArrow accessorKey="amount" sort={sort} textRight>
                  {t('core.amount')}
                </TableSortArrow>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody loading={isFetching}>
            {!invoiceAdjustments || !invoiceAdjustments.length ? (
              <TableDisplay colSpan={5}>
                <NoDataState
                  className="py-8"
                  {...(options.filter
                    ? {
                        icon: <img src={emptystatements} alt="emptystatements" className="h-28 w-28" />,
                        title: t('invoices.noMatchingInvoices'),
                        message: t('invoices.noMatchingEligibleInvoicesDescription'),
                      }
                    : {
                        icon: <img src={invoices} alt="invoices" className="h-28 w-28" />,
                        title: t('invoices.noAvailableInvoices'),
                        message: t('invoices.noAvailableInvoiceAdjustmentsDescription'),
                      })}
                />
              </TableDisplay>
            ) : (
              invoiceAdjustments.map((invoiceAdjustment) => (
                <TableRow key={invoiceAdjustment.id}>
                  <TableCell>
                    <span className="capitalize">
                      {invoiceAdjustment.status === 'matched'
                        ? t('taker.invoices.adjustment.statusMatched')
                        : t('taker.invoices.adjustment.statusUnmatched')}
                    </span>
                  </TableCell>
                  <TableCell className="max-w-[224px] whitespace-pre-wrap break-words">
                    {invoiceAdjustment.adjInvoiceId}
                  </TableCell>
                  <TableCell className="max-w-[224px] whitespace-pre-wrap break-words">
                    {invoiceAdjustment.voucherId}
                  </TableCell>
                  <TableCell className="text-right">
                    {invoiceAdjustment.dueDate && trimDateTimestamp(invoiceAdjustment.dueDate)}
                  </TableCell>
                  <TableCell className="text-right">
                    {asCurrency(invoiceAdjustment.amount, invoiceAdjustment.currency)}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        className="mt-auto"
        disableNext={
          pagination.currentPage ===
          Math.ceil((invoiceAdjustmentStats?.count ?? pagination.pageSize) / pagination.pageSize)
        }
        disablePrev={pagination.currentPage === 1}
        onNextChange={() => pagination.onNextChange()}
        onPageSizeChange={(e) => pagination.onPageSizeChange(e)}
        onPrevChange={() => pagination.onPrevChange()}
        pageSize={pagination.pageSize}
      />
    </>
  );
};

export default InvoiceAdjustmentTable;

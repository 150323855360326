import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { GlobalOfferInputDto } from '@/generated/gql/graphql';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';
import { gqlClient } from '@/lib/gqlClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';

interface GlobalPriceDiscoveryArgs {
  isDiscountBidding?: boolean;
  isEnabled?: boolean;
  maxApr?: number;
  maxDiscount?: number;
}

export const SET_PRICE_DISCOVERY_GLOBAL_OFFER = graphql(`
  mutation SetPriceDiscoveryGlobalOffer($input: GlobalOfferInputDto!) {
    setPriceDiscoveryGlobalOffer(input: $input) {
      failures {
        supplierDivisionUuid
        marketUuid
        error
      }
      successes {
        marketUuid
        supplierDivisionUuid
      }
    }
  }
`);

/**
 * used for both global offer and global participation submits
 * global offer submit sets only rate and type
 * global participation sets only enabled
 */
const useGlobalPriceDiscoveryOffer = () => {
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();
  const { subscribeToMarketStats } = useServerSideEventListeners();

  return useMutation({
    mutationKey: ['setPriceDiscoveryGlobalOffer'],
    mutationFn: ({ isDiscountBidding, isEnabled, maxApr, maxDiscount }: GlobalPriceDiscoveryArgs) => {
      const input: GlobalOfferInputDto =
        typeof isEnabled !== 'undefined'
          ? {
              enabled: isEnabled,
            }
          : {
              rate: isDiscountBidding ? maxDiscount : maxApr,
              type: isDiscountBidding ? 'DISC' : 'APR',
            };

      return gqlClient.request(SET_PRICE_DISCOVERY_GLOBAL_OFFER, { input });
    },
    onSuccess: async (data, { isDiscountBidding, isEnabled, maxDiscount, maxApr }) => {
      const updatedTakerMarkets = data?.setPriceDiscoveryGlobalOffer.successes ?? [];

      // update the cache with the returned data
      queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) =>
        (prevTakerMarkets ?? []).map((tm) => {
          const updatedData = updatedTakerMarkets.find(
            (tmData) => tmData.marketUuid === tm.marketUuid && tmData.supplierDivisionUuid === tm.takerDivisionUuid
          );

          if (updatedData) {
            const offerConfigUpdate =
              typeof isEnabled !== 'undefined'
                ? {
                    isEnabled,
                  }
                : {
                    isDiscountBidding,
                    ...(!isDiscountBidding && { maxApr }),
                    ...(isDiscountBidding && { maxDiscount }),
                  };

            return {
              ...tm,
              offerConfig: {
                ...tm.offerConfig,
                ...offerConfigUpdate,
              },
            };
          }

          return tm;
        })
      );

      // subscribe to market stats for each updated taker market
      // this will refetch stats queries after we know stats have updated
      updatedTakerMarkets.forEach((tm) => {
        // map tm uuid to get taker division id for subscribing to events
        const tmMeta = takerMarkets?.find(
          (meta) => meta.marketUuid === tm.marketUuid && meta.takerDivisionUuid === tm.supplierDivisionUuid
        );

        if (tmMeta?.marketUuid && tmMeta?.takerDivisionId) {
          subscribeToMarketStats({
            marketUuid: tmMeta.marketUuid,
            takerId: tmMeta.takerDivisionId,
          });
        }
      });
    },
  });
};

export default useGlobalPriceDiscoveryOffer;

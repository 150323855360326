import useTakerMarkets from './useTakerMarkets';

/**
 * returns array of unique currencies based on the user's taker markets
 * defaults to ['USD']
 */

const useCurrencies = () => {
  const { data } = useTakerMarkets((takerMarkets) => [
    ...new Set(takerMarkets?.map(({ currency }) => currency).sort((a, b) => a.localeCompare(b))),
  ]);

  return data && data.length > 0 ? data : ['USD'];
};

export default useCurrencies;

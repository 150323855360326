import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, cn, useSnackbar } from '@c2fo/liquidity';
import Modal, { ModalProps } from '@/components/Modal';
import { OfferConfigDetails, OfferConfigDetailsItem } from '@/components/OfferConfigDetails';
import { OfferReviewDetails } from '@/components/OfferReviewDetails';
import { useConfirmAgreementsWhenRequired } from '@/data/useAgreements';
import useSubmitStaticOffer from '@/data/useSubmitStaticOffer';
import { TakerMarket } from '@/data/useTakerMarkets';
import { useReporting } from '@/reporting';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useReadOnlyUser from '@/utils/useReadOnlyUser';
import { Agreement } from '../agreements/Agreement';
import useFixedRateDetails from './useFixedRateDetails';

interface SetFixedOfferFormInputs {
  rate: string | null;
}

const FixedRateSetOfferComponent = ({
  onClose,
  open,
  takerMarket,
}: {
  onClose: () => void;
  open: boolean;
  takerMarket: TakerMarket;
}) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const readOnlyUser = useReadOnlyUser();
  const { asCurrency, asPercent } = useLocaleFormat();
  const [step, setStep] = useState<1 | 2>(1);
  const { getFixedRateDetails } = useFixedRateDetails();
  const { formattedMarketRate, marketRate } = getFixedRateDetails(takerMarket);
  const { mutateAsync: submitStaticOffer, isLoading: submitStaticOfferIsLoading } = useSubmitStaticOffer();
  const { validateAndConfirmAgreementsConditionally } = useConfirmAgreementsWhenRequired([takerMarket]);
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  const [isAgreementsErrorNotChecked, setIsAgreementsErrorNotChecked] = useState(false);

  const defaultValues: SetFixedOfferFormInputs = {
    rate: takerMarket.offerConfig.maxApr
      ? String(takerMarket.offerConfig.maxApr)
      : marketRate
      ? String(marketRate)
      : '',
  };

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setError,
    reset,
  } = useForm<SetFixedOfferFormInputs>({
    defaultValues: useMemo(() => {
      if (takerMarket) {
        return defaultValues;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [takerMarket]),
  });

  useEffect(() => {
    if (takerMarket) {
      reset(defaultValues);
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takerMarket]);

  const onSubmit: SubmitHandler<SetFixedOfferFormInputs> = async (data) => {
    if (readOnlyUser || !takerMarket) {
      return;
    }

    if (!marketRate) {
      console.error(
        `User attempted to submit an offer for a fixed rate market (${takerMarket.marketUuid}) that does not have a defined market rate.`
      );
      return;
    }

    const { rate } = data;

    if (step === 1) {
      // step 1 - validate rate/rate type are set & send to step 2
      const validRate = !!rate && !isNaN(parseFloat(rate)) && parseFloat(rate) >= marketRate;

      const advanceStep = () => {
        setStep(2);
        track('offer-next::fixed-rate::clicked');
      };

      return !validRate
        ? setError('rate', {
            message: t('offerSubmitDialog.fixedRate.rateInputError'),
          })
        : advanceStep();
    } else {
      try {
        await validateAndConfirmAgreementsConditionally({ isAgreementsChecked });
      } catch {
        setIsAgreementsErrorNotChecked(true);
        return;
      }

      // step 2 - submit the form
      // make sure rate is positive and rounded to 2 decimal places
      const normalizedRate = Math.abs(Math.round(parseFloat(rate!) * 100) / 100);

      const submitValues = {
        rate: normalizedRate,
        marketUuid: takerMarket.marketUuid,
        supplierDivisionUuid: takerMarket.takerDivisionUuid,
      };

      await submitStaticOffer(submitValues, {
        onSuccess: () => showSnackbar({ message: t('offerSubmitDialog.offerSet') }),
        onError: () => showSnackbar({ message: t('offerSubmitDialog.offerSetError') }),
      });

      onClose();
      track('offer::fixed-rate::submitted', submitValues);
    }
  };

  const offerConfigItems = useMemo(() => {
    const items: OfferConfigDetailsItem[] = [
      {
        label: t('taker.dictionary.availableAR.label'),
        value: asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency),
      },
      {
        label: t('core.fixedRate'),
        value: formattedMarketRate,
      },
    ];

    if (step === 2) {
      items.push({
        label: t('taker.offer'),
        value: `${asPercent({
          value: parseFloat(getValues('rate')!) / 100,
          decimals: 2,
        })} ${t('core.apr').toUpperCase()}`,
      });
    }

    return items;
  }, [
    asCurrency,
    asPercent,
    formattedMarketRate,
    getValues,
    step,
    t,
    takerMarket.currency,
    takerMarket.eligibleInvoiceAmount,
  ]);

  const modalConfig: Partial<ModalProps> =
    step === 1
      ? {
          cancelText: t('core.cancel'),
          okButtonVariant: 'primary',
          okText: t('core.next'),
          onCancel: onClose,
          okButtonDisabled: !!errors.rate,
          onOk: handleSubmit(onSubmit),
        }
      : {
          cancelText: t('core.back'),
          okButtonVariant: 'cta',
          okText: t('core.submit'),
          onCancel: () => setStep(1),
          okButtonDisabled: false,
          onOk: handleSubmit(onSubmit),
        };

  return (
    <Modal
      className="w-fit"
      fullWidthButtons
      loading={submitStaticOfferIsLoading}
      onClose={onClose}
      open={open}
      subTitle={getTakerMarketName(takerMarket)}
      title={t('core.setFixedRateOfferLabel')}
      variant="info"
      {...modalConfig}
    >
      {takerMarket.eligibleInvoiceAmount <= 0 && (
        <Alert
          variant="outlined"
          type="warning"
          full
          title={t('offerSubmitDialog.zeroApWarningTitle')}
          description={t('offerSubmitDialog.zeroApWarningDescription')}
        />
      )}
      <OfferConfigDetails items={offerConfigItems} />
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        {step === 1 ? (
          <>
            <p className="block text-base">{t('fixedRate.details.rateEntry', { rate: formattedMarketRate })}</p>
            <div className="space-y-2">
              <label htmlFor="rate" className="block text-sm">
                {t('core.offerRate')}
              </label>
              <div className="flex items-center gap-2">
                <div className="max-w-[212px] space-y-2">
                  <div className="relative">
                    <input
                      id="rate"
                      className={cn(
                        'block w-full rounded-md border-0 py-2 pl-3 pr-10 outline-0 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600',
                        { 'bg-red-50 ring-red-500 focus:ring-red-500': errors.rate }
                      )}
                      placeholder={t('offer.enterRate')}
                      type="text"
                      {...register('rate')}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <div aria-hidden="true">%</div>
                    </div>
                  </div>
                </div>
                <p className="text-base">{t('core.apr')}</p>
              </div>
              {!!errors.rate && <p className="text-sm text-red-500">{t('fixedRate.details.rateEntry.error')}</p>}
            </div>
          </>
        ) : (
          <div className="space-y-4">
            <OfferReviewDetails takerMarket={takerMarket} />

            <Agreement
              takersMarkets={[takerMarket]}
              onChange={(v) => {
                setIsAgreementsChecked(v);
                setIsAgreementsErrorNotChecked(false);
              }}
            />
            {isAgreementsErrorNotChecked && (
              <Alert type="error" variant="filled" description={t('agreements.error.checkbox')} full />
            )}
          </div>
        )}
      </form>
    </Modal>
  );
};

const FixedRateSetOffer = ({
  onClose,
  open,
  takerMarket,
}: {
  onClose: () => void;
  open: boolean;
  takerMarket?: TakerMarket | null;
}) => {
  return takerMarket ? <FixedRateSetOfferComponent onClose={onClose} open={open} takerMarket={takerMarket} /> : null;
};

export default FixedRateSetOffer;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  IconButton,
} from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { ArrowLeftFromLineIcon, EllipsisVerticalIcon } from '@c2fo/liquidity/icons';
import ExcludeInvoicesModal from '@/components/ExcludeInvoicesModal';
import { TakerMarket } from '@/data/useTakerMarkets';
import RecurringRulesFormModal from '@/features/recurringRules/RecurringRulesFormModal';
import { RecurringRulesMenuItem } from '@/features/takerMarketTabs/components/TabActionsDropdown';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import useCanEditInvoices from '@/utils/useCanEditInvoices';
import useReadOnlyUser from '@/utils/useReadOnlyUser';

const EligibleInvoiceActionsDropdown = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const readOnlyUser = useReadOnlyUser();
  const canEditInvoices = useCanEditInvoices(takerMarket);
  const [showRecurringRulesModal, setShowRecurringRulesModal] = useState<boolean>(false);
  const [enableRecurringRules] = useFeature('enterprise-ui_enableRecurringRules');
  const [showExcludeInvoicesModal, setShowExcludeInvoicesModal] = useState<boolean>(false);

  const openExcludeInvoicesModal = () => {
    setShowExcludeInvoicesModal(true);
    track('exclude-invoices::clicked', {
      takerMarket: { takerId: takerMarket.takerDivisionId, marketUuid: takerMarket.marketUuid },
      type: 'division',
    });
  };

  const openEligibleInvoiceActionsDropdown = () => {
    track('eligible-invoice-actions::clicked', {
      takerMarket: { takerId: takerMarket.takerDivisionId, marketUuid: takerMarket.marketUuid },
      type: 'division',
    });
  };

  const openRecurringRulesModal = () => {
    setShowRecurringRulesModal(true);
    track('recurring-rules::clicked', { type: 'division' });
  };

  return (
    <IntercomDataTarget target="eligibleInvoiceActionsDropdown">
      <RecurringRulesFormModal
        onClose={() => setShowRecurringRulesModal(false)}
        open={showRecurringRulesModal}
        showDivisionSelect={false}
        takerMarkets={[takerMarket]}
      />
      <ExcludeInvoicesModal
        open={showExcludeInvoicesModal}
        onClose={() => setShowExcludeInvoicesModal(false)}
        takerMarkets={[takerMarket]}
        type="division"
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild onClick={openEligibleInvoiceActionsDropdown}>
          <IconButton icon={EllipsisVerticalIcon} name={t('taker.invoices.eligible.actionDropdown')} variant="cta" />
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          avoidCollisions={false}
          className="min-w-64"
          collisionPadding={24}
          data-intercom-target="eligibleInvoiceActionsDropdownMenu"
        >
          <DropdownMenuLabel>{t('core.invoiceActions')}</DropdownMenuLabel>
          {enableRecurringRules && <RecurringRulesMenuItem onClick={openRecurringRulesModal} />}
          <DropdownMenuItem
            disabled={readOnlyUser || !canEditInvoices}
            {...(!readOnlyUser && canEditInvoices && { onClick: openExcludeInvoicesModal })}
          >
            <ArrowLeftFromLineIcon className="h-6 w-6" fill={colors.text.secondary} />
            <span className="ml-3">{t('core.exclude')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </IntercomDataTarget>
  );
};

export default EligibleInvoiceActionsDropdown;

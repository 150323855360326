import { useTranslation } from 'react-i18next';

const MARKETING_SITE_LOCALES = [
  {
    languageCode: 'en',
    marketingSiteDomain: 'c2fo.com',
    terms: '/terms',
    privacy: '/privacy-policy',
  },
  {
    languageCode: 'es',
    marketingSiteDomain: 'mx.c2fo.com',
    terms: '/terms',
    privacy: '/privacy-policy',
  },
  {
    languageCode: 'fr',
    marketingSiteDomain: 'fr.c2fo.com',
    terms: '/terms',
    privacy: '/privacy-policy',
  },
  {
    languageCode: 'it',
    marketingSiteDomain: 'it.c2fo.com',
    terms: '/terms',
    privacy: '/privacy-policy',
  },
  {
    languageCode: 'de',
    marketingSiteDomain: 'de.c2fo.com',
    terms: '/terms',
    privacy: '/privacy-policy',
  },
  {
    languageCode: 'nl',
    // No marketing site for Dutch
    marketingSiteDomain: 'c2fo.com',
    terms: '/terms-conditions-dutch',
    privacy: '/privacy-policy-dutch',
  },
  {
    languageCode: 'pt-BR',
    // No marketing site for Portuguese
    marketingSiteDomain: 'c2fo.com',
    terms: '/terms-conditions-portuguese',
    privacy: '/privacy-policy-portuguese',
  },
  {
    languageCode: 'ru',
    // No marketing site for Russian
    marketingSiteDomain: 'c2fo.com',
    terms: '/terms-conditions-russian',
    privacy: '/privacy-policy-russian',
  },
  {
    languageCode: 'tr',
    // No marketing site for Turkish
    marketingSiteDomain: 'c2fo.com',
    terms: '/terms-conditions-turkish',
    privacy: '/privacy-policy-turkish',
  },
  {
    languageCode: 'ja',
    // No marketing site for Japanese
    marketingSiteDomain: 'c2fo.com',
    terms: '/terms-conditions-japanese',
    privacy: '/privacy-policy-japanese',
  },
  {
    languageCode: 'zh-CN',
    marketingSiteDomain: 'chn.c2fo.com',
    terms: '/terms',
    privacy: '/privacy-policy',
  },
  {
    languageCode: 'zh-TW',
    marketingSiteDomain: 'tw.c2fo.com',
    terms: '/terms-conditions',
    privacy: '/privacy-policy',
  },
];

export const useAgreementsUrl = () => {
  const { i18n } = useTranslation();

  const { marketingSiteDomain, terms, privacy } =
    MARKETING_SITE_LOCALES.find((l) => l.languageCode === i18n.language) ?? MARKETING_SITE_LOCALES[0];

  const privacyLink = `https://${marketingSiteDomain.concat(privacy)}`;
  const termsOfUseLink = `https://${marketingSiteDomain.concat(terms)}`;

  return {
    privacyLink,
    termsOfUseLink,
  };
};

type Primitive = undefined | null | boolean | string | number;

interface LooseObject {
  [key: string]: Primitive | Primitive[] | LooseObject | LooseObject[];
}

type MapValuesToKeysIfAllowed<T> = {
  [K in keyof T]: T[K] extends PropertyKey ? K : never;
};

type Filter<T> = MapValuesToKeysIfAllowed<T>[keyof T];

type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`;

export type DotNestedKeys<T> = (
  T extends object
    ? { [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}` }[Exclude<keyof T, symbol>]
    : ''
) extends infer D
  ? Extract<D, string>
  : never;

/**
 * Recursively merge objects and arrays (suspending the rules of immutability).
 * @param target The object to merge into.
 * @param sources The objects to merge from.
 * @returns The merged object.
 */
export function deepAssign(target: LooseObject, ...sources: LooseObject[]): LooseObject {
  if (!sources.length) {
    return target;
  }

  const source = sources.shift();

  if (typeof target === 'object' && typeof source === 'object') {
    for (const key in source) {
      if (typeof source[key] === 'object') {
        if (!target[key]) {
          Object.assign(target, {
            [key]: Array.isArray(source[key]) ? [] : {},
          });
        }

        deepAssign(target[key] as LooseObject, source[key] as LooseObject);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepAssign(target, ...sources);
}

/**
 * Remove duplicate objects from an array by a given key.
 * @param arr The array to remove duplicates from.
 * @param key The key to use to determine uniqueness.
 * @returns The array with duplicates removed.
 */
export function toUniqueListBy<T>(arr: T[], key: keyof T) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

/**
 * Groups an array of objects by a given key.
 * @param arr The array to group from.
 * @param key The key to use to group by.
 * @returns A new object keyed with the given group by key.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupBy<T extends Record<PropertyKey, any>, Key extends Filter<T>>(
  arr: T[],
  key: Key
): Record<T[Key], T[]> {
  return arr.reduce(
    (accumulator, val) => {
      const groupedKey = val[key];
      if (!accumulator[groupedKey]) {
        accumulator[groupedKey] = [];
      }

      accumulator[groupedKey].push(val);
      return accumulator;
    },
    {} as Record<T[Key], T[]>
  );
}

/**
 * Plucks from an array by a given key.
 * @param arr The array to pluck from.
 * @param key The key to pluck.
 * @returns A new array of the plucked items.
 */
export function pluck<T, Key extends keyof T>(arr: T[], key: Key) {
  return arr.map((o) => o[key]);
}

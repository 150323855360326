import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import { AngleRightIcon } from '@c2fo/liquidity/icons';
import QueryBoundaries from '@/components/QueryBoundaries';
import { useReporting } from '@/reporting';
import AwardArchiveDownload from './AwardArchiveDownload';
import { getRangeKey, getRangeLabel } from './utils';

export interface RangeHeaderProps {
  startDate: string;
  endDate: string;
  currency?: string;
  onCurrencyChange?: (currency: string) => void;
  currencies?: string[];
  isDownloadDisabled: boolean;
  /**
   * The link to download the award file.
   * If this is provided, it will show a "download CSV" link in the left sidebar.
   */
  awardFileDownloadLink?: string;
  /**
   * The customers to display. If provided, the customer filter will be displayed.
   * If not provided, the customer filter will not be displayed.
   */
  customers?: Array<{ value: string; label: string }>;
  /**
   * The selected customers.
   */
  selectedCustomers?: string[];
}

export default function RangeHeader({
  startDate,
  endDate,
  awardFileDownloadLink,
  customers = [],
  selectedCustomers,
  isDownloadDisabled,
}: RangeHeaderProps) {
  const { t } = useTranslation();
  const { track } = useReporting();
  const rangeKey = useMemo(() => getRangeKey(startDate, endDate), [startDate, endDate]);
  return (
    <div className="border-b px-8 py-4">
      <div className="flex items-center">
        <div className="flex flex-1 items-center gap-2">
          <h4 className="m-0 font-normal">
            {t(...getRangeLabel(rangeKey))}
            <AngleRightIcon className="inline-block h-6 w-6" />
            <span>
              {startDate} {t('core.to')} {endDate}
            </span>
          </h4>
        </div>
        <div>
          {/* market history download csv link */}
          {awardFileDownloadLink && (
            <Button
              disabled={isDownloadDisabled}
              variant="primary"
              size="sm"
              onClick={() => {
                window.location.href = awardFileDownloadLink;
                track('history-award-download::clicked');
              }}
            >
              {t('core.downloadCSV')}
            </Button>
          )}
          {/* consolidated history download csv link */}
          {!!customers?.length && (
            <QueryBoundaries>
              <AwardArchiveDownload
                startDate={startDate}
                endDate={endDate}
                makerIds={selectedCustomers?.length ? selectedCustomers : customers.map((c) => c.value)}
                disabled={isDownloadDisabled}
              />
            </QueryBoundaries>
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * temporary modal for recurring rule form dev
 */
import { TakerMarket } from '@/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import RecurringRulesForm, { RecurringRuleFormValues } from './RecurringRulesForm';

const RecurringRulesFormModal = ({
  defaultValues,
  onClose,
  open,
  showDivisionSelect = true,
  takerMarkets,
  type,
}: {
  onClose: () => void;
  open: boolean;
  takerMarkets: TakerMarket[];
  defaultValues?: Partial<RecurringRuleFormValues>;
  showDivisionSelect?: boolean;
  type?: TakerMarketGroupType;
}) => {
  return open ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/20">
      <div className="relative h-fit max-h-[80%] w-fit overflow-scroll rounded bg-white p-6">
        <div className="absolute right-2 top-2">
          <button onClick={() => onClose()} type="button">
            close
          </button>
        </div>
        <div className="overflow-scroll p-6">
          <RecurringRulesForm
            defaultValues={defaultValues}
            showDivisionSelect={showDivisionSelect}
            takerMarkets={takerMarkets}
            type={type}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default RecurringRulesFormModal;

import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  IconButton,
  useSnackbar,
} from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import {
  ArrowLeftFromLineIcon,
  EllipsisVerticalIcon,
  PercentIcon,
  ToggleLargeOffIcon,
  ToggleLargeOnIcon,
  CalendarLinesIcon,
  PenRulerIcon,
} from '@c2fo/liquidity/icons';
import { useSelectedCurrency } from '@/components/CurrencySelect';
import ExcludeInvoicesModal from '@/components/ExcludeInvoicesModal';
import useGlobalPriceDiscoveryOffer from '@/data/useGlobalPriceDiscoveryOffer';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';
import useTakerMarketsGroups, { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import NameYourRateSetGlobalOffer from '@/features/nameYourRate/SetGlobalOffer';
import NameYourRateSetGlobalParticipation from '@/features/nameYourRate/SetGlobalParticipation';
import RecurringRulesFormModal from '@/features/recurringRules/RecurringRulesFormModal';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import useReadOnlyUser from '@/utils/useReadOnlyUser';
import { SetBulkExpirationDate } from './SetBulkExpirationDate';

export const RecurringRulesMenuItem = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const readOnlyUser = useReadOnlyUser();

  const menuItemContent = (
    <>
      <PenRulerIcon className="h-6 w-6" fill={colors.text.secondary} />
      <span className="ml-3">{t('recurringRules.createRecurringRule')}</span>
    </>
  );

  return readOnlyUser ? (
    <DropdownMenuItem disabled>{menuItemContent}</DropdownMenuItem>
  ) : (
    <DropdownMenuItem onClick={onClick}>{menuItemContent}</DropdownMenuItem>
  );
};

const TabActionsDropdown = ({ tab }: { tab: TakerMarketGroupType }) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const readOnlyUser = useReadOnlyUser();
  const selectedCurrency = useSelectedCurrency();
  const takerMarketsGroups = useTakerMarketsGroups();
  const [showSetGlobalOfferModal, setShowSetGlobalOfferModal] = useState<boolean>(false);
  const [showExcludeInvoicesModal, setShowExcludeInvoicesModal] = useState<boolean>(false);
  const [showSetGlobalParticipationModal, setShowSetGlobalParticipationModal] = useState<boolean>(false);
  const [showBulkExpirationDateModal, setShowBulkExpirationDateModal] = useState<boolean>(false);
  const [showRecurringRulesModal, setShowRecurringRulesModal] = useState<boolean>(false);
  const [enableRecurringRules] = useFeature('enterprise-ui_enableRecurringRules');
  const [isSetExpirationDateEnabled] = useFeature('enterprise-ui_enableSetExpirationDate');
  const { data: disableApr = false } = useTakerMarkets(
    useCallback((takerMarkets: TakerMarket[]) => {
      const disableApr = takerMarkets.some((takerMarket) => takerMarket.isDiscountMarket);
      return disableApr;
    }, [])
  );
  const { mutateAsync: toggleGlobalPriceDiscoveryParticipationOff } = useGlobalPriceDiscoveryOffer();

  const turnGlobalParticipationOff = async () => {
    if (readOnlyUser) {
      return;
    }

    const submitValues = { isEnabled: false };

    await toggleGlobalPriceDiscoveryParticipationOff(submitValues, {
      onSuccess: () => {
        showSnackbar({ message: t('globalOfferSubmitDialog.offerUpdate') });
        track('global-participation::submitted', submitValues);
      },
      onError: () => showSnackbar({ message: t('globalOfferSubmitDialog.offerUpdateError') }),
    });
  };

  const openSetGlobalOfferModal = () => {
    setShowSetGlobalOfferModal(true);
    track('global-offer::clicked');
  };

  const openSetGlobalParticipationModal = () => {
    setShowSetGlobalParticipationModal(true);
    track('global-participation::clicked');
  };

  const openExcludeInvoicesModal = () => {
    setShowExcludeInvoicesModal(true);
    track('exclude-invoices::clicked', { type: tab });
  };

  const openRecurringRulesModal = () => {
    setShowRecurringRulesModal(true);
    track('recurring-rules::clicked', { type: tab });
  };

  const openTabActionsDropdown = () => {
    track('tab-actions::clicked', { type: tab });
  };

  const isBulkExpirationDisabled = useMemo(() => {
    // Ensure feature flag is enabled
    if (!isSetExpirationDateEnabled) {
      return true;
    }

    // Only allow supported offer types
    if (!['NAME_YOUR_RATE', 'BENCHMARK'].includes(tab)) {
      return true;
    }

    // At least one offer within the selected tab must be enabled
    const isSomeTabTypeOffersEnabled = takerMarketsGroups[tab].takerMarkets.some(
      (takerMarket) => takerMarket.offerConfig?.isEnabled
    );

    if (!isSomeTabTypeOffersEnabled) {
      return true;
    }

    return false;
  }, [isSetExpirationDateEnabled, tab, takerMarketsGroups]);

  const openExpirationDate = () => {
    // Failsafe for not allowing expiration date update if bulk expiration date is disabled
    if (isBulkExpirationDisabled) {
      return;
    }

    setShowBulkExpirationDateModal(true);
    track('bulk-expiration-date-update::opened');
  };

  return (
    <IntercomDataTarget target="tabActionsDropdown">
      <RecurringRulesFormModal
        onClose={() => setShowRecurringRulesModal(false)}
        open={showRecurringRulesModal}
        takerMarkets={takerMarketsGroups[tab].takerMarkets}
        type={tab}
      />
      <NameYourRateSetGlobalOffer
        disableApr={disableApr}
        numMarkets={takerMarketsGroups[tab].rollup.count}
        onClose={() => setShowSetGlobalOfferModal(false)}
        open={showSetGlobalOfferModal}
      />
      <NameYourRateSetGlobalParticipation
        onClose={() => setShowSetGlobalParticipationModal(false)}
        open={showSetGlobalParticipationModal}
      />
      <ExcludeInvoicesModal
        open={showExcludeInvoicesModal}
        onClose={() => setShowExcludeInvoicesModal(false)}
        takerMarkets={takerMarketsGroups[tab].takerMarkets}
        toCurrency={selectedCurrency}
        type={tab}
      />
      <SetBulkExpirationDate
        open={showBulkExpirationDateModal}
        onClose={() => setShowBulkExpirationDateModal(false)}
        takerMarkets={takerMarketsGroups[tab].takerMarkets}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild onClick={openTabActionsDropdown}>
          <IconButton icon={EllipsisVerticalIcon} name={t('takerMarketTabs.tabActions')} variant="cta" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" collisionPadding={24} avoidCollisions={false} className="min-w-64">
          <DropdownMenuLabel>{t('core.invoiceActions')}</DropdownMenuLabel>
          {enableRecurringRules && <RecurringRulesMenuItem onClick={openRecurringRulesModal} />}
          <DropdownMenuItem onClick={openExcludeInvoicesModal}>
            <ArrowLeftFromLineIcon className="h-6 w-6" fill={colors.text.secondary} />
            <span className="ml-3">{t('core.exclude')}</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuLabel>{t('core.globalActions')}</DropdownMenuLabel>
          {tab === 'NAME_YOUR_RATE' && (
            <DropdownMenuItem onClick={openSetGlobalOfferModal}>
              <PercentIcon className="h-6 w-6" fill={colors.text.secondary} />
              <span className="ml-3">{t('takerMarketTabs.setOneOfferForAllDivisions')}</span>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem disabled={tab !== 'NAME_YOUR_RATE'} onClick={openSetGlobalParticipationModal}>
            <ToggleLargeOnIcon className="h-6 w-6" fill={colors.text.secondary} />
            <span className="ml-3">{t('takerMarketTabs.allParticipationOn')}</span>
          </DropdownMenuItem>
          <DropdownMenuItem disabled={tab !== 'NAME_YOUR_RATE'} onClick={turnGlobalParticipationOff}>
            <ToggleLargeOffIcon className="h-6 w-6" fill={colors.text.secondary} />
            <span className="ml-3">{t('takerMarketTabs.allParticipationOff')}</span>
          </DropdownMenuItem>
          {isSetExpirationDateEnabled && (
            <DropdownMenuItem disabled={isBulkExpirationDisabled} onClick={openExpirationDate}>
              <CalendarLinesIcon className="h-6 w-6" fill={colors.text.secondary} />
              <span className="ml-3">{t('expirationDate.bulkUpdate.title')}</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </IntercomDataTarget>
  );
};

export default TabActionsDropdown;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { BenchmarkOfferInput } from '@/generated/gql/graphql';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';
import { gqlClient } from '@/lib/gqlClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';

export const SUBMIT_BENCHMARK_OFFER = graphql(`
  mutation SubmitBenchmarkOffer($offer: BenchmarkOfferInput!) {
    submitBenchmarkOffer(offer: $offer)
  }
`);

const useSubmitBenchmarkOffer = () => {
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();
  const { subscribeToMarketStats } = useServerSideEventListeners();

  return useMutation({
    mutationKey: ['submitBenchmarkOffer'],
    mutationFn: ({ marketUuid, supplierDivisionUuid }: { marketUuid: string; supplierDivisionUuid: string }) => {
      const takerMarket = takerMarkets?.find(
        (tm) => tm.marketUuid === marketUuid && tm.takerDivisionUuid === supplierDivisionUuid
      );
      const offer: BenchmarkOfferInput = {
        excludeNewInvoices: takerMarket?.offerConfig.exclusionSettings.excludeNewInvoices ?? false,
        frequency: 'RECURRING',
        marketUuid,
        supplierDivisionUuid,
      };

      return gqlClient.request(SUBMIT_BENCHMARK_OFFER, { offer });
    },
    onSuccess: (_data, variables) => {
      const tmMeta = takerMarkets?.find(
        (meta) => meta.marketUuid === variables.marketUuid && meta.takerDivisionUuid === variables.supplierDivisionUuid
      );

      if (tmMeta?.marketUuid && tmMeta?.takerDivisionId) {
        // update the cache with the returned data
        queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) =>
          (prevTakerMarkets ?? []).map((tm) => ({
            ...(tm.marketUuid === variables.marketUuid && tm.takerDivisionUuid === variables.supplierDivisionUuid
              ? {
                  ...tm,
                  offerConfig: {
                    ...tm.offerConfig,
                    isEnabled: true,
                  },
                }
              : tm),
          }))
        );

        // subscribe to market stats
        // this will refetch stats queries after we know stats have updated
        subscribeToMarketStats({
          marketUuid: tmMeta.marketUuid,
          takerId: tmMeta.takerDivisionId,
        });
      }
    },
  });
};

export default useSubmitBenchmarkOffer;

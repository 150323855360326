import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { Button } from '@c2fo/liquidity';
import { useReporting } from '@/reporting';
import brokenLinkIcon from '../../src/assets/broken-link.svg';
import { ErrorPageTemplate } from './ErrorPageTemplate';

interface MarketLayoutErrorProps {
  marketId: string;
  takerId: number;
}

export const MarketLayoutError: React.FC<MarketLayoutErrorProps> = ({ marketId, takerId }) => {
  const navigate = useNavigate();
  const { track } = useReporting();
  const { t } = useTranslation();

  useEffectOnce(() => {
    track('error::invalid-market::shown', { marketId, takerId });
  });

  return (
    <ErrorPageTemplate
      title={t('draftOffer.errors.invalid.title')}
      cardSlot={
        <div>
          <div className="flex flex-col">
            <div className="flex flex-col items-center gap-4 sm:flex-row">
              <img src={brokenLinkIcon} alt="invalid-market-error" className="h-20 w-20" />
              <p>{t('core.noAccessMessage')}</p>
            </div>
          </div>
          <div className="mt-6 flex justify-center sm:mt-4 sm:justify-end">
            <Button className="w-fit" onClick={() => navigate('/supplier/markets')} variant="primary">
              {t('draftOffer.errors.action')}
            </Button>
          </div>
        </div>
      }
    />
  );
};

import { useQuery } from '@tanstack/react-query';
import { fetchExchangeRates, ExchangeRate } from './useExchangeRates';

const fetchExchangeRate = async (currency: string): Promise<ExchangeRate | undefined> => {
  try {
    const response = await fetchExchangeRates({ currencies: [currency] });
    return response[currency];
  } catch {
    return undefined;
  }
};

const useExchangeRate = (currency: string) => {
  return useQuery({
    queryKey: ['exchange-rates', currency],
    queryFn: () => fetchExchangeRate(currency),
  });
};

export default useExchangeRate;

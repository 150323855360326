import { useMutation } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

// https://fss-gateway.c2fo.com/api/c2fo/user-source/requested-division-link

export interface VendorIds {
  value: string;
}

export interface CreateRequestedCustomerDivisionLinkParams {
  customerName: string;
  vendorIds: VendorIds[];
}

const useCreateRequestedCustomerDivisionLink = () => {
  return useMutation({
    mutationKey: ['createRequestedCustomerDivisionLink'],
    mutationFn: (params: CreateRequestedCustomerDivisionLinkParams) => {
      return apiClient
        .post('api/c2fo/user-source/requested-division-link', {
          json: params,
        })
        .json();
    },
  });
};

export default useCreateRequestedCustomerDivisionLink;

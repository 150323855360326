import { useMutation } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';
import { EligibleInvoice } from './useEligibleInvoices';

// https://fss-gateway.c2fo.com/api/c2fo/taker/3563872/market/b578662a-6125-4bb7-88cd-87835199ca57/invoice/exclude

export interface ExcludeInvoiceParams {
  takerId: number;
  invoice: EligibleInvoice;
  marketUuid: string;
}

const useInvoices = () => {
  return useMutation({
    mutationKey: ['excludeInvoice'],
    mutationFn: ({ takerId, marketUuid, invoice }: ExcludeInvoiceParams) => {
      return apiClient
        .post(`api/c2fo/taker/${takerId}/market/${marketUuid}/invoice/exclude`, {
          json: invoice,
        })
        .json<EligibleInvoice>();
    },
  });
};

export default useInvoices;

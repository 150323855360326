import Spinner from '@/components/Spinner';
import { DraftOfferTemplate } from './DraftOfferTemplate';

export const DraftOfferLoading = () => {
  return (
    <DraftOfferTemplate
      cardSlot={
        <div className="flex items-center justify-center">
          <Spinner className="h-12 w-12 fill-primary-500" />
        </div>
      }
      actionSlot={null}
    />
  );
};

import { formatISO } from 'date-fns';
import { trimDateTimestamp } from './trimDateTimestamp';

/**
 * Returns a given date as YYYY-MM-DD, defaults to today.
 *
 * Using formatISO instead of toISOString to format the time because toISOString converts
 * the date-time to UTC, which can result in the previous date being returned when the local
 * time zone is ahead of UTC. Using formatISO ensures that the time is formatted according
 * to the local time zone difference.

 * For example, if the local time zone is one hour ahead of UTC, formatISO will return
 * 2024-06-27T00:00:00+01:00. This ensures that the date returned matches the selected date
 * regardless of the time zone difference.
 */
export function dateAsIsoString(date?: Date) {
  const dateToTrim = date ? formatISO(date) : formatISO(new Date());
  return trimDateTimestamp(dateToTrim);
}

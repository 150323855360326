import ReactDOM from 'react-dom/client';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Snackbar, SnackbarProvider, Tooltip } from '@c2fo/liquidity';
import { FullPageLoader } from '@/components/FullPageLoader';
import { FeaturesProvider } from '@/lib/features';
import queryClient from '@/lib/queryClient';
import { ReportingProvider } from '@/reporting';
import MutationErrorTracker from '@/reporting/MutationErrorTracker';
import i18n from './i18n';
import Router from './Router';
import './index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<FullPageLoader />}>
          <FeaturesProvider>
            <ReportingProvider namespace="enterprise">
              <SnackbarProvider>
                <Tooltip.Provider delayDuration={0}>
                  <Suspense fallback={<FullPageLoader />}>
                    <Router />
                    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                    <Snackbar vertical="bottom" horizontal="left" />
                    <MutationErrorTracker />
                  </Suspense>
                </Tooltip.Provider>
              </SnackbarProvider>
            </ReportingProvider>
          </FeaturesProvider>
        </Suspense>
      </QueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>
);

import { useTranslation } from 'react-i18next';
import { Popover } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { InfoCircleIcon } from '@c2fo/liquidity/icons';
import useLocaleFormat from '@/utils/useLocaleFormat';

const InvoiceAdjustmentPopover = ({
  adjustmentAmount,
  amount,
  coversUnmatched,
  currency,
  originalAmount,
}: {
  adjustmentAmount: number;
  amount: number;
  coversUnmatched: boolean;
  currency: string;
  originalAmount: number;
}) => {
  const { t } = useTranslation();
  const { asCurrency } = useLocaleFormat();

  return (
    <Popover>
      <Popover.Trigger>
        <div className="flex items-center gap-2 italic">
          {asCurrency(amount, currency)} <InfoCircleIcon fill={colors.primary[500]} />
        </div>
      </Popover.Trigger>
      <Popover.Content arrow side="bottom">
        <div className="w-64 space-y-2 text-sm">
          <div className="flex justify-between gap-2">
            <div>{t('core.originalAmount')}</div>
            <div>{asCurrency(originalAmount, currency)}</div>
          </div>
          <div className="flex justify-between gap-2">
            <div>{t('core.adjustments')}</div>
            <div>{asCurrency(adjustmentAmount ?? 0, currency)}</div>
          </div>
          <div className="flex justify-between gap-2 border-t pt-2 font-medium">
            <div>{t('core.adjustedAmount')}</div>
            <div>{asCurrency(amount, currency)}</div>
          </div>
          {coversUnmatched && <div>{t('core.cannotExcludeAdjustedInvoice')}</div>}
        </div>
      </Popover.Content>
    </Popover>
  );
};

export default InvoiceAdjustmentPopover;

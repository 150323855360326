import useAccountPreferredConfig from '@/data/useAccountPreferredConfig';
import { TakerMarket } from '@/data/useTakerMarkets';

const useCanEditInvoices = (takerMarket: TakerMarket) => {
  const { data: accountPreferredConfigData } = useAccountPreferredConfig();

  const { isEnabled, offerType } = takerMarket.offerConfig;

  if (isEnabled && offerType === 'PREFERRED_TERM') {
    if (accountPreferredConfigData?.canEditInvoices) {
      return true;
    }

    return false;
  }

  return true;
};

export default useCanEditInvoices;

import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import apiClient from '@/lib/apiClient';
import useCurrencies from './useCurrencies';

// https://fss-gateway.c2fo.com/
// find/api/c2fo/exchange-rate
// ?currencies%5B%5D=USD&currencies%5B%5D=CAD&currencies%5B%5D=EUR

export interface ExchangeRate {
  [currency: string]: number;
}

export interface ExchangeRates {
  [currency: string]: ExchangeRate;
}

export const fetchExchangeRates = async ({ currencies }: { currencies: string[] }): Promise<ExchangeRates> => {
  const params = qs.stringify({ currencies }, { arrayFormat: 'brackets' });
  return apiClient.get(`find/api/c2fo/exchange-rate?${params}`).json<ExchangeRates>();
};

const useExchangeRates = () => {
  const currencies = useCurrencies();

  return useQuery({
    queryKey: ['exchange-rates'],
    queryFn: () => fetchExchangeRates({ currencies }),
  });
};

export default useExchangeRates;

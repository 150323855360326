import { useMutation } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

// https://fss-gateway.c2fo.com/api/c2fo/user-source/requested-customers

export interface CreateRequestedCustomersParams {
  customerNames: string[];
}

const useCreateRequestedCustomers = () => {
  return useMutation({
    mutationKey: ['createRequestedCustomer'],
    mutationFn: ({ customerNames }: CreateRequestedCustomersParams) => {
      return apiClient
        .post('api/c2fo/user-source/requested-customers', {
          json: { customerNames: JSON.stringify(customerNames) },
        })
        .json();
    },
  });
};

export default useCreateRequestedCustomers;

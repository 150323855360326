import { useMemo } from 'react';
import { useSelectedCurrency } from '@/components/CurrencySelect';
import useFeature, { Features } from '@/lib/features';
import convertCurrency from '@/utils/convertCurrency';
import useIsNewDivision from '@/utils/useIsNewDivision';
import useExchangeRates from './useExchangeRates';
import useTakerMarkets, { TakerMarket } from './useTakerMarkets';

export type TakerMarketGroupType = 'NAME_YOUR_RATE' | 'BENCHMARK' | 'FIXED' | 'PREFERRED' | 'INACTIVE';
interface TakerMarketGroupRollup {
  count: number;
  amount: number;
  newDivisionCount: number;
  excludedAmount: number;
}
export type TakerMarketGroupData = Record<
  TakerMarketGroupType,
  {
    takerMarkets: TakerMarket[];
    rollup: TakerMarketGroupRollup;
  }
>;

const defaultRollup: TakerMarketGroupRollup = {
  count: 0,
  amount: 0,
  newDivisionCount: 0,
  excludedAmount: 0,
};

const useTakerMarketsGroups = (): TakerMarketGroupData => {
  const { data: takerMarkets = [] } = useTakerMarkets();
  const { isNewDivision } = useIsNewDivision();
  const { data: exchangeRates = {} } = useExchangeRates();
  const selectedCurrency = useSelectedCurrency();
  const [preferredOffersEnabled] = useFeature(Features['enterprise-ui_enablePreferredOffers']);

  const takerMarketTabData = useMemo(() => {
    const data: TakerMarketGroupData = {
      NAME_YOUR_RATE: {
        takerMarkets: [],
        rollup: { ...defaultRollup },
      },
      BENCHMARK: {
        takerMarkets: [],
        rollup: { ...defaultRollup },
      },
      FIXED: {
        takerMarkets: [],
        rollup: { ...defaultRollup },
      },
      PREFERRED: {
        takerMarkets: [],
        rollup: { ...defaultRollup },
      },
      INACTIVE: {
        takerMarkets: [],
        rollup: { ...defaultRollup },
      },
    };

    takerMarkets.forEach((takerMarket) => {
      const convertedEligibleInvoiceAmount = convertCurrency({
        amount: takerMarket.eligibleInvoiceAmount,
        from: takerMarket.currency,
        to: selectedCurrency,
        exchangeRates,
      });
      const convertedExcludedInvoiceAmount = convertCurrency({
        amount: takerMarket.takerExcludedInvoiceAmount,
        from: takerMarket.currency,
        to: selectedCurrency,
        exchangeRates,
      });
      const incrementNewDivisionCount = isNewDivision(takerMarket.userAddedToDivision) ? 1 : 0;

      /**
       * Each TM should only ever go into a single tab
       */
      if (
        preferredOffersEnabled &&
        takerMarket.offerConfig.isEnabled &&
        takerMarket.offerConfig.offerType === 'PREFERRED_TERM'
      ) {
        // Preferred will always take precedence, even over "inactive" state
        data.PREFERRED.takerMarkets.push(takerMarket);
        data.PREFERRED.rollup.count += 1;
        data.PREFERRED.rollup.amount += convertedEligibleInvoiceAmount;
        data.PREFERRED.rollup.newDivisionCount += incrementNewDivisionCount;
        data.PREFERRED.rollup.excludedAmount += convertedExcludedInvoiceAmount;
      } else if (takerMarket.marketIsEnabled === false) {
        data.INACTIVE.takerMarkets.push(takerMarket);
        data.INACTIVE.rollup.count += 1;
        data.INACTIVE.rollup.amount += convertedEligibleInvoiceAmount;
        data.INACTIVE.rollup.newDivisionCount += incrementNewDivisionCount;
        data.INACTIVE.rollup.excludedAmount += convertedExcludedInvoiceAmount;
      } else if (takerMarket.marketPricingType === 'BENCHMARK') {
        data.BENCHMARK.takerMarkets.push(takerMarket);
        data.BENCHMARK.rollup.count += 1;
        data.BENCHMARK.rollup.amount += convertedEligibleInvoiceAmount;
        data.BENCHMARK.rollup.newDivisionCount += incrementNewDivisionCount;
        data.BENCHMARK.rollup.excludedAmount += convertedExcludedInvoiceAmount;
      } else if (takerMarket.marketPricingType === 'STATIC') {
        data.FIXED.takerMarkets.push(takerMarket);
        data.FIXED.rollup.count += 1;
        data.FIXED.rollup.amount += convertedEligibleInvoiceAmount;
        data.FIXED.rollup.newDivisionCount += incrementNewDivisionCount;
        data.FIXED.rollup.excludedAmount += convertedExcludedInvoiceAmount;
      } else {
        // Default to NAME_YOUR_RATE
        data.NAME_YOUR_RATE.takerMarkets.push(takerMarket);
        data.NAME_YOUR_RATE.rollup.count += 1;
        data.NAME_YOUR_RATE.rollup.amount += convertedEligibleInvoiceAmount;
        data.NAME_YOUR_RATE.rollup.newDivisionCount += incrementNewDivisionCount;
        data.NAME_YOUR_RATE.rollup.excludedAmount += convertedExcludedInvoiceAmount;
      }
    });

    return data;
  }, [exchangeRates, isNewDivision, preferredOffersEnabled, selectedCurrency, takerMarkets]);

  return takerMarketTabData;
};

export default useTakerMarketsGroups;

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  AlarmClockIcon,
  AngleRightIcon,
  CalendarIcon,
  MoneyBillWaveIcon,
  SquareQuoteIcon,
} from '@c2fo/liquidity/icons';
import windTurbine from '@/assets/windturbine.svg?url';
import { defaultRuleValues, RecurringRuleFormValues } from '../RecurringRulesForm';

interface RuleCategoryOption {
  description: string;
  icon: React.ElementType;
  label: string;
  value: RecurringRuleFormValues['category'];
}

const CategorySelect = ({ advanceStep }: { advanceStep: () => void }) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const ruleCategoryOptions: RuleCategoryOption[] = [
    {
      description: t('recurringRules.dpeDescription'),
      icon: CalendarIcon,
      label: t('core.daysPaidEarly'),
      value: 'dpe',
    },
    {
      description: t('recurringRules.invoiceAmountDescription'),
      icon: MoneyBillWaveIcon,
      label: t('recurringRules.invoiceAmountLabel'),
      value: 'amount',
    },
    {
      description: t('recurringRules.dueDateDescription'),
      icon: AlarmClockIcon,
      label: t('core.dueDate'),
      value: 'dueDate',
    },
    {
      description: t('recurringRules.invoiceIdDescription'),
      icon: SquareQuoteIcon,
      label: t('recurringRules.invoiceIdLabel'),
      value: 'invoiceId',
    },
  ];

  const onCategorySelect = (value: RecurringRuleFormValues['category']) => {
    setValue('category', value);
    // set default criteria and taker market values based on selected category
    setValue('criteria', defaultRuleValues[value].criteria);
    setValue('takerMarkets', defaultRuleValues[value].takerMarkets);
    advanceStep();
  };

  return (
    <div>
      <h3 className="m-0 text-center text-3xl font-medium">{t('recurringRules.createRecurringRule')}</h3>
      <div className="mb-14 mt-10">
        <img src={windTurbine} alt={t('recurringRules.createRecurringRule')} className="mx-auto h-16 w-16" />
      </div>
      <div className="mb-4">{t('recurringRules.createARuleDescription')}</div>
      <div className="flex flex-col space-y-3">
        {ruleCategoryOptions.map((option) => {
          const Icon = option.icon;

          return (
            <button
              key={option.value}
              aria-label={option.label}
              className="group relative flex w-full items-center justify-between rounded border border-transparent bg-canvas px-5 py-4 shadow transition-colors duration-200 hover:border-lightBlue-500 focus-visible:border-lightBlue-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-lightBlue-500"
              onClick={() => onCategorySelect(option.value)}
              type="button"
            >
              <div className="flex items-center gap-5">
                <Icon className="h-9 w-9 shrink-0 fill-text-secondary transition-colors duration-200 group-hover:fill-lightBlue-500" />
                <div className="text-left">
                  <div className="font-medium">{option.label}</div>
                  <div className="text-sm text-text-secondary">{option.description}</div>
                </div>
              </div>
              <div className="ml-5 flex h-11 w-11 shrink-0 items-center justify-center rounded-full bg-gray-200 transition-colors duration-200 group-hover:bg-gray-400">
                <AngleRightIcon className="h-6 w-6 fill-secondary-500" />
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default CategorySelect;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Modal, useSnackbar } from '@c2fo/liquidity';
import { TimesIcon } from '@c2fo/liquidity/icons';
import { useConfirmAgreementsWhenRequired } from '@/data/useAgreements';
import useGlobalPriceDiscoveryOffer from '@/data/useGlobalPriceDiscoveryOffer';
import useTakerMarketsGroups from '@/data/useTakerMarketsGroups';
import { useReporting } from '@/reporting';
import useReadOnlyUser from '@/utils/useReadOnlyUser';
import { Agreement } from '../agreements/Agreement';

const NameYourRateSetGlobalParticipation = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const readOnlyUser = useReadOnlyUser();
  const {
    mutateAsync: submitGlobalPriceDiscoveryParticipation,
    isLoading: submitGlobalPriceDiscoveryParticipationLoading,
  } = useGlobalPriceDiscoveryOffer();
  const {
    NAME_YOUR_RATE: { takerMarkets },
  } = useTakerMarketsGroups();
  const { validateAndConfirmAgreementsConditionally } = useConfirmAgreementsWhenRequired(takerMarkets);
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  const [isAgreementsErrorNotChecked, setIsAgreementsErrorNotChecked] = useState(false);

  const onSubmit = async () => {
    if (readOnlyUser) {
      return;
    }

    try {
      await validateAndConfirmAgreementsConditionally({ isAgreementsChecked });
    } catch {
      setIsAgreementsErrorNotChecked(true);
      return;
    }

    const submitValues = { isEnabled: true };

    await submitGlobalPriceDiscoveryParticipation(submitValues, {
      onSuccess: () => {
        showSnackbar({ message: t('globalOfferSubmitDialog.offerUpdate') });
        track('global-participation::submitted', submitValues);
        onClose();
      },
      onError: () => showSnackbar({ message: t('globalOfferSubmitDialog.offerUpdateError') }),
    });
  };

  return (
    <Modal className="w-fit" open={open} onClose={onClose}>
      <div className="flex justify-center p-6">
        <div className="w-96">
          <div className="relative space-y-4 bg-white text-text-primary">
            {/* header */}
            <div>
              <div className="text-xl font-medium">{t('offer.setParticipation')}</div>
              {/* info */}
              <div className="my-4 divide-y divide-gray-300 rounded-md bg-gray-100 p-4 font-medium">
                <div>{t('offer.participateInFutureClearsMessage')}</div>
              </div>
              <div className="absolute -right-3 -top-3">
                <button className="rounded-md p-2 text-xl hover:bg-gray-100" onClick={onClose} type="button">
                  <TimesIcon />
                </button>
              </div>
            </div>

            {/* understand info */}
            <div>
              <ul className="ml-4 list-disc space-y-2">
                <li>{t('allOfferDialogs.description2')}</li>
                <li>{t('allOfferDialogs.description3')}</li>
                <li>{t('offerSubmitDialog.disclaimer')}</li>
              </ul>
            </div>

            <Agreement
              takersMarkets={takerMarkets}
              onChange={(v) => {
                setIsAgreementsChecked(v);
                setIsAgreementsErrorNotChecked(false);
              }}
            />
            {isAgreementsErrorNotChecked && (
              <Alert type="error" variant="filled" description={t('agreements.error.checkbox')} full />
            )}

            <div className="grid grid-cols-2 gap-2 pt-6">
              <Button
                variant="secondary"
                onClick={onClose}
                type="button"
                disabled={submitGlobalPriceDiscoveryParticipationLoading}
              >
                {t('core.cancel')}
              </Button>
              <Button
                variant="cta"
                type="submit"
                disabled={submitGlobalPriceDiscoveryParticipationLoading}
                onClick={onSubmit}
                loading={submitGlobalPriceDiscoveryParticipationLoading}
              >
                {t('core.submit')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NameYourRateSetGlobalParticipation;

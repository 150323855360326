import { useTranslation } from 'react-i18next';
import { Alert } from '@c2fo/liquidity';
import { useSrm } from '@/features/srmCard/SrmCard';
import useFeature from '@/lib/features';
import QueryBoundaries from './QueryBoundaries';

const BannerAnnouncementsCore = () => {
  const { t } = useTranslation();
  const { calendarLink } = useSrm();

  const [enableFedRateBanner] = useFeature('enterprise-ui_fedRateBanner');
  return (
    <>
      {enableFedRateBanner && (
        <Alert
          full
          type="info"
          variant="outlined"
          title={t('banner.fedRates.title')}
          description={t('banner.fedRates.description')}
          action={
            calendarLink
              ? {
                  onClick: () => {
                    window.open(calendarLink, '_blank', 'noreferrer');
                  },
                  text: t('banner.fedRates.action'),
                }
              : undefined
          }
        />
      )}
    </>
  );
};

// Ensure we never block entire homepage due to suspense loading something in a banner, or if a banner throws an error.
export const BannerAnnouncements = () => {
  return (
    <QueryBoundaries>
      <BannerAnnouncementsCore />
    </QueryBoundaries>
  );
};

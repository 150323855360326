import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import apiClient from '@/lib/apiClient';

// https://fss-gateway.c2fo.com/
// api/c2fo/taker/3563184/market/0c20157f-ba29-4efd-b8e6-93baacff1793/invoices/awarded
// ?filter=%7B%7D&page=%7B%22page%22:1,%22limit%22:100%7D&sort=-newPayDate

export interface AwardedInvoice {
  id: number;
  isCleared: boolean;
  marketId: number;
  takerId: number;
  isPendingClear: boolean;
  isAwarded: boolean;
  voucherId: string;
  dueDate: string;
  currency: string;
  amount: number;
  minDiscount: number;
  earn: number;
  clearDate: string;
  newPayDate: string;
  manualAwardUuid: string | null;
  awardType: string | null;
  normalizedClearDate: string;
}

export type AwardedInvoiceSort =
  | 'amount'
  | '-amount'
  | 'dueDate'
  | '-dueDate'
  | 'earn'
  | '-earn'
  | 'isCleared'
  | '-isCleared'
  | 'minDiscount'
  | '-minDiscount'
  | 'newPayDate'
  | '-newPayDate'
  | 'normalizedClearDate'
  | '-normalizedClearDate'
  | 'voucherId'
  | '-voucherId';

export interface AwardedInvoiceFetchOptions {
  page: { page: number; limit: number };
  sort: AwardedInvoiceSort;
}

const fetchAwardedInvoices = async ({
  marketId,
  takerId,
  options,
}: {
  marketId: string;
  takerId: number;
  options: Partial<AwardedInvoiceFetchOptions>;
}): Promise<AwardedInvoice[]> => {
  const params = qs.stringify({
    page: options.page ?? { page: 1, limit: 100 },
    sort: options.sort ?? '-newPayDate',
  });

  const data = await apiClient
    .get(`api/c2fo/taker/${takerId}/market/${marketId}/invoices/awarded?${params}`)
    .json<AwardedInvoice[]>();

  return data || [];
};

const useAwardedInvoices = <TData = AwardedInvoice[]>(
  params: {
    marketId: string;
    takerId: number;
    options: Partial<AwardedInvoiceFetchOptions>;
  },
  select?: (data: AwardedInvoice[]) => TData
) => {
  const { marketId, takerId, options } = params;

  return useQuery({
    enabled: !!marketId && !!takerId,
    keepPreviousData: true,
    queryKey: ['awarded-invoices', marketId, takerId, options],
    queryFn: () =>
      fetchAwardedInvoices({
        marketId,
        takerId,
        options,
      }),
    select,
  });
};

export default useAwardedInvoices;

import QueryBoundaries from '@/components/QueryBoundaries';
import useServerSideEventsInterval from './useServerSideEventsInterval';

const ServerSentEventsIntervalCaller = () => {
  useServerSideEventsInterval();
  return null;
};

export function ServerSentEventsInterval() {
  return (
    <QueryBoundaries ErrorComponent={() => <></>}>
      <ServerSentEventsIntervalCaller />
    </QueryBoundaries>
  );
}

export default ServerSentEventsInterval;

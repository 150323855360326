import apiClient from '@/lib/apiClient';
import reauthenticate from './reauthenticate';
import getToken from './token';

/**
 * Logout the user.
 * This will redirect the user to the login page.
 */
export default async function logout() {
  const token = getToken();
  const response = await apiClient.delete(`broadcast/api/auth/c2fo-user-auth/${token}`);

  if (response.ok) {
    reauthenticate('/');
  }
}

import { useMutation, useQuery } from '@tanstack/react-query';
import decode from 'jwt-decode';
import apiClient from '@/lib/apiClient';
import queryClient from '@/lib/queryClient';

interface User {
  uuid: string;
  sessionUuid: string;
  isAccountLocked: boolean;
  source: string;
  datacenters: string[];
  isSource: boolean;
  emailAddress: string;
  lastLogin: Date;
  hasPassword: boolean;
  needsPasswordReset: boolean;
  features: string[];
}

interface Payload {
  type: string;
  access: string;
  user: User;
  impersonationSubject?: User;
}

export interface TokenContent {
  payload: Payload;
  iat: number; // Issue At Time (seconds)
  exp: number; // Expiration Time (seconds)
  iss: string;
}

interface TokenResponse {
  jwt: string;
  payload: Payload;
}

export const tokenName = import.meta.env.VITE_TOKEN_NAME;

/**
 * Gets the token from local storage.
 * For use internally in the exposed getToken function and useToken hook.
 */
function _getToken() {
  const params = new URLSearchParams(window.location.search);
  const authToken = params.get('authToken');
  const tokenFromParams = authToken ?? params.get('authorization');

  let token = localStorage.getItem(tokenName);

  // if a token is passed as a param, always attempt to honor it first
  if (tokenFromParams) {
    _setToken(tokenFromParams);

    params.delete('authorization');
    params.delete('authToken');

    const hasParams = params.size > 0;
    const newUrl = hasParams
      ? window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + params.toString()
      : window.location.protocol + '//' + window.location.host + window.location.pathname;

    // Change the URL without reloading the page
    history.replaceState({}, '', newUrl);

    token = tokenFromParams;
  }

  return token;
}

/**
 * Gets the token content from local storage.
 * For use internally in the exposed getTokenContent function and useToken hook.
 */
function _getTokenContent() {
  const token = _getToken();
  const tokenContent = token ? (decode(token) as TokenContent) : null;

  return tokenContent;
}

/**
 * Sets the token in local storage.
 * For use internally in the exposed setToken function and useToken hook.
 * @param token The token to set in local storage. If null, the token is removed from local storage.
 */
function _setToken(token: string | null) {
  if (!token) {
    localStorage.removeItem(tokenName);
  } else {
    localStorage.setItem(tokenName, token);
  }
}

/**
 * Gets the token from local storage.
 * The token is cached.
 */
export const getToken = () => {
  let token: string | null = null;
  token = queryClient.getQueryData<string | undefined>([tokenName]) ?? null;
  if (token) {
    return token;
  }

  token = _getToken();
  queryClient.setQueryData<string | null>([tokenName], token);
  return token;
};

/**
 * Gets the token content from local storage.
 * The token content is cached.
 * The cache is invalidated when the token is set.
 */
export const getTokenContent = () => {
  let tokenContent: TokenContent | null = null;

  tokenContent = queryClient.getQueryData<TokenContent | undefined>([tokenName, 'content']) ?? null;
  const tokenQuery = queryClient.getQueryCache().find([tokenName, 'content']);
  if (tokenContent && tokenQuery?.state.isInvalidated === false) {
    return tokenContent;
  }

  tokenContent = _getTokenContent();
  queryClient.setQueryData<TokenContent | null>([tokenName, 'content'], tokenContent);

  return tokenContent;
};

/**
 * Sets the token in local storage.
 * The token is also updated/removed from the query cache.
 * @param token The token to set in local storage. If null, the token is removed from local storage.
 */
export const setToken = (token: string | null) => {
  _setToken(token);

  if (token) {
    // only update the query cache if the token is being set
    // if the token is being removed, the page will be reloaded
    queryClient.invalidateQueries([tokenName]);
  }
};

/**
 * Refreshes the currently used token and updates it in local storage
 */
export const refreshToken = async () => {
  const response = await apiClient.post('api/c2fo/refresh-token').json<TokenResponse>();

  setToken(response.jwt);
};

/**
 * Gets the current auth user from the token content based on impersonation.
 */
export const getCurrentAuthUser = () => {
  const tokenContent = getTokenContent();
  return tokenContent?.payload.impersonationSubject ?? tokenContent?.payload.user;
};

/**
 * Returns if the current session is impersonated.
 * For use internally in the isImpersonationSession hook.
 */
export const isImpersonationSession = () => {
  const tokenContent = getTokenContent();
  return !!tokenContent?.payload.impersonationSubject;
};

/**
 * Provides access to the token and token content.
 * Also provides a function to set the token.
 * Both token and token content are cached.
 * The cache is invalidated when the token is set.
 */
export function useToken() {
  const { data: token, ...tokenResult } = useQuery({
    queryKey: [tokenName],
    queryFn: () => _getToken(),
  });

  const { data: tokenContent, ...tokenContentResult } = useQuery({
    queryKey: [tokenName, 'content'],
    queryFn: () => _getTokenContent(),
  });

  const { mutate: setTokenFn, ...setTokenResult } = useMutation({
    mutationFn: async (token: string | null) => _setToken(token),
    onSuccess: () => {
      queryClient.invalidateQueries([tokenName]);
    },
  });

  return {
    token,
    tokenResult,
    tokenContent,
    tokenContentResult,
    setToken: setTokenFn,
    setTokenResult,
    isImpersonationSession: !!tokenContent?.payload.impersonationSubject,
  };
}

export default getToken;

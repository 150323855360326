import { Outlet } from 'react-router-dom';
import NavigateWithRedirect from '@/components/NavigateWithRedirect';
import useFeature, { Feature } from '@/lib/features';

const FeatureFlagProtectedRoute = ({ featureFlag }: { featureFlag: Feature }) => {
  const [enableFeatureFlag] = useFeature(featureFlag);

  if (!enableFeatureFlag) {
    return <NavigateWithRedirect to="/supplier/markets" />;
  }

  return <Outlet />;
};

export default FeatureFlagProtectedRoute;

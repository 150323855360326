import { useTranslation } from 'react-i18next';
import { Popover } from '@c2fo/liquidity';
import { InfoCircleIcon } from '@c2fo/liquidity/icons';

interface PopoverProps {
  iconColor?: string;
}

const FixedRateInfoPopover = ({ iconColor }: PopoverProps) => {
  const { t } = useTranslation();

  return (
    <span className="flex items-center gap-2">
      <span>{t('taker.dictionary.marketRate.label')}</span>
      <Popover>
        <Popover.Trigger onClick={(e) => e.stopPropagation()}>
          <InfoCircleIcon fill={iconColor} />
        </Popover.Trigger>
        <Popover.Content arrow side="top">
          <div className="w-40 space-y-2 text-sm">
            <div>{t('taker.dictionary.fixedRate.description')}</div>
          </div>
        </Popover.Content>
      </Popover>
    </span>
  );
};

export default FixedRateInfoPopover;

import { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cn, Logo } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { BarsIcon } from '@c2fo/liquidity/icons';
import CurrencySelect from '@/components/CurrencySelect';
import useFeature from '@/lib/features';
import useSmbUser from '@/utils/useSmbUser';
import QueryBoundaries from '../QueryBoundaries';
import LanguageChangeMenu from './LanguageChangeMenu';
import MobileNavigation from './MobileNavigation';
import RecentAwardsDropdown from './RecentAwardsDropdown';
import UserMenu from './UserMenu';

export const useLinks = () => {
  const { t } = useTranslation();
  const isSmbUser = useSmbUser();
  const [enableRecurringRules] = useFeature('enterprise-ui_enableRecurringRules');

  return useMemo(() => {
    let linksArray = [
      {
        key: 'markets',
        text: t('core.markets'),
        to: '/supplier/markets',
      },
      {
        key: 'history',
        text: t('taker.consolidatedHistory.general'),
        to: '/supplier/history',
      },
      {
        key: 'search',
        text: t('registration.anonymousCallToAction'),
        to: '/supplier/markets/search',
      },
    ];

    if (enableRecurringRules) {
      linksArray.push({
        key: 'rules',
        text: t('recurringRules.title'),
        to: '/supplier/recurring-rules',
      });
    }

    // For NSE users, only show consolidated history link
    if (isSmbUser) {
      linksArray = [
        {
          key: 'history',
          text: t('taker.consolidatedHistory.general'),
          to: '/supplier/history',
        },
      ];
    }

    return linksArray;
  }, [t, isSmbUser, enableRecurringRules]);
};

const isCurrent = (key: string, link: string, pathname: string) => {
  if (key === 'markets') {
    if (pathname.includes('search')) {
      return pathname === link;
    }
  }

  return pathname.includes(link);
};

const NavigationComponent = () => {
  const links = useLinks();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isSmbUser = useSmbUser();
  const [navIsOpen, setNavIsOpen] = useState<boolean>(false);

  return (
    <>
      <nav className="fixed z-10 h-16 w-full border-b bg-white text-secondary-500">
        <div className="mx-auto flex h-16 max-w-screen-2xl items-center space-x-6 px-4 lg:px-6">
          {/* logo */}
          <div className="flex gap-4">
            <div className="flex flex-1 lg:hidden">
              <button onClick={() => setNavIsOpen(true)} type="button">
                <BarsIcon fill={colors.secondary[500]} className="h-6 w-6" />
              </button>
            </div>
            {!isSmbUser ? (
              <Link to="/supplier/markets" aria-label={t('ariaLabel.linkToHomepage')} className="hidden lg:block">
                <Logo className="h-9 w-auto" color="primary" />
              </Link>
            ) : (
              <Logo className="hidden h-9 w-auto lg:block" color="primary" />
            )}
            <Logo className="h-6 w-auto lg:hidden" color="primary" brandMark />
          </div>
          {/* desktop nav */}
          <div className="hidden flex-1 items-center justify-between gap-6 font-bold lg:flex">
            {/* left nav section */}
            <div className="flex">
              {links.map((link) => {
                const current = isCurrent(link.key, link.to, pathname);

                return (
                  <Link
                    key={link.key}
                    to={link.to}
                    aria-current={current ? 'page' : undefined}
                    className={cn(
                      'flex h-16 items-center whitespace-nowrap border-b-2 border-transparent px-4 transition-all duration-200',
                      { 'border-primary-500 bg-deepGray-50': current }
                    )}
                  >
                    {t(`${link.text}`)}
                  </Link>
                );
              })}
              <RecentAwardsDropdown />
            </div>
            {/* right nav section */}
            <QueryBoundaries>
              <div className="flex items-center space-x-4">
                <LanguageChangeMenu />
                <CurrencySelect />
                <UserMenu />
              </div>
            </QueryBoundaries>
          </div>
        </div>
      </nav>
      {/* mobile nav */}
      <MobileNavigation navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
    </>
  );
};

const Navigation = () => <NavigationComponent />;

export default Navigation;

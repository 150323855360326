import { cn } from '@c2fo/liquidity';

interface NoDataStateProps {
  icon?: JSX.Element;
  title?: string;
  message?: string;
  action?: JSX.Element;
  className?: string;
}

const NoDataState = ({ icon, title, message, action, className }: NoDataStateProps) => {
  return (
    <div className={cn('m-auto flex flex-col items-center justify-center', className)}>
      {icon && <div className="pb-4">{icon}</div>}
      {title && (
        <h3 className="m-0 pb-4 text-center text-lg font-normal capitalize text-text-secondary sm:text-2xl">{title}</h3>
      )}
      {message && <p className="max-w-md text-wrap pb-4 text-center text-text-secondary">{message}</p>}
      {action && <div>{action}</div>}
    </div>
  );
};

export default NoDataState;

import { useTranslation } from 'react-i18next';
import { PencilIcon } from '@c2fo/liquidity/icons';
import { useOfferDiscountAPRDisplay } from '../utils/useOfferDiscountAPRDisplay';

const OfferDiscountAPRDisplay = ({
  isDiscountBidding,
  isDiscountMarket,
  maxDiscount,
  maxApr,
  onClick,
}: {
  isDiscountBidding?: boolean | null;
  isDiscountMarket?: boolean | null;
  maxDiscount?: number | null;
  maxApr?: number | null;
  onClick?: () => void;
}) => {
  const { t } = useTranslation();
  const { offerDiscountAPRDisplay } = useOfferDiscountAPRDisplay();

  if (isDiscountBidding || isDiscountMarket) {
    return (
      <div className="flex items-center justify-end">
        <button
          className="-mr-2 flex items-center gap-2 rounded p-2 uppercase hover:bg-gray-100"
          onClick={onClick}
          type="button"
        >
          {offerDiscountAPRDisplay(maxDiscount ?? 0, t('core.discountAbbreviation'))}
          <PencilIcon />
        </button>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-end">
      <button
        className="-mr-2 flex items-center gap-2 rounded p-2 uppercase hover:bg-gray-100"
        onClick={onClick}
        type="button"
      >
        {offerDiscountAPRDisplay(maxApr ?? 0, t('core.aprAbbreviation'))}
        <PencilIcon />
      </button>
    </div>
  );
};

export default OfferDiscountAPRDisplay;

const utmKeyMap = {
  utmPartner: 'utm_partner',
  utmLang: 'utm_lang',
  utmSource: 'utm_source',
  utmMedium: 'utm_medium',
  utmRegion: 'utm_region',
  utmLifecycle: 'utm_lifecycle',
  utmAudience: 'utm_audience',
  utmCampaign: 'utm_campaign',
  utmSeries: 'utm_series',
  utmCountry: 'utm_country',
  utmContent: 'utm_content',
  utmTerm: 'utm_term',
};

const sessionStorageKeyMap = {
  ...utmKeyMap,
  journeySessionUuid: 'journey_session_uuid',
};

export const getSessionTrackingData = () => {
  let sessionTrackingData = {};

  if (window.sessionStorage) {
    sessionTrackingData = Object.entries(sessionStorageKeyMap).reduce<{ [key: string]: string | null }>(
      (acc, [key, value]) => {
        if (window.sessionStorage.getItem(value)) {
          acc[key] = window.sessionStorage.getItem(value);
        }

        return acc;
      },
      {}
    );
  }

  return sessionTrackingData;
};

export const removeSessionTrackingData = () => {
  if (window.sessionStorage) {
    Object.values(sessionStorageKeyMap).forEach((value) => {
      window.sessionStorage.removeItem(value);
    });
  }
};

// Will need to translate this file after we get proper language
// Didn't want to kick off a Smartling job while specifics are in flux

export const getDpeRule = ({ minDpe, maxDpe }: { minDpe?: number; maxDpe?: number }) => {
  if (!minDpe && !maxDpe) {
    return;
  }

  if (minDpe && maxDpe) {
    return {
      title: `< ${minDpe}, > ${maxDpe}`,
      description: `Exclude invoices with less than ${minDpe} and greater than ${maxDpe} DPE`,
    };
  } else if (minDpe) {
    return {
      title: `< ${minDpe}`,
      description: `Exclude invoices with less than ${minDpe} DPE`,
    };
  } else if (maxDpe) {
    return {
      title: `> ${maxDpe}`,
      description: `Exclude invoices with greater than ${maxDpe} DPE`,
    };
  }
};

export const getDueDateRule = ({ minDueDate, maxDueDate }: { minDueDate?: string; maxDueDate?: string }) => {
  if (!minDueDate && !maxDueDate) {
    return;
  }

  if (minDueDate && maxDueDate) {
    return {
      title: `before ${minDueDate}, after ${maxDueDate}`,
      description: `Exclude invoices with due dates before ${minDueDate} and after ${maxDueDate}`,
    };
  } else if (minDueDate) {
    return {
      title: `before ${minDueDate}`,
      description: `Exclude invoices with due dates before ${minDueDate}`,
    };
  } else if (maxDueDate) {
    return {
      title: `after ${maxDueDate}`,
      description: `Exclude invoices with due dates after ${maxDueDate}`,
    };
  }
};

export const getInvoiceAmountRule = ({
  minInvoiceAmount,
  maxInvoiceAmount,
}: {
  minInvoiceAmount?: string;
  maxInvoiceAmount?: string;
}) => {
  if (!minInvoiceAmount && !maxInvoiceAmount) {
    return;
  }

  if (minInvoiceAmount && maxInvoiceAmount) {
    return {
      title: `< ${minInvoiceAmount}, > ${maxInvoiceAmount}`,
      description: `Exclude invoices less than ${minInvoiceAmount} and greater than ${maxInvoiceAmount}`,
    };
  } else if (minInvoiceAmount) {
    return {
      title: `< ${minInvoiceAmount}`,
      description: `Exclude invoices less than ${minInvoiceAmount}`,
    };
  } else if (maxInvoiceAmount) {
    return {
      title: `> ${maxInvoiceAmount}`,
      description: `Exclude invoices greater than ${maxInvoiceAmount}`,
    };
  }
};

export const getInvoiceIdsRule = (excludedVoucherIds?: string[]) => {
  if (excludedVoucherIds && excludedVoucherIds.length) {
    return {
      title: excludedVoucherIds.join(', '),
      description: `Exclude invoices with IDs containing: ${excludedVoucherIds.join(', ')}`,
    };
  }

  return;
};

import { useTranslation } from 'react-i18next';
import { Switch, useSnackbar } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CheckIcon, TimesIcon } from '@c2fo/liquidity/icons';
import Spinner from '@/components/Spinner';
import { TakerMarket } from '@/data/useTakerMarkets';
import useToggleOffer from '@/data/useToggleOffer';
import { useReporting } from '@/reporting';
import useReadOnlyUser from '@/utils/useReadOnlyUser';

const FixedRateParticipationToggle = ({
  disabled = false,
  openEditOfferModal,
  takerMarket,
}: {
  openEditOfferModal: (takerMarket: TakerMarket) => void;
  takerMarket: TakerMarket;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const readOnlyUser = useReadOnlyUser();
  const { mutateAsync: toggleOffer, isLoading } = useToggleOffer();

  const isDisabledDueToClosedMarket = !takerMarket.offerConfig.isEnabled && takerMarket.marketIsEnabled === false;
  const toggleDisabled = disabled || isLoading || readOnlyUser || isDisabledDueToClosedMarket;

  const toggleParticipationOff = async () => {
    const submitValues = {
      marketUuid: takerMarket.offerConfig.marketUuid,
      supplierDivisionUuid: takerMarket.takerDivisionUuid,
    };

    return await toggleOffer(submitValues, {
      onSuccess: () => {
        showSnackbar({ message: t('offerSubmitDialog.participationUpdated') });
        track('participation::fixed-rate::submitted', submitValues);
      },
      onError: () => showSnackbar({ message: t('offerSubmitDialog.participationError') }),
    });
  };

  const onClick = async () => {
    // if offer is turned on, toggle participation off without showing anything
    // if offer is turned off, send to the set fixed rate offer modal
    if (takerMarket.offerConfig.isEnabled) {
      await toggleParticipationOff();
    } else {
      openEditOfferModal(takerMarket);
    }
  };

  return (
    <Switch
      accessibilityLabel={t('core.toggleParticipation')}
      checked={takerMarket.offerConfig.isEnabled ?? false}
      disabled={toggleDisabled}
      onCheckedChange={onClick}
      size="large"
      uncheckedColor="error-500"
    >
      {(checked) =>
        checked ? (
          isLoading ? (
            <Spinner className="fill-primary-500 " />
          ) : (
            <CheckIcon fill={colors.success[500]} className="h-full w-full p-1" />
          )
        ) : isLoading ? (
          <Spinner className="fill-error-500" />
        ) : (
          <TimesIcon fill={colors.error[500]} className="h-full w-full p-1" />
        )
      }
    </Switch>
  );
};

export default FixedRateParticipationToggle;

import { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
  fallback: ReactNode | ((error: Error, info: ErrorInfo) => ReactNode);
  children?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private caughtError: Error | null = null;
  private errorInfo: ErrorInfo | null = null;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.error('Error caught:', error, info);
    this.caughtError = error;
    this.errorInfo = info;
  }

  render(): ReactNode {
    if (this.state.hasError) {
      const { fallback } = this.props;
      if (typeof fallback === 'function') {
        return this.caughtError && this.errorInfo ? fallback(this.caughtError, this.errorInfo) : null;
      } else {
        return fallback;
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Checkbox, useSnackbar } from '@c2fo/liquidity';
import { TechnicalDifficultyIcon } from '@c2fo/liquidity/icons';
import invoices from '@/assets/invoicesgrey.svg?url';
import { useInvoiceLayoutOutletContext } from '@/components/InvoiceLayout';
import NoDataState from '@/components/NoDataState';
import QueryBoundaries from '@/components/QueryBoundaries';
import RangePicker from '@/components/RangePicker';
import { TableSortDirection } from '@/components/Table';
import useBulkInvoices from '@/data/useBulkInvoices';
import {
  EligibleInvoice,
  EligibleInvoiceFetchFilters,
  EligibleInvoiceFetchOptions,
  EligibleInvoiceOrder,
} from '@/data/useEligibleInvoices';
import useInvoices from '@/data/useInvoices';
import EligibleInvoiceTable from '@/features/eligibleInvoiceTable/EligibleInvoiceTable';
import {
  isExcluded,
  isIncluded,
  isPartiallyAdjustedInvoiceCoveringUnmatchedAdjustment,
  selectedInvoiceReducer,
} from '@/features/eligibleInvoiceTable/utils';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { useReporting } from '@/reporting';
import addOrRemoveFromArray from '@/utils/addOrRemoveFromArray';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useUrlState from '@/utils/useUrlState';

export interface EligibleInvoiceTableSort {
  key: EligibleInvoiceOrder;
  direction: TableSortDirection;
}

const EligibleInvoices = () => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const { asCurrency } = useLocaleFormat();
  const queryClient = useQueryClient();
  const { mutate: toggleInvoices } = useInvoices();
  const { mutate: bulkExclude } = useBulkInvoices();
  const [showDueDatePicker, setShowDueDatePicker] = useState<boolean>(false);
  const { subscribeToMarketStats } = useServerSideEventListeners();
  const [urlState, setUrlState] = useUrlState<EligibleInvoiceFetchOptions>({
    limit: 100,
    order: 'amount',
    orderDirection: 'desc',
    page: 1,
  });
  const [selectedInvoiceState, selectedInvoiceDispatch] = useReducer(selectedInvoiceReducer, {
    enableExcludeButton: false,
    enableIncludeButton: false,
    isBulk: false,
    selectedInvoices: [],
    selectedInvoiceAmount: 0,
    selectedInvoiceCount: 0,
  });
  const { loader, takerMarket } = useInvoiceLayoutOutletContext();
  const { setLoading } = loader;

  const sort: EligibleInvoiceTableSort = {
    key: urlState.order ?? 'amount',
    direction: urlState.orderDirection ?? 'desc',
  };

  const handleSort = (key: EligibleInvoiceOrder) => {
    setUrlState((f) => ({
      ...f,
      order: key,
      orderDirection: f.order === key && f.orderDirection === 'desc' ? 'asc' : 'desc',
    }));

    selectedInvoiceDispatch({ type: 'unselectAll' });

    track('invoice-sort::clicked', { key });
  };

  const handleFilter = ({
    key,
    value,
  }: {
    key: keyof Omit<EligibleInvoiceFetchFilters, 'search'>;
    value?: boolean | string;
  }) => {
    setUrlState((f) => ({
      ...f,
      filter: {
        ...f.filter,
        [key]: key === 'dueDate' ? [value] : addOrRemoveFromArray(f.filter?.[key] ?? [], value),
      },
    }));

    selectedInvoiceDispatch({ type: 'unselectAll' });

    track('invoice-filter::clicked', { key, value });
  };

  const handleSearch = (search: string) => {
    setUrlState((f) => {
      let { filter } = f;

      if (!search) {
        delete filter?.search;
      } else {
        filter = { ...filter, search };
      }

      return {
        ...f,
        filter,
        page: 1,
      };
    });

    selectedInvoiceDispatch({ type: 'unselectAll' });

    track('invoice-search::submitted', { value: search });
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const limit = Number(e.target.value);

    setUrlState((f) => ({
      ...f,
      limit,
      page: 1,
    }));

    selectedInvoiceDispatch({ type: 'unselectAll' });

    track('invoice-page-size::clicked', { size: limit });
  };

  const handleNextChange = () => {
    setUrlState((f) => ({
      ...f,
      page: f.page! + 1,
    }));

    selectedInvoiceDispatch({ type: 'unselectAll' });

    track('invoice-page::clicked');
  };

  const handlePrevChange = () => {
    setUrlState((f) => ({
      ...f,
      page: f.page! - 1,
    }));

    selectedInvoiceDispatch({ type: 'unselectAll' });

    track('invoice-page::clicked');
  };

  const handleInvoiceSelect = (invoice: EligibleInvoice) => {
    selectedInvoiceDispatch({
      type: 'invoiceClicked',
      payload: { invoice },
    });

    track('invoice-select::clicked');
  };

  const toggleInvoiceInclusionExclusion = (action: 'include' | 'exclude') => {
    let invoicesToChange: EligibleInvoice[] = [];
    setLoading(true);

    if (action === 'exclude') {
      invoicesToChange = selectedInvoiceState.selectedInvoices
        .filter((invoice) => isIncluded(invoice))
        .filter((invoice) => !isPartiallyAdjustedInvoiceCoveringUnmatchedAdjustment(invoice));
    }

    if (action === 'include') {
      invoicesToChange = selectedInvoiceState.selectedInvoices.filter((invoice) => isExcluded(invoice));
    }

    if (!invoicesToChange.length) {
      setLoading(false);
      return;
    }

    invoicesToChange.forEach((invoice) => {
      const params = {
        takerId: takerMarket.takerDivisionId,
        marketUuid: takerMarket.marketUuid,
        invoice: {
          ...invoice,
          takerExcluded: action === 'exclude',
        },
      };

      toggleInvoices(params, {
        onSuccess: (_data, variables) => {
          setLoading(false);
          selectedInvoiceDispatch({ type: 'unselectAll' });
          showSnackbar({ message: t('invoices.updated') });

          queryClient.refetchQueries({ queryKey: ['eligible-invoices'] });

          subscribeToMarketStats({
            marketUuid: variables.marketUuid,
            takerId: variables.takerId,
          });
        },
        onError: () => {
          setLoading(false);
          showSnackbar({ message: t('invoices.errorUpdating') });
        },
      });
    });

    track(`invoice-${action}::submitted`, {
      takerId: takerMarket.takerDivisionId,
      takerDivisionUuid: takerMarket.takerDivisionUuid,
      marketId: takerMarket.id,
      marketUuid: takerMarket.marketUuid,
      invoicesToChange: invoicesToChange.map((invoice) => invoice.id),
      action,
    });
  };

  const toggleBulkInvoiceInclusionExclusion = async (action: 'include' | 'exclude') => {
    setLoading(true);

    const params = {
      takerId: takerMarket.takerDivisionId,
      marketUuid: takerMarket.marketUuid,
      exclude: action === 'exclude',
      filters: urlState.filter,
    };

    bulkExclude(params, {
      onSuccess: () => {
        setLoading(false);
        selectedInvoiceDispatch({ type: 'unselectAll' });
        showSnackbar({ message: t('invoices.updated') });
      },
      onError: () => {
        setLoading(false);
        showSnackbar({ message: t('invoices.errorUpdating') });
      },
    });

    track(`invoice-bulk-${action}::submitted`, {
      takerId: takerMarket.takerDivisionId,
      marketUuid: takerMarket.marketUuid,
    });
  };

  const dueDateFrom = urlState.filter?.dueDate ? urlState.filter?.dueDate[0].split(',')[0] : null;
  const dueDateTo = urlState.filter?.dueDate ? urlState.filter?.dueDate[0].split(',')[1] : null;
  const dueDatePickerValue = {
    startDate: urlState.filter?.dueDate && dueDateFrom ? dueDateFrom : new Date().toISOString().split('T')[0],
    endDate: urlState.filter?.dueDate && dueDateTo ? dueDateTo : new Date().toISOString().split('T')[0],
  };

  return (
    <div className="flex border-t">
      {/* FILTERS */}
      <div className="w-44 shrink-0 border-r lg:w-64">
        <div className="px-8 py-6">
          <div className="pb-6 font-medium">{t('core.filters')}</div>
          <div className="space-y-6">
            <div className="text-sm">
              <div className="pb-3 font-medium">{t('core.included')}</div>
              <div className="space-y-3">
                <Checkbox
                  checked={(urlState?.filter?.included ?? []).includes(true)}
                  color="primary"
                  id="included-true"
                  label={t('core.included')}
                  onChange={() => handleFilter({ key: 'included', value: true })}
                  value="true"
                />
                <Checkbox
                  checked={(urlState?.filter?.included ?? []).includes(false)}
                  color="primary"
                  id="included-false"
                  label={t('core.excluded')}
                  onChange={() => handleFilter({ key: 'included', value: false })}
                  value="false"
                />
              </div>
            </div>
            <div className="text-sm">
              <div className="pb-3 font-medium">{t('core.clearing')}</div>
              <div className="space-y-3">
                <Checkbox
                  checked={(urlState?.filter?.clearing ?? []).includes(true)}
                  color="primary"
                  id="clearing-true"
                  label={t('core.yes')}
                  onChange={() => handleFilter({ key: 'clearing', value: true })}
                  value="true"
                />
                <Checkbox
                  checked={(urlState?.filter?.clearing ?? []).includes(false)}
                  color="primary"
                  id="clearing-false"
                  label={t('core.no')}
                  onChange={() => handleFilter({ key: 'clearing', value: false })}
                  value="false"
                />
              </div>
            </div>
            <div className="text-sm">
              <div className="pb-3 font-medium">{t('core.daysPaidEarlyWithAbbreviation')}</div>
              <div className="space-y-3 lowercase">
                <Checkbox
                  checked={(urlState?.filter?.dpe ?? []).includes('0,9')}
                  color="primary"
                  id="0,9"
                  label={t('core.rangeFilterLowest', { max: `9 ${t('core.days')}` })}
                  onChange={() => handleFilter({ key: 'dpe', value: '0,9' })}
                  value="0,9"
                />
                <Checkbox
                  checked={(urlState?.filter?.dpe ?? []).includes('10,20')}
                  color="primary"
                  id="10,20"
                  label={t('core.rangeFilterMiddle', { min: `10 ${t('core.days')}`, max: `20 ${t('core.days')}` })}
                  onChange={() => handleFilter({ key: 'dpe', value: '10,20' })}
                  value="10,20"
                />
                <Checkbox
                  checked={(urlState?.filter?.dpe ?? []).includes('21,30')}
                  color="primary"
                  id="21,30"
                  label={t('core.rangeFilterMiddle', { min: `21 ${t('core.days')}`, max: `30 ${t('core.days')}` })}
                  onChange={() => handleFilter({ key: 'dpe', value: '21,30' })}
                  value="21,30"
                />
                <Checkbox
                  checked={(urlState?.filter?.dpe ?? []).includes('31,60')}
                  color="primary"
                  id="31,60"
                  label={t('core.rangeFilterMiddle', { min: `31 ${t('core.days')}`, max: `60 ${t('core.days')}` })}
                  onChange={() => handleFilter({ key: 'dpe', value: '31,60' })}
                  value="31,60"
                />
                <Checkbox
                  checked={(urlState?.filter?.dpe ?? []).includes('61,')}
                  color="primary"
                  id="61,"
                  label={t('core.rangeFilterHighest', { min: `61 ${t('core.days')}` })}
                  onChange={() => handleFilter({ key: 'dpe', value: '61,' })}
                  value="61,"
                />
              </div>
            </div>
            <div className="text-sm">
              <div className="pb-3 font-medium">{t('core.originalDueDate')}</div>
              <div className="space-y-3">
                <RangePicker
                  onChange={(range) =>
                    handleFilter({
                      key: 'dueDate',
                      value: `${range?.startDate},${range?.endDate}`,
                    })
                  }
                  onOpenChange={setShowDueDatePicker}
                  open={showDueDatePicker}
                  trigger={
                    urlState.filter?.dueDate ? (
                      <div className="text-left capitalize">
                        {dueDateFrom && (
                          <div>
                            {t('core.from')} {dueDateFrom}
                          </div>
                        )}
                        {dueDateTo && (
                          <div>
                            {t('core.to')} {dueDateTo}
                          </div>
                        )}
                      </div>
                    ) : (
                      t('core.selectDateRange')
                    )
                  }
                  value={dueDatePickerValue}
                />
                {urlState.filter?.dueDate && (
                  <button onClick={() => handleFilter({ key: 'dueDate', value: undefined })} type="button">
                    {t('core.clearDateRange')}
                  </button>
                )}
              </div>
            </div>
            <div className="text-sm">
              <div className="pb-3 font-medium">{t('core.eligibleInvoiceAmount')}</div>
              <div className="space-y-3">
                <Checkbox
                  color="primary"
                  id=",25000"
                  checked={(urlState?.filter?.amount ?? []).includes(',25000')}
                  onChange={() => handleFilter({ key: 'amount', value: ',25000' })}
                  label={t('core.rangeFilterLowest', { max: asCurrency(25000, takerMarket.currency) })}
                />
                <Checkbox
                  checked={(urlState?.filter?.amount ?? []).includes('25000,50000')}
                  color="primary"
                  id="25000,50000"
                  label={t('core.rangeFilterMiddle', {
                    min: asCurrency(25000, takerMarket.currency),
                    max: asCurrency(50000, takerMarket.currency),
                  })}
                  onChange={() => handleFilter({ key: 'amount', value: '25000,50000' })}
                  value="25000,50000"
                />
                <Checkbox
                  checked={(urlState?.filter?.amount ?? []).includes('50000,75000')}
                  color="primary"
                  id="50000,75000"
                  label={t('core.rangeFilterMiddle', {
                    min: asCurrency(50000, takerMarket.currency),
                    max: asCurrency(75000, takerMarket.currency),
                  })}
                  onChange={() => handleFilter({ key: 'amount', value: '50000,75000' })}
                  value="50000,75000"
                />
                <Checkbox
                  checked={(urlState?.filter?.amount ?? []).includes('75000,')}
                  color="primary"
                  id="75000,"
                  label={t('core.rangeFilterHighest', { min: asCurrency(75000, takerMarket.currency) })}
                  onChange={() => handleFilter({ key: 'amount', value: '75000,' })}
                  value="75000,"
                />
                {/* <Checkbox label="Custom Range" color="primary" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* TABLE */}
      <div className="relative flex w-full flex-col overflow-hidden">
        <QueryBoundaries
          ErrorComponent={() => (
            <NoDataState
              icon={<TechnicalDifficultyIcon className="h-24 w-24" />}
              title={t('invoices.errorLoadingInvoices')}
              message={t('invoices.errorLoadingInvoicesDescription')}
              action={
                <Button size="sm" onClick={() => window.location.reload()}>
                  {t('somethingsWrong.cta1Heading')}
                </Button>
              }
            />
          )}
          LoadingComponent={() => (
            <NoDataState
              icon={<img src={invoices} alt="invoices" className="h-28 w-28" />}
              message={t('invoices.fetchingEligibleInvoices')}
            />
          )}
        >
          <EligibleInvoiceTable
            handleInvoiceSelect={handleInvoiceSelect}
            handleSearch={handleSearch}
            handleSort={handleSort}
            options={urlState}
            pagination={{
              currentPage: urlState.page ?? 1,
              onNextChange: handleNextChange,
              onPageSizeChange: (e) => handlePageSizeChange(e),
              onPrevChange: handlePrevChange,
              pageSize: urlState.limit ?? 100,
            }}
            selectedInvoiceDispatch={selectedInvoiceDispatch}
            selectedInvoiceState={selectedInvoiceState}
            sort={sort}
            takerMarket={takerMarket}
            toggleBulkInvoiceInclusionExclusion={toggleBulkInvoiceInclusionExclusion}
            toggleInvoiceInclusionExclusion={toggleInvoiceInclusionExclusion}
          />
        </QueryBoundaries>
      </div>
    </div>
  );
};

export default EligibleInvoices;

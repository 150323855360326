import { useCallback, useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import useUserDetails from '@/data/useUserDetails';
import { useToken } from '@/utils/token';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercomSettings: any;
  }
}

function createScript() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://widget.intercom.io/widget/dn0blqde';
  return script;
}

const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;

// See the tracking guide for more information on how to test locally.
export function useIntercom() {
  useEffectOnce(() => {
    if (INTERCOM_APP_ID) {
      const script = createScript();
      document.head.appendChild(script);
    }
  });

  const { Intercom, intercomSettings } = window;

  useEffect(() => {
    if (typeof Intercom === 'function') {
      Intercom('reattach_activator');
      Intercom('update', intercomSettings);
    }
  }, [Intercom, intercomSettings]);

  const setIntercomSettings = useCallback(
    (settings: Record<string, unknown>) => {
      window.intercomSettings = settings;
      if (Intercom) {
        Intercom('update', settings);
      }
    },
    [Intercom]
  );

  const openChat = useCallback(() => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  }, []);

  return {
    Intercom,
    intercomSettings,
    setIntercomSettings,
    openChat,
  };
}

export default function Intercom() {
  const { tokenContent } = useToken();
  const { data: user } = useUserDetails(({ me }) => ({
    name: `${me?.personalInfo?.firstName} ${me?.personalInfo?.lastName}`,
    email: me?.emailAddress,
    created: me?.created,
    userHash: me?.intercomUserId,
  }));

  const authServiceUuid = tokenContent?.payload?.user?.uuid ?? '';

  const { setIntercomSettings } = useIntercom();

  useEffect(() => {
    if (user) {
      const { email, userHash } = user;
      setIntercomSettings({
        api_base: 'https://api-iam.intercom.io',
        app_id: INTERCOM_APP_ID,
        email,
        user_id: tokenContent?.payload.user.uuid,
        user_hash: userHash,
        experience: import.meta.env.VITE_APP_NAME,
      });
    }
  }, [user, authServiceUuid, setIntercomSettings, tokenContent?.payload.user.uuid]);

  return null;
}

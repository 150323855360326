import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, cn, useSnackbar } from '@c2fo/liquidity';
import useCreateRequestedCustomers from '@/data/useCreateRequestedCustomers';
import { useReporting } from '@/reporting';

const RequestCustomerModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const [customerNamesValue, setCustomerNamesValue] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const { mutateAsync: createRequestedCustomers, isLoading } = useCreateRequestedCustomers();

  const handleClose = () => {
    setCustomerNamesValue('');
    setHasError(false);
    onClose();
  };

  const onSubmit = async () => {
    const customerNames = customerNamesValue.split(',');

    if (!customerNames.filter((name) => name).length) {
      setHasError(true);
      return;
    }

    await createRequestedCustomers(
      { customerNames },
      {
        onSuccess: () => {
          showSnackbar({ message: t('taker.marketSearch.customerRequestDialog.toastSuccess') });

          handleClose();

          track('market-search::submitted-customers-request', {
            numRequested: customerNames.length,
          });
        },
        onError: () => showSnackbar({ message: t('taker.marketSearch.customerRequestDialog.toastFailure') }),
      }
    );
  };

  return (
    <Modal open={open} onClose={handleClose} variant="default">
      <Modal.Title className="capitalize">{t('taker.marketSearch.customerRequestDialog.requestCustomers')}</Modal.Title>
      <div className="m-6 space-y-4 text-base sm:min-w-[500px]">
        <div>{t('taker.marketSearch.customerRequestDialog.dontSeeTitle')}</div>
        <div className="flex flex-col">
          <label className="mb-2">{t('taker.marketSearch.customerRequestDialog.inputPlaceholder')}</label>
          <input
            className={cn('h-10 rounded-md border px-2 focus-visible:outline-secondary-600', {
              'border-error-500 focus-visible:outline-error-500': hasError,
            })}
            onChange={(e) => {
              if (hasError) {
                setHasError(false);
              }

              setCustomerNamesValue(e.target.value);
            }}
            type="text"
            value={customerNamesValue}
          />
        </div>
      </div>
      <Modal.Actions className="gap-2">
        <Button disabled={isLoading} onClick={handleClose} variant="ancillary">
          {t('core.cancel')}
        </Button>
        <Button loading={isLoading} onClick={onSubmit} variant="primary">
          {t('core.submit')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default RequestCustomerModal;

const PreferredLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181.85 103.02">
      <title>c2fo-preferred-logo</title>
      <path
        fill="#06a76c"
        strokeWidth="0px"
        d="M34.45,28.49c-.19-.37-.37-.56-.74-.56h-10.8c-5.77,0-10.61-4.28-11.36-10.06v-.19h.19c1.49-.56,2.98-.93,4.47-.93h11.92c.74,0,1.49-.37,1.86-1.12L38.17,1.3c.19-.37.19-.74,0-.93s-.37-.37-.74-.37h-14.34c-4.1,0-8.01,1.49-10.99,4.28-2.98,2.79-4.84,6.52-5.21,10.43h0C2.61,17.69,0,22.72,0,27.93c0,8.94,7.26,16.39,16.39,16.39h8.19c.74,0,1.49-.37,1.86-1.12l8.19-14.15s0-.19-.19-.56ZM16.2,39.85c-3.54,0-6.89-1.68-9.12-4.47-2.23-2.79-2.98-6.52-1.86-10.06.37-1.3.93-2.61,2.05-3.91l.19-.19v.19c.93,3.35,2.98,6.14,5.77,8.19s6.14,3.17,9.68,3.17h2.79c.19,0,.37.19.56.37.19.19.19.37,0,.56l-2.98,5.21c-.19.37-.74.74-1.3.74l-5.77.19ZM12.29,12.48c1.68-4.47,5.96-7.26,10.61-7.26h6.52c.19,0,.37.19.56.37.19.19.19.37,0,.56l-2.98,5.4c-.19.37-.74.74-1.3.74h-9.5c-1.3,0-2.42.19-3.72.37h-.19v-.19Z"
      />
      <path
        fill="#393939"
        strokeWidth="0px"
        d="M50.65,27.93c-4.1,0-7.82,1.3-10.43,3.72-3.17,2.79-4.66,7.08-4.66,12.48s1.68,9.5,4.66,12.29c2.61,2.42,6.33,3.72,10.43,3.72,3.35,0,11.54-.93,13.41-10.61,0-.19,0-.37-.19-.56-.19-.19-.37-.19-.56-.19h-4.28c-.37,0-.56.19-.74.56-1.12,3.72-3.72,5.96-7.63,5.96-5.77,0-9.12-4.47-9.12-11.17,0-3.17.74-6.33,2.42-8.19,1.68-2.05,3.91-2.98,6.89-2.98,3.91,0,6.7,2.42,7.63,5.96,0,.37.37.56.74.56h4.28c.19,0,.37,0,.56-.19.19-.19.19-.37.19-.56-2.05-9.31-9.31-10.8-13.59-10.8Z"
      />
      <path
        fill="#393939"
        strokeWidth="0px"
        d="M109.86,21.04v-4.1c0-.19-.19-.37-.37-.37h-4.1c-5.77,0-9.12,3.35-9.12,8.94v2.79h-4.1c-.19,0-.56.19-.56.56v3.72q0,.19.19.37s.19.19.37.19h4.1v26.63c0,.19.19.56.56.56h5.4c.19,0,.56-.19.56-.56v-26.63h4.66c.19,0,.56-.19.56-.56v-3.72c0-.19-.19-.56-.56-.56h-4.84v-1.86c0-3.35,1.49-5.03,4.66-5.03h2.23c.19,0,.37-.19.37-.37Z"
      />
      <path
        fill="#393939"
        strokeWidth="0px"
        d="M133.51,56.42c2.98-2.79,4.66-7.08,4.66-12.29s-1.68-9.5-4.66-12.29c-2.61-2.42-6.33-3.91-10.43-3.91-6.89,0-15.08,4.28-15.08,16.01,0,5.4,1.68,9.68,5.03,12.48,2.61,2.23,6.33,3.54,10.24,3.54,3.91.37,7.45-1.12,10.24-3.54ZM116.57,52.7c-1.86-1.86-2.61-5.03-2.61-8.57s.93-6.7,2.61-8.57c1.68-1.68,3.91-2.61,6.52-2.61s4.84.93,6.52,2.61c1.86,1.86,2.61,5.03,2.61,8.57s-.93,6.7-2.61,8.57c-1.68,1.68-3.91,2.61-6.52,2.61-2.61.19-4.84-.74-6.52-2.61Z"
      />
      <path
        fill="#393939"
        strokeWidth="0px"
        d="M67.59,59.96h21.04c.37,0,.74-.37.74-.74v-3.17c0-.37-.37-.74-.74-.74h-14.9v-.74c0-2.42,2.05-3.91,6.89-6.7l.37-.19c3.72-2.05,8.57-4.66,8.57-10.61s-4.1-9.12-10.99-9.12c-4.28,0-7.45,1.3-9.31,3.91-1.49,2.23-1.86,4.84-1.86,6.14v.56c0,.37.37.74.74.74h4.28c.37,0,.74-.37.74-.74v-.37c0-3.35,2.05-5.59,5.21-5.59,1.68,0,3.17.56,4.1,1.49.74.74,1.12,1.86,1.12,3.35,0,3.54-2.98,5.03-6.52,7.08-.56.19-1.12.56-1.49.93-7.08,4.1-8.57,7.82-8.57,13.41v.37c-.19.37.19.74.56.74Z"
      />
      <path fill="#393939" strokeWidth="0px" d="M137.79,30.35h-.93v2.23h-.37v-2.23h-.74v-.37h2.05v.37h0Z" />
      <path
        fill="#393939"
        strokeWidth="0px"
        d="M138.72,29.98l.74,2.05.74-2.05h.56v2.61h-.37v-2.05l-.74,2.05h-.37l-.74-2.05v2.05h-.37v-2.61h.56Z"
      />
      <rect fill="#eb6d00" strokeWidth="0px" x="35.97" y="70.62" width="145.88" height="32.4" rx="4.7" ry="4.7" />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M51.59,89.72v5.92h-3.46v-16.81h6.56c1.26,0,2.37.23,3.33.69.96.46,1.69,1.12,2.21,1.97s.77,1.82.77,2.9c0,1.65-.56,2.95-1.69,3.9-1.13.95-2.69,1.43-4.68,1.43h-3.04ZM51.59,86.92h3.09c.92,0,1.61-.22,2.1-.65.48-.43.72-1.05.72-1.85s-.24-1.49-.73-2c-.48-.51-1.15-.77-2.01-.78h-3.18v5.28Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M69.25,89.49h-2.76v6.16h-3.46v-16.81h6.25c1.99,0,3.52.44,4.6,1.33s1.62,2.14,1.62,3.75c0,1.15-.25,2.1-.74,2.87-.5.77-1.25,1.38-2.26,1.83l3.64,6.87v.16h-3.72l-3.15-6.16ZM66.49,86.69h2.79c.87,0,1.54-.22,2.02-.66s.72-1.05.72-1.83-.22-1.42-.68-1.87c-.45-.45-1.14-.68-2.07-.68h-2.78v5.05Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M87.88,88.36h-6.65v4.5h7.81v2.78h-11.27v-16.81h11.25v2.81h-7.78v4.01h6.65v2.71Z"
      />
      <path fill="#fff" strokeWidth="0px" d="M100.83,88.78h-6.65v6.87h-3.46v-16.81h10.95v2.81h-7.48v4.34h6.65v2.79Z" />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M113.43,88.36h-6.65v4.5h7.81v2.78h-11.27v-16.81h11.25v2.81h-7.78v4.01h6.65v2.71Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M122.49,89.49h-2.76v6.16h-3.46v-16.81h6.25c1.99,0,3.52.44,4.6,1.33,1.08.89,1.62,2.14,1.62,3.75,0,1.15-.25,2.1-.74,2.87-.5.77-1.25,1.38-2.26,1.83l3.64,6.87v.16h-3.72l-3.15-6.16ZM119.73,86.69h2.79c.87,0,1.54-.22,2.02-.66s.72-1.05.72-1.83-.22-1.42-.68-1.87c-.45-.45-1.14-.68-2.07-.68h-2.78v5.05Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M137.22,89.49h-2.76v6.16h-3.46v-16.81h6.25c1.99,0,3.52.44,4.6,1.33s1.62,2.14,1.62,3.75c0,1.15-.25,2.1-.74,2.87-.5.77-1.25,1.38-2.26,1.83l3.64,6.87v.16h-3.72l-3.15-6.16ZM134.46,86.69h2.79c.87,0,1.54-.22,2.02-.66s.72-1.05.72-1.83-.22-1.42-.68-1.87c-.45-.45-1.14-.68-2.07-.68h-2.78v5.05Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M155.85,88.36h-6.65v4.5h7.81v2.78h-11.27v-16.81h11.25v2.81h-7.78v4.01h6.65v2.71Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M158.69,95.65v-16.81h5.17c1.48,0,2.8.33,3.97,1,1.17.66,2.08,1.61,2.73,2.84.65,1.23.98,2.62.98,4.19v.77c0,1.56-.32,2.95-.96,4.17-.64,1.22-1.55,2.16-2.72,2.83-1.17.67-2.49,1.01-3.96,1.02h-5.21ZM162.15,81.64v11.22h1.67c1.35,0,2.39-.44,3.11-1.33s1.08-2.15,1.1-3.8v-.89c0-1.71-.35-3-1.06-3.88-.71-.88-1.74-1.32-3.11-1.32h-1.71Z"
      />
    </svg>
  );
};

export default PreferredLogo;

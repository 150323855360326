import { useReducer } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, cn } from '@c2fo/liquidity';
import useCreateRecurringRule, { RecurringRuleArgument } from '@/data/useCreateRecurringRule';
import { TakerMarket } from '@/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import CategorySelect from './components/CategorySelect';
import CreateAnother from './components/CreateAnother';
import CriteriaSelect from './components/CriteriaSelect';
import DivisionSelectTable from './components/DivisionSelectTable';
import RuleReview from './components/ReviewRule';
import groupRulesByMakerOrg from './utils/groupRulesByMakerOrg';
import { getInitialState, recurringRulesFormReducer } from './utils/recurringRulesFormReducer';

interface RecurringRulesFormProps {
  /* a list of taker markets to render within the division select table */
  takerMarkets: TakerMarket[];
  /* default values to pre-populate the form with */
  defaultValues?: Partial<RecurringRuleFormValues>;
  /* whether or not to show the division select table */
  showDivisionSelect?: boolean;
  /* the type of taker market group */
  type?: TakerMarketGroupType;
}

export type RecurringRuleCategory = 'dpe' | 'invoiceId' | 'amount' | 'dueDate';

export type RecurringRuleCriteria =
  | 'minDpe'
  | 'maxDpe'
  | 'minDueDate'
  | 'maxDueDate'
  | 'minInvoiceAmount'
  | 'maxInvoiceAmount'
  | 'excludedVoucherIds';

export interface RecurringRuleFormValues {
  category: RecurringRuleCategory;
  criteria: {
    type: RecurringRuleCriteria;
    value: string;
  }[];
  takerMarkets: TakerMarket[];
}

export const defaultRuleValues: Record<RecurringRuleFormValues['category'], RecurringRuleFormValues> = {
  amount: {
    category: 'amount',
    criteria: [{ type: 'minInvoiceAmount', value: '' }],
    takerMarkets: [],
  },
  dpe: {
    category: 'dpe',
    criteria: [{ type: 'minDpe', value: '' }],
    takerMarkets: [],
  },
  dueDate: {
    category: 'dueDate',
    criteria: [{ type: 'minDueDate', value: '' }],
    takerMarkets: [],
  },
  invoiceId: {
    category: 'invoiceId',
    criteria: [{ type: 'excludedVoucherIds', value: '' }],
    takerMarkets: [],
  },
};

/**
 * initial form values default to days paid early
 * if a category is provided, the default values will be set to the category
 */
const getInitialFormValues = (defaultValues?: Partial<RecurringRuleFormValues>) => {
  return {
    defaultValues: {
      ...(defaultValues?.category ? defaultRuleValues[defaultValues?.category] : defaultRuleValues['dpe']),
      ...defaultValues,
    },
  };
};

const RecurringRulesForm = ({
  defaultValues,
  showDivisionSelect = true,
  takerMarkets,
  type,
}: RecurringRulesFormProps) => {
  const { mutateAsync: createRecurringRule } = useCreateRecurringRule();
  const initialState = getInitialState(defaultValues?.category);
  const [state, dispatch] = useReducer(recurringRulesFormReducer, initialState);
  const initialFormValues = getInitialFormValues(defaultValues);
  const methods = useForm<RecurringRuleFormValues>(initialFormValues);

  const selectedTakerMarkets: RecurringRuleFormValues['takerMarkets'] = methods.watch('takerMarkets');

  const onTakerMarketTableSelect = (takerMarkets: TakerMarket[]) => {
    methods.setValue('takerMarkets', takerMarkets);
  };

  const onSubmit: SubmitHandler<RecurringRuleFormValues> = async (data) => {
    // map rules to maker org
    const mappedRulesToMaketOrg = groupRulesByMakerOrg(data);
    const createRuleArguments: RecurringRuleArgument[] = Object.entries(mappedRulesToMaketOrg).flatMap(
      ([makerOrganizationUuid, ruleArgument]) => {
        return {
          makerOrganizationUuid,
          ...ruleArgument,
        };
      }
    );

    const successes: typeof createRuleArguments = [];
    const errors: typeof createRuleArguments = [];

    // loop through each argument
    // Promise.allSettled() is used to keep track of which promises resolve/reject
    await Promise.allSettled(
      createRuleArguments.map(async (argument) => {
        try {
          await createRecurringRule(argument);
          successes.push(argument);
        } catch {
          errors.push(argument);
        }
      })
    );

    // send to create another if there are no errors
    dispatch({ type: 'setDisplay', payload: { display: 'createAnother' } });
  };

  return (
    <div
      className={cn('w-full p-1', {
        'w-[550px]': state.currentStep !== 'division', // both width class names may be temporary until we have integrated with a modal component
        'w-[982px]': state.currentStep === 'division',
      })}
    >
      {state.display === 'form' && (
        <FormProvider {...methods}>
          <form className="space-y-6" onSubmit={methods.handleSubmit(onSubmit)}>
            {/* step 1 - category select */}
            {state.currentStep === 'category' && (
              <CategorySelect advanceStep={() => dispatch({ type: 'stepForward' })} />
            )}
            {/* step 2 - division select */}
            {state.currentStep === 'division' && (
              <>
                <CriteriaSelect />
                {showDivisionSelect && (
                  <DivisionSelectTable
                    onSelect={onTakerMarketTableSelect}
                    selectedTakerMarkets={selectedTakerMarkets}
                    takerMarkets={takerMarkets}
                    type={type}
                  />
                )}
              </>
            )}
            {/* step 3 - review */}
            {state.currentStep === 'review' && <RuleReview />}
            {/* actions */}
            {state.currentStep !== 'category' && (
              <div className="flex gap-2">
                {state.availableSteps.indexOf(state.currentStep) !== 0 && (
                  <Button onClick={() => dispatch({ type: 'stepBack' })} variant="secondary">
                    Back
                  </Button>
                )}
                {state.currentStep !== 'review' && (
                  <Button onClick={() => dispatch({ type: 'stepForward' })}>Next</Button>
                )}
                {state.currentStep === 'review' && (
                  <Button type="submit" variant="cta">
                    Apply Rule
                  </Button>
                )}
              </div>
            )}
          </form>
        </FormProvider>
      )}
      {/* create another */}
      {state.display === 'createAnother' && (
        <>
          <CreateAnother />
          <div className="pt-6">
            <Button variant="secondary">Close</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default RecurringRulesForm;

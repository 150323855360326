import { TakerMarket } from '@/data/useTakerMarkets';
import { RecurringRuleCriteria, RecurringRuleFormValues } from '../RecurringRulesForm';

/**
 * utility function for grouping rules data submitted via recurring rules form by maker organization
 */

type RuleRecord = Record<RecurringRuleCriteria, string>;

interface RuleByMakerOrg {
  [makerOrganizationUuid: string]: {
    takerMarkets: { marketId: number; takerId: number }[];
  } & Partial<RuleRecord>;
}

const groupRulesByMakerOrg = (data: RecurringRuleFormValues): RuleByMakerOrg =>
  data.takerMarkets.reduce(
    (rulesByMakerOrg: RuleByMakerOrg, { makerOrganizationUuid, takerDivisionId, legacyMarketId }: TakerMarket) => {
      const takerMarketMeta = {
        marketId: legacyMarketId,
        takerId: takerDivisionId,
      };
      // map criteria from form values to rule format for backend
      const ruleMeta = data.criteria.reduce(
        (ruleRecord: RuleRecord, formCriteria: RecurringRuleFormValues['criteria'][0]) => {
          ruleRecord[formCriteria.type] = formCriteria.value;
          return ruleRecord;
        },
        {} as RuleRecord
      );

      const makerOrganizationUuidFound = Object.keys(rulesByMakerOrg).includes(makerOrganizationUuid);

      if (makerOrganizationUuidFound) {
        rulesByMakerOrg[makerOrganizationUuid].takerMarkets.push(takerMarketMeta);
      } else {
        rulesByMakerOrg[makerOrganizationUuid] = {
          takerMarkets: [takerMarketMeta],
          ...ruleMeta,
        };
      }

      return rulesByMakerOrg;
    },
    {}
  );

export default groupRulesByMakerOrg;

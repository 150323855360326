import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import apiClient from '@/lib/apiClient';

// https://fss-gateway.c2fo.com/
// api/c2fo/taker/3563184/market/9e6db030-3f8c-4daf-89da-2a99d3f0e7c3/invoices/eligible
// ?limit=100&order=amount&orderDirection=desc&page=1

export interface EligibleInvoice {
  id: number;
  adjustmentAmount?: number;
  amount: number;
  companyId: string;
  created: string;
  currency: string;
  daysPaidEarly: number;
  dueDate: string;
  earn: number;
  invoiceId: string;
  isInCashPool: boolean;
  marketId: number;
  minDiscount: number;
  originalAmount: number;
  takerExcludedForToday: boolean;
  takerExcluded: boolean;
  takerId: number;
  voucherId: string;
  earlyPayDepositDate: string;
  originalDepositDate: string;
  coversUnmatched: boolean;
  legacyMarketId: number;
  marketUuid: string;
  payDate: string;
  vendorName: string;
  makerOrganizationUuid: string;
  companyName: string;
  adjustmentSettings: {
    skipUnmatched: boolean;
    unmatchedDPERange: number;
    ignoreToleranceIfInvoicesPastDue: boolean;
    removeUserExclusionsIfInvoiceUsedToCover: boolean;
  };
}

type EligibleInvoiceAmount = ',25000' | '25000,50000' | '50000,75000' | '75000,';
type EligibleInvoiceDpe = '0,9' | '10,20' | '21,30' | '31,60' | '61,';
export type EligibleInvoiceOrder =
  | 'takerExcluded'
  | 'isInCashPool'
  | 'voucherId'
  | 'dueDate'
  | 'daysPaidEarly'
  | 'amount'
  | 'minDiscount'
  | 'earn';

export interface EligibleInvoiceFetchFilters {
  included?: boolean[]; // takerExcluded
  clearing?: boolean[]; // isInCashPool
  dpe?: EligibleInvoiceDpe[]; // daysPaidEarly
  amount?: EligibleInvoiceAmount[];
  dueDate?: string[];
  search?: string; // invoiceId
}

export interface EligibleInvoiceFetchOptions {
  limit: number;
  order: EligibleInvoiceOrder;
  orderDirection: 'asc' | 'desc';
  page: number;
  filter?: EligibleInvoiceFetchFilters;
}

export const buildEligibleInvoiceFetchFilter = (filterOptions: EligibleInvoiceFetchFilters) => {
  if (!filterOptions) {
    return;
  }

  const filter = [];

  if (filterOptions.included) {
    if (
      filterOptions.included.length !== 2 ||
      (!filterOptions.included.includes(true) && !filterOptions.included.includes(false))
    ) {
      // !taker excluded means the invoice is included in offer
      filter.push([{ takerExcluded: !filterOptions.included[0] }]);
    }
  }

  if (filterOptions.clearing) {
    if (
      filterOptions.clearing.length !== 2 ||
      (!filterOptions.clearing.includes(true) && !filterOptions.clearing.includes(false))
    ) {
      filter.push([{ isInCashPool: filterOptions.clearing[0] }]);
    }
  }

  if (filterOptions.dpe) {
    const dpeRange: { daysPaidEarly: { from?: number; to?: number } }[] = [];

    filterOptions.dpe.forEach((o) => {
      const split = o.split(',');
      const range = {
        ...(split[0] && { from: Number(split[0]) }),
        ...(split[1] && { to: Number(split[1]) }),
      };
      dpeRange.push({ daysPaidEarly: range });
    });

    filter.push(dpeRange);
  }

  if (filterOptions.amount) {
    const amountRange: { amount: { from?: number; to?: number } }[] = [];

    filterOptions.amount.forEach((o) => {
      const split = o.split(',');
      const range = {
        ...(split[0] && { from: Number(split[0]) }),
        ...(split[1] && { to: Number(split[1]) }),
      };
      amountRange.push({ amount: range });
    });

    filter.push(amountRange);
  }

  if (filterOptions.dueDate) {
    const dueDateRange: { dueDate: { from?: string; to?: string } }[] = [];

    const split = filterOptions.dueDate[0].split(',');
    const range = {
      ...(split[0] && { from: split[0] }),
      ...(split[1] && { to: split[1] }),
    };
    dueDateRange.push({ dueDate: range });

    filter.push(dueDateRange);
  }

  if (filterOptions.search) {
    filter.push([{ invoiceId: filterOptions.search }]);
  }

  if (!filter.length) {
    return;
  }

  return { filter };
};

const fetchEligibleInvoices = async ({
  marketId,
  takerId,
  options,
}: {
  marketId: string;
  takerId: number;
  options: Partial<EligibleInvoiceFetchOptions>;
}): Promise<EligibleInvoice[]> => {
  const params = qs.stringify({
    limit: options.limit ?? 100,
    order: options.order ?? 'amount',
    orderDirection: options.orderDirection ?? 'desc',
    page: options.page ?? 1,
    ...(options.filter && buildEligibleInvoiceFetchFilter(options.filter)),
  });

  const data = await apiClient
    .get(`api/c2fo/taker/${takerId}/market/${marketId}/invoices/eligible?${params}`)
    .json<{ data: EligibleInvoice[] }>();

  return data?.data || [];
};

const useEligibleInvoices = <TData = EligibleInvoice[]>(
  params: {
    marketId: string;
    takerId: number;
    options: Partial<EligibleInvoiceFetchOptions>;
  },
  select?: (data: EligibleInvoice[]) => TData
) => {
  const { marketId, takerId, options } = params;

  return useQuery({
    enabled: !!marketId && !!takerId,
    keepPreviousData: true,
    queryKey: ['eligible-invoices', marketId, takerId, options],
    queryFn: () =>
      fetchEligibleInvoices({
        marketId,
        takerId,
        options,
      }),
    select,
  });
};

export default useEligibleInvoices;

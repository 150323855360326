import { useTranslation } from 'react-i18next';
import { Switch, Tooltip, TooltipContentProps } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { LockAltIcon } from '@c2fo/liquidity/icons';

const PreferredParticipationToggle = ({ variant = 'light' }: Pick<TooltipContentProps, 'variant'>) => {
  const { t } = useTranslation();

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <span className="inline-block">
          <Switch
            accessibilityLabel={t('core.toggleParticipation')}
            checked
            disabled
            checkedColor="success-500"
            size="large"
          >
            {(checked) => checked && <LockAltIcon fill={colors.success[500]} className="h-full w-full p-1" />}
          </Switch>
        </span>
      </Tooltip.Trigger>
      <Tooltip.Content arrow side="bottom" variant={variant}>
        {t('takerMarketTabs.preferredRate.switchTooltip')}
      </Tooltip.Content>
    </Tooltip>
  );
};

export default PreferredParticipationToggle;

import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { Alert, Button } from '@c2fo/liquidity';
import { EnterpriseOfferType } from '@/generated/gql/graphql';
import successSvg from '@/assets/success.svg?url';
import Modal from '@/components/Modal';
import { useConfirmAgreementsWhenRequired } from '@/data/useAgreements';
import useDraftOffer from '@/data/useDraftOffer';
import useSubmitOfferFromDraft from '@/data/useSubmitOfferFromDraft';
import { Agreement } from '@/features/agreements/Agreement';
import { useReporting } from '@/reporting';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import { DraftOfferProps } from '../common/DraftOffer.types';
import { DraftOfferTemplate } from '../common/DraftOfferTemplate';
import { useTakerMarketsInDraftOffer } from '../common/useTakerMarketsInDraftOffer';

export const DraftOfferPriceDiscovery = ({ draftOfferUuid }: DraftOfferProps) => {
  const { data: draftOfferData } = useDraftOffer(draftOfferUuid);
  const { takerMarkets: takerMarketsInOffer, stats } = useTakerMarketsInDraftOffer(draftOfferUuid);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const { track } = useReporting();
  const { mutateAsync: submitDraftOffer, isLoading: isSubmittingDraftOffer } = useSubmitOfferFromDraft(draftOfferUuid);
  const { validateAndConfirmAgreementsConditionally } = useConfirmAgreementsWhenRequired(takerMarketsInOffer);
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  const [isAgreementsErrorNotChecked, setIsAgreementsErrorNotChecked] = useState(false);

  const draftOfferTrackEventData: {
    draftOfferUuid: string;
    type: EnterpriseOfferType;
  } = {
    draftOfferUuid,
    type: 'PRICE_DISCOVERY',
  };

  useEffectOnce(() => {
    track('draft-offer::viewed', draftOfferTrackEventData);
  });

  const todayDetailsOfferRowContent: { title: ReactNode; content: ReactNode }[] = [
    {
      title: t('draftOffer.labels.totalAr'),
      content: stats.formattedEligibleInvoiceAmount,
    },
    {
      title: t('draftOffer.labels.yourRateAndDiscount'),
      content: (
        <span className="block uppercase">
          {stats.formattedRate} (-{stats.formattedDiscountAmount})
        </span>
      ),
    },
    {
      title: t('draftOffer.labels.estimatedDeposit'),
      content: stats.formattedEstimatedDepositAmount,
    },
    {
      title: t('draftOffer.labels.estimatedDepositDate'),
      content: stats.estimatedDepositDate,
    },
    {
      title: t('draftOffer.labels.invoicesIncluded'),
      content: stats.eligibleInvoiceCount,
    },
  ];

  const ongoingOfferRowContent: { title: ReactNode; content: ReactNode }[] = [
    {
      title: t('draftOffer.labels.offerType'),
      content: t('core.nameYourRate'),
    },
    {
      title: t('draftOffer.labels.customer'),
      content: takerMarketsInOffer.length ? (
        <span className="flex flex-col gap-3">
          {takerMarketsInOffer.map((tm) => (
            <span key={tm.id}>
              <span className="block text-right">{tm.makerDivisionName}</span>
              <span className="block text-right text-xs font-normal">{getTakerMarketDivisionTitle(tm)?.title}</span>
            </span>
          ))}
        </span>
      ) : (
        t('core.na')
      ),
    },
    {
      title: t('draftOffer.labels.rate'),
      content: <span className="uppercase">{stats.formattedRate}</span>,
    },
  ];

  const onCancel = () => {
    track('draft-offer::cancel', draftOfferTrackEventData);
    navigate('/supplier/markets');
  };

  const onSubmit = async () => {
    try {
      setIsSubmitError(false);

      track('draft-offer::submit', draftOfferTrackEventData);

      if (!draftOfferUuid || !draftOfferData) {
        throw new Error('Not enough draft offer data available to submit the offer.');
      }

      try {
        await validateAndConfirmAgreementsConditionally({ isAgreementsChecked });
      } catch {
        setIsAgreementsErrorNotChecked(true);
        return;
      }

      await submitDraftOffer();

      setIsSuccessModalOpen(true);

      track('draft-offer::submit::success', draftOfferTrackEventData);
    } catch (error) {
      console.error(error);
      setIsSubmitError(true);
      track('draft-offer::submit::error', {
        ...draftOfferTrackEventData,
        errorMessage: error instanceof Error ? error.message : 'Unknown error',
      });
    }
  };

  const onViewDetails = () => {
    track('draft-offer::view-details', draftOfferTrackEventData);
    navigate('/supplier/markets');
  };

  return (
    <div>
      <DraftOfferTemplate
        cardSlot={
          <div className="space-y-8">
            <div className="space-y-4">
              <div className="space-y-2">
                <p className="text-base font-bold ">{t('draftOffer.todaysDetails')}</p>
                <p className="text-sm text-secondary-300">{t('draftOffer.reviewCurrentInvoices')}</p>
              </div>
              <div className="divide-y divide-gray-300 rounded-lg bg-gray-100">
                {todayDetailsOfferRowContent.map((rowData, i) => (
                  <div key={i} className="flex items-center justify-between gap-4 p-4">
                    <p className="text-sm">{rowData.title}</p>
                    <p className="text-sm font-semibold">{rowData.content}</p>
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="space-y-4">
              <div className="space-y-2">
                <p className="text-base font-bold ">{t('draftOffer.ongoingOffer')}</p>
                <p className="text-sm text-secondary-300">{t('draftOffer.ongoingOfferDescription')}</p>
              </div>
              <div className="divide-y divide-gray-300 rounded-lg bg-gray-100">
                {ongoingOfferRowContent.map((rowData, i) => (
                  <div key={i} className="flex items-center justify-between gap-4 p-4">
                    <p className="text-sm">{rowData.title}</p>
                    <p className="text-sm font-semibold">{rowData.content}</p>
                  </div>
                ))}
              </div>
              {isSubmitError && <Alert type="error" variant="filled" description={t('draftOffer.error')} full />}
              {isAgreementsErrorNotChecked && (
                <Alert type="error" variant="filled" description={t('agreements.error.checkbox')} full />
              )}
              <Agreement
                takersMarkets={takerMarketsInOffer}
                onChange={(v) => {
                  setIsAgreementsChecked(v);
                  setIsAgreementsErrorNotChecked(false);
                }}
              />
            </div>
          </div>
        }
        actionSlot={
          <>
            <Button className="w-1/3" onClick={onCancel} variant="secondary">
              {t('core.cancel')}
            </Button>
            <Button
              className="w-1/3"
              onClick={onSubmit}
              variant="cta"
              disabled={isSubmittingDraftOffer}
              loading={isSubmittingDraftOffer}
            >
              {t('draftOffer.submit')}
            </Button>
          </>
        }
      />

      {/* Success Modal */}
      <Modal
        className="w-fit"
        fullWidthButtons
        open={isSuccessModalOpen}
        title={t('draftOffer.success.title')}
        variant="primary"
        okText={t('draftOffer.viewDetails')}
        onOk={onViewDetails}
        okButtonVariant="primary"
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <img src={successSvg} alt="success" />
          <p className="max-w-xs text-center text-base">{t('draftOffer.success.body')}</p>
        </div>
      </Modal>
    </div>
  );
};

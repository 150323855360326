import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@c2fo/liquidity';
import { TakerMarket } from '@/data/useTakerMarkets';
import { parseIsoDateStringToDateCorrectTz } from '@/utils/parseIsoDateStringToDate';
import { usePreferredOffersNearTermEnd } from '@/utils/preferredOfferRenewal';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import RenewalRequestModal from './RenewalRequestModal';

const PreferredAlertBanner = ({ takerMarket }: { takerMarket?: TakerMarket }) => {
  const isMarketDetail = !!takerMarket;
  const { t } = useTranslation();
  const { asDateString } = useLocaleFormat();
  const { takerMarketsNearTermEnd } = usePreferredOffersNearTermEnd();
  const [openModal, setOpenModal] = useState(false);
  const soonestEndDateString = takerMarketsNearTermEnd.reduce((earliestDate, takerMarket) => {
    if (!takerMarket.disableAfterMarketClearsDate) {
      return earliestDate;
    }

    const disableAfterMarketClearsDate = parseIsoDateStringToDateCorrectTz(takerMarket.disableAfterMarketClearsDate);

    if (
      !earliestDate ||
      disableAfterMarketClearsDate.getTime() < parseIsoDateStringToDateCorrectTz(earliestDate).getTime()
    ) {
      return takerMarket.disableAfterMarketClearsDate;
    }

    return earliestDate;
  }, '');

  if (!takerMarket && soonestEndDateString === '') {
    return null;
  }

  const termEndDate = takerMarket?.disableAfterMarketClearsDate
    ? takerMarket.disableAfterMarketClearsDate
    : soonestEndDateString;

  const formattedTermEndDate = asDateString(trimDateTimestamp(termEndDate), {
    month: 'long',
    year: 'numeric',
  });

  const description = isMarketDetail
    ? t('preferredRenewal.banner.body.marketDetail', { formattedTermEndDate })
    : takerMarketsNearTermEnd.length > 1
    ? t('preferredRenewal.banner.body.multipleOffers')
    : t('preferredRenewal.banner.body.singleOffer', { formattedTermEndDate });

  return (
    <div className="mb-6">
      <Alert
        title={t('preferredRenewal.banner.title')}
        description={description}
        type="error"
        variant="outlined"
        full
        action={{
          text: t('preferredRenewal.banner.action'),
          onClick: () => setOpenModal(true),
        }}
      />
      <RenewalRequestModal open={openModal} onClose={() => setOpenModal(false)} />
    </div>
  );
};

export default PreferredAlertBanner;

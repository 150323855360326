import { useTranslation } from 'react-i18next';
import useLocaleFormat from '@/utils/useLocaleFormat';

export interface HistoryStatsBarProps {
  clearedInvoiceAmount: number | undefined;
  clearedEarn: number | undefined;
  clearedDiscountWeightedAvg: number | undefined | null;
  currency?: string;
}

export default function HistoryStatsBar({
  clearedInvoiceAmount = 0,
  clearedEarn = 0,
  clearedDiscountWeightedAvg,
  currency = 'USD',
}: HistoryStatsBarProps) {
  const { asCurrency, asPercent } = useLocaleFormat();
  const { t } = useTranslation();
  return (
    <div>
      <section className="flex flex-col sm:flex-row">
        <article className="flex-1 border-r py-10">
          <dl className="flex flex-col items-center justify-center">
            <dt className="text-sm">{t('core.awardedAR')}</dt>
            <dd className="text-2xl font-bold">{asCurrency(clearedInvoiceAmount, currency)}</dd>
          </dl>
        </article>
        <article className="flex-1 border-r py-10">
          <dl className="flex flex-col items-center justify-center">
            <dt className="text-sm">{t('core.totalDiscount')}</dt>
            <dd className="text-2xl font-bold text-green-500">{asCurrency(clearedEarn, currency)}</dd>
          </dl>
        </article>
        <article className="flex-1 py-10">
          <dl className="flex flex-col items-center justify-center">
            <dt className="text-sm">{t('core.averageDiscount')}</dt>
            <dd className="text-2xl font-bold text-green-500">
              {clearedDiscountWeightedAvg ? asPercent({ value: clearedDiscountWeightedAvg / 100, decimals: 2 }) : '-'}
            </dd>
          </dl>
        </article>
      </section>
    </div>
  );
}

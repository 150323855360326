import { useMemo } from 'react';
import { Checkbox } from '@c2fo/liquidity';

export interface ChecklistFilterProps {
  options: { label: string; value: string }[];
  selected: string[] | undefined;
  onChange: (selected: string[]) => void;
}
export default function ChecklistFilter({ options, selected = [], onChange }: ChecklistFilterProps) {
  const selectedMap = useMemo(
    () =>
      selected.reduce<{ [key: string]: boolean }>((acc, item) => {
        acc[item] = true;
        return acc;
      }, {}),
    [selected]
  );
  return (
    <div className="flex flex-col space-y-3 text-sm">
      {options
        .sort((a, b) => a.label.localeCompare(b.label))
        .map(({ label, value }) => (
          <Checkbox
            key={value}
            color="primary"
            id={label}
            label={label}
            value={value}
            checked={!!selectedMap[value]}
            onChange={(e) => {
              onChange(
                [...new Set([...selected, e.target.value])].filter((v) => (v === value ? e.target.checked : true))
              );
            }}
          />
        ))}
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CaretDownFilledIcon } from '@c2fo/liquidity/icons';
import useUserDetails from '@/data/useUserDetails';
import { useAgreementsUrl } from '@/features/agreements/utils/useAgreementsUrl';
import { useReporting } from '@/reporting';
import { TrackEventNames } from '@/reporting/trackEventNames';
import logout from '@/utils/logout';
import QueryBoundaries from '../QueryBoundaries';

const prefixUrl = window.location.origin;

export const useUserName = () => {
  const { data } = useUserDetails();
  return `${data?.me?.personalInfo?.firstName} ${data?.me?.personalInfo?.lastName}`;
};

interface UserMenuItem {
  name: string;
  label: string;
  href?: string;
  onClick?: () => void;
  track: TrackEventNames;
}

const UserMenuComponent = ({ itemsOnly = false, onClose }: { itemsOnly?: boolean; onClose?: () => void }) => {
  const { t } = useTranslation();
  const name = useUserName();
  const { track } = useReporting();
  const { privacyLink } = useAgreementsUrl();

  const items: UserMenuItem[] = [
    {
      name: 'settings',
      label: t('core.accountSettings'),
      href: `${prefixUrl}/early-pay/account/settings`,
      track: 'user-menu::account-settings::clicked',
    },
    {
      name: 'privacy',
      label: t('core.privacyPolicy'),
      href: privacyLink,
      track: 'user-menu::privacy-policy::clicked',
    },
    { name: 'logout', label: t('core.logout'), onClick: () => logout(), track: 'user-menu::logout::clicked' },
  ];

  return itemsOnly ? (
    <>
      {items
        ?.filter((item) => item.name !== 'logout')
        .map((item) => (
          <div key={item.name} className="relative flex w-full border-b last:border-b-0">
            <a
              className="flex grow justify-between gap-2 border-l-4 border-l-transparent px-4 py-3 font-normal text-text-primary transition-all duration-200 hover:bg-lightBlue-50"
              href={item.href!}
              onClick={() => {
                track(item.track);
                if (onClose) {
                  onClose();
                }
              }}
              rel="noreferrer"
              target="_blank"
              type="button"
            >
              {item.label}
            </a>
          </div>
        ))}
    </>
  ) : (
    <DropdownMenu>
      <DropdownMenuTrigger aria-label={t('core.userMenu')}>
        <div className="flex h-9 items-center space-x-1 whitespace-normal">
          <span>{name}</span>
          <CaretDownFilledIcon className="hidden h-5 w-5 sm:block" fill={colors.secondary[500]} aria-hidden="true" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {items.map((item) => {
          return item?.href ? (
            <DropdownMenuItem key={item.name} asChild onClick={() => track(item.track)}>
              <a href={item.href} rel="noreferrer" target="_blank">
                {item.label}
              </a>
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem
              key={item.name}
              onClick={async () => {
                // Logging out will delete user auth token, so ensure we have fired track event before logging out
                item.name === 'logout' ? await track(item.track) : track(item.track);
                item.onClick && item.onClick();
              }}
            >
              {item.label}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const UserMenu = ({ itemsOnly = false, onClose }: { itemsOnly?: boolean; onClose?: () => void }) => (
  <QueryBoundaries>
    <UserMenuComponent itemsOnly={itemsOnly} onClose={onClose} />
  </QueryBoundaries>
);

export default UserMenu;

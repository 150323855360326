import { useTranslation } from 'react-i18next';
import cloudDownload from '@/assets/clouddownload.svg?url';
import Modal from '@/components/Modal';
import { useToken } from '@/utils/token';

export interface RequestCreditNoteModalProps {
  open: boolean;
  onClose: () => void;
}

const RequestCreditNoteModal = ({ open, onClose }: RequestCreditNoteModalProps) => {
  const { t } = useTranslation();
  const { tokenContent } = useToken();
  const email = tokenContent?.payload.user.emailAddress;

  return (
    <Modal
      onCancel={onClose}
      cancelText={t('core.close')}
      onClose={onClose}
      open={open}
      title={t('maker.requestArchivePendingHeader')}
    >
      <div className="flex w-full justify-center">
        <img src={cloudDownload} alt="download" className="h-16 w-16" />
      </div>
      <div>{t('maker.requestArchivePendingText', { email })}</div>
    </Modal>
  );
};

export default RequestCreditNoteModal;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, useSnackbar } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CheckIcon, TimesIcon } from '@c2fo/liquidity/icons';
import Spinner from '@/components/Spinner';
import { useIsAgreementRequired } from '@/data/useAgreements';
import { TakerMarket } from '@/data/useTakerMarkets';
import useToggleOffer from '@/data/useToggleOffer';
import { useReporting } from '@/reporting';
import useReadOnlyUser from '@/utils/useReadOnlyUser';
import NameYourRateSetOffer from './SetOffer';

const NameYourRateParticipationToggle = ({
  disabled = false,
  takerMarket,
}: {
  disabled?: boolean;
  takerMarket: TakerMarket;
}) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const readOnlyUser = useReadOnlyUser();
  const { mutateAsync: toggleOffer, isLoading: toggleOfferIsLoading } = useToggleOffer();
  const isAgreementRequired = useIsAgreementRequired([takerMarket.marketUuid]);
  const [isReviewOfferModalOpen, setIsReviewOfferModalOpen] = useState(false);

  const isDisabledDueToClosedMarket = !takerMarket.offerConfig.isEnabled && takerMarket.marketIsEnabled === false;
  const toggleDisabled = disabled || readOnlyUser || toggleOfferIsLoading || isDisabledDueToClosedMarket;

  const onClick = async () => {
    // When a market has custom terms, the user must always go through the submit flow and accept them.
    if (isAgreementRequired && !takerMarket.offerConfig.isEnabled) {
      return setIsReviewOfferModalOpen(true);
    }

    const submitValues = {
      marketId: takerMarket.offerConfig.marketId,
      marketUuid: takerMarket.offerConfig.marketUuid,
      offerConfig: {
        ...takerMarket.offerConfig,
        isEnabled: !takerMarket.offerConfig.isEnabled,
        // in some cases, a taker config can exist with null values set for both maxApr and maxDiscount
        // when this is the case, we want to set either maxApr = 0 or maxDiscount = 0 (when discount bidding)
        // to allow for participation to be toggled
        ...(!takerMarket.offerConfig.maxApr &&
          !takerMarket.offerConfig.maxDiscount && {
            ...(takerMarket.offerConfig.isDiscountBidding ? { maxDiscount: 0 } : { maxApr: 0 }),
          }),
      },
      supplierDivisionUuid: takerMarket.takerDivisionUuid,
      takerId: takerMarket.offerConfig.divisionId,
    };

    return await toggleOffer(submitValues, {
      onSuccess: () => {
        showSnackbar({ message: t('offerSubmitDialog.participationUpdated') });
        track('participation::submitted', submitValues);
        setIsReviewOfferModalOpen(false);
      },
      onError: () => showSnackbar({ message: t('offerSubmitDialog.participationError') }),
    });
  };

  return (
    <>
      <NameYourRateSetOffer
        open={isReviewOfferModalOpen}
        onClose={() => setIsReviewOfferModalOpen(false)}
        takerMarket={takerMarket}
        showReviewStep
      />
      <Switch
        accessibilityLabel={t('core.toggleParticipation')}
        checked={takerMarket.offerConfig.isEnabled ?? false}
        disabled={toggleDisabled}
        onCheckedChange={onClick}
        size="large"
        uncheckedColor="error-500"
      >
        {(checked) =>
          checked ? (
            toggleOfferIsLoading ? (
              <Spinner className="fill-primary-500 " />
            ) : (
              <CheckIcon fill={colors.success[500]} className="h-full w-full p-1" />
            )
          ) : toggleOfferIsLoading ? (
            <Spinner className="fill-error-500" />
          ) : (
            <TimesIcon fill={colors.error[500]} className="h-full w-full p-1" />
          )
        }
      </Switch>
    </>
  );
};

export default NameYourRateParticipationToggle;

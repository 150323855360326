import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelectedCurrency } from '@/components/CurrencySelect';
import QueryBoundaries from '@/components/QueryBoundaries';
import Skeleton from '@/components/Skeleton';
import useTakerHistory from '@/data/useTakerHistory';
import useTakerHistoryStats from '@/data/useTakerHistoryStats';
import useTakerMarkets from '@/data/useTakerMarkets';
import History from '@/features/history/History';
import RangeHeader from '@/features/history/RangeHeader';
import { dateRanges } from '@/features/history/utils';
import { useReporting } from '@/reporting';
import { toUniqueListBy } from '@/utils/common';
import useParams from '@/utils/useParams';

function ConsolidatedHistoryComponent() {
  const { track } = useReporting();
  const currency = useSelectedCurrency();
  const [searchParams, setParams] = useParams();

  const params = useMemo(() => {
    const { last30Days: range } = dateRanges;
    return {
      startDate: searchParams.get('startDate') ?? range.startDate,
      endDate: searchParams.get('endDate') ?? range.endDate,
      sort: searchParams.get('sort') ?? 'payDate',
      limit: Number(searchParams.get('limit') ?? 25),
      page: Number(searchParams.get('page') ?? 1),
      makerIds: searchParams.getAll('makerIds'),
    };
  }, [searchParams]);

  const { data: tableData = [], isFetching: fetchingTableData } = useTakerHistory({
    ...params,
    currency,
    pendingClear: false,
  });

  const { data: pendingHistory, isFetching: fetchingPendingHistory } = useTakerHistory({
    ...params,
    currency,
    pendingClear: true,
  });

  const rangeHasClearHistory = (tableData?.length ?? 0) > 0 || (pendingHistory?.length ?? 0) > 0;

  const { data: chartData = [] } = useTakerHistory(
    {
      currency,
      ...params,
    },
    { enabled: rangeHasClearHistory }
  );

  const { data: stats } = useTakerHistoryStats(
    {
      currency,
      startDate: params.startDate,
      endDate: params.endDate,
      makerIds: params.makerIds,
    },
    { enabled: rangeHasClearHistory }
  );

  const { data: customers = [] } = useTakerMarkets((data) =>
    toUniqueListBy(
      [
        ...new Set(
          data.map(({ makerDivisionId, makerDivisionName }) => ({
            value: `${makerDivisionId}`,
            label: makerDivisionName,
          }))
        ),
      ],
      'label'
    )
  );

  return (
    <div className="rounded-md bg-white shadow-md">
      <RangeHeader
        currency={currency}
        customers={customers}
        endDate={params.endDate}
        selectedCustomers={params.makerIds}
        startDate={params.startDate}
        isDownloadDisabled={!stats?.clearedInvoiceAmount}
      />
      <History
        currency={currency}
        startDate={params.startDate}
        endDate={params.endDate}
        onDateRangeChange={(startDate, endDate) => {
          setParams({ startDate, endDate });
        }}
        sort={params.sort}
        onSortChange={(sort) => {
          setParams({ sort });
          track('history-sort::clicked', { key: sort });
        }}
        page={params.page}
        onPageChange={(page) => {
          setParams({ page: `${page ?? 1}` });
          track('history-page::clicked');
        }}
        limit={params.limit}
        onLimitChange={(limit) => {
          setParams({ limit: `${limit ?? 100}` });
          track('history-page-size::clicked', { size: limit });
        }}
        chartData={chartData}
        awardedData={tableData}
        awardedDataFetching={fetchingTableData}
        pendingData={pendingHistory}
        pendingDataFetching={fetchingPendingHistory}
        stats={stats}
        customers={customers}
        selectedCustomers={params.makerIds}
        onCustomersChange={(makerIds) => setParams({ makerIds })}
        historyType="consolidated"
      />
    </div>
  );
}

export default function ConsolidatedHistory() {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="mt-0 text-4xl">{t('taker.consolidatedHistory.general')}</h1>
      <QueryBoundaries LoadingComponent={() => <Skeleton />}>
        <ConsolidatedHistoryComponent />
      </QueryBoundaries>
    </>
  );
}

import { IntercomDataTargetNames } from './intercomDataTargetNames';

/**
 * a div component that wraps children with a data-intercom-target attribute
 */

interface IntercomDataTargetProps {
  children: React.ReactNode;
  target: IntercomDataTargetNames;
  className?: string;
}

const IntercomDataTarget = ({ children, className, target }: IntercomDataTargetProps) => (
  <div data-intercom-target={target} {...(className && { className })}>
    {children}
  </div>
);

export default IntercomDataTarget;

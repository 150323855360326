import { useQuery } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import {
  BenchmarkPricing,
  EarlyPaySupplierStatsDeepFilter,
  GetTakerMarketsQuery,
  OfferRateUnion,
  PriceDiscoveryPricing,
  Pricing,
  StaticPricing,
} from '@/generated/gql/graphql';
import { gqlClient } from '@/lib/gqlClient';
import { Flatten } from '@/types';
import useExchangeRate from './useExchangeRate';
import { ExchangeRate } from './useExchangeRates';

export interface OfferConfig {
  id?: number | null;
  divisionId: number;
  exclusionSettings: {
    excludeNewInvoices?: boolean | null;
  };
  isReferenceRateBidding?: boolean | null;
  isDiscountBidding?: boolean | null;
  isEnabled?: boolean;
  marketId: string;
  marketUuid: string;
  maxApr?: number | null;
  maxDiscount?: number | null;
  offerType?: string | null;
  type?: string | null;
}

type RateInfo = OfferRateUnion | null | undefined;

type BenchmarkPriceType = Pick<BenchmarkPricing, 'type' | 'rateName' | 'spread' | '__typename'>;
type StaticPriceType = Pick<StaticPricing, 'type' | 'apr' | '__typename'>;
type PriceDiscoveryPriceType = Pick<PriceDiscoveryPricing, 'type' | '__typename'>;
type PriceTypeUnion = BenchmarkPriceType | StaticPriceType | PriceDiscoveryPriceType;

export interface TakerMarket {
  id: number;
  acceptedDpeWeightedSum: number;
  acceptedEarn: number;
  acceptedInvoiceAmount: number;
  acceptedInvoiceCount: number;
  configExclusionSettings?: {
    excludeNewInvoices?: boolean;
  };
  configId?: number | null;
  configIsDiscountBidding?: boolean;
  configIsEnabled?: boolean;
  configMaxApr?: number | null;
  configMaxDiscount?: number | null;
  currency: string;
  disableAfterMarketClearsDate?: string | null;
  eligibleDpeWeightedAvg: number;
  eligibleDpeWeightedSum: number;
  eligibleInvoiceAmount: number;
  eligibleInvoiceAmountUsd: number;
  eligibleInvoiceCount: number;
  isDiscountMarket: boolean;
  isTpf?: boolean;
  lastClearDate?: string;
  legacyMarketId: number;
  makerDivisionName: string;
  makerDivisionId: number;
  makerOrganizationName: string;
  makerOrganizationUuid: string;
  marketId: string;
  marketIsEnabled: boolean;
  marketMaxApr?: number | null;
  marketMaxDiscount?: number | null;
  marketNextClearTime: string;
  marketPayDate: string;
  marketPricingType?: Pricing | null;
  marketFeatures: string[];
  staticMarketRate?: number | null;
  marketType?: string;
  marketUuid: string;
  notAcceptedDpeWeightedAvg: number;
  notAcceptedDpeWeightedSum: number;
  notAcceptedInvoiceAmount: number;
  notAcceptedInvoiceCount: number;
  offerConfig: OfferConfig;
  rateInfo?: RateInfo;
  takerDivisionId: number;
  takerDivisionUuid: string;
  takerDivisionName: string;
  takerExcludedInvoiceAmount: number;
  takerExcludedInvoiceCount: number;
  takerMakerId: string;
  userAddedToDivision?: string;
}

export type ShimTakerMarket = Flatten<GetTakerMarketsQuery['earlyPayUserSupplierStats']['nodes'][0]>;

const GET_TAKER_MARKETS = graphql(`
  query GetTakerMarkets($filter: EarlyPaySupplierStatsDeepFilter!) {
    earlyPayUserSupplierStats(filter: $filter, paging: { limit: 1000 }) {
      nodes {
        id
        accepted {
          dpeWeightedAvg
          dpeWeightedSum
          earn
          invoiceAmount
          invoiceCount
        }
        eligible {
          dpeWeightedAvg
          dpeWeightedSum
          invoiceAmount
          invoiceCount
        }
        divisionUsers {
          created
        }
        lastClearDate
        market {
          id
          buyerDivision {
            id
            uuid
            name
            organization {
              name
              uuid
            }
          }
          currency
          isDiscountOnly
          isEnabled
          marketType
          maxApr
          maxDiscount
          nextClearTime
          payDate
          productType
          uuid
          features {
            feature
          }
          pricing {
            ... on BenchmarkPricing {
              __typename
              type
              rateName
              spread
              c2foSpread
            }
            ... on StaticPricing {
              __typename
              type
              apr
            }
            ... on PriceDiscoveryPricing {
              __typename
              type
            }
          }
        }
        notAccepted {
          dpeWeightedAvg
          dpeWeightedSum
          invoiceAmount
          invoiceCount
        }
        offer {
          id
          exclusionSettings {
            excludeNewInvoices
          }
          isDiscountBidding
          isReferenceRateBidding
          isEnabled
          maxApr
          maxDiscount
          disableAfterMarketClearsDate
          offerType
          rateInfo {
            ... on BenchmarkRateInfo {
              __typename
              estimatedRate
              referenceRate
              rateName
              totalSpread
            }
            ... on PreferredRateInfo {
              rateSourceName
              referenceRate
              spread
              totalRate
            }
          }
        }
        offerTypes {
          ... on BenchmarkOffer {
            __typename
            rateInfo {
              estimatedRate
              rateName
              referenceRate
              totalSpread
            }
          }
          ... on StaticOffer {
            __typename
            type
            apr
          }
          ... on PriceDiscoveryOffer {
            __typename
            type
            options
            referenceRateStats {
              rateName
            }
          }
        }
        supplierBuyerId
        supplierDivision {
          id
          name
          uuid
        }
        supplierExcluded {
          invoiceAmount
          invoiceCount
        }
      }
    }
  }
`);

/**
 * pull rate information from the offer or offerTypes and/or market
 * depending on if the market is currently in offer or not
 */
const getTakerMarketRateInfo = (takerMarket: ShimTakerMarket): RateInfo => {
  let rateInfo: RateInfo = null;

  if (takerMarket.offer && takerMarket.offer?.rateInfo) {
    rateInfo = takerMarket.offer.rateInfo;
  } else if (takerMarket.offerTypes?.__typename === 'BenchmarkOffer' && takerMarket.offerTypes?.rateInfo) {
    rateInfo = takerMarket.offerTypes.rateInfo;
  } else if (
    takerMarket.offerTypes?.__typename === 'PriceDiscoveryOffer' &&
    takerMarket.offerTypes.options.includes('PREFERRED')
  ) {
    rateInfo = {
      rateSourceName: takerMarket.offerTypes.referenceRateStats?.rateName ?? '',
      referenceRate: 0,
      totalRate: 0,
    };
  }

  // Always defer to the market pricing defined rate and spread
  if (takerMarket.market?.pricing?.type === 'BENCHMARK') {
    const pricing = takerMarket.market?.pricing as BenchmarkPricing | undefined;
    if (pricing) {
      rateInfo = {
        ...rateInfo,
        ...{
          rateName: pricing.rateName,
          // Some cash pools have a normal spread and a c2foSpread
          // Need to add cash pool spread plus c2fo spread into total spread
          totalSpread: pricing.spread + (pricing?.c2foSpread ?? 0),
        },
      } as RateInfo;
    }
  }

  return rateInfo;
};

const getMarketPricingInformation = (marketPricing: PriceTypeUnion) => {
  if (marketPricing.__typename === 'StaticPricing') {
    return marketPricing.apr;
  }

  return null;
};

/**
 * maps list of taker markets from the shim to the object shape the frontend expects
 */
const formatTakersMarketsOfferConfigShim = (
  takerMarkets: ShimTakerMarket[],
  usdExchangeRates?: ExchangeRate
): TakerMarket[] =>
  takerMarkets.map((takerMarket) => {
    let exchangeRate = usdExchangeRates?.[takerMarket.market!.currency] ?? 1;

    if (exchangeRate === 0) {
      exchangeRate = 1;
    }

    const takerMarketWithOfferConfig: TakerMarket & { lastFetchedAt: number } = {
      id: takerMarket.id,
      acceptedDpeWeightedSum: takerMarket.accepted.dpeWeightedSum,
      acceptedEarn: takerMarket.accepted.earn,
      acceptedInvoiceAmount: takerMarket.accepted.invoiceAmount,
      acceptedInvoiceCount: takerMarket.accepted.invoiceCount,
      currency: takerMarket.market!.currency,
      disableAfterMarketClearsDate: takerMarket.offer?.disableAfterMarketClearsDate,
      eligibleDpeWeightedAvg: takerMarket.eligible.dpeWeightedAvg,
      eligibleDpeWeightedSum: takerMarket.eligible.dpeWeightedSum,
      eligibleInvoiceAmount: takerMarket.eligible.invoiceAmount,
      eligibleInvoiceAmountUsd: takerMarket.eligible.invoiceAmount / exchangeRate,
      eligibleInvoiceCount: takerMarket.eligible.invoiceCount,
      isDiscountMarket: takerMarket.market!.isDiscountOnly,
      legacyMarketId: takerMarket.market!.id,
      makerDivisionId: takerMarket.market!.buyerDivision!.id,
      makerDivisionName: takerMarket.market!.buyerDivision!.name,
      makerOrganizationName: takerMarket.market!.buyerDivision!.organization.name,
      makerOrganizationUuid: takerMarket.market!.buyerDivision!.organization.uuid,
      marketFeatures: takerMarket.market?.features.map((feature) => feature.feature) ?? [],
      marketMaxApr: takerMarket.market!.maxApr,
      marketMaxDiscount: takerMarket.market!.maxDiscount,
      marketId: takerMarket.market!.uuid,
      marketIsEnabled: takerMarket.market!.isEnabled,
      marketNextClearTime: takerMarket.market!.nextClearTime,
      marketPayDate: takerMarket.market!.payDate,
      marketPricingType: takerMarket.market?.pricing?.type,
      staticMarketRate: takerMarket.market?.pricing && getMarketPricingInformation(takerMarket.market?.pricing),
      marketType: takerMarket.market?.marketType,
      marketUuid: takerMarket.market!.uuid,
      notAcceptedDpeWeightedAvg: takerMarket.notAccepted.dpeWeightedAvg,
      notAcceptedDpeWeightedSum: takerMarket.notAccepted.dpeWeightedSum,
      notAcceptedInvoiceAmount: takerMarket.notAccepted.invoiceAmount,
      notAcceptedInvoiceCount: takerMarket.notAccepted.invoiceCount,
      offerConfig: {
        id: takerMarket.offer?.id,
        isEnabled: takerMarket.offer?.isEnabled,
        maxApr: takerMarket.offer?.maxApr,
        maxDiscount: takerMarket.offer?.maxDiscount,
        divisionId: takerMarket.supplierDivision!.id,
        isReferenceRateBidding: takerMarket.offer?.isReferenceRateBidding,
        isDiscountBidding: takerMarket.offer?.isDiscountBidding,
        exclusionSettings: takerMarket.offer?.exclusionSettings ? takerMarket.offer.exclusionSettings : {},
        marketId: takerMarket.market!.uuid,
        marketUuid: takerMarket.market!.uuid,
        type: takerMarket.market?.pricing?.type,
        offerType: takerMarket.offer?.offerType,
      },
      rateInfo: getTakerMarketRateInfo(takerMarket),
      takerDivisionId: takerMarket.supplierDivision!.id,
      takerDivisionUuid: takerMarket.supplierDivision!.uuid,
      takerDivisionName: takerMarket.supplierDivision!.name,
      takerExcludedInvoiceAmount: takerMarket.supplierExcluded.invoiceAmount,
      takerExcludedInvoiceCount: takerMarket.supplierExcluded.invoiceCount,
      takerMakerId: takerMarket.supplierBuyerId,
      userAddedToDivision: takerMarket.divisionUsers?.[0]?.created,
      lastFetchedAt: Date.now(),
    };

    return takerMarketWithOfferConfig;
  });

const DEFAULT_TAKER_MARKETS_FILTER: EarlyPaySupplierStatsDeepFilter = { market: { isTpf: { is: false } } };

interface FetchTakerMarketsShimOptions {
  filter?: EarlyPaySupplierStatsDeepFilter;
}

export const fetchTakerMarketsShim = async (
  usdExchangeRates?: ExchangeRate,
  options?: FetchTakerMarketsShimOptions
): Promise<TakerMarket[]> => {
  const filter = {
    ...options?.filter,
    ...DEFAULT_TAKER_MARKETS_FILTER,
  };

  const data = await gqlClient.request(GET_TAKER_MARKETS, { filter });
  return data.earlyPayUserSupplierStats.nodes
    ? formatTakersMarketsOfferConfigShim(data.earlyPayUserSupplierStats.nodes, usdExchangeRates)
    : [];
};

const useTakerMarkets = <TData = TakerMarket[]>(select?: (data: TakerMarket[]) => TData) => {
  const usdRates = useExchangeRate('USD').data;

  return useQuery({
    queryKey: ['taker-markets'],
    queryFn: () => fetchTakerMarketsShim(usdRates),
    select,
  });
};

export default useTakerMarkets;

import { useMutation } from '@tanstack/react-query';
import qs from 'qs';
import apiClient from '@/lib/apiClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents/useServerSideEventListeners';

interface FilterRange {
  from?: string | number;
  to?: string | number;
}

export enum InvoiceEditOperation {
  EXCLUDE = 'EXCLUDE',
  INCLUDE = 'INCLUDE',
}

export interface CalculatedStatsFilter {
  operation: InvoiceEditOperation;
  all?: boolean;
  companyId?: string;
  amount?: FilterRange;
  amountSearch?: FilterRange;
  daysPaidEarly?: FilterRange;
  daysPaidEarlySearch?: FilterRange;
  earlyPayDate?: FilterRange;
  earlyPaymentSent?: FilterRange;
  invoiceStatusSearch?: string;
  originalDueDate?: FilterRange;
  voucherIds?: string[];
  voucherSearchText?: string;
}

interface CalculatedStatsBody {
  filters: CalculatedStatsFilter[];
  takersMarkets: { marketId: number; takerId: number }[];
}

interface UpdateCalculatedInvoicesParams extends CalculatedStatsBody {
  currency: string;
  makerOrganizationUuid: string;
  takersMarkets: { marketId: number; marketUuid: string; takerId: number }[];
  toCurrency?: string;
}

const useUpdateCalculatedInvoices = () => {
  const { subscribeToMarketStats } = useServerSideEventListeners();

  return useMutation({
    mutationKey: ['updateCalculatedInvoices'],
    mutationFn: ({
      currency,
      filters,
      makerOrganizationUuid,
      takersMarkets,
      toCurrency,
    }: UpdateCalculatedInvoicesParams) => {
      // currency is the currency of makerOrg/takersMarkets
      // toCurrency is the currency converting to
      // if toCurrency isn't passed, no currency conversion is considered
      const params = qs.stringify({
        currency,
        ...(toCurrency && { toCurrency }),
      });

      return apiClient.post(
        `api/c2fo/taker/maker/${makerOrganizationUuid}/invoices/update-calculated-invoices?${params}`,
        {
          json: {
            filters,
            takersMarkets: takersMarkets.map(({ marketId, takerId }) => ({ marketId, takerId })),
          } satisfies CalculatedStatsBody,
        }
      );
    },
    onSuccess: (_, variables) => {
      variables.takersMarkets.forEach(({ marketUuid, takerId }) => {
        subscribeToMarketStats({
          marketUuid,
          takerId,
        });
      });
    },
  });
};

export default useUpdateCalculatedInvoices;
